/* eslint-disable no-param-reassign */

import moment from 'moment';

import * as ACTIONS from '../actionTypes';

import { getStaffWorkDay, getStaffAppointments } from '../../api';

const appointmentScheduleModule = {
    namespaced: true,

    state: {
        appointmentList: [],
        fetching: false,
        error: null,
        forDate: moment(),
        workStartTime: null,
        workEndTime: null,
    },

    mutations: {
        [ACTIONS.APPOINTMENT_SCHEDULE_SET_DATE](state, payload) {
            state.forDate = payload.date;
        },
        [ACTIONS.APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_START](state) {
            state.fetching = true;
            state.error = null;
        },
        [ACTIONS.APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_DONE](state, payload) {
            state.fetching = !payload.fetched;
            state.workStartTime = payload.startTime;
            state.workEndTime = payload.endTime;
        },
        [ACTIONS.APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_ERROR](state, payload) {
            state.fetching = false;
            state.error = payload.error;
        },
        [ACTIONS.APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_START](state) {
            state.fetching = true;
            state.error = null;
        },
        [ACTIONS.APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_DONE](state, payload) {
            state.fetching = false;
            state.appointmentList = payload.data;
        },
        [ACTIONS.APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_ERROR](state, payload) {
            state.fetching = false;
            state.error = payload.error;
        },
    },

    actions: {
        setScheduleDate({ commit }, date) {
            commit(ACTIONS.APPOINTMENT_SCHEDULE_SET_DATE, { date });
        },

        async fetchSchedule({ commit, state }, params) {
            commit(ACTIONS.APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_START);
            try {
                const { data } = await getStaffWorkDay({
                    clinicId: params.clinicId,
                    staffUserId: params.staffUserId,
                    workDay: params.date,
                });
                if (!data.length) {
                    commit(ACTIONS.APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_DONE, {
                        fetched: true,
                        startTime: null,
                        endTime: null,
                    });
                    return;
                }

                const [beforeBreak, afterBreak] = data;
                const startTime = moment(beforeBreak.timeStart, 'HH:mm');
                const endTime = moment(
                    afterBreak
                        ? afterBreak.timeEnd
                        : beforeBreak.timeEnd,
                    'HH:mm',
                );

                commit(ACTIONS.APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_DONE, {
                    fetched: false,
                    startTime,
                    endTime,
                });
            } catch (error) {
                commit(ACTIONS.APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_ERROR, {
                    error,
                });
            }

            commit(ACTIONS.APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_START);
            try {
                const { data } = await getStaffAppointments({
                    staffUserId: params.staffUserId,
                    dayFrom: params.date,
                    dayTo: params.date,
                    stateCodes: params.stateCodes,
                });

                const transformed = data.map((appointment) => {
                    const positionTop = moment.duration(
                        moment(appointment.timeStart, 'HH:mm').diff(
                            state.workStartTime,
                        ),
                    ).asMinutes();
                    const blockHeight = moment.duration(
                        moment(appointment.timeEnd, 'HH:mm').diff(
                            moment(appointment.timeStart, 'HH:mm'),
                        ),
                    ).asMinutes();
                    return {
                        id: appointment.id,
                        patientId: appointment.patientId,
                        previewFileId: appointment.previewFileId,
                        patientShortName: appointment.patientShortName,
                        timeStart: appointment.timeStart,
                        timeEnd: appointment.timeEnd,
                        stateCode: appointment.stateCode,
                        stateName: appointment.stateName,
                        protocolId: appointment.appointmentProtocolId,
                        isRemote: appointment.isRemote,
                        remoteUrl: appointment.remoteUrl,
                        positionTop,
                        blockHeight,
                    };
                });

                commit(ACTIONS.APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_DONE, {
                    data: transformed,
                });
            } catch (error) {
                commit(ACTIONS.APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_ERROR, {
                    error,
                });
            }
        },
    },
};

export default appointmentScheduleModule;
