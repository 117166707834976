<template>
    <div class="recommendation-notification">
        <a-switch
            :checked="!!valueModel.required"
            :disabled="valueModel.isDone"
            @change="(e) => valueModel.required = e"
        />

        <span>
            Добавить задание администратору
            оповестить пациента о рекомендациях
        </span>

        <div v-if="valueModel.required">
            <div class="recommendation-notification__comment">
                <div>
                    Комментарий:
                </div>

                <a-input
                    v-model:value="valueModel.comment"
                    :disabled="valueModel.isDone"
                    class="recommendation-notification__comment__input"
                />
            </div>
            <div class="recommendation-notification__alarm">
                <div>
                    Оповестить до:
                </div>

                <a-date-picker
                    v-model:value="valueModel.dueDate"
                    :disabled="valueModel.isDone"
                    :disabled-date="(current) => current < $moment().startOf('day')"
                    format="DD.MM.YYYY"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    emits: ['change'],
    data() {
        return {
            valueModel: null,
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (!isEqual(value, this.valueModel)) {
                    this.valueModel = cloneDeep(value);
                }
            },
        },
        valueModel: {
            deep: true,
            handler(value) {
                this.$emit('change', value);
            },
        },
    },
};
</script>

<style lang="scss">
.recommendation-notification {
    padding-top: 10px;
}
.recommendation-notification__comment,
.recommendation-notification__alarm
 {
    display: flex;
    align-items: center;
    margin-top: 10px;
    & > div {
        min-width: 150px;
    }
    flex-grow: 1;
}
</style>
