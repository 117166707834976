<template>
    <div class="anamneses">
        <div class="anamneses-section">
            <h4>ДЕТСКИЕ ЗАБОЛЕВАНИЯ</h4>
            <p>
                <diagnoses-picker
                    v-model="patientData.childDiagnoses"
                    :options="childDiagnoses"
                />
            </p>
        </div>
        <div
            v-if="!isMalePatient"
            class="anamneses-section"
        >
            <h4>ГИНЕКОЛОГИЧЕСКИЙ (АКУШЕРСКИЙ) АНАМНЕЗ</h4>
            <br>
            <h4>МЕНСТУРАЛЬНАЯ ФУНКЦИЯ</h4>
            <p>
                <menstruation
                    v-model="patientData.menstruation"
                    :pain="statuses ? statuses.pain : []"
                    :bleeding="statuses ? statuses.bleeding : []"
                />
            </p>
        </div>
        <div
            v-if="!isMalePatient"
            class="anamneses-section"
        >
            <h4>СВЕДЕНИЯ О БЕРЕМЕННОСТИ И РОДАХ</h4>
            <p>
                <childering
                    v-model="patientData.childering"
                    :assistance="statuses ? statuses.assistance : []"
                    :child="statuses ? statuses.child : []"
                    :sterility="statuses ? statuses.sterility : []"
                />
            </p>
        </div>
        <div
            v-if="!isMalePatient"
            class="anamneses-section"
        >
            <h4>СВЕДЕНИЯ О ХРАНИЧЕСКИХ ГИНЕКОЛОГИЧЕСКИХ ЗАБОЛЕВАНИЯХ</h4>
            <p>
                <diagnoses-picker
                    v-model="patientData.gyneDiagnoses"
                    :options="gyneDiagnoses"
                />
            </p>
        </div>
        <div
            class="anamneses-section"
        >
            <h4>ОНКОЛОГИЯ</h4>
            <p>
                <diagnoses-tree-picker
                    ref="diagnosesTreePicker"
                    title=""
                    :patient-diagnoses="patientData.oncologyDiagnoses"
                    :diagnoses="diagnoses"
                    :header="['Диагноз', 'Начало', 'Завершение']"
                    :fields="['startByMonth', 'endByMonth']"
                    :required="['name', 'timeStart']"
                    @diagnosis-changed="diagnosisChange"
                    @diagnosis-added="diagnosisAdd"
                    @diagnosis-removed="diagnosisRemove"
                />
            </p>
        </div>
        <div
            class="anamneses-section last"
        >
            <p>
                <span>Гинекологические операции</span>
                <a-textarea
                    v-model:value="patientData.gyneOperations"
                    style="min-height: 120px"
                />
            </p>
        </div>
    </div>
</template>

<script>
import DiagnosesPicker from './DiagnosesPicker.vue';
import Childering from './Childering.vue';
import Menstruation from './Menstruation.vue';
import DiagnosesTreePicker from './DiagnosesTreePicker.vue';
import { useEmitter } from '../../utils';

export default {
    components: {
        DiagnosesPicker,
        Childering,
        Menstruation,
        DiagnosesTreePicker,
    },
    props: {
        patientId: {
            type: [Number, String],
            required: true,
        },
    },
    // NOTE: object emits is not supported in vue3-webcomponent-wrapper
    emits: [
        'datachange',
        'domupdate',
        'on-data-loaded',
        'destroy',
        'auth-lost',
    ],

    setup(props, { emit }) {
        const emitter = useEmitter();
        if (emitter) {
            emitter.on('auth-lost', () => emit('auth-lost'));
        }
    },

    data() {
        return {
            patientResponse: null,
            patientGender: null,
            patientData: {
                oncologyDiagnoses: [],
                childDiagnoses: [],
                gyneDiagnoses: [],
                gyneOperations: null,
                menstruation: {
                    startedAt: null,
                    duration: null,
                    pain: null,
                    bleeding: null,
                    sexLifeStartedAt: null,
                    sexIsRegular: null,
                },
                childering: {
                    canceledCount: 0,
                    throwed: [], // array of {week: number, reason: string}
                    stopped: [], // array of {week: number, reason: string}
                    successed: [], // array of {week: number, assistance: string, status: string}
                    sterility: null,
                },
            },
            statuses: null,
            diagnoses: [],
            isLoading: true,
            isLoadingFailed: false,
        };
    },
    computed: {
        isMalePatient() {
            if (!this.patientGender) {
                return true;
            }
            return this.patientGender === 'Мужской';
        },
        childDiagnoses() {
            return this.diagnoses
                .filter((d) => d.childDiagnosis).map((d) => ({ label: d.name, value: d.id }));
        },
        gyneDiagnoses() {
            return this.diagnoses
                .filter((d) => d.gyneDiagnosis).map((d) => ({ label: d.name, value: d.id }));
        },
    },
    watch: {
        patientData: {
            deep: true,
            immediate: true,
            handler() {
                this.$emit('datachange', { ...this.patientResponse, history: this.patientData });
            },
        },
        'patientData.menstruation.startedAt': function w1(val) {
            this.validateMinMaxVal(val, this.patientData.menstruation, 'startedAt');
        },
        'patientData.menstruation.duration': function w2(val) {
            this.validateMinMaxVal(val, this.patientData.menstruation, 'duration');
        },
        'patientData.menstruation.sexLifeStartedAt': function w3(val) {
            this.validateMinMaxVal(val, this.patientData.menstruation, 'sexLifeStartedAt');
        },
        'patientData.childering.canceledCount': function w4(val) {
            this.validateMinMaxVal(val, this.patientData.childering, 'canceledCount');
        },
        'patientData.childering.successed': function w5(val) {
            if (!val) {
                return;
            }
            val.forEach((entry, i) => {
                this.validateMinMaxVal(entry.week, this.patientData.childering, 'successed', i);
            });
        },
        'patientData.childering.throwed': function w6(val) {
            if (!val) {
                return;
            }
            val.forEach((entry, i) => {
                this.validateMinMaxVal(entry.week, this.patientData.childering, 'throwed', i);
            });
        },
        'patientData.childering.stopped': function w7(val) {
            if (!val) {
                return;
            }
            val.forEach((entry, i) => {
                this.validateMinMaxVal(entry.week, this.patientData.childering, 'stopped', i);
            });
        },
    },
    async created() {
        try {
            this.patientResponse = (await this.$http.get(`patient/card/${this.patientId}`)).data;
            const oncologyDiagnoses = this.patientResponse.history.oncologyDiagnoses.map((d) => ({
                ...d,
                timeStart: d.timeStart ? this.$moment(d.timeStart) : null,
                timeEnd: d.timeEnd ? this.$moment(d.timeEnd) : null,
            }));
            this.patientData = { ...this.patientResponse.history, oncologyDiagnoses };
            if (!this.patientData.menstruation) {
                this.patientData.menstruation = {
                    startedAt: null,
                    duration: null,
                    pain: null,
                    bleeding: null,
                    sexLifeStartedAt: null,
                    sexIsRegular: null,
                };
            }
            this.patientData.menstruation
                .sexIsRegular = !!this.patientData.menstruation.sexIsRegular;
            this.statuses = (await this.$http.get('/refs/anameses')).data;
            this.diagnoses = (await this.$http.get('diagnoses')).data;
            this.patientGender = (await this.$http.get(`patient/${this.patientId}/health-passport`)).data.patientGender;
            this.$emit('on-data-loaded', this.patientGender);
        } catch (err) {
            this.isLoadingFailed = true;
        }
        this.isLoading = false;
    },
    updated() {
        this.$emit('domupdate');
    },
    beforeUnmount() {
        this.$emit('destroy');
    },
    methods: {
        diagnosisChange(idx, key, value) {
            if (key === 'deleted') {
                this.diagnosisRemove(idx);
                return;
            }
            this.patientData.oncologyDiagnoses[idx][key] = value;
        },
        diagnosisAdd(value) {
            this.patientData.oncologyDiagnoses.push(value);
        },
        diagnosisRemove(idx) {
            this.patientData.oncologyDiagnoses.splice(idx, 1);
        },
        cancelEditing() {
            this.$refs.diagnosesTreePicker.editing = false;
            this.patientData.oncologyDiagnoses = this.patientData
                .oncologyDiagnoses.map((diagnosis) => ({
                    ...diagnosis,
                    editing: null,
                    creating: null,
                }));
        },
        validateMinMaxVal(val, obj, target, idx) {
            const parsedNum = Number.parseInt(val, 10);
            const isValid = !Number.isNaN(parsedNum) && parsedNum >= 0;
            if (val === null) {
                return;
            }
            if (val !== '' && isValid && val < 99) {
                return;
            }
            if (idx !== undefined) {
                // eslint-disable-next-line no-param-reassign
                obj[target][idx].week = 0;
                return;
            }
            // eslint-disable-next-line no-param-reassign
            obj[target] = 0;
        },
    },
};
</script>

<style lang="scss">

    .anamneses {
        max-width: 95vw;
        margin-left: 30px;
        &-section {
            padding-top: 20px;
        }
        h4 {
            font-weight: bold;
        }
        p {
            margin-left: 10px;
        }
    }
    .anamneses-section.last {
        padding-bottom: 20px;
    }
</style>
