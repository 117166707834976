<template>
    <a-table
        :columns="clinicSurveyColumns"
        :data-source="surveyLabData"
        :row-class-name="setRowClassName"
        :custom-row="customRow"
        class="survey-table"
        row-key="id"
    >
        <template #expandedRowRender="{ record }">
            <a-table
                v-if="record.surveys"
                :columns="innerSurveyColumns"
                :data-source="record.surveys"
                :pagination="false"
            >
                <template #userCreate="{ record: subRecord }">
                    <span>
                        <a-tag color="blue">
                            {{ subRecord.userCreate
                                ? subRecord.userCreate
                                : (subRecord.autoTransferred ? 'Автоматически' : null)
                            }}
                        </a-tag>
                    </span>
                </template>
            </a-table>
        </template>
        <template #userCreate="{ record }">
            <span>
                <a-tag
                    v-if="record.userCreate"
                    color="blue"
                >
                    {{ record.userCreate }}
                </a-tag>
            </span>
        </template>
        <template #userEdit="{ record }">
            <span>
                <a-tag
                    v-if="record.userEdit"
                    color="blue"
                >
                    {{ record.userEdit }}
                </a-tag>
            </span>
        </template>
    </a-table>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        patientId: {
            type: Number,
            required: true,
        },
        surveyBaseUrl: {
            type: String,
            required: true,
        },
    },

    emits: [
        'ready-loaded',
    ],

    data() {
        return {
            clinicSurveyColumns: [{
                title: 'Исследование',
                width: 300,
                key: 'surveyType',
                dataIndex: 'surveyType',
            }, {
                title: 'Дата проведения',
                width: 100,
                key: 'dateSurveyRu',
                dataIndex: 'dateSurveyRu',
            }, {
                title: 'Создал',
                width: 200,
                key: 'userCreate',
                dataIndex: 'userCreate',
                slots: { customRender: 'userCreate' },
            }, {
                title: 'Изменил',
                width: 200,
                key: 'userEdit',
                dataIndex: 'userEdit',
                slots: { customRender: 'userEdit' },
            }],
            innerSurveyColumns: [
                { title: 'Название исследования', dataIndex: 'surveyType' },
                { title: 'Дата создания', dataIndex: 'curDateRu' },
                { title: 'Создал', dataIndex: 'userCreate', slots: { customRender: 'userCreate' } },
            ],
            surveyLabData: [],
        };
    },

    watch: {
        patientId(val) {
            this.getSurveys(val);
        },
    },
    mounted() {
        this.getSurveys(this.patientId);
    },
    methods: {
        getSurveys(id) {
            this.$http.get('/patient/surveys/labs', {
                params: {
                    patientId: id,
                    page: JSON.stringify([0, 50]),
                },
            }).then((response) => {
                this.surveyLabData = response.data.data;
                this.$emit('ready-loaded', true);
            });
        },

        setRowClassName(record) {
            return !record.surveys ? 'hidden-expander' : '';
        },

        customRow(record) {
            return {
                onDblclick: (e) => {
                    if (e.target.classList.contains('ant-table-row-expand-icon')) {
                        e.preventDefault();
                        return;
                    }
                    this.$router.push({ path: `${this.surveyBaseUrl}${record.id}` });
                },
            };
        },
    },
});
</script>

<style lang="scss" scoped>
div.survey-table {
    width: calc(100vw - 335px);
}
</style>
