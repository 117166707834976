import axios from 'axios';

export type ErrorResponse = {
    msg: string;
} | string;

export const catchAxiosError: (
    err: unknown,
    defaultErrorMessage: string,
) => string = (err, msg) => {
    if (!axios.isAxiosError(err) || !err.response) {
        return msg;
    }
    if (typeof err.response.data === 'string') {
        return err.response.data;
    }
    return err.response.data.msg;
};
