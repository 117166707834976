import { AxiosRequestConfig } from 'axios';
import { IIntakeDependancy, IIntakeForms, IMedicationUnit } from '../../../life-impulse-tools/types';
import { get } from '../../utils/axiosConnectionTimeout';

export interface RefItem {
    id: number;
    name: string;
}

export interface ISchemeRef extends RefItem {
   biomaterialTakingIsNecessary: number,
}

export const getFunds = () => get<RefItem[]>('refs/funds');

export const getIntakeForms = () => get<IIntakeForms[]>('/refs/intake/forms/');

export const getIntakeDependency = async () => get<IIntakeDependancy[]>('/refs/intake/dependency/');

export const getMedicationUnits = (
    options?: AxiosRequestConfig,
) => get<IMedicationUnit[]>('/clinic/medication/units', options);

export const getBiomaterials = (
    options?: AxiosRequestConfig,
) => get<RefItem[]>('/refs/biomaterial', options);

export const getProductionSchemes = (
    options?: AxiosRequestConfig,
) => get<ISchemeRef[]>('/refs/production-schemes', options);

export const getAppSettings = () => get('/app/settings');

export const getMedSpecialties = () => get<RefItem[]>('/refs/med/specialty');

export const getUsers = (
    options?: AxiosRequestConfig,
) => get<RefItem[]>('/users', options);
