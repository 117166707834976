import { keyBy, flatten } from 'lodash';
import { IMedication, IGoodEntity } from '../types';

export interface IMedicationWithGoodsMap extends IMedication {
    goods?: IGoodEntity[],
    prices?: {
        min: number,
        max: number,
    },
}

export function createSubtypesMap(medications: IMedication[]) {
    return keyBy(
        flatten(
            medications
                .filter(({ subtypes }) => subtypes)
                .map(({ subtypes }) => subtypes),
        ),
        'id',
    );
}

export function createGoodsMap(goods: IGoodEntity[]) {
    return keyBy(goods, 'id');
}

export function createMedicationsMap(medications: IMedication[]) {
    return keyBy(medications, 'id');
}

export function createMedicationWithGoodsMap(
    medications: IMedication[],
    goodList?: IGoodEntity[],
) {
    if (!goodList || !goodList.length || !medications.length) {
        return undefined;
    }
    const medicationsMap = keyBy<IMedicationWithGoodsMap>(medications, 'id');
    goodList.forEach((good) => {
        if (!good.clinicMedications) {
            return;
        }

        good.clinicMedications.forEach((medication) => {
            if (!medicationsMap[medication.id]) {
                return;
            }
            let { goods, prices } = medicationsMap[medication.id];
            if (!goods || !prices) {
                medicationsMap[medication.id].goods = [];
                medicationsMap[medication.id].prices = {
                    min: Infinity,
                    max: -Infinity,
                };
            }
            ({ goods, prices } = medicationsMap[medication.id]);
            goods!.push(good);
            prices!.min = good.price.value < prices!.min ? good.price.value : prices!.min;
            prices!.max = good.price.value > prices!.max ? good.price.value : prices!.max;
        });
    });
    return medicationsMap;
}
