<template>
    <div>
        <a-form
            ref="form"
            :model="formState"
            :rules="formRules"
        >
            <div class="form">
                <a-form-item
                    label="Клиника"
                    name="clinicId"
                >
                    <clinic-combobox
                        :value="formState.clinicId"
                        :disabled="readOnly"
                        @change="(newValue) => { formState.clinicId = newValue; }"
                    />
                </a-form-item>
                <a-form-item
                    label="Дата забора"
                    name="dateMaterial"
                >
                    <a-date-picker
                        v-model:value="formState.dateMaterial"
                        :disabled="readOnly || !!surveyId"
                        format="DD.MM.YYYY"
                    />
                </a-form-item>
                <a-form-item
                    label="Дата проведения"
                    name="dateSurvey"
                >
                    <a-date-picker
                        v-model:value="formState.dateSurvey"
                        :disabled="readOnly"
                        format="DD.MM.YYYY"
                    />
                </a-form-item>
                <a-form-item
                    label="Оборудование"
                    name="equipments"
                >
                    <lab-equipment
                        :value="formState.equipments"
                        :disabled="readOnly"
                        @change="(newValue) => { formState.equipments = newValue; }"
                    />
                </a-form-item>
                <a-form-item
                    label="Статус"
                    name="surveyStatus"
                    style="margin-right: 0px"
                >
                    <survey-status
                        :value="formState.surveyStatus"
                        :disabled="readOnly"
                        @change="(newValue) => { formState.surveyStatus = newValue; }"
                    />
                </a-form-item>
            </div>
            <div class="form-end">
                <a-upload
                    :action="'/api/patient/'+patientId+'/survey/file/'"
                    :file-list="fileList"
                    @change="onUploadChange"
                >
                    <a-button :disabled="readOnly">
                        <upload-outlined /> Добавить файл
                    </a-button>
                </a-upload>
                <a-form-item
                    label="Заключение"
                    name="comment"
                >
                    <a-textarea
                        v-model:value="formState.comment"
                        :rows="4"
                        :disabled="readOnly"
                    />
                </a-form-item>
            </div>
            <div
                v-for="blank in blanks"
                :key="blank.id"
                class="blank"
            >
                <h2>{{ blank.caption || blank.name }}</h2>
                <survey-blank
                    :data="blank"
                    :editable="!readOnly"
                    :survey-blank-id="blank.surveyBlankId"
                    :blanks-in-edit="blanksInEdit"
                />
            </div>
        </a-form>
    </div>
</template>

<script>
import UploadOutlined from '@ant-design/icons-vue/UploadOutlined';
import { defineComponent } from 'vue';
import ClinicCombobox from '@/components/comboboxes/Clinic.vue';
import LabEquipment from '@/components/comboboxes/LabEquipment.vue';
import SurveyStatus from '@/components/comboboxes/SurveyStatus.vue';
import SurveyBlank from '@/components/patientcard/SurveyBlank.vue';

export default defineComponent({
    components: {
        ClinicCombobox,
        LabEquipment,
        SurveyStatus,
        SurveyBlank,
        UploadOutlined,
    },
    props: {
        surveyId: {
            type: Number,
            default: null,
        },
        blankIds: {
            type: Array,
            default: () => [],
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        defaults: {
            type: Object,
            default: () => {},
        },
        blanksInEdit: {
            type: Boolean,
            default: false,
        },
    },

    emits: {
        'survey-data-load': null,
    },

    data() {
        return {
            blanks: [],
            formState: {
                clinicId: null,
                dateMaterial: null,
                dateSurvey: null,
                equipments: [],
                surveyStatus: null,
                comment: null,
            },
            formRules: {
                clinicId: [{ required: true, message: 'Обязательное поле' }],
                dateMaterial: [{ required: true, message: 'Обязательное поле' }],
                dateSurvey: [{ required: true, message: 'Обязательное поле' }],
                surveyStatus: [{ required: true, message: 'Обязательное поле' }],
            },
            fileList: [],
            patientId: null,
        };
    },

    watch: {
        surveyId(val) {
            this.getSurveyData(val);
        },
    },
    created() {
        if (this.surveyId) {
            this.getSurveyData(this.surveyId);
        } else if (this.blankIds && this.blankIds.length > 0) {
            this.getBlanks(this.blankIds);
        }

        if (this.defaults.clinicId) {
            this.formState.clinicId = this.defaults.clinicId;
        }
        if (this.defaults.dateMaterial) {
            this.formState.dateMaterial = this.$moment(
                this.defaults.dateMaterial,
                'YYYY-MM-DD',
            );
        }
        if (typeof this.defaults.surveyStatus !== 'undefined') {
            this.formState.surveyStatus = this.defaults.surveyStatus;
        }
        if (this.defaults.patientId) {
            this.patientId = this.defaults.patientId;
        }
    },
    methods: {
        getSurveyData(id) {
            this.$http.get(`/patient/survey/labs/${id}`).then((response) => {
                this.blanks = response.data.blanks;
                this.formState.clinicId = response.data.clinicId;
                if (response.data.dateTake) {
                    this.formState.dateMaterial = this.$moment(
                        response.data.dateTake,
                        'YYYY-MM-DD',
                    );
                }
                if (response.data.dateSurvey) {
                    this.formState.dateSurvey = this.$moment(
                        response.data.dateSurvey,
                        'YYYY-MM-DD',
                    );
                }
                this.formState.equipments = response.data.equipment;
                this.fileList = response.data.files.map((f) => ({
                    uid: f.id,
                    name: f.filename,
                    url: `/api/patient/survey/file/${f.id}`,
                    status: 'done',
                }));
                this.formState.comment = response.data.comment;
                this.formState.surveyStatus = response.data.stateId;
                this.patientId = response.data.patientId;
                this.$emit('survey-data-load', {
                    patientName: response.data.patientName,
                    patientId: response.data.patientId,
                    surveyType: response.data.surveyType,
                });
            });
        },
        getBlanks(ids) {
            this.$http.get('survey/labs/blanks', {
                params: {
                    ids: JSON.stringify(ids),
                    indicators: 1,
                },
            }).then((response) => {
                this.blanks = response.data;
            });
        },

        edit() {
            this.blanks.forEach((b) => {
                // eslint-disable-next-line no-param-reassign
                b.editInProgress = false;
            });
        },
        onUploadChange(info) {
            this.fileList = info.fileList;
        },
    },
});
</script>

<style lang="scss">
@import "@/styles/page.scss";
.main.page {
    .ant-form-item-label {
        text-align: left;
        line-height: 20px;
    }
    .form {
        display: grid;
        grid-template-columns: 16% 17% 16% 17% 17% 17%;
        .ant-form-item {
            margin-right: 10px;
            display: flex;
            flex-direction: column;
        }
        .ant-calendar-picker {
            width: 100%;
        }
    }
    .form-end {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 170px auto;

        .ant-upload {
            margin-top: 10px;
        }
        .ant-btn-default {
            margin-bottom: 0;
        }
        & > .ant-form-item {
            & .ant-form-item-label {
                height: 65px;
            }
            display: flex;
            flex-direction: column;
        }
    }
    .blank {
        margin-top: 70px;
        h2 {
            text-align: left;
        }
    }
}
</style>
