<template>
    <a-modal
        :visible="true"
        :mask-closable="false"
        :width="800"
        @cancel="close"
    >
        <template #title>
            {{ windowTitle }}
        </template>
        <description-history-fetcher
            :patient-id="patientId"
            :field-name="fieldName"
        >
            <template #default="{ isLoading, isLoadingFailed, history }">
                <div>
                    <fetch-status
                        :is-loading="isLoading"
                        :is-loading-failed="isLoadingFailed"
                        :is-empty="!history.length"
                    />
                    <description-history
                        v-if="history.length"
                        :history="history"
                        :with-paste="withPaste"
                        @copy-to-clipboard="copyToClipboard"
                        @paste="paste"
                    />
                </div>
            </template>
        </description-history-fetcher>
        <template #footer>
            <a-button
                type="primary"
                @click="close"
            >
                Закрыть
            </a-button>
        </template>
    </a-modal>
</template>

<script>
import { defineComponent } from 'vue';
import { showErrorNotification, showInfoNotification } from '../../utils';

import FetchStatus from './FetchStatus.vue';
import DescriptionHistory from './DescriptionHistory.vue';
import DescriptionHistoryFetcher from './DescriptionHistoryFetcher.vue';

export default defineComponent({
    components: {
        FetchStatus,
        DescriptionHistory,
        DescriptionHistoryFetcher,
    },

    props: {
        patientId: {
            type: Number,
            required: true,
        },
        fieldName: {
            type: String,
            required: true,
        },
        withPaste: {
            type: Boolean,
            default: true,
        },
    },
    emits: {
        close: null,
        paste: null,
    },
    computed: {
        windowTitle() {
            if (this.fieldName === 'complaints') {
                return 'История жалоб';
            } if (this.fieldName === 'externalEvidence') {
                return 'История объективных данных';
            }
            return 'История рекомендаций';
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },

        async copyToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                showInfoNotification('Скопировано!');
            } catch (err) {
                showErrorNotification();
            }
        },

        paste(text) {
            this.$emit('paste', text);
        },
    },
});
</script>
