<template class="main-events-timeline">
    <a-spin
        v-if="isLoading"
        spinning
        :indicator="loadingIndicator"
        tip="Загрузка..."
    />

    <a-empty
        v-else-if="!historyData.length"
        :image="emptyImage"
    >
        <template #description>
            История лечения отсутствует.
        </template>
    </a-empty>

    <a-tree
        v-else
        v-model:selectedKeys="historySelectedKeys"
        :tree-data="historyData"
        show-icon
        class="events-timeline"
        @select="onHistoryItemSelect"
    >
        <template #title="item">
            <span :data-test-id="`treatment-entry-${item.pos}`">
                <profile-two-tone
                    v-if="getEventDescription(item.dataRef.entityName.toUpperCase())
                        .icon === 'profile'"
                    :two-tone-color="getEventDescription(
                        item.dataRef.entityName.toUpperCase()
                    ).color"
                    style="font-size: 16px;"
                />
                <medicine-box-two-tone
                    v-if="getEventDescription(item.dataRef.entityName.toUpperCase())
                        .icon === 'medicine-box'"
                    :two-tone-color="getEventDescription(
                        item.dataRef.entityName.toUpperCase()
                    ).color"
                    style="font-size: 16px;"
                />
                <experiment-two-tone
                    v-if="getEventDescription(item.dataRef.entityName.toUpperCase())
                        .icon === 'experiment'"
                    :two-tone-color="getEventDescription(
                        item.dataRef.entityName.toUpperCase()
                    ).color"
                    style="font-size: 16px;"
                />
                <phone-two-tone
                    v-if="getEventDescription(item.dataRef.entityName.toUpperCase())
                        .icon === 'phone'"
                    :two-tone-color="getEventDescription(
                        item.dataRef.entityName.toUpperCase()
                    ).color"
                    style="font-size: 16px;"
                />
            </span>&nbsp;
            <template v-if="!item.isChild">
                {{ item.eventDate
                    ? $moment(item.eventDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
                    : 'Дата отсутствует'
                }}
                -
                <span v-if="!patientId">
                    {{ item.patientName }}.
                </span>
                {{ item.eventName }}
            </template>
            <strong v-if="item.eventInfo">
                "{{ item.eventInfo }}"
            </strong>
        </template>
    </a-tree>
</template>

<script>
import { createVNode } from 'vue';
import axios from 'axios';
import ProfileTwoTone from '@ant-design/icons-vue/ProfileTwoTone';
import MedicineBoxTwoTone from '@ant-design/icons-vue/MedicineBoxTwoTone';
import ExperimentTwoTone from '@ant-design/icons-vue/ExperimentTwoTone';
import PhoneTwoTone from '@ant-design/icons-vue/PhoneTwoTone';
import {
    Empty as AEmpty,
} from 'ant-design-vue';
import LoadingSpinner from '@/utils/Spinner.vue';
import { showErrorNotification } from '@/utils';

export default {
    components: {
        AEmpty,
        ProfileTwoTone,
        MedicineBoxTwoTone,
        ExperimentTwoTone,
        PhoneTwoTone,
    },
    props: {
        patientId: {
            type: Number,
            default: null,
        },
        onlyStaffPatients: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            historyRequestAbortSource: undefined,
            historyData: [],
            emptyImage: AEmpty.PRESENTED_IMAGE_SIMPLE,
            loadingIndicator: createVNode(LoadingSpinner),
        };
    },
    computed: {
        historySelectedKeys: {
            // disable tree nodes selection
            get() {
                return [];
            },
            set() {},
        },
    },
    watch: {
        patientId(val) {
            this.getTreatmentHistory(val);
        },
        onlyStaffPatients() {
            this.getTreatmentHistory(this.patientId);
        },
    },
    activated() {
        this.getTreatmentHistory(this.patientId);
    },
    mounted() {
        this.getTreatmentHistory(this.patientId);
    },
    methods: {
        getTreatmentHistory(id) {
            this.isLoading = true;
            this.historyData = [];

            if (this.historyRequestAbortSource) {
                this.historyRequestAbortSource.cancel();
            }
            // TODO: update axios to ^0.22.0 and replace token with AbortController
            this.historyRequestAbortSource = axios.CancelToken.source();

            const params = {
                doctorInChargeId: this.onlyStaffPatients ? localStorage.staffId : null,
            };
            if (id) {
                params.patientId = id;
            } else {
                params.dateTo = this.$moment().format('YYYY-MM-DD');
                params.dateFrom = this.$moment().add(-7, 'days').format('YYYY-MM-DD');
            }
            axios.get('/patient/treatment/history', {
                params,
                cancelToken: this.historyRequestAbortSource.token,
            }).then((response) => {
                this.historyData = this.prepareHistoryData(response.data);
                this.isLoading = false;
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    return;
                }
                showErrorNotification('Не удалось загрузить историю лечения.');
                this.isLoading = false;
            });
        },

        getEventDescription(entityName) {
            switch (entityName) {
            case 'SURVEYQUIZ': return {
                icon: 'profile',
                color: '#ff5118',
                path: '/poll/',
                key: 'poll',
            };
            case 'APPOINTMENTPROTOCOL': return {
                icon: 'medicine-box',
                color: '#1890ff',
                path: '/reception/',
                key: 'reception',
            };
            case 'SURVEYLABS': return {
                icon: 'experiment',
                color: '#52c41a',
                path: '/survey/',
                key: 'survey',
            };
            case 'PATIENTTASK': return {
                icon: 'phone',
                color: '#1890ff',
                path: '/reception/',
                key: 'patient-task',
            };
            default: return {
                icon: 'profile',
                color: '#ff5118',
                path: '',
                key: entityName,
            };
            }
        },

        onHistoryItemSelect(selectedKeys, { selectedNodes }) {
            if (!selectedNodes.length) {
                return;
            }
            const url = selectedNodes[0].props
                ? selectedNodes[0].props.url
                : null;
            if (!url) {
                return;
            }
            this.$router.push({
                path: url,
            });
        },

        prepareHistoryData(data) {
            const chargedData = data.map((rec) => ({
                ...rec,
                key: this.getEventDescription(rec.entityName.toUpperCase()).key + rec.id,
                url: this.getEventDescription(rec.entityName.toUpperCase()).path + rec.id,
            }));
            const recordsMap = chargedData.reduce((acc, rec) => {
                acc[rec.id] = rec;
                return acc;
            }, {});

            const surveysByDate = [];
            let dateGroupId = 1;
            chargedData.forEach((rec) => {
                if (rec.parentEventId) {
                    const parent = recordsMap[rec.parentEventId];
                    if (!parent.children) {
                        parent.children = [];
                    }
                    parent.children.push(rec);
                    // eslint-disable-next-line no-param-reassign
                    rec.isChild = true;
                    return;
                }
                if (rec.entityName !== 'surveyLabs') {
                    return;
                }

                const key = this.patientId
                    ? rec.eventDate
                    : `${rec.eventDate}${rec.patientName}`;
                if (!recordsMap[key]) {
                    const dateRecord = {
                        entityName: 'surveyLabs',
                        eventDate: rec.eventDate,
                        eventInfo: '',
                        eventName: rec.eventName,
                        id: `dateGroup${dateGroupId}`,
                        key: `dateGroup${dateGroupId}`,
                        parentEventId: undefined,
                        url: undefined,
                        patientName: rec.patientName,
                        children: [],
                    };
                    dateGroupId += 1;
                    surveysByDate.push(dateRecord);
                    recordsMap[key] = dateRecord;
                } else {
                    recordsMap[key].eventName = 'Введён результат исследований';
                }
                // eslint-disable-next-line no-param-reassign
                rec.parentEventId = recordsMap[key].id;
                recordsMap[key].children.push(rec);
                // eslint-disable-next-line no-param-reassign
                rec.isChild = true;
            });
            const resultData = chargedData
                .filter((rec) => !rec.parentEventId)
                .concat(surveysByDate)
                .sort((a, b) => (b.eventDate || Date()).localeCompare(a.eventDate));
            return resultData;
        },
    },
};
</script>

<style lang="scss">
.main-events-timeline {
    .ant-tree {
        background-color: #fff;
        overflow: auto;
        height: 100%;
    }
    .ant-spin-spinning {
        margin-top: 10px;
        width: 100%;
    }
    .ant-spin-container {
        height: 100%;
    }
}
.events-timeline .ant-tree li {
    white-space: inherit;
    span.ant-tree-iconEle {
        width: 16px;
    }
    .ant-tree-title {
        margin-left: 4px;
    }
}
.events-timeline .ant-tree li .ant-tree-node-content-wrapper {
    height: auto;
    display: flex;
}
.events-timeline {
    .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
        float: left;
    }
    .ant-tree li span.ant-tree-switcher {
        float: left;
    }
}
</style>
