import { v4 as uuidv4 } from 'uuid';

export default class Publisher {
    constructor(connection) {
        this.connection = connection;
    }

    publish(topic, type, body) {
        this.connection.publish(
            topic,
            JSON.stringify({
                type,
                requestGUID: uuidv4(),
                body,
            }),
        );
    }
}
