<template>
    <div class="protocol-text-description">
        <div>
            Жалобы:
            <a-tooltip>
                <template #title>
                    <template v-if="copyPreviousIsLoading">
                        Загружаются жалобы из предыдущих протоколов...
                    </template>
                    <template v-else-if="copyPreviousIsDisabled('complaints')">
                        Жалобы из предыдущих протоколов недоступны.
                    </template>
                    <template v-else>
                        Копировать жалобы из предыдущего протокола.
                    </template>
                </template>

                <a-button
                    size="small"
                    :loading="copyPreviousIsLoading"
                    :disabled="copyPreviousIsDisabled('complaints')"
                    @click="onCopyPreviousClick('complaints')"
                >
                    <template #icon>
                        <select-outlined />
                    </template>
                </a-button>
            </a-tooltip>

            <a-tooltip v-if="patientId">
                <template #title>
                    Открыть историю жалоб
                </template>

                <a-button
                    size="small"
                    @click="descriptionHistoryFieldName = 'complaints'"
                >
                    <template #icon>
                        <ellipsis-outlined />
                    </template>
                </a-button>
            </a-tooltip>
        </div>

        <a-textarea
            :value="complaints"
            :rows="4"
            :auto-size="{ minRows: 4 }"
            data-test-id="complaints-input"
            @change="(e) => onDescriptionChange('complaints', e.target.value)"
        />

        <div>
            Объективные данные:
            <a-tooltip>
                <template #title>
                    <template v-if="copyPreviousIsLoading">
                        Загружаются объективные данные из предыдущих протоколов...
                    </template>
                    <template v-else-if="copyPreviousIsDisabled('externalEvidence')">
                        Объективные данные из предыдущих протоколов недоступны.
                    </template>
                    <template v-else>
                        Копировать объективные данные из предыдущего протокола.
                    </template>
                </template>

                <a-button
                    size="small"
                    :loading="copyPreviousIsLoading"
                    :disabled="copyPreviousIsDisabled('externalEvidence')"
                    @click="onCopyPreviousClick('externalEvidence')"
                >
                    <template #icon>
                        <select-outlined />
                    </template>
                </a-button>
            </a-tooltip>

            <a-tooltip v-if="patientId">
                <template #title>
                    Открыть историю объективных данных
                </template>

                <a-button
                    size="small"
                    @click="descriptionHistoryFieldName = 'externalEvidence'"
                >
                    <template #icon>
                        <ellipsis-outlined />
                    </template>
                </a-button>
            </a-tooltip>

            <a-button
                size="small"
                @click="showMetricsWindow = true"
            >
                Добавить физиологические данные
                <template #icon>
                    <ellipsis-outlined />
                </template>
            </a-button>
        </div>

        <a-textarea
            :value="externalEvidence"
            :rows="4"
            :auto-size="{ minRows: 4 }"
            data-test-id="external-evidence-input"
            @change="(e) => onDescriptionChange('externalEvidence', e.target.value)"
        />

        <div>
            Рекомендации:
            <a-tooltip>
                <template #title>
                    <template v-if="copyPreviousIsLoading">
                        Загружаются рекомендации из предыдущих протоколов...
                    </template>
                    <template v-else-if="copyPreviousIsDisabled('recommendations')">
                        Рекомендации из предыдущих протоколов недоступны.
                    </template>
                    <template v-else>
                        Копировать рекомендации из предыдущего протокола.
                    </template>
                </template>

                <a-button
                    size="small"
                    :loading="copyPreviousIsLoading"
                    :disabled="copyPreviousIsDisabled('recommendations')"
                    @click="onCopyPreviousClick('recommendations')"
                >
                    <template #icon>
                        <select-outlined />
                    </template>
                </a-button>
            </a-tooltip>

            <a-tooltip v-if="patientId">
                <template #title>
                    Открыть историю рекомендаций
                </template>

                <a-button
                    size="small"
                    @click="descriptionHistoryFieldName = 'recommendations'"
                >
                    <template #icon>
                        <ellipsis-outlined />
                    </template>
                </a-button>
            </a-tooltip>
        </div>

        <a-textarea
            :value="recommendations"
            :rows="4"
            :auto-size="{ minRows: 4 }"
            data-test-id="recommendations-input"
            @change="(e) => onDescriptionChange('recommendations', e.target.value)"
        />

        <recommendation-notification
            v-if="hasRecommendations()
                || recommendationNotification.isDone"
            :value="recommendationNotification"
            @change="(e) => $emit('recommendation-notification-change', e)"
        />

        <description-history-window
            v-if="descriptionHistoryFieldName"
            :patient-id="patientId"
            :field-name="descriptionHistoryFieldName"
            @close="descriptionHistoryFieldName = null"
            @paste="onDescriptionPaste"
        />

        <physical-metrics-window
            :patient-id="patientId"
            :visible="showMetricsWindow"
            @close="showMetricsWindow = false"
        />
    </div>
</template>

<script>
import EllipsisOutlined from '@ant-design/icons-vue/EllipsisOutlined';
import SelectOutlined from '@ant-design/icons-vue/SelectOutlined';

import DescriptionHistoryWindow from 'vue-web-components/src/components/descriptionhistory/DescriptionHistoryWindow.vue';
import RecommendationNotification from '@/components/reception/RecommendationNotification.vue';
import PhysicalMetricsWindow from '@/components/reception/PhysicalMetricsWindow.vue';

export default {
    components: {
        DescriptionHistoryWindow,
        PhysicalMetricsWindow,
        RecommendationNotification,
        EllipsisOutlined,
        SelectOutlined,
    },

    props: {
        recommendationNotification: {
            type: Object,
            required: true,
        },

        patientId: {
            type: Number,
            default: null,
        },

        complaints: {
            type: String,
            default: null,
        },

        externalEvidence: {
            type: String,
            default: null,
        },

        recommendations: {
            type: String,
            default: null,
        },
    },
    emits: [
        'text-description-change',
        'recommendation-notification-change',
    ],
    data() {
        return {
            descriptionHistoryFieldName: null,
            showMetricsWindow: false,
            history: [],
            historyIsLoading: false,
            historyLoadingIsFailed: false,
        };
    },

    computed: {
        copyPreviousIsLoading() {
            return this.historyIsLoading;
        },
    },

    watch: {
        patientId: {
            immediate: true,
            handler(newPatientId) {
                if (!newPatientId) {
                    this.history = [];
                    return;
                }
                this.fetchProtocolDescriptionHistory(newPatientId);
            },
        },
    },

    methods: {
        getPreviousValue(field) {
            const withValue = this.history.filter(
                (record) => (record[field] || '').trim(),
            );
            return withValue.length
                ? withValue[withValue.length - 1][field].trim()
                : null;
        },

        copyPreviousIsDisabled(field) {
            return this.historyIsLoading
                || this.historyLoadingIsFailed
                || !this.getPreviousValue(field);
        },

        onCopyPreviousClick(field) {
            this.onDescriptionChange(
                field,
                this.getPreviousValue(field),
            );
        },

        async fetchProtocolDescriptionHistory(patientId) {
            this.historyIsLoading = true;
            this.historyLoadingIsFailed = false;
            try {
                this.history = (await this.$http.get(
                    `patient/${patientId}/protocol/history`,
                )).data;
            } catch {
                this.historyLoadingIsFailed = true;
            } finally {
                this.historyIsLoading = false;
            }
        },

        hasRecommendations() {
            return !!(this.recommendations && this.recommendations.trim());
        },

        onDescriptionPaste(text) {
            const fieldName = this.descriptionHistoryFieldName;
            if (!fieldName) {
                return;
            }

            let currentText = this[fieldName];
            currentText = currentText ? `${currentText}\n` : '';
            this.onDescriptionChange(fieldName, `${currentText}${text}`);
        },

        onDescriptionChange(target, value) {
            if (target === 'recommendations'
                && !value.trim()
                && this.recommendationNotification.required
                && !this.recommendationNotification.isDone
            ) {
                this.$emit('recommendation-notification-change', {
                    ...this.recommendationNotification,
                    required: false,
                    comment: null,
                    dueDate: null,
                });
            }
            this.$emit('text-description-change', target, value);
        },
    },
};
</script>

<style lang="scss">
.protocol-text-description {
    .ant-btn {
        margin-left: 10px;
        margin-top: 6px;
    }
    & > div {
        margin: 10px;
    }
}
</style>
