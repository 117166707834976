<template>
    <div>
        <div class="doc-block">
            <span>
                Врач:
            </span>
            <a-select
                v-model:value="selectedDoctor"
                :default-value="0"
            >
                <a-select-option :value="0">
                    Все
                </a-select-option>
                <a-select-option
                    v-for="doc in docs"
                    :key="doc.value"
                >
                    {{ doc.name }}
                </a-select-option>
            </a-select>
        </div>
        <div class="sort-block">
            <span>
                Сортировать по времени:
            </span>
            <a-tooltip>
                <template #title>
                    По убыванию
                </template>
                <a-button
                    data-test-id="sort-by-time-create-desc-btn"
                    @click="sortDesc"
                >
                    <template #icon>
                        <arrow-down-outlined />
                    </template>
                </a-button>
            </a-tooltip>
            <a-tooltip>
                <template #title>
                    По возрастанию
                </template>
                <a-button
                    data-test-id="sort-by-time-create-asc-btn"
                    @click="sortAsc"
                >
                    <template #icon>
                        <arrow-up-outlined />
                    </template>
                </a-button>
            </a-tooltip>
        </div>
        <div
            v-for="record in recordsToShow"
            :key="record.id"
            data-test-id="note-div"
            class="note-block"
        >
            <div>
                <a-avatar
                    :src="avatarSrc(record.userCreateAvatarPreviewFileId)"
                    size="large"
                >
                    <template #icon>
                        <user-outlined />
                    </template>
                </a-avatar>
            </div>
            <div>
                <div>
                    {{ record.userCreateName }}, от
                    <span data-test-id="time-create-span">
                        {{ record.timeCreate }}
                    </span>
                    <span v-if="record.timeEdit">
                        (изменено {{ record.timeEdit }})
                    </span>
                    <a-tooltip>
                        <template #title>
                            Скопировать в буфер обмена
                        </template>
                        <a-button
                            size="small"
                            data-test-id="copy-btn"
                            class="copy-btn"
                            @click="copyToClipboard(record.text)"
                        >
                            <template #icon>
                                <copy-outlined />
                            </template>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip v-if="withPaste">
                        <template #title>
                            Вставить в протокол приёма
                        </template>
                        <a-button
                            size="small"
                            data-test-id="paste-btn"
                            class="paste-btn"
                            @click="paste(record.text)"
                        >
                            <template #icon>
                                <download-outlined />
                            </template>
                        </a-button>
                    </a-tooltip>
                </div>
                <p class="text-content">
                    {{ record.text }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowDownOutlined from '@ant-design/icons-vue/ArrowDownOutlined';
import ArrowUpOutlined from '@ant-design/icons-vue/ArrowUpOutlined';
import UserOutlined from '@ant-design/icons-vue/UserOutlined';
import DownloadOutlined from '@ant-design/icons-vue/DownloadOutlined';
import CopyOutlined from '@ant-design/icons-vue/CopyOutlined';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        ArrowDownOutlined,
        ArrowUpOutlined,
        UserOutlined,
        DownloadOutlined,
        CopyOutlined,
    },
    props: {
        history: {
            type: Array,
            required: true,
        },
        withPaste: {
            type: Boolean,
            default: true,
        },
    },

    emits: {
        'copy-to-clipboard': null,
        paste: null,
    },

    data() {
        return {
            selectedDoctor: 0,
            sortOrder: null,
        };
    },

    computed: {
        docs() {
            const filtered = this.history.reduce((result, note) => ({
                ...result,
                [note.userCreateId]: note.userCreateName,
            }), {});
            return Object.keys(filtered).map((userId) => ({
                value: userId,
                name: filtered[userId],
            }));
        },

        recordsToShow() {
            const filtered = this.selectedDoctor === 0
                ? [...this.history]
                : this.history.filter(
                    (record) => record.userCreateId === parseInt(this.selectedDoctor, 10),
                );
            if (!this.sortOrder) {
                return filtered;
            }
            filtered.sort((a, b) => (this.sortOrder === 'ASC'
                ? (a.timeCreate || '').localeCompare(b.timeCreate)
                : (b.timeCreate || '').localeCompare(a.timeCreate)));
            return filtered;
        },
    },

    methods: {
        sortAsc() {
            if (this.sortOrder === 'ASC') {
                this.sortOrder = null;
            } else {
                this.sortOrder = 'ASC';
            }
        },

        sortDesc() {
            if (this.sortOrder === 'DESC') {
                this.sortOrder = null;
            } else {
                this.sortOrder = 'DESC';
            }
        },

        copyToClipboard(text) {
            this.$emit('copy-to-clipboard', text);
        },

        paste(text) {
            this.$emit('paste', text);
        },

        avatarSrc(id) {
            return id ? `/api/user/file/${id}` : '';
        },
    },
});
</script>

<style lang="scss">
.doc-block {
    margin: 10px 0;
    & > span {
        display: inline-block;
        width: 50px;
        font-weight: bold;
    }
    & > .ant-select {
        width: 300px;
    }
}
.sort-block {
    margin: 10px 0;
    & > span {
        display: inline-block;
        width: 190px;
        font-weight: bold;
    }
    & > button {
        padding: 5px;
        margin: 2px;
    }
}
.note-block {
    margin: 10px 0;
    display: flex;
    & > div {
        vertical-align: top;
        margin-right: 10px;
    }
    & > div:last-child {
        flex: 1;
    }
    & > div:first-child {
        margin-top: 10px;
    }
    .paste-btn, .copy-btn {
        margin: 2px;
    }
    .text-content {
        white-space: pre-wrap;
        border: 1px solid rgba(0,0,0,0.3);
        border-radius: 5px;
        padding: 7px 5px;
    }
}
</style>
