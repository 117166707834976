<template>
    <div class="app-number-block">
        <tool-outlined
            v-if="icon === 'tool'"
            class="app-number-icon"
        />
        <experiment-outlined
            v-if="icon === 'experiment'"
            class="app-number-icon"
        />
        <a-badge
            :count="number"
            :number-style="numberStyle"
        />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ToolOutlined from '@ant-design/icons-vue/ToolOutlined';
import ExperimentOutlined from '@ant-design/icons-vue/ExperimentOutlined';

export default defineComponent({
    components: {
        ToolOutlined,
        ExperimentOutlined,
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        number: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            numberStyle: {
                backgroundColor: '#1890FF',
            },
        };
    },
});
</script>

<style lang="scss">
    .app-number-icon.anticon {
        font-size: 1.35em;
        vertical-align: -0.3em;
    }

    .app-number-block {
        display: inline-block;
    }
</style>
