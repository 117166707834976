<template>
    <a-modal
        :width="'90%'"
        :visible="visible"
        :footer="null"
        :mask-closable="false"
        centered
        title="Физиологические данные"
        class="patient-schedule"
        @cancel="() => handleCancel()"
    >
        <physical-metrics
            :patient-id="patientId"
        />
    </a-modal>
</template>

<script>
import PhysicalMetrics from 'vue-web-components/src/components/physical-metrics/PhysicalMetrics.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        PhysicalMetrics,
    },
    props: {
        patientId: {
            type: Number,
            required: true,
        },
        visible: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['close'],
    methods: {
        handleCancel(data) {
            this.$emit('close', data);
        },
    },
});
</script>
