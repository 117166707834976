import {
    Dictionary,
    keyBy,
    min,
} from 'lodash';
import moment, { Moment } from 'moment';
import {
    createMedicationsMap,
    createMedicationWithGoodsMap,
    createSubtypesMap,
} from './mappers';
import {
    IDayAction,
    IGoodEntity,
    IMedicationCourse,
    IMedication,
    IPatientDayActions,
    IMedicationTime,
} from '../types';

interface IScheduleDose {
    dose: IMedicationTime,
    courseId?: number;
    timeTake: Date;
    medicationPortion: number;
}

export const calcPatientSchedule = (
    patientDayActions: IPatientDayActions,
    commonDayActions: IPatientDayActions,
) => {
    const patientDayActionsMap = keyBy(patientDayActions.dayActions, 'dayActionId');
    const effectiveDayActions = (commonDayActions.dayActions || [])
        .map((defaultDayAction) => {
            const { dayActionId } = defaultDayAction;
            return patientDayActionsMap[dayActionId]
                ? patientDayActionsMap[dayActionId]
                : defaultDayAction;
        });

    return keyBy(effectiveDayActions, 'dayActionId');
};

interface ILocalStore {
    medications: IMedication[],
    goods: IGoodEntity[],
    medicationsMap: ReturnType<typeof createMedicationWithGoodsMap>,
    subtypesMap: ReturnType<typeof createSubtypesMap>,
    patientSchedule: ReturnType<typeof calcPatientSchedule>,
}

const localStore: ILocalStore = {
    medications: [],
    goods: [],
    medicationsMap: {},
    subtypesMap: {},
    patientSchedule: {},
};

export const useScheduleStore = (
    medications: IMedication[],
    commonActions: IPatientDayActions,
    patientActions: IPatientDayActions,
    goods?: IGoodEntity[],
) => {
    localStore.medications = medications;
    if (goods) {
        localStore.goods = goods;
        localStore.medicationsMap = createMedicationWithGoodsMap(medications, goods);
    } else {
        localStore.medicationsMap = createMedicationsMap(medications);
    }
    localStore.subtypesMap = createSubtypesMap(medications);
    localStore.patientSchedule = calcPatientSchedule(patientActions, commonActions);
    return localStore;
};

export const calcMedicationTakeDays = (
    datePlanStart: Moment,
    dateWithOffset: Moment,
    daysTakeRegularity: number,
    daysTakePeriod: number,
): string[] => {
    const daysCount = Math.ceil(daysTakePeriod / daysTakeRegularity);
    const result = Array(daysCount).fill(0).map((value, i) => {
        const days = i * daysTakeRegularity;
        const takingDate = moment(dateWithOffset).add(days, 'days');
        if (takingDate >= moment(datePlanStart).add(daysTakePeriod, 'days')) {
            return '';
        }
        return takingDate.format('YYYY-MM-DD');
    }).filter((date) => date);
    return result;
};

export const calculateTime = (
    daysSchedule: Dictionary<IDayAction>,
    dose: IMedicationTime,
) => {
    const MORNING_TIME = 4;
    const dayActionId = dose.dayActionId || MORNING_TIME;

    const dayAction = daysSchedule[dayActionId];
    return {
        timeTake: dayAction ? dayAction.timeAction : null,
        timeTakeHoliday: dayAction ? dayAction.timeActionHoliday : null,
    };
};

export const calculateSchedule = (
    course: IMedicationCourse,
    datePlanStart: string | Moment | Date,
    patientSchedule: Dictionary<IDayAction>,
) => (course.times || []).map((dose) => {
    if (!datePlanStart) {
        return [];
    }

    const doseTime = calculateTime(patientSchedule, dose);
    const dateWithOffset = moment(datePlanStart);
    const lastDayOfTaking = moment(datePlanStart)
        .add(course.daysTakePeriod, 'days');
    dateWithOffset.add(dose.daysTakeOffset, 'days');
    const daysSchedule = calcMedicationTakeDays(
        moment(datePlanStart),
        dateWithOffset,
        dose.daysTakeRegularity,
        course.daysTakePeriod,
    )
        .filter((day) => moment(day) <= lastDayOfTaking)
        .map((day) => {
            const dayDate = new Date(`${day}T00:00:00.000`);
            const weekDay = dayDate.getDay();
            const timeTake = new Date(`${day}T${
                ([6, 0]).indexOf(weekDay) >= 0 && doseTime.timeTakeHoliday
                    ? doseTime.timeTakeHoliday
                    : doseTime.timeTake}${course.timeZone || '+03:00'}`);
            if (dose.minutesDeviation) {
                timeTake.setMinutes(
                    timeTake.getMinutes() + (dose.minutesDeviation * dose.takeDirection),
                );
            }
            return {
                dose,
                courseId: course.id,
                timeTake,
                medicationPortion: dose.medicationPortion,
            };
        });
    return daysSchedule;
}).flat().sort((a, b) => a.timeTake.getTime() - b.timeTake.getTime());

const calcProductionRanges = (
    schedule: IScheduleDose[],
    // eslint-disable-next-line default-param-last
    totalRange: IScheduleDose[][] = [],
    freq: number,
): IScheduleDose[][] => {
    if (!schedule.length) {
        return [];
    }
    // eslint-disable-next-line arrow-body-style
    const dosesInProductionRangesNumber = totalRange.reduce((pr, cr) => {
        return cr.length
            ? pr + cr.length
            : pr + 1;
    }, 0);

    if (schedule.length <= dosesInProductionRangesNumber) {
        return totalRange;
    }

    // seems like we can't be far from the last element
    const currentDose = schedule[dosesInProductionRangesNumber];
    const endRangeDoseTakeTime = moment(currentDose.timeTake).add(freq, 'days');
    const range = schedule
        .filter((dose) => moment(dose.timeTake)
            .isBetween(currentDose.timeTake, endRangeDoseTakeTime, null, '[)'));

    if (range.length) {
        totalRange.push(range);
    }

    return calcProductionRanges(schedule, totalRange, freq);
};

const calcProductionSchedule = (schedule: IScheduleDose[], course: IMedicationCourse) => {
    const { productionFreq } = course;

    if (!productionFreq) {
        return [schedule];
    }

    const minRegularity = min(schedule.map(({ dose }) => dose.daysTakeRegularity));
    if (!minRegularity) {
        throw new Error('schedule: minRegularity is null');
    }
    const range = calcProductionRanges(schedule, [], productionFreq);
    return range.filter((doses) => doses.length);
};

export const calcMedicationProduction = (
    course: IMedicationCourse,
    datePlanStart: string | Moment | Date,
    store = localStore,
) => {
    if (!course) {
        throw new Error('schedule: course is not provided');
    }
    if (!store.subtypesMap || !store.medicationsMap) {
        throw Error('schedule: store not initialized');
    }
    const schedule = calculateSchedule(
        course,
        datePlanStart,
        store.patientSchedule,
    );
    if (!schedule.length) {
        return {
            courseDoses: [],
            productionSchedule: [],
        };
    }

    const productionSchedule = calcProductionSchedule(schedule, course);
    const medication = course.medicationSubtypeId
        ? store.subtypesMap[course.medicationSubtypeId]
        : store.medicationsMap[course.clinicMedicationId];
    if (!medication) {
        throw new Error('schedule: medication not found');
    }
    const plannedDoses = medication.plannedDosesCount || 1;
    const courseDoses = productionSchedule.map(
        (period) => period.reduce(
            (acc, cur, i) => {
                acc.dose += cur.medicationPortion;
                if (i !== period.length - 1) {
                    return acc;
                }
                acc.date = moment(
                    moment(period[0].timeTake)
                        .subtract(medication.productionTime, 'days'),
                ).toDate();
                acc.dose /= plannedDoses;
                return acc;
            },
            { dose: 0, date: new Date() },
        ),
    );
    return {
        courseDoses,
        productionSchedule,
    };
};
