<template>
    <div class="main page">
        <a-layout>
            <page-header />
            <a-layout-content class="info">
                <a-breadcrumb>
                    <a-breadcrumb-item href="">
                        <home-outlined
                            @click="$router.push({name: 'main'})"
                        />
                    </a-breadcrumb-item>
                    <a-breadcrumb-item href="">
                        <a @click="$router.push({path: `/card/${patientId}`})">{{ patientName }}</a>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        {{ pollType }}
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <div class="main-info">
                    <div
                        class="card-info-wrapper"
                        style="padding: 20px"
                    >
                        <a-button
                            :style="{marginBottom: '20px'}"
                            type="primary"
                            @click="print"
                        >
                            <template #icon>
                                <printer-outlined />
                            </template>
                            Печать
                        </a-button>

                        <div
                            v-for="blank in blanks"
                            :key="blank.id"
                            class="blank"
                        >
                            <h2>{{ blank.caption || blank.name }}</h2>
                            <survey-blank
                                :data="blank"
                                show-poll-files
                            />
                        </div>
                    </div>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>

<script>
import HomeOutlined from '@ant-design/icons-vue/HomeOutlined';
import PrinterOutlined from '@ant-design/icons-vue/PrinterOutlined';
import titleMixin from '@/mixins/titleMixin';
import PageHeader from '@/components/PageHeader.vue';
import SurveyBlank from '@/components/patientcard/SurveyBlank.vue';
import { showErrorNotification } from '@/utils';

export default {
    components: {
        PageHeader,
        SurveyBlank,
        HomeOutlined,
        PrinterOutlined,
    },
    mixins: [titleMixin],
    props: {
        pollId: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            blanks: [],
            patientId: null,
            patientName: '',
            pollType: '',
        };
    },
    mounted() {
        this.getPollData(this.pollId);
    },
    methods: {
        getPollData(id) {
            this.$http.get(`/patient/survey/quiz/${id}`).then((response) => {
                this.blanks = response.data.blanks;
                this.patientName = response.data.patientName;
                this.patientId = response.data.patientId;
                this.pollType = response.data.surveyType;
                this.setPageTitle(response.data.patientName);
            }).catch(() => showErrorNotification('Не удалось загрузить опрос.'));
        },
        print() {
            window.open(`/api/patient/survey/quiz/${this.pollId}/download`);
        },
    },
};
</script>
