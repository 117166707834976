<template>
    <a-dropdown
        v-if="protocolId"
        v-model:visible="visible"
        class="print-menu"
        :disabled="disabled"
    >
        <template #overlay>
            <a-menu class="print-submenu">
                <a-menu-item
                    v-for="(item, index) of menuItems"
                    :key="index"
                >
                    {{ item.title }}
                    <a-switch v-model:checked="item.value" />
                </a-menu-item>

                <a-menu-item class="print-button-line">
                    <a-button @click="print">
                        Печать
                    </a-button>
                </a-menu-item>
            </a-menu>
        </template>

        <a-button>
            <printer-outlined />
            <span>Печать</span>
        </a-button>
    </a-dropdown>
</template>

<script>
import PrinterOutlined from '@ant-design/icons-vue/PrinterOutlined';

export default {
    components: {
        PrinterOutlined,
    },
    props: {
        protocolId: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            visible: false,
            menuItems: [
                { name: 'showComplaints', title: 'Жалобы', value: true },
                { name: 'showObjectiveData', title: 'Объективные данные', value: true },
                { name: 'showRecommendations', title: 'Рекомендации', value: true },
                { name: 'showDiagnoses', title: 'Диагнозы', value: true },
                { name: 'showICDDiagnoses', title: 'Диагнозы МКБ', value: true },
            ],
        };
    },
    methods: {
        print() {
            const queryString = this.menuItems
                .filter((item) => item.value)
                .map((item) => `${item.name}=true`)
                .join('&');

            window.open(`/api/patient/protocol/${
                this.protocolId
            }/download?${queryString}`);

            this.visible = false;
        },
    },
};
</script>

<style lang="scss">
.print-submenu {
    .ant-dropdown-menu-title-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 15px;

        .ant-switch {
            margin-left: 15px;
        }
    }

    .print-button-line {
        justify-content: flex-end;
    }
}
</style>
