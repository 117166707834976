/* eslint-disable quote-props */

import { h } from 'vue';
import { notification } from 'ant-design-vue';
import CloseCircleOutlined from '@ant-design/icons-vue/CloseCircleOutlined';
import axios from 'axios';

axios.defaults.baseURL = '/api';

export { axios };

export const showErrorNotification = (msg) => {
    notification.open({
        message: 'Ошибка',
        duration: 0,
        description: msg,
        icon: h(CloseCircleOutlined, { style: { color: '#f5222d' } }),
    });
};

export const showNotification = (title, msg) => {
    notification.open({
        message: title,
        duration: 0,
        description: msg,
        icon: h(CloseCircleOutlined, { style: { color: '#f5222d' } }),
    });
};

export const debounce = ({ ms = 300, callbackFn }) => {
    if (!callbackFn) {
        throw new Error('No callback function provided.');
    }
    let timerId;
    return {
        start: (input) => {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(() => {
                timerId = undefined;
                callbackFn(input);
            }, ms);
        },

        stop: () => {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = undefined;
        },
    };
};

const layoutMapping = {
    'q': 'й',
    'w': 'ц',
    'e': 'у',
    'r': 'к',
    't': 'е',
    'y': 'н',
    'u': 'г',
    'i': 'ш',
    'o': 'щ',
    'p': 'з',
    '[': 'х',
    ']': 'ъ',
    'a': 'ф',
    's': 'ы',
    'd': 'в',
    'f': 'а',
    'g': 'п',
    'h': 'р',
    'j': 'о',
    'k': 'л',
    'l': 'д',
    ';': 'ж',
    '\'': 'э',
    'z': 'я',
    'x': 'ч',
    'c': 'с',
    'v': 'м',
    'b': 'и',
    'n': 'т',
    'm': 'ь',
    ',': 'б',
    '.': 'ю',
    '/': '.',
    '`': 'ё',
    'Q': 'Й',
    'W': 'Ц',
    'E': 'У',
    'R': 'К',
    'T': 'Е',
    'Y': 'Н',
    'U': 'Г',
    'I': 'Ш',
    'O': 'Щ',
    'P': 'З',
    '{': 'Х',
    '}': 'Ъ',
    'A': 'Ф',
    'S': 'Ы',
    'D': 'В',
    'F': 'А',
    'G': 'П',
    'H': 'Р',
    'J': 'О',
    'K': 'Л',
    'L': 'Д',
    ':': '^',
    '"': 'Э',
    '|': '/',
    'Z': 'Я',
    'X': 'Ч',
    'C': 'С',
    'V': 'М',
    'B': 'И',
    'N': 'Т',
    'M': 'Ь',
    '<': 'Б',
    '>': 'Ю',
    '?': ',',
    '~': 'Ё',
    '@': '"',
    '#': '№',
    '$': ';',
    '^': ':',
    '&': '?',
    'й': 'q',
    'ц': 'w',
    'у': 'e',
    'к': 'r',
    'е': 't',
    'н': 'y',
    'г': 'u',
    'ш': 'i',
    'щ': 'o',
    'з': 'p',
    'х': '[',
    'ъ': ']',
    'ф': 'a',
    'ы': 's',
    'в': 'd',
    'а': 'f',
    'п': 'g',
    'р': 'h',
    'о': 'j',
    'л': 'k',
    'д': 'l',
    'ж': ';',
    'э': '\'',
    'я': 'z',
    'ч': 'x',
    'с': 'c',
    'м': 'v',
    'и': 'b',
    'т': 'n',
    'ь': 'm',
    'б': ',',
    'ю': '.',
    'ё': '`',
    'Й': 'Q',
    'Ц': 'W',
    'У': 'E',
    'К': 'R',
    'Е': 'T',
    'Н': 'Y',
    'Г': 'U',
    'Ш': 'I',
    'Щ': 'O',
    'З': 'P',
    'Х': '{',
    'Ъ': '}',
    'Ф': 'A',
    'Ы': 'S',
    'В': 'D',
    'А': 'F',
    'П': 'G',
    'Р': 'H',
    'О': 'J',
    'Л': 'K',
    'Д': 'L',
    'Ж': ':',
    'Э': '"',
    'Я': 'Z',
    'Ч': 'X',
    'С': 'C',
    'М': 'V',
    'И': 'B',
    'Т': 'N',
    'Ь': 'M',
    'Б': '<',
    'Ю': '>',
    'Ё': '~',
    '№': '#',
};

const alphabetMapping = {
    'e': 'е',
    't': 'т',
    'y': 'у',
    'o': 'о',
    'p': 'р',
    'a': 'а',
    'h': 'н',
    'k': 'к',
    'x': 'х',
    'c': 'с',
    'n': 'н',
    'm': 'м',
    'E': 'Е',
    'T': 'Т',
    'Y': 'У',
    'O': 'О',
    'P': 'Р',
    'A': 'А',
    'H': 'Н',
    'K': 'К',
    'X': 'Х',
    'C': 'С',
    'N': 'Н',
    'M': 'М',
    'у': 'y',
    'к': 'k',
    'е': 'e',
    'н': 'n',
    'х': 'x',
    'а': 'a',
    'р': 'p',
    'о': 'o',
    'с': 'c',
    'м': 'm',
    'т': 't',
    'У': 'Y',
    'К': 'K',
    'Е': 'E',
    'Н': 'H',
    'Х': 'X',
    'В': 'B',
    'А': 'A',
    'Р': 'P',
    'О': 'O',
    'С': 'C',
    'М': 'M',
    'Т': 'T',
};

export const convertAlphabet = (str) => {
    if (typeof str !== 'string') {
        return undefined;
    }
    return str.replace(
        /[etyopahkxcnmETYOPAHKXCNMукенхаросмтУКЕНХВАРОСМТ]/g,
        (m) => alphabetMapping[m],
    );
};

export const convertLayout = (str) => {
    if (typeof str !== 'string') {
        return undefined;
    }
    return str.replace(
        /[qwertyuiop[\]asdfghjkl;'zxcvbnm,./`QWERTYUIOP{}ASDFGHJKL:"|ZXCVBNM<>?~@#$^&йцукенгшщзхъфывапролджэячсмитьбюёЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮЁ№]/g,
        (m) => layoutMapping[m],
    );
};
