<template>
    <div>
        <div class="prescription-header">
            <div
                v-if="disabled"
                class="prescription-header-selects"
            />
            <div
                v-if="!disabled"
                class="prescription-header-selects"
            >
                <div>
                    <li-select
                        v-if="templatesWithParentId.length"
                        :options="templatesWithParentId"
                        placeholder="Шаблон лечения"
                        id-key="id"
                        parent-key="parentId"
                        title-key="name"
                        hide-selected
                        tree-paths
                        data-test-id="reception-treatment-templates-combo"
                        @select="onTemplateSelect"
                    />
                </div>

                <li-select
                    v-if="goodsWithParentId.length"
                    :options="goodsWithParentId"
                    placeholder="Услуга"
                    id-key="id"
                    parent-key="parentId"
                    title-key="name"
                    hide-selected
                    tree-paths
                    data-test-id="reception-goods-combo"
                    @change="(e) => e ? onGoodSelect(e) : null"
                >
                    <template #beforeOptions="{ data, optionsGroup }">
                        <span
                            v-if="optionsGroup['Лабораторные исследования']
                                .map((o) => o.id).includes(data.parentId)"
                            @click.stop="onGoodSelect(data.id)"
                        >
                            <div class="prescription-header-add-icon" />
                        </span>
                    </template>
                </li-select>

                <li-select
                    v-if="medicationsWithParentId.length"
                    :options="medicationsWithParentId"
                    placeholder="Препарат"
                    id-key="id"
                    parent-key="parentId"
                    title-key="name"
                    hide-selected
                    tree-paths
                    data-test-id="reception-medication-combo"
                    @select="onMedicationSelect"
                />
            </div>
            <div class="prescription-header-controls">
                <a-checkbox
                    v-model:checked="onlyMedication"
                    data-test-id="only-medication"
                    style="float: right; margin-right: 5px;"
                    @change="({ target }) => onOnlyMedicationChange(target.checked)"
                >
                    Только препараты
                </a-checkbox>
                <a-switch
                    :checked="onlyActiveAssignments"
                    data-test-id="only-active-assignments"
                    checked-children="Текущие"
                    un-checked-children="Все"
                    style="float: right"
                    size="default"
                    @change="onSwitchActiveAssignments"
                />
            </div>
        </div>
        <assignment-template-completion
            v-if="showTemplateCompletionModal"
            :assignments="currentModal.payload"
            :medication-types="medications"
            @done="onTemplateCompleted"
            @cancel="closeModal"
        />

        <medication-instruction
            v-if="showMedicationInstructionModal"
            :course="currentModal.payload.course"
            :disabled="disabled || currentModal.payload.disabled"
            :medications="medications"
            :patient-id="patientId"
            @cancel="closeModal"
            @create-medication-course="onMedicationCourseCreate"
        />

        <good-medications-subtypes
            v-if="showMedicationSubtypesModal"
            :patient-id="patientId"
            :good="currentModal.payload.good"
            :disabled="disabled || currentModal.payload.disabled"
            :allow-to-skip-donor="currentModal.payload.allowToSkipDonor"
            @cancel="onMedicationSubtypesSelectionCancel"
            @select-medication-subtypes="onMedicationSubtypesSelect"
        />
        <div class="prescription-table-controls">
            <div
                v-if="disabled"
            />
            <a-button
                v-if="!disabled"
                class="update-rulecode-btn"
                data-test-id="update-rulecode-btn"
                :disabled="updateRuleCodeButtonIsDisabled"
                @click="updateRuleCodeWindowIsVisible = true"
            >
                Установить правило
            </a-button>

            <a-switch
                v-model:checked="showDeletedAssignments"
                data-test-id="deleted-assignments-switch"
                checked-children="Отображать удаленные"
                un-checked-children="Отображать удаленные"
                size="default"
            />
        </div>

        <patient-prescriptions-grid
            v-if="medicationMap"
            ref="prescriptions"
            :patient-id="patientId"
            :medications-map="medicationMap"
            :subtypes-map="subtypesMap"
            :medications="medications"
            :disabled="disabled"
            :checked-assignments="checkedAssignments"
            :patient-assignments="patientAssignments"
            :displayed-patient-assignments="displayedPatientAssignments"
            :patient-assignments-indices="patientAssignmentsIndices"
            :goods-map="goodsMap"
            :good-medication-map="goodMedicationMap"
            :goods="goods"
            :biomaterial="biomaterial"
            :all-assignments-checked="allAssignmentsChecked"
            :show-mask="isPrescriptionGridMaskVisible"
            @create-medication-course="onMedicationCourseCreate"
            @donor-changed="onDonorChange"
            @assignment-edit-click="onAssignmentEdit"
            @check-all-assignments="checkAllAssignments"
            @check-assignment="checkAssignment"
            @change-rule-code="(e, record) => onRuleCodeChange(record, e)"
            @date-plan-change="(e, record) => updateAssignmentPlannedStartDate(record, e)"
            @preceding-assignment-change="(e, record) => onPrecedingAssignmentChange(record, e)"
            @over-days-count-change="(e, record) => updateAssignmentDayGap(record, e)"
            @clarification-required-change="(e, record) => {
                $emit('change-assignment', record, 'unaccepted', e.target.checked);
                $emit('change-assignment', record, 'modified', true);
            }"
            @survey-result-notify-change="(e, record) => {
                $emit('change-assignment', record, 'notifyAfterSurveysIsReady', e.target.checked);
                $emit('change-assignment', record, 'modified', true);
            }"
            @comment-change="(e, record) => {
                $emit('change-assignment', record, 'comment', e.target.value);
                $emit('change-assignment', record, 'modified', true);;
            }"
            @remove-record="onPrescriptionRemove"
            @assignment-changed="(record, key, value) =>
                $emit('change-assignment', record, key, value)"
            @assignment-replaced="(record, data) =>
                $emit('replace-assignment', record, data)"
            @sort-change="onSortChange"
            @fix-period-click="onFixMedicationPeriodClick"
        />
        <a-skeleton
            v-else
            active
        />

        <set-rule-window
            v-if="updateRuleCodeWindowIsVisible"
            :patient-assignments="displayedPatientAssignments"
            :checked-assignments="checkedAssignments"
            :patient-assignments-indices="patientAssignmentsIndices"
            @prescription-rule-change="updatePrescriptionRules"
            @close="updateRuleCodeWindowIsVisible = false"
        />
        <biomaterial-specification-window
            v-if="showSurveyBiomaterialModal"
            :goods-with-surveys="currentModal.payload.data"
            :biomaterial="biomaterial"
            :disabled="disabled || currentModal.payload.disabled"
            @close="closeModal"
            @done="onBiomaterialSpecified"
        />
    </div>
</template>

<script>
/* eslint-disable no-param-reassign */

import {
    chunk,
    keyBy,
    transform,
    property as prop,
    groupBy,
    flatten,
    uniq,
    uniqBy,
    partition,
} from 'lodash';
import moment from 'moment';
import {
    Modal,
    Skeleton as ASkeleton,
} from 'ant-design-vue';
import { v4 as uuidv4 } from 'uuid';
import MedicationInstruction from 'vue-web-components/src/components/prescriptiontemplate/prescription/MedicationInstruction.vue';
import BiomaterialSpecificationWindow from 'vue-web-components/src/components/prescriptiontemplate/BiomaterialSpecificationWindow.vue';
import GoodMedicationsSubtypes from 'vue-web-components/src/components/prescriptiontemplate/prescription/GoodMedicationsSubtypes.vue';
import {
    useTaskQueue,
} from 'vue-web-components/src/utils';
import {
    getAppSettings,
} from '@api';
import {
    showErrorNotification,
} from '@/utils';

import LiSelect from '@/../vue-web-components/src/components/base/LiSelect.vue';
import SetRuleWindow from '@/components/reception/SetRuleWindow.vue';
import PatientPrescriptionsGrid from './PatientPrescriptionsGrid.vue';
import AssignmentTemplateCompletion from './AssignmentTemplateCompletion.vue';

export default {
    components: {
        PatientPrescriptionsGrid,
        MedicationInstruction,
        LiSelect,
        AssignmentTemplateCompletion,
        BiomaterialSpecificationWindow,
        SetRuleWindow,
        GoodMedicationsSubtypes,
        ASkeleton,
    },
    props: {
        patientId: {
            type: Number,
            required: true,
        },
        protocolId: {
            type: Number,
            default: null,
        },
        patientAssignments: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        goodMedicationMap: {
            type: Object,
            required: true,
        },
    },
    emits: [
        'add-assignment',
        'change-assignment',
        'replace-assignment',
    ],

    setup() {
        const taskQueue = useTaskQueue();
        return {
            currentModal: taskQueue.currentTask,
            enqueueModals: taskQueue.enqueue,
            closeModal: taskQueue.done,
            cancelModals: taskQueue.cancel,
        };
    },

    data() {
        return {
            goods: [],
            archivedGoods: [],
            templates: [],
            medications: [],
            commonDayActions: [],
            patientDayActions: {
                dayActions: [],
            },
            onlyMedication: false,
            onlyActive: true,
            checkedAssignments: {},
            biomaterial: [],
            assignmentEdit: null,
            updateRuleCodeWindowIsVisible: false,
            onlyActiveAssignments: true,
            showDeletedAssignments: true,
            sorting: {
                field: null,
                order: null,
            },
            appSettings: [],
            isPrescriptionGridMaskVisible: false,
        };
    },
    computed: {
        showSurveyBiomaterialModal() {
            return this.currentModal && this.currentModal.type === 'surveyBiomaterial';
        },

        showMedicationInstructionModal() {
            return this.currentModal && this.currentModal.type === 'medicationInstruction';
        },

        showMedicationSubtypesModal() {
            return this.currentModal && this.currentModal.type === 'medicationSubtypes';
        },

        showTemplateCompletionModal() {
            return this.currentModal && this.currentModal.type === 'templateCompletion';
        },

        patientAssignmentsMap() {
            return keyBy(
                this.patientAssignments,
                (a) => (a.id ? `id-${a.id}` : `localId-${a.localId}`),
            );
        },
        medicationMap() {
            if (!this.goods.length || !this.medications.length) {
                return null;
            }
            const medicationsMap = keyBy(this.medications, 'id');
            this.goods
                .filter((a) => a.clinicMedications)
                .forEach((good) => {
                    good.clinicMedications.forEach((medication) => {
                        if (!medicationsMap[medication.id].prices) {
                            medicationsMap[medication.id].prices = {
                                min: Infinity,
                                max: -Infinity,
                            };
                        }
                        const { prices } = medicationsMap[medication.id];
                        medicationsMap[medication.id].prices.min = good.price.value < prices.min
                            ? good.price.value : prices.min;
                        medicationsMap[medication.id].prices.max = good.price.value > prices.max
                            ? good.price.value : prices.max;
                    });
                });
            return medicationsMap;
        },
        subtypesMap() {
            return keyBy(
                flatten(
                    this.medications.map(
                        ({ subtypes }) => subtypes || [],
                    ),
                ),
                'id',
            );
        },
        templatesMap() {
            return keyBy(this.templates.reduce(
                (templates, group) => templates.concat(group.assignmentTemplates),
                [],
            ), 'id');
        },

        goodsMap() {
            return keyBy(this.goods.concat(this.archivedGoods), 'id');
        },

        noSchemeMedicationProdFreq() {
            const option = this.appSettings.find(
                ({ code }) => code === 'PRODUCTION_FREQUENCY_OF_MEDICATION_WO_SCHEME',
            );
            return option ? option.field.value : null;
        },

        medicationsWithParentId() {
            return transform(
                this.medications.filter(
                    (medication) => !medication.deleted
                        && medication.id === medication.currentId,
                ),
                (acc, value) => {
                    const isSelectable = value.schemeId === 1 || value.schemeId === 2;
                    acc.push({
                        id: value.id,
                        selectable: isSelectable,
                        parentId: null,
                        name: `<l style="color:#ccc">${value.code || ''}</l> ${value.name}`,
                    });
                    value.subtypes.forEach(
                        (subtype) => {
                            if (subtype.deleted) {
                                return;
                            }
                            acc.push({
                                id: subtype.id,
                                selectable: !isSelectable || value.schemeId === 2,
                                parentId: subtype.parentId,
                                name: `<l style="color:#ccc">${subtype.code || ''}</l> ${subtype.name}`,
                            });
                        },
                    );
                },
                [],
            );
        },
        goodsWithParentId() {
            if (!this.goods.length) {
                return [];
            }
            // extract groups
            const groups = transform(
                groupBy(this.goods, 'groupId'),
                (acc, value) => {
                    acc.push({ id: value[0].groupName, name: value[0].groupName });
                },
                [],
            );
            const surveyGroups = uniqBy(
                this.goods.filter((x) => (
                    x.surveyTypeIds
                    && x.surveyTypeIds.length === 1
                    && x.groupName === 'Лабораторные исследования'
                )).map((x) => ({
                    id: x.surveyGroupName,
                    parentId: x.groupName,
                    name: x.surveyGroupName,
                })),
                'id',
            );
            // extract sub-groups
            const surveyGroupsEmpty = uniqBy(
                this.goods
                    .filter((x) => !x.surveyTypeIds || !x.surveyGroupName)
                    .map((x) => ({
                        id: 'Без исследований',
                        parentId: x.groupName,
                        name: 'Без исследований',
                    })),
                'id',
            );
            // extract sub-groups
            const surveyGroupsCombo = [{
                id: 'Комбинированные',
                parentId: 'Лабораторные исследования',
                name: 'Комбинированные',
            }];
            // extract options
            const goods = this.goods.map((good) => {
                let parentId;
                if (
                    good.groupName === 'Лабораторные исследования'
                    && good.surveyTypeIds
                    && good.surveyTypeIds.length > 1
                ) {
                    if (
                        good.surveyTypes
                            .every((surveyType, _, arr) => surveyType.parentId === arr[0].parentId)
                    ) {
                        parentId = good.surveyGroupName ? good.surveyGroupName : good.groupName;
                    } else {
                        parentId = 'Комбинированные';
                    }
                }
                if (
                    good.groupName === 'Лабораторные исследования'
                    && good.surveyTypeIds
                    && good.surveyTypeIds.length === 1
                ) {
                    parentId = good.surveyGroupName ? good.surveyGroupName : good.groupName;
                }
                if (!good.surveyTypeIds || good.groupName === 'Консультации и процедуры') {
                    if (good.groupName === 'Лабораторные исследования') {
                        parentId = 'Без исследований';
                    } else {
                        parentId = good.groupName;
                    }
                }
                return {
                    id: good.id,
                    parentId,
                    name: `<l style="color:#ccc">${good.code}</l> ${good.name}`,
                };
            });
            return [
                ...groups,
                ...surveyGroups,
                ...surveyGroupsEmpty,
                ...surveyGroupsCombo,
                ...goods,
            ];
        },
        templatesWithParentId() {
            return transform(this.templates, (acc, value) => {
                acc.push({
                    id: value.id + value.name,
                    name: value.name,
                    selectable: false,
                });
                value.assignmentTemplates.forEach(
                    (entry) => acc.push({ ...entry, parentId: value.id + value.name }),
                );
            }, []);
        },
        updateRuleCodeButtonIsDisabled() {
            const ids = Object.keys(this.checkedAssignments);
            return !ids.length || ids.every((id) => !this.checkedAssignments[id]);
        },
        allAssignmentsChecked() {
            const keys = Object.keys(this.checkedAssignments);
            if (!keys.length) {
                return false;
            }
            return keys.filter(
                (id) => this.checkedAssignments[id],
            ).length === this.shownActivePatientAssignments.length;
        },

        editableAssignments() {
            return this.patientAssignments.filter((assignment) => !(
                assignment.timeStarted
                || assignment.cancellationComment
                || assignment.deleted
            ));
        },

        displayedPatientAssignments() {
            const assignments = this.nonDeletedAssignments.filter((x) => (
                !this.onlyActiveAssignments
                || (!x.timeStarted && !x.cancellationComment)
            ) && (
                !this.onlyMedication || !x.clinicGoodId
            ));

            if (this.sorting.field !== 'datePlanStart') {
                if (this.onlyActiveAssignments) {
                    return assignments;
                }
                const [active, notActive] = partition(
                    assignments,
                    (a) => !a.timeStarted
                        && !a.cancellationComment,
                );
                return [...active, ...notActive];
            }

            assignments.sort((a, b) => {
                const sign = this.sorting.order === 'ascend' ? 1 : -1;
                if (a.datePlanStart && b.datePlanStart) {
                    const result = a.datePlanStart.toDate() - b.datePlanStart.toDate();
                    return result ? result * sign : 0;
                }
                if (a.datePlanStart) {
                    return sign;
                }
                if (b.datePlanStart) {
                    return -sign;
                }
                return 0;
            });
            return assignments;
        },
        patientAssignmentsIndices() {
            const [active, notActive] = partition(
                this.nonDeletedAssignments,
                (a) => !a.timeStarted
                    && !a.cancellationComment,
            );

            return [...active, ...notActive].reduce((map, assignment, index) => {
                const id = this.getAssignmentId(assignment);
                // eslint-disable-next-line no-param-reassign
                map[id] = index + 1;
                return map;
            }, {});
        },
        nonDeletedAssignments() {
            return this.patientAssignments.filter(
                (a) => {
                    if (!a.deleted) {
                        return true;
                    }
                    if (!this.showDeletedAssignments) {
                        return false;
                    }
                    if (!this.protocolId) {
                        return true;
                    }
                    if (a.previouslyDeleted) {
                        return true;
                    }
                    if (this.protocolId === a.patientProtocolId.toString()) {
                        return false;
                    }
                    if (!a.patientProtocolId) {
                        return false;
                    }
                    return true;
                },
            );
        },
        shownActivePatientAssignments() {
            return this.patientAssignments.filter((assignment) => {
                if (assignment.deleted
                    || assignment.timeStarted
                    || assignment.cancellationComment
                ) {
                    return false;
                }

                const isMedication = !assignment.clinicGoodId;
                return this.onlyMedication ? isMedication : true;
            });
        },
    },
    watch: {
        disabled: {
            immediate: true,
            handler(isDisabled) {
                this.showDeletedAssignments = !isDisabled;
            },
        },
    },
    async created() {
        try {
            const templates = await this.$http.get('/assignment/template/groups');
            this.templates = templates.data;
            const commonDayActions = await this.$http.get('/common/patient/day/actions/');
            this.commonDayActions = commonDayActions.data.dayActions;
            const patientDayActions = await this.$http.get(`/patient/${this.patientId}/day/actions/`);
            this.patientDayActions = patientDayActions.data;
            const goods = await this.$http.get('/clinic/goods/patient/', {
                params: {
                    clinicId: localStorage.clinics[0],
                    patientId: this.patientId,
                    sort: JSON.stringify([{
                        property: 'name',
                        direction: 'asc',
                    }]),
                },
            });
            this.goods = goods.data;

            await this.loadArchivedVersionsOfPatientGoods();

            const medications = await this.$http.get('/clinic/medications', {
                params: {
                    archived: true,
                    deleted: true,
                },
            });
            this.medications = medications.data;
            const biomaterial = await this.$http.get('/refs/biomaterial');
            this.biomaterial = biomaterial.data.map(
                ({ id, name }) => ({ id, name }),
            );
            this.appSettings = (await getAppSettings()).data;
        } catch (err) {
            showErrorNotification('Произошла ошибка при загрузке данных.');
        }
    },
    methods: {
        onSortChange(field, order) {
            if (!field || !order) {
                this.resetSorting();
                return;
            }
            this.sorting.field = field;
            this.sorting.order = order;
        },

        async loadArchivedVersionsOfPatientGoods() {
            const archGoodIds = uniq(this.patientAssignments.filter(
                ({ clinicGoodId }) => clinicGoodId && !this.goodsMap[clinicGoodId],
            ).map(prop('clinicGoodId')));
            if (!archGoodIds.length) {
                return;
            }

            const REQUEST_CHUNK_SIZE = 100;
            const chunksOfIds = chunk(archGoodIds, REQUEST_CHUNK_SIZE);
            const responses = await Promise.all(chunksOfIds.map((listOfGoodIds) => {
                const params = { patientId: this.patientId };
                if (listOfGoodIds.length === 1) {
                    [params.id] = listOfGoodIds;
                } else {
                    params.ids = listOfGoodIds;
                }
                return this.$http.get('/clinic/goods/patient/', { params });
            }));
            this.archivedGoods = responses.map(prop('data')).flat();
        },

        resetSorting() {
            this.sorting.field = null;
            this.sorting.order = null;
        },

        onDonorChange(assignmentId, donorId) {
            const assignment = this.patientAssignments
                .find((assignmen) => assignmen.id === assignmentId);
            this.$emit(
                'change-assignment',
                assignment,
                'medicationCourse',
                { ...assignment.medicationCourse, donorId },
            );
        },

        findFurthestPredecessorAssignment(
            assignment,
            stumbledUponReadonlyAssignment = false,
        ) {
            const isReadonly = stumbledUponReadonlyAssignment
                || !!(assignment.timeStarted
                    || assignment.cancellationComment
                    || assignment.deleted);

            if (!assignment.overAssignmentLocalId && !assignment.overAssignmentId) {
                const id = this.getAssignmentId(assignment);
                return {
                    assignment: this.patientAssignmentsMap[id],
                    stumbledUponReadonlyAssignment: isReadonly,
                };
            }

            const nextId = assignment.overAssignmentLocalId
                ? `localId-${assignment.overAssignmentLocalId}`
                : `id-${assignment.overAssignmentId}`;
            return this.findFurthestPredecessorAssignment(
                this.patientAssignmentsMap[nextId],
                isReadonly,
            );
        },

        async checkAssignmentsProduction(confirmedAssignments) {
            const promise = this.editableAssignments
                .filter(
                    (a) => a.medicationCourse
                        && a.medicationCourse.productionFreq
                        && !confirmedAssignments.has(a.id),
                )
                .map((a) => {
                    const medication = this.medicationMap[a.medicationCourse.clinicMedicationId];
                    const medicationSubType = medication.subtypes
                        .find((el) => el.id === a.medicationCourse.medicationSubtypeId);
                    const productionTime = medicationSubType
                        ? medicationSubType.productionTime
                        : medication.productionTime;

                    const productionStartAt = moment(
                        moment(a.datePlanStart)
                            .subtract(productionTime, 'days'),
                    ).endOf('day');
                    return { assignment: a, productionStartAt };
                })
                .filter((a) => a.productionStartAt < moment().endOf('day'))
                .map((a) => {
                    const {
                        assignment,
                        stumbledUponReadonlyAssignment,
                    } = this.findFurthestPredecessorAssignment(a.assignment);
                    return {
                        ...a,
                        parent: assignment,
                        stumbledUponReadonlyAssignment,
                    };
                })
                .reduce((p, assignmentMeta) => p.then(async (assignmentIds) => {
                    if (assignmentMeta.stumbledUponReadonlyAssignment
                        || assignmentMeta.parent.timeStarted
                        || assignmentMeta.parent.cancellationComment
                    ) {
                        const id = await new Promise((resolve, reject) => {
                            Modal.confirm({
                                content: `С учётом срока производства приём препарата "${
                                    assignmentMeta.assignment.name
                                }" невозможно начать в указанную дату. Продолжить сохранение протокола?`,
                                onOk: () => resolve(assignmentMeta.assignment.id),
                                onCancel: () => reject(),
                                cancelText: 'Нет',
                                okText: 'Да',
                            });
                        });
                        return assignmentIds.concat(id);
                    }

                    const daysDiff = moment.duration(moment().endOf('day').diff(
                        assignmentMeta.productionStartAt,
                    )).asDays();
                    const toDate = moment(assignmentMeta.parent.datePlanStart).add(
                        daysDiff,
                        'days',
                    );
                    const id = await new Promise((resolve) => {
                        Modal.confirm({
                            content: `С учётом срока производства приём препарата "${
                                assignmentMeta.assignment.name
                            }" невозможно начать в указанную дату. Перенести на ${
                                daysDiff
                            } дней?`,
                            onOk: () => {
                                this.updateAssignmentPlannedStartDate(
                                    assignmentMeta.parent,
                                    toDate,
                                );
                                resolve(null);
                            },
                            onCancel: () => resolve(assignmentMeta.assignment.id),
                            cancelText: 'Нет',
                            okText: 'Да',
                        });
                    });
                    return assignmentIds.concat(id);
                }), Promise.resolve([]));
            return [...await promise, ...confirmedAssignments];
        },
        validatePrescriptions() {
            this.$refs.prescriptions.validateForms();
        },
        onOnlyMedicationChange(checked) {
            if (!checked) {
                return;
            }
            const ids = Object.keys(this.checkedAssignments).filter((id) => {
                if (!this.checkedAssignments[id]) {
                    return false;
                }
                const assignment = this.patientAssignmentsMap[id];
                if (!assignment) {
                    return false;
                }
                return assignment.medicationCourse;
            });
            this.checkedAssignments = {};
            ids.forEach((id) => {
                this.checkedAssignments[id] = checked;
            });
        },

        onMedicationSelect(id) {
            this.resetSorting();
            const subtype = this.subtypesMap[id];
            const medication = this.medicationMap[subtype ? subtype.parentId : id];
            const { schemeId } = medication;

            const recipes = subtype?.recipes?.length
                ? subtype.recipes
                : medication.recipes;
            let times = [];
            let daysTakePeriod = null;
            if (recipes.length === 1) {
                times = recipes[0].times.map(
                    (dose) => ({
                        ...dose,
                        id: undefined,
                    }),
                );
                daysTakePeriod = recipes[0].daysTakePeriod;
            }

            this.enqueueModals({
                type: 'medicationInstruction',
                payload: {
                    course: {
                        clinicMedicationId: subtype ? subtype.parentId : id,
                        productionFreq: schemeId ? null : this.noSchemeMedicationProdFreq,
                        medicationSubtypeId: subtype ? subtype.id : null,
                        daysTakePeriod,
                        times,
                        donorId: null,
                    },
                    disabled: false,
                },
            });
        },

        onTemplateSelect(id) {
            this.resetSorting();
            this.enqueueModals({
                type: 'templateCompletion',
                payload: this.templatesMap[id].records,
            });
        },

        onMedicationSubtypesSelectionCancel() {
            const patientAssignment = this.getChangedAssignment(
                this.currentModal.payload.good,
            );
            if (!patientAssignment
                || patientAssignment.deleted
                || patientAssignment.timeStarted
                || patientAssignment.cancellationComment
            ) {
                this.closeModal();
                return;
            }

            if ((patientAssignment.medicationAssignmentInfo || []).some(
                ({ clinicMedicationId, medicationSubtypeId }) => {
                    const { schemeId } = this.medicationMap[clinicMedicationId];
                    return (!schemeId || schemeId === 3) && !medicationSubtypeId;
                },
            )) {
                this.$emit('change-assignment', patientAssignment, 'deleted', true);
                this.cancelModals('medicationInstruction');
            }

            this.closeModal();
        },

        onMedicationSubtypesSelect(result) {
            const patientAssignment = this.getChangedAssignment(result);

            if (!patientAssignment) {
                throw new Error('No patient assignment found.');
            }

            if (!this.currentModal.payload.initialRun) {
                this.$emit('change-assignment', patientAssignment, 'modified', true);
            }
            this.$emit(
                'change-assignment',
                patientAssignment,
                'medicationAssignmentInfo',
                result.medicationAssignmentInfo,
                this.currentModal.payload.initialRun,
            );

            this.enqueueModals(
                result.medicationAssignmentInfo.map((rec) => {
                    const { schemeId } = this.medicationMap[rec.clinicMedicationId];
                    return {
                        type: 'medicationInstruction',
                        payload: {
                            course: {
                                clinicMedicationId: rec.clinicMedicationId,
                                medicationSubtypeId: rec.medicationSubtypeId,
                                daysTakePeriod: null,
                                times: [],
                                donorId: rec.donorId,
                                productionFreq: schemeId ? null : this.noSchemeMedicationProdFreq,
                            },
                            disabled: false,
                            addMedicationAfterGood: true,
                        },
                    };
                }),
            );
            this.closeModal();
        },

        updatePrescriptionRules(rule) {
            const assignmentsToUpdate = Object.keys(this.checkedAssignments)
                .filter((id) => this.checkedAssignments[id])
                .map((id) => this.patientAssignmentsMap[id]);
            assignmentsToUpdate.forEach((assignment) => {
                this.$emit('change-assignment', assignment, 'ruleCode', rule.ruleCode);
                if (rule.overAssignmentId) {
                    const localIdMatch = rule.overAssignmentId.match(/localId-([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/);
                    const idMatch = rule.overAssignmentId.match(/id-(\d+)/);
                    if (localIdMatch) {
                        this.$emit(
                            'change-assignment',
                            assignment,
                            'overAssignmentLocalId',
                            localIdMatch[1],
                        );
                    } else if (idMatch) {
                        this.$emit(
                            'change-assignment',
                            assignment,
                            'overAssignmentId',
                            idMatch[1],
                        );
                    } else {
                        throw new Error(`Unknown assignment id: ${rule.overAssignmentId}`);
                    }
                }
                switch (rule.ruleCode) {
                case 'MANUAL_DATE':
                    this.$emit(
                        'change-assignment',
                        assignment,
                        'datePlanStart',
                        rule.datePlanStart,
                    );
                    break;
                case 'AFTER_MED_COURSE_START':
                case 'AFTER_MED_COURSE_END':
                case 'AFTER_GOOD_CREATED':
                    this.$emit(
                        'change-assignment',
                        assignment,
                        'overDaysCount',
                        rule.overDaysCount,
                    );
                    break;
                default:
                    break;
                }
            });

            assignmentsToUpdate.forEach((assignment) => {
                const precedingAssignment = this.getPrecedingAssignment(assignment);
                if (precedingAssignment) {
                    this.updateAssignmentPlannedStartDateUsingPrecedingAssignment(
                        precedingAssignment,
                        assignment,
                    );
                    return;
                }

                this.updateAssignmentPlannedStartDate(
                    assignment,
                    assignment.datePlanStart,
                );
            });

            this.checkedAssignments = {};
        },
        onBiomaterialSpecified(specifiedBiomaterial) {
            Object.keys(specifiedBiomaterial).forEach((goodAssignmentId) => {
                const surveysMap = specifiedBiomaterial[goodAssignmentId];
                const goodAssignment = this.patientAssignmentsMap[goodAssignmentId];
                const newSurveyBiomaterial = {
                    ...goodAssignment.surveyBiomaterial,
                    ...surveysMap,
                };

                this.$emit(
                    'change-assignment',
                    goodAssignment,
                    'surveyBiomaterial',
                    newSurveyBiomaterial,
                );
            });
            this.closeModal();
        },
        updateMedicationCourse(course) {
            const { id, localId } = course;
            const assignment = this.patientAssignments.find((x) => x.medicationCourse
                && ((id && x.medicationCourse.id === id)
                || (localId && x.medicationCourse.localId === localId)));
            if (!assignment) {
                return;
            }
            this.$emit('change-assignment', assignment, 'medicationCourse', course);
            this.updateAssignmentPlannedStartDate(
                assignment,
                assignment.datePlanStart,
            );
        },

        addMedicationCourseFromTemplate(templateAssignment) {
            templateAssignment.justCreated = true;
            templateAssignment.userCreateName = localStorage.username;
            templateAssignment.medicationCourse.id = null;
            templateAssignment.medicationCourse.localId = templateAssignment.localId;
            templateAssignment.overDaysCount = templateAssignment.overDaysCount || 0;
            const { times: doses } = templateAssignment.medicationCourse;
            if (doses) {
                templateAssignment.medicationCourse.times = doses.map(
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    ({ id, ...dose }) => dose,
                );
            }

            this.$emit('add-assignment', templateAssignment);

            const medication = this.medicationMap[
                templateAssignment.medicationCourse.clinicMedicationId];

            if (!doses
                || !doses.length
                || (medication.allowDonorBiomaterial
                    && !templateAssignment.medicationCourse.skipDonor)
            ) {
                this.enqueueModals({
                    type: 'medicationInstruction',
                    payload: {
                        course: {
                            ...templateAssignment.medicationCourse,
                            times: templateAssignment.medicationCourse.times || [],
                            productionFreq: medication.schemeId
                                ? null
                                : this.noSchemeMedicationProdFreq,
                        },
                        disabled: false,
                    },
                });
            }
        },

        onFixMedicationPeriodClick(record) {
            if (!this.noSchemeMedicationProdFreq) {
                showErrorNotification(
                    'Нет необходимых настроек... Пожалуйста, перезагрузите страницу.',
                );
                return;
            }
            this.onMedicationCourseCreate({
                ...record.medicationCourse,
                productionFreq: this.noSchemeMedicationProdFreq,
            });
        },

        onMedicationCourseCreate(course) {
            let addMedicationAfterGood = false;
            if (this.showMedicationInstructionModal) {
                addMedicationAfterGood = this.currentModal.payload.addMedicationAfterGood;
            }
            this.closeModal();
            if ((course.id || course.localId)) {
                this.updateMedicationCourse(course);
                return;
            }

            const newMedication = {
                medicationCourse: {},
            };
            const medication = this.medicationMap[course.clinicMedicationId];
            newMedication.medicationCourse.times = course.times;
            newMedication.medicationCourse.daysTakePeriod = course.daysTakePeriod;
            newMedication.medicationCourse.productionFreq = course.productionFreq;
            newMedication.medicationCourse.clinicMedicationId = medication.id;

            if (course.medicationSubtypeId) {
                newMedication.medicationCourse.medicationSubtypeId = course.medicationSubtypeId;
            }
            if (medication && medication.allowDonorBiomaterial) {
                newMedication.medicationCourse.donorId = course.donorId;
            }
            newMedication.justCreated = true;
            newMedication.name = medication.name;
            newMedication.ruleCode = 'MANUAL_DATE';
            newMedication.datePlanStart = this.$moment().add(medication.productionTime, 'days');
            newMedication.overDaysCount = 0;
            newMedication.userCreateName = localStorage.username;
            newMedication.localId = newMedication.localId || uuidv4();
            newMedication.medicationCourse.localId = newMedication.localId;

            this.$emit(
                'add-assignment',
                newMedication,
                addMedicationAfterGood ? 1 : 0,
            );
        },

        onGoodSelect(value, template) {
            this.resetSorting();
            const newGood = {};
            const good = this.goodsMap[value];
            newGood.clinicGoodPrice = good.price ? good.price.value : 0;
            newGood.userCreateName = localStorage.username;
            newGood.justCreated = true;
            newGood.clinicGoodId = good.id;
            newGood.name = good.name;
            newGood.unaccepted = false;
            newGood.notifyAfterSurveysIsReady = false;
            newGood.datePlanStart = this.$moment();
            newGood.ruleCode = 'MANUAL_DATE';
            newGood.overDaysCount = 0;
            if (template) {
                newGood.localId = template.localId;
                newGood.overAssignmentLocalId = template.overAssignmentLocalId;
                newGood.medicationAssignmentInfo = template.medicationAssignmentInfo;
                newGood.ruleCode = template.ruleCode;
                newGood.overDaysCount = template.overDaysCount == null ? 0 : template.overDaysCount;
                newGood.notifyAfterSurveysIsReady = !!template.notifyAfterSurveysAreReady;
                newGood.datePlanStart = template.datePlanStart;
            }
            newGood.localId = newGood.localId || uuidv4();
            this.onlyMedication = false;

            newGood.surveyBiomaterial = good.surveyTypes.filter(
                (surveyType) => surveyType.primaryBiomaterialId,
            ).reduce((surveyBiomaterial, surveyType) => {
                const templateSurveyBiomaterial = (template
                    && template.surveyBiomaterial) || {};
                // eslint-disable-next-line no-param-reassign
                surveyBiomaterial[surveyType.id] = templateSurveyBiomaterial[surveyType.id]
                    || surveyType.primaryBiomaterialId;
                return surveyBiomaterial;
            }, {});

            const surveysWithBiomaterial = good.surveyTypes.filter(
                (surveyType) => surveyType.biomaterial.length > 1,
            );
            if (surveysWithBiomaterial.length) {
                this.enqueueModals({
                    type: 'surveyBiomaterial',
                    payload: {
                        data: [{
                            localId: newGood.localId,
                            goodName: newGood.name,
                            surveys: surveysWithBiomaterial.map(
                                ({ id, biomaterial, name }) => ({
                                    id,
                                    name,
                                    biomaterial,
                                    biomaterialId: newGood.surveyBiomaterial[id],
                                }),
                            ),
                        }],
                        disabled: false,
                    },
                });
            }

            if (newGood.medicationAssignmentInfo && newGood.medicationAssignmentInfo.some(
                (record) => {
                    const medication = this.medicationMap[record.clinicMedicationId];
                    return (this.medicationAcceptsSubtype(medication)
                        && !record.medicationSubtypeId
                    ) || (medication.allowDonorBiomaterial && !record.skipDonor);
                },
            )) {
                this.enqueueModals({
                    type: 'medicationSubtypes',
                    payload: {
                        good: {
                            ...newGood,
                            clinicMedications: newGood.medicationAssignmentInfo.map(
                                (record) => this.medicationMap[record.clinicMedicationId],
                            ),
                        },
                        disabled: false,
                        allowToSkipDonor: true,
                        initialRun: true,
                    },
                });
            }

            if (!good.clinicMedications || !good.clinicMedications.length || template) {
                this.$emit('add-assignment', newGood);
                return;
            }

            newGood.medicationAssignmentInfo = [];
            const neitherDonorNorSubtypeMedication = [];

            good.clinicMedications.forEach((medication) => {
                if (this.medicationAcceptsSubtype(medication)
                    || medication.allowDonorBiomaterial
                ) {
                    newGood.medicationAssignmentInfo.push({
                        clinicMedicationId: medication.id,
                        medicationSubtypeId: null,
                        donorId: null,
                    });
                    return;
                }
                neitherDonorNorSubtypeMedication.push(medication.id);
            });

            this.$emit('add-assignment', newGood);

            if (newGood.medicationAssignmentInfo.length > 0) {
                this.enqueueModals({
                    type: 'medicationSubtypes',
                    payload: {
                        good: {
                            ...newGood,
                            clinicMedications: newGood.medicationAssignmentInfo.map(
                                (record) => this.medicationMap[record.clinicMedicationId],
                            ),
                        },
                        disabled: false,
                        allowToSkipDonor: false,
                        initialRun: true,
                    },
                });
            }

            // NOTE: these windows should go after the previous one
            this.enqueueModals(
                neitherDonorNorSubtypeMedication.map((id) => {
                    const { schemeId } = this.medicationMap[id];
                    return {
                        type: 'medicationInstruction',
                        payload: {
                            course: {
                                clinicMedicationId: id,
                                daysTakePeriod: null,
                                donorId: null,
                                medicationSubtypeId: null,
                                productionFreq: schemeId ? null : this.noSchemeMedicationProdFreq,
                                times: [],
                            },
                            disabled: false,
                            addMedicationAfterGood: true,
                        },
                    };
                }),
            );
        },

        medicationAcceptsSubtype(medication) {
            return medication.schemeId !== 1;
        },

        replaceIdsWithLocalOnes(assignments) {
            const localIdMap = {};
            const updatedAssignments = assignments.map((assignment) => {
                const localId = uuidv4();
                localIdMap[assignment.id] = localId;
                const updatedAssignment = {
                    ...assignment,
                    localId,
                    id: undefined,
                };
                return updatedAssignment;
            });

            return updatedAssignments.map((assignment) => {
                let overAssignmentLocalId;
                const { overAssignmentId } = assignment;
                if (overAssignmentId) {
                    overAssignmentLocalId = localIdMap[overAssignmentId];
                }
                return {
                    ...assignment,
                    overAssignmentLocalId,
                    overAssignmentId: undefined,
                };
            });
        },

        setTemplateAssignmentsDates(templateAssignments) {
            const assignmentsMap = keyBy(templateAssignments, 'localId');

            templateAssignments.forEach((templateAssignment) => {
                this.setTemplateAssignmentDate(
                    templateAssignment,
                    assignmentsMap,
                    1,
                );
            });
        },

        setTemplateAssignmentDate(templateAssignment, assignmentsMap) {
            if (templateAssignment.datePlanStart || !templateAssignment.overAssignmentLocalId) {
                return templateAssignment.datePlanStart;
            }

            const precedingAssignment = assignmentsMap[templateAssignment.overAssignmentLocalId];
            if (!precedingAssignment) {
                return undefined;
            }

            const date = this.setTemplateAssignmentDate(
                precedingAssignment,
                assignmentsMap,
            );
            if (!date) {
                return date;
            }

            let daysToAdd = templateAssignment.overDaysCount || 0;
            if (templateAssignment.ruleCode === 'AFTER_MED_COURSE_END') {
                daysToAdd += precedingAssignment.medicationCourse.daysTakePeriod;
            }

            templateAssignment.datePlanStart = this.$moment(date).add(daysToAdd, 'days');
            return date;
        },

        onTemplateCompleted(templateAssignments) {
            this.closeModal();
            const updatedTemplateAssignments = this.replaceIdsWithLocalOnes(
                templateAssignments,
            );
            this.setTemplateAssignmentsDates(updatedTemplateAssignments);

            const [medication, goods] = partition(
                updatedTemplateAssignments,
                (assignment) => assignment.medicationCourse,
            );

            medication.forEach((assignment) => this.addMedicationCourseFromTemplate(
                assignment,
            ));

            goods.forEach((assignment) => this.onGoodSelect(
                assignment.clinicGoodId,
                assignment,
            ));
        },

        checkAllAssignments(checked) {
            this.checkedAssignments = {};
            const checkedResult = {
                checked,
                ids: checked ? this.shownActivePatientAssignments.map(
                    this.getAssignmentId,
                ) : [],
            };
            checkedResult.ids.forEach((id) => {
                this.checkedAssignments[id] = checked;
            });
        },
        onPrescriptionRemove(record) {
            const changedAssignment = this.getChangedAssignment(record);
            if (!changedAssignment) {
                return;
            }
            this.$emit('change-assignment', record, 'modified', true);
            this.$emit('change-assignment', changedAssignment, 'deleted', true);
            const followingAssignments = this.getFollowingAssignments(record).filter(
                (assignment) => !assignment.deleted
                    && !assignment.timeStarted
                    && !assignment.cancellationComment,
            );
            followingAssignments.forEach((follower) => {
                this.$emit('change-assignment', follower, 'modified', true);
                this.$emit('change-assignment', follower, 'overAssignmentLocalId', null);
                this.$emit('change-assignment', follower, 'overAssignmentId', null);
                this.updateAssignmentPlannedStartDate(follower, null);
            });
        },
        getFollowingAssignments(assignment) {
            return this.patientAssignments.filter(
                (follower) => (
                    assignment.localId && follower.overAssignmentLocalId === assignment.localId
                ) || (
                    assignment.id && follower.overAssignmentId === assignment.id
                ),
            );
        },
        updateAssignmentDayGap(record, value) {
            const changedAssignment = this.getChangedAssignment(record);
            if (!changedAssignment) {
                return;
            }
            this.$emit('change-assignment', record, 'modified', true);
            this.$emit('change-assignment', record, 'overDaysCount', value);
            if (!changedAssignment.datePlanStart) {
                return;
            }
            const precedingAssignment = this.getPrecedingAssignment(record);
            if (!precedingAssignment) {
                return;
            }
            this.updateAssignmentPlannedStartDateUsingPrecedingAssignment(
                precedingAssignment,
                changedAssignment,
            );
        },
        updateAssignmentPlannedStartDateUsingPrecedingAssignment(
            precedingAssignment,
            assignmentToUpdate,
        ) {
            if (!precedingAssignment.datePlanStart) {
                this.updateAssignmentPlannedStartDate(assignmentToUpdate, null);
                return;
            }
            let daysToAdd = assignmentToUpdate.overDaysCount;
            if (assignmentToUpdate.ruleCode === 'AFTER_MED_COURSE_END') {
                daysToAdd += precedingAssignment.medicationCourse.daysTakePeriod;
            }
            this.updateAssignmentPlannedStartDate(
                assignmentToUpdate,
                this.$moment(
                    precedingAssignment.datePlanStart,
                ).add(daysToAdd, 'days'),
            );
        },
        onAssignmentEdit(assignment) {
            const {
                clinicGoodId,
                medicationCourse,
                timeStarted,
                cancellationComment,
                deleted,
            } = assignment;
            const isDisabled = !!(
                timeStarted
                || cancellationComment
                || deleted
            );
            if (!clinicGoodId) {
                this.enqueueModals({
                    type: 'medicationInstruction',
                    payload: {
                        course: medicationCourse,
                        disabled: isDisabled,
                    },
                });
                return;
            }

            const { medicationAssignmentInfo: medAInfo } = assignment;
            const { clinicMedications } = this.goodsMap[clinicGoodId];
            if (clinicMedications && clinicMedications.length) {
                // NOTE: medication can be added into the good
                // after the good has been added into the protocol
                // -> medicationAssignmentInfo can contain less medication than needed
                this.enqueueModals({
                    type: 'medicationSubtypes',
                    payload: {
                        good: {
                            ...assignment,
                            clinicMedications,
                            medicationAssignmentInfo: clinicMedications.map(({ id }) => {
                                const info = medAInfo && medAInfo.find(
                                    (rec) => rec.clinicMedicationId === id,
                                );
                                return info || { clinicMedicationId: id };
                            }),
                        },
                        disabled: isDisabled,
                        allowToSkipDonor: false,
                        initialRun: false,
                    },
                });
            }

            const surveysWithSeveralBiomaterialOptions = this.goodsMap[clinicGoodId]
                .surveyTypes.filter((surveyType) => surveyType.biomaterial.length);
            if (!surveysWithSeveralBiomaterialOptions.length) {
                return;
            }

            this.enqueueModals({
                type: 'surveyBiomaterial',
                payload: {
                    data: [{
                        id: assignment.id,
                        localId: assignment.localId,
                        goodName: assignment.name,
                        surveys: surveysWithSeveralBiomaterialOptions.map(
                            ({ id, biomaterial, name }) => ({
                                id,
                                name,
                                biomaterial,
                                biomaterialId: assignment.surveyBiomaterial[id],
                            }),
                        ),
                    }],
                    disabled: isDisabled,
                },
            });
        },
        getAssignmentId(record) {
            return record.id ? `id-${record.id}` : `localId-${record.localId}`;
        },
        checkAssignment({
            checked,
            id,
            localId,
        }) {
            const newId = id ? `id-${id}` : `localId-${localId}`;
            this.checkedAssignments[newId] = checked;
        },
        onRuleCodeChange(record, value) {
            const changedAssignment = this.getChangedAssignment(record);
            if (!changedAssignment) {
                return;
            }
            const fromGoodToMedicationRule = changedAssignment.ruleCode === 'AFTER_GOOD_CREATED'
                && (value === 'AFTER_MED_COURSE_START' || value === 'AFTER_MED_COURSE_END');
            const fromMedicationToGoodRule = value === 'AFTER_GOOD_CREATED'
                && (changedAssignment.ruleCode === 'AFTER_MED_COURSE_START'
                || changedAssignment.ruleCode === 'AFTER_MED_COURSE_END');
            this.$emit('change-assignment', changedAssignment, 'ruleCode', value);
            this.$emit('change-assignment', changedAssignment, 'modified', true);
            if (fromGoodToMedicationRule || fromMedicationToGoodRule) {
                this.$emit('change-assignment', changedAssignment, 'overAssignmentLocalId', null);
                this.$emit('change-assignment', changedAssignment, 'overAssignmentId', null);
                this.updateAssignmentPlannedStartDate(changedAssignment, null);
                return;
            }

            if (value === 'MANUAL_DATE') {
                this.$emit('change-assignment', changedAssignment, 'overAssignmentLocalId', null);
                this.$emit('change-assignment', changedAssignment, 'overAssignmentId', null);
                this.updateAssignmentPlannedStartDate(changedAssignment, null);
                return;
            }

            const precedingAssignment = this.getPrecedingAssignment(record);
            if (!precedingAssignment) {
                return;
            }

            this.updateAssignmentPlannedStartDateUsingPrecedingAssignment(
                precedingAssignment,
                changedAssignment,
            );
        },
        onPrecedingAssignmentChange(record, value) {
            const { id, isLocal } = this.getAttributesOfPrefixedId(value);
            const precedingAssignment = this.patientAssignments.find(
                (a) => a[isLocal ? 'localId' : 'id'] === id,
            );
            const changedAssignment = this.getChangedAssignment(record);
            if (!precedingAssignment || !changedAssignment) {
                return;
            }
            this.$emit(
                'change-assignment',
                changedAssignment,
                isLocal ? 'overAssignmentLocalId' : 'overAssignmentId',
                id,
            );
            this.$emit(
                'change-assignment',
                changedAssignment,
                'modified',
                true,
            );
            this.updateAssignmentPlannedStartDateUsingPrecedingAssignment(
                precedingAssignment,
                changedAssignment,
            );
        },
        getAttributesOfPrefixedId(prefixedId) {
            const localIdMatch = prefixedId.match(/localId-([a-z0-9-]*$)/);
            if (localIdMatch) {
                const [, localId] = localIdMatch;
                return {
                    id: localId,
                    isLocal: true,
                };
            }
            const idMatch = prefixedId.match(/id-(\d+)/);
            if (idMatch) {
                const [, id] = idMatch;
                return {
                    id: parseInt(id, 10),
                    isLocal: false,
                };
            }
            throw new Error(`Unknown id format: ${prefixedId}`);
        },
        updateAssignmentPlannedStartDate(assignment, newDate) {
            if (assignment.timeStarted
                || assignment.cancellationComment
                || assignment.deleted
            ) {
                return;
            }

            this.$emit('change-assignment', assignment, 'modified', true);
            this.$emit('change-assignment', assignment, 'datePlanStart', newDate);
            const followingAssignments = this.getFollowingAssignments(assignment);
            followingAssignments.forEach((follower) => {
                if (!newDate) {
                    this.updateAssignmentPlannedStartDate(follower, null);
                    return;
                }

                let daysToAdd = follower.overDaysCount;
                if (follower.ruleCode === 'AFTER_MED_COURSE_END') {
                    daysToAdd += assignment.medicationCourse.daysTakePeriod;
                }
                this.updateAssignmentPlannedStartDate(
                    follower,
                    this.$moment(
                        newDate,
                    ).add(daysToAdd, 'days'),
                );
            });
        },

        getPrecedingAssignment(assignment) {
            const { overAssignmentId, overAssignmentLocalId } = assignment;
            if (!overAssignmentId && !overAssignmentLocalId) {
                return null;
            }
            const id = overAssignmentId
                ? `id-${overAssignmentId}`
                : `localId-${overAssignmentLocalId}`;
            return this.patientAssignmentsMap[id];
        },

        getChangedAssignment(record) {
            const { id, localId } = record;
            return this.patientAssignments.find(
                (a) => (localId && a.localId === localId)
                    || (id && a.id === id),
            );
        },

        onSwitchActiveAssignments(checked) {
            this.isPrescriptionGridMaskVisible = true;
            setTimeout(() => {
                this.onlyActiveAssignments = checked;
                this.isPrescriptionGridMaskVisible = false;
            });
        },
    },
};
</script>

<style lang="scss">
.prescription-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -5px;
    flex-basis: fit-content;
    .prescription-header-selects {
        display: flex;
        flex-wrap: wrap;
        max-width: 70%;
    }
    .prescription-header-selects > div {
        min-width: 260px;
        max-width: 30%;
        margin: 5px;
    }
    .prescription-header-controls {
        display: flex;
        width: 260px;
        margin: 5px;
        align-items: center;
        justify-content: space-between;
    }
    .prescription-header-select-item {
        display: flex;
        align-items: center;
    }
    .prescription-header-add-icon {
        margin-bottom: -3px;
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 448'%3E%3Cdefs/%3E%3Cpath d='M41.373 278.627l160 160c12.496 12.497 32.758 12.497 45.255 0l160-160c12.495-12.497 12.495-32.758 0-45.255-12.497-12.497-32.759-12.497-45.256 0L256 338.745V32c0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32v306.745L86.627 233.372C80.379 227.124 72.189 224 64 224s-16.379 3.124-22.627 9.372c-12.497 12.497-12.497 32.758 0 45.255z'/%3E%3C/svg%3E");
        width: 1em;
        height: 1em;
        display: inline-block;
        mask-repeat: no-repeat;
        mask-position: center;
        transform: scaleX(1.2);
        margin-right: 5px;
        background-color: #555;
        transition: transform 0.1s ease-out;
    }
    .prescription-header-add-icon:hover {
        background-color: #333;
        transform: rotate(-90deg);
        transition: transform 0.1s ease-in;
    }
}
.prescription-table-controls {
    display: flex;
    justify-content: space-between;
}
</style>
