<script>
export default {
    props: {
        patientId: {
            type: Number,
            required: true,
        },
        fieldName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            data: [],
            isLoading: true,
            isLoadingFailed: false,
        };
    },

    computed: {
        history() {
            return this.data.map((rec) => ({
                ...rec,
                text: (rec[this.fieldName] || '').trim(),
            })).filter((rec) => rec.text);
        },
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        async fetchData() {
            this.isLoading = true;
            this.isLoadingFailed = false;
            try {
                const response = await this.$http.get(
                    `patient/${this.patientId}/protocol/history`,
                );
                this.data = response.data;
            } catch (err) {
                this.isLoadingFailed = true;
            }
            this.isLoading = false;
        },
    },

    render() {
        return this.$slots.default({
            history: this.history,
            isLoading: this.isLoading,
            isLoadingFailed: this.isLoadingFailed,
        });
    },
};
</script>
