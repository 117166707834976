<template>
    <div class="three-column-picker">
        <a-checkbox-group
            :value="modelValue"
            name="checkboxgroup"
            :options="separatedOptionsGroups[0]"
            @change="(e) => onValueChange('optionsGroup1', e)"
        />
        <a-checkbox-group
            :value="modelValue"
            name="checkboxgroup"
            :options="separatedOptionsGroups[1]"
            @change="(e) => onValueChange('optionsGroup2', e)"
        />
        <a-checkbox-group
            :value="modelValue"
            name="checkboxgroup"
            :options="separatedOptionsGroups[2]"
            @change="(e) => onValueChange('optionsGroup3', e)"
        />
    </div>
</template>

<script>
import { chunk, flatten } from 'lodash';

export default {
    props: {
        modelValue: {
            type: Array,
            required: false,
            default: () => [],
        },
        options: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: {
        'update:modelValue': null,
    },
    data() {
        return {
            initialized: false,
            optionsGroup1: [],
            optionsGroup2: [],
            optionsGroup3: [],
        };
    },
    computed: {
        targetValue() {
            return this.separatedOptionsGroups.map(
                (options) => options.map(
                    (option) => option.value,
                ).filter((option) => this.modelValue.indexOf(option) > -1),
            );
        },
        separatedOptionsGroups() {
            // create 3 groups of diagnoses
            // if length not devided by 3 - 4 groups (last with not devided elems)
            const chunks = chunk(
                this.options,
                (this.options.length < 3 ? 3 : this.options.length) / 3,
            );
            // populate if elems < 3
            while (chunks.length < 3) {
                chunks.push([]);
            }
            // if groups = 4 - distribute last in turn
            const groups = chunks.slice(0, 3);
            flatten(chunks.slice(3))
                .forEach((option, idx) => groups[idx].push(option));
            return groups;
        },
        selected() {
            return this.optionsGroup1
                .concat(this.optionsGroup2)
                .concat(this.optionsGroup3);
        },
    },
    watch: {
        selected() {
            this.$emit('update:modelValue', this.selected);
        },
    },
    methods: {
        onValueChange(optionsGroup, updatedData) {
            if (!this.initialized) {
                const [
                    optionsGroup1,
                    optionsGroup2,
                    optionsGroup3,
                ] = this.targetValue;
                this.optionsGroup1 = optionsGroup1;
                this.optionsGroup2 = optionsGroup2;
                this.optionsGroup3 = optionsGroup3;
                this.initialized = true;
            }
            this[optionsGroup] = updatedData;
        },
    },
};
</script>

<style lang="scss">
    .three-column-picker {
        min-height: 20px;
        display: flex;
        justify-content: space-between;
        .ant-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 20px 40px;
            width: 33.3vw;
            &:not(:first-child) {
                border-left: 1px solid #ccc;
            }
        }
    }
</style>
