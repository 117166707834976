<template>
    <loading-outlined />
</template>

<script>
import LoadingOutlined from '@ant-design/icons-vue/LoadingOutlined';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        LoadingOutlined,
    },
});
</script>
