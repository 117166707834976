<template>
    <div
        class="diagnoses-tree-picker"
    >
        <div class="diagnoses-tree-picker_switch">
            <h2>{{ title }}</h2>
            <a-switch
                v-if="colorMode"
                v-model:checked="showDeletedDiagnoses"
                data-test-id="show-deleted-diagnoses"
                checked-children="Отображать удаленные"
                un-checked-children="Отображать удаленные"
                size="default"
            />
        </div>
        <header class="diagnoses-tree-picker_header">
            <div
                v-for="entry in header"
                :key="entry"
            >
                {{ entry }}
            </div>
            <div style="width:100px" />
        </header>
        <li-draggable-tree
            :nodes="patientDiagnoses"
            :editing="editing || disabled"
            @parent-changed="changeParents"
        >
            <template #default="{ data, lvl }">
                <a-tooltip
                    placement="top"
                    :overlay-style="{maxWidth: '800px'}"
                >
                    <template #title>
                        <strong v-if="data.previous && data.helpers">
                            Изменено в протоколе
                            №{{ data.updatedIn }} от {{ data.updatedAt }}
                            врачом: {{ data.updatedBy }}</strong>
                        <ul
                            v-if="data.previous && data.helpers"
                            style="margin-right:30px"
                        >
                            <li
                                v-for=" entry in data.helpers"
                                :key="entry[0] + entry[1]"
                            >
                                {{ entry[0] }}: {{ entry[1] }} - {{ entry[2] }}
                            </li>
                        </ul>
                    </template>
                    <div
                        v-show="showDeletedDiagnoses
                            ? !(data.deleted && data.justCreated)
                            : !data.deleted && !(data.deleted && data.justCreated)"
                        :data-deleted="!!data.deleted"
                        class="diagnoses-tree-picker__item"
                        :style="{'background-color': getNodeColor(data)}"
                    >
                        <li-select
                            data-test-id="diagnoses-tree"
                            :class="getValidationClass('name', data)"
                            :style="`padding-left: ${(lvl)*20}px;`"
                            style="max-width:100%"
                            :disabled="!data.editing && !data.creating"
                            :options="diagnoses"
                            :placeholder="data.name"
                            id-key="id"
                            parent-key="parentId"
                            title-key="name"
                            tree-paths
                            clear-on-select
                            @select="(diagnosisId, diagnosis) =>
                                onDiagnosisChange(diagnosisId, data.id, diagnosis.path)"
                            @select-search="(e) => $emit('diagnosis-search', e)"
                        />
                        <a-select
                            v-if="fields.includes('stateCode')"
                            v-model:value="data.stateCode"
                            data-test-id="diagnosis-status-list"
                            :class="getValidationClass('stateCode', data)"
                            :dropdown-match-select-width="true"
                            :disabled="!data.editing && !data.creating"
                        >
                            <a-select-option
                                v-for="state in diagnoseStates"
                                :key="state.code"
                            >
                                {{ state.name }}
                            </a-select-option>
                        </a-select>
                        <a-month-picker
                            v-if="fields.includes('startByMonth')"
                            v-model:value="data.timeStart"
                            data-test-id="diagnosis-month-from"
                            :class="getValidationClass('timeStart', data)"
                            format="MMMM YYYY"
                            :suffix-icon="!data.editing && !data.creating ? ' ' : '' "
                            placeholder=""
                            :disabled="!data.editing && !data.creating"
                            @change="handleTimeStart(data)"
                        />
                        <a-date-picker
                            v-if="fields.includes('startByDay')"
                            v-model:value="data.timeStart"
                            data-test-id="diagnosis-date-from"
                            :class="getValidationClass('timeStart', data)"
                            format="DD.MM.YYYY"
                            :suffix-icon="!data.editing && !data.creating ? ' ' : '' "
                            placeholder=""
                            :disabled="!data.editing && !data.creating"
                            @change="handleTimeStart(data)"
                        />
                        <a-month-picker
                            v-if="fields.includes('endByMonth')"
                            v-model:value="data.timeEnd"
                            data-test-id="diagnosis-month-to"
                            :class="getValidationClass('timeEnd', data)"
                            :disabled-date="(date) => !data.timeStart || date <= data.timeStart"
                            format="MMMM YYYY"
                            :suffix-icon="!data.editing && !data.creating ? ' ' : '' "
                            placeholder=""
                            :disabled="!data.editing && !data.creating"
                        />
                        <a-date-picker
                            v-if="fields.includes('endByDay')"
                            v-model:value="data.timeEnd"
                            data-test-id="diagnosis-date-to"
                            :class="{
                                'validate-error': getValidationClass('timeEnd', data),
                                'item-not-active': data.stateCode !== 'CURED'
                            }"
                            :disabled-date="(date) => !data.timeStart || date <= data.timeStart"
                            format="DD.MM.YYYY"
                            :suffix-icon="!data.editing && !data.creating ? ' ' : '' "
                            placeholder=""
                            :disabled="!data.editing && !data.creating"
                        />
                        <a-select
                            v-if="attributes.length
                                && fields.includes('attributes')
                                && data.diagnosisId
                                && diagnosesMap[data.diagnosisId]
                                && diagnosesMap[data.diagnosisId].attributes.length"
                            data-test-id="diagnosis-attributes"
                            :mode="diagnosesMap[data.diagnosisId] ?
                                diagnosesMap[data.diagnosisId].attributeCountConstraint === 'SINGLE'
                                    ? undefined
                                    : 'multiple'
                                : undefined"
                            :value="getAttributeValue(data)"
                            class="attributes"
                            :class="getValidationClass('attributes', data)"
                            :dropdown-match-select-width="false"
                            :disabled="(!data.editing && !data.creating)
                                || !data.diagnosisId
                                || (!diagnosesMap[data.diagnosisId].attributes
                                    && !diagnosesMap[data.diagnosisId].attributes.length)"
                            @change="(selected) => data.attributes = Array.isArray(selected)
                                ? selected
                                : [selected]"
                        >
                            <a-select-option
                                v-for="attr of diagnosesMap[data.diagnosisId].attributes"
                                :key="attr"
                            >
                                {{ attributesMap[attr].name }}
                            </a-select-option>
                        </a-select>
                        <a-select
                            v-else
                            v-show="fields.includes('attributes')"
                            class="attributes"
                            disabled
                        />
                        <div
                            v-if="!data.mergeIsRequired && (disabled || data.deleted)"
                            class="diagnoses-tree-picker__controls"
                        >
                            <a-button
                                v-show="data.deleted
                                    && !data.previouslyDeleted
                                    && !disabled"
                                data-test-id="restore-deleted-diagnosis-btn"
                                :disabled="editing"
                                size="small"
                                @click="onDeletedRestore(data)"
                            >
                                <template #icon>
                                    <rollback-outlined />
                                </template>
                            </a-button>
                        </div>
                        <div
                            v-if="!disabled && (!data.deleted || data.mergeIsRequired)"
                            class="diagnoses-tree-picker__controls"
                        >
                            <a-button
                                v-show="controlsIsVisible(data)"
                                data-test-id="create-subdiagnosis-btn"
                                :disabled="editing"
                                size="small"
                                @click="onCreateClick(data)"
                            >
                                <template #icon>
                                    <plus-square-outlined />
                                </template>
                            </a-button>
                            <a-button
                                v-show="data.editing"
                                data-test-id="finish-diagnosis-editing-btn"
                                size="small"
                                @click="onEditConfirmClick(data)"
                            >
                                <template #icon>
                                    <check-outlined />
                                </template>
                            </a-button>
                            <a-button
                                v-show="data.creating"
                                data-test-id="finish-diagnosis-creation-btn"
                                :disabled="!editing && !data.creating"
                                size="small"
                                @click="onCreateConfirmClick(data)"
                            >
                                <template #icon>
                                    <check-outlined />
                                </template>
                            </a-button>
                            <a-button
                                v-show="data.creating"
                                data-test-id="cancel-diagnosis-create-btn"
                                :disabled="!editing && !data.creating"
                                size="small"
                                @click="onCreateCancelClick(data.id)"
                            >
                                <template #icon>
                                    <close-outlined />
                                </template>
                            </a-button>
                            <a-button
                                v-show="controlsIsVisible(data)"
                                data-test-id="edit-diagnosis-btn"
                                :disabled="editing"
                                size="small"
                                @click="onEditClick(data)"
                            >
                                <template #icon>
                                    <edit-outlined />
                                </template>
                            </a-button>
                            <a-button
                                v-show="controlsIsVisible(data)"
                                data-test-id="delete-diagnosis-btn"
                                :disabled="editing"
                                size="small"
                                @click="onDeleteClick(data)"
                            >
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                            <a-tooltip placement="top">
                                <template #title>
                                    <span>Согласовать</span>
                                </template>
                                <a-button
                                    v-show="data.mergeIsRequired"
                                    data-test-id="diagnosis-merge-apply-btn"
                                    size="small"
                                    @click="onMergeApply(data)"
                                >
                                    <template #icon>
                                        <check-outlined />
                                    </template>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip placement="top">
                                <template #title>
                                    <span>Вернуть мою версию</span>
                                </template>
                                <a-button
                                    v-show="data.mergeIsRequired"
                                    data-test-id="diagnosis-merge-reject-btn"
                                    size="small"
                                    @click="onMergeReject(data)"
                                >
                                    <template #icon>
                                        <close-outlined />
                                    </template>
                                </a-button>
                            </a-tooltip>
                        </div>
                    </div>
                </a-tooltip>
            </template>
        </li-draggable-tree>
        <div class="plus-diagnosis-wrapper">
            <a-button
                v-if="!disabled"
                size="small"
                :disabled="editing"
                data-test-id="add-new-diagnosis-btn"
                @click="onCreateClick(null)"
            >
                <template #icon>
                    <plus-square-outlined />
                </template>
                Новый диагноз
            </a-button>
        </div>
    </div>
</template>

<script>
import { keyBy, cloneDeep, isEqual } from 'lodash';

import PlusSquareOutlined from '@ant-design/icons-vue/PlusSquareOutlined';
import EditOutlined from '@ant-design/icons-vue/EditOutlined';
import CloseOutlined from '@ant-design/icons-vue/CloseOutlined';
import CheckOutlined from '@ant-design/icons-vue/CheckOutlined';
import DeleteOutlined from '@ant-design/icons-vue/DeleteOutlined';
import RollbackOutlined from '@ant-design/icons-vue/RollbackOutlined';

import LiDraggableTree from '../base/LiTree/LiDraggableTree.vue';
import LiSelect from '../base/LiSelect.vue';

export default {
    components: {
        LiDraggableTree,
        LiSelect,
        PlusSquareOutlined,
        EditOutlined,
        CloseOutlined,
        CheckOutlined,
        DeleteOutlined,
        RollbackOutlined,
    },

    props: {
        diagnoses: {
            type: Array,
            default: () => [],
        },
        patientDiagnoses: {
            type: Array,
            default: () => [],
        },
        header: {
            type: Array,
            default: () => [],
        },
        required: {
            type: Array,
            default: () => [],
        },
        fields: {
            type: Array,
            default: () => ['startByMonth', 'endByMonth'],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        colorMode: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Дерево диагнозов',
        },
    },

    emits: {
        'diagnosis-changed': null,
        'diagnosis-added': null,
        'diagnosis-removed': null,
        'diagnosis-replaced': null,
        'diagnosis-search': null,
    },

    data() {
        return {
            attributes: [],
            diagnoseStates: [{
                code: 'UNCONFIRMED',
                name: 'Не подтверждён',
                icon: 'warning',
            }, {
                code: 'CONFIRMED',
                name: 'Подтверждён',
                icon: 'check-circle',
            }, {
                code: 'CURED',
                name: 'Вылечен',
                icon: 'medicine-box',
            }],
            editing: false,
            editingEntry: null,
            showDeletedDiagnoses: true,
        };
    },
    computed: {
        attributesMap() {
            return keyBy(this.attributes, 'id');
        },
        diagnosesMap() {
            return keyBy(this.diagnoses, 'id');
        },
        ids() {
            return this.patientDiagnoses.map((x) => x.id);
        },
    },
    watch: {
        disabled: {
            immediate: true,
            handler(isDisabled) {
                this.showDeletedDiagnoses = !isDisabled;
            },
        },
        patientDiagnoses: {
            immediate: true,
            deep: true,
            handler() {
                this.editing = this.patientDiagnoses.some(
                    (diagnosis) => diagnosis.editing || diagnosis.creating,
                );
            },
        },
    },
    async created() {
        if (this.fields.includes('attributes')) {
            this.attributes = (await this.$http.get('refs/attributes')).data;
        }
    },
    methods: {
        changeParents(node, key, value) {
            const idx = this.ids.indexOf(node);
            const data = this.patientDiagnoses[idx];
            this.$emit('diagnosis-changed', idx, 'modified', true);
            this.$emit('diagnosis-changed', idx, key, value, data);
            if (!data) {
                return;
            }
            this.$emit('diagnosis-changed', idx, 'justUpdated', true);
        },
        controlsIsVisible(data) {
            return !data.editing && !data.creating && !data.mergeIsRequired;
        },
        onMergeApply(data) {
            const idx = this.ids.indexOf(data.id);
            this.$emit('diagnosis-changed', idx, 'mergeIsRequired', undefined);
            if (data.deleteByMerge) {
                this.$emit('diagnosis-removed', idx);
                return;
            }
            this.$emit('diagnosis-changed', idx, 'previous', undefined);
            this.$emit('diagnosis-changed', idx, 'deleted', undefined);
        },
        onMergeReject(data) {
            const idx = this.ids.indexOf(data.id);
            this.$emit('diagnosis-replaced', idx, data.previous);
        },
        getNodeColor(data) {
            if (!this.colorMode) {
                return 'none';
            }
            if (data.previous) {
                return '#ffffb5';
            }
            if (data.deleted && !data.created) {
                return '#f5e5dd';
            }
            if (data.justUpdated) {
                return '#ddfeff';
            }
            if (data.justCreated) {
                return '#ddf5d3';
            }
            return '#fff';
        },
        handleTimeStart(data) {
            if (!data.timeStart) {
                // eslint-disable-next-line no-param-reassign
                data.timeEnd = null;
            }
        },
        getValidationClass(key, data) {
            if (this.required.includes('stateCode') && data.stateCode === 'UNCONFIRMED') {
                return '';
            }
            if (key === 'timeStart' && ['CONFIRMED', 'CURED'].includes(data.stateCode)) {
                return !data.timeStart ? 'validate-error' : '';
            }
            if (key === 'timeEnd' && data.stateCode === 'CURED') {
                return !data.timeEnd ? 'validate-error' : '';
            }
            return this.required.includes(key) && !data[key]
                ? 'validate-error'
                : '';
        },
        getAttributeValue(data) {
            if (!this.diagnosesMap[data.diagnosisId]) {
                return null;
            }
            if (this.diagnosesMap[data.diagnosisId].attributeCountConstraint === 'SINGLE') {
                if (!data.attributes.length) {
                    return null;
                }
                return data.attributes[0];
            }
            return data.attributes;
        },
        onDiagnosisChange(diagnosisId, dataId, path) {
            const idx = this.ids.indexOf(dataId);
            this.$emit('diagnosis-changed', idx, 'attributes', []);
            this.$emit('diagnosis-changed', idx, 'diagnosisId', diagnosisId);
            this.$emit('diagnosis-changed', idx, 'name', path);
            this.$emit('diagnosis-changed', idx, 'modified', true);
        },
        findChildren(dataId) {
            const children = this.patientDiagnoses
                .filter((x) => x.parentId === dataId).map((x) => x.id);
            if (children.length === 0) {
                return [];
            }
            return children.concat(children.map((x) => this.findChildren(x)).flat());
        },
        onDeleteClick(data) {
            const idx = this.ids.indexOf(data.id);
            this.findChildren(data.id).forEach((childId) => {
                const childIdx = this.ids.indexOf(childId);
                this.$emit('diagnosis-changed', childIdx, 'modified', true);
                this.$emit('diagnosis-changed', childIdx, 'deleted', true);
            });
            this.$emit('diagnosis-changed', idx, 'modified', true);
            this.$emit('diagnosis-changed', idx, 'deleted', true);
        },
        onDeletedRestore(data) {
            const idx = this.ids.indexOf(data.id);
            this.$emit('diagnosis-changed', idx, 'deleted', undefined);
        },
        onEditClick(data) {
            this.editing = true;
            this.$emit('diagnosis-changed', this.ids.indexOf(data.id), 'editing', true);
            this.editingEntry = cloneDeep(data);
        },
        onEditConfirmClick(data) {
            const idx = this.ids.indexOf(data.id);
            if (!this.validateInputs(data)) {
                return;
            }
            if (!isEqual(data, this.editingEntry)) {
                this.$emit('diagnosis-changed', idx, 'justUpdated', !data.created);
                this.$emit('diagnosis-changed', idx, 'modified', true);
            }
            this.$emit('diagnosis-changed', idx, 'editing', undefined);
            this.editing = false;
        },
        onCreateClick(data) {
            this.editing = true;
            this.$emit(
                'diagnosis-added',
                {
                    justCreated: true,
                    localId: Date.now(),
                    parentId: data ? data.id : null,
                    name: null,
                    stateCode: null,
                    stateName: null,
                    timeStart: null,
                    timeEnd: null,
                    attributes: [],
                    creating: true,
                    get id() { return (this.localId); },
                },
                data,
            );
        },
        onCreateConfirmClick(data) {
            if (!this.validateInputs(data)) {
                return;
            }
            this.$emit('diagnosis-changed', this.ids.indexOf(data.id), 'creating', undefined);
            this.editing = false;
        },
        onCreateCancelClick(dataId) {
            this.$emit('diagnosis-removed', this.ids.indexOf(dataId));
            this.editing = false;
        },
        validateInputs(data) {
            const checkRequired = this.required
                .map((key) => !!data[key])
                .every((key) => key === true);
            if (!checkRequired) {
                return false;
            }
            const checkFields = ['stateCode', 'timeStart', 'timeEnd'].map((key) => {
                if (key === 'timeStart' && !this.required.includes('stateCode')) {
                    return !!data.timeStart;
                }
                if (key === 'timeStart' && ['CONFIRMED', 'CURED'].includes(data.stateCode)) {
                    return !!data.timeStart;
                }
                if (key === 'timeEnd' && data.stateCode === 'CURED') {
                    return !!data.timeEnd;
                }
                return true;
            });
            if (checkFields.every((x) => x === true)) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss">
    .diagnoses-tree-picker_switch {
        display: flex;
        justify-content: space-between;
    }
    .diagnoses-tree-picker_header {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 10px 0;
        background-color: #FAFAFA;
        border-bottom: 1px solid #E8E8E8;
        font-weight: 700;
        & div:nth-child(1) {
            text-align: center;
            width: 30% !important;
        }
        & div {
            text-align: center;
            width: 200px;
        }
    }
    .diagnoses-tree-picker__controls {
        width: 100px;
    }
    .diagnoses-tree-picker__item {
        padding: 3px 0;
        display: flex;
        flex-wrap: nowrap;
        border-bottom: 1px solid #E8E8E8;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
        cursor: move;
        & > div:nth-child(1) {
            width: 30%;
        }
        &:hover {
            background-color: #E6F7FF;
        }
        & > *:not(:first-child):not(:last-child)::before {
            content: '';
            border-left: 1px solid #f0f0f0;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0px;
            left: -20px;
        }
        & > *:not(:first-child):not(:last-child)::after {
            content: '';
            border-left: 1px solid #f0f0f0;
            width: 1px;
            height: 100%;
            position: absolute;
            right: -20px;
            top: 0px;
        }
        @media screen and (max-width: 1600px) {
            & > *:not(:first-child):not(:last-child)::after {
                border: none;
            }
            & > *:not(:first-child):not(:last-child)::before {
                border: none;
            }
        }
        & .ant-select-selection-selected-value {
            white-space: nowrap;
            width: 100%;
            text-align: center;
            vertical-align: middle;
        }
        & div.attributes.ant-select-disabled {
            white-space: nowrap;
            & div.ant-select-selection--multiple {
                border: none;
                & .ant-select-search--inline {
                    height: 1px;
                }
            }
            & .ant-select-selection div.ant-select-selection-selected-value {
                    white-space: nowrap;
            }
        }
        & .item-not-active {
            min-height: 30px;
            & > div {
                display: none;
                min-height: 1em;
            }
        }
        & .li-select:not(.disabled) {
            .li-select-input {
                background-color: #fff;
            }
        }
        & .li-select.disabled {
            .li-select-input {
                border: none;
                background-color: transparent;
            }
            & .clear {
                display: none;
            }
            & .li-select-input::placeholder {
                color: rgba(102, 102, 102, 1);
                opacity: 1;
            }
        }
    }
    .diagnoses-tree-picker {
        .ant-calendar-picker-input {
            text-align: center;
        }
        .ant-select-selection__rendered {
            padding:0px auto;
        }
        .ant-select-disabled .ant-select-selection {
                background: transparent;
                cursor: move;
                border: none;
                & i {
                    display: none;
                }
                & .ant-select-selection__placeholder,
                    .ant-select-selection-selected-value {
                    position: relative;
                    white-space: initial;
                    color: rgba(0,0,0,0.65);
                }
        }
        div.ant-select, span.ant-calendar-picker,
        .diagnoses-tree-picker input.ant-calendar-picker-input {
                width: 170px;
        }
        .ant-input-disabled {
                background-color: transparent;
                border: none;
                cursor: move;
                color: rgba(0,0,0,0.65);
                & i {
                    display: none;
                }
        }
        .ant-select-multiple.ant-select-disabled.ant-select,
        .ant-select-disabled.ant-select {
                & .ant-select-arrow {
                    display: none;
                }
                & .ant-select-selector {
                    border: none;
                    cursor: move;
                    background-color: transparent;
                    color: #555;
                    pointer-events: none;
                }
        }
        .ant-btn[disabled],
        .ant-btn,
        .ant-btn:hover {
            border: none;
            background-color: transparent;
        }
        .validate-error {
            .ant-select-selection, .ant-calendar-picker-input {
                border-color: #f5222d;
            }
        }
        .plus-diagnosis-wrapper {
            border: 1px solid #ccc;
            border-radius: 3px;
            display: inline-block;
            margin: 10px 0;
        }
    }
</style>
