<template>
    <a-modal
        :visible="true"
        :footer="null"
        centered
        :mask-closable="false"
        :width="600"
        title="Установить правило"
        class="prescription-rule-updater-window"
        @cancel="onCancelClick"
    >
        <a-row v-if="assignmentRules.data">
            <a-col :span="8">
                <a-select
                    v-model:value="ruleCode"
                    :dropdown-match-select-width="false"
                    :get-popup-container="(triggerNode) => triggerNode.parentNode"
                    placeholder="Условие"
                    @select="value => onRuleCodeChange(value)"
                >
                    <a-select-option
                        v-for="rule in availableAssignmentRules"
                        :key="rule.code"
                    >
                        {{ rule.name }}
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col
                v-if="ruleCode === 'MANUAL_DATE'"
                :span="16"
            >
                <a-date-picker
                    v-model:value="datePlanStart"
                    :class="datePlanStart ? '' : 'has-error'"
                    :disabled-date="(current) => dateIsDisabled(current)"
                    format="DD.MM.YYYY"
                />
            </a-col>
            <a-col
                v-if="afterGoodRuleSelected || afterMedicationRuleSelected"
                :span="8"
            >
                <a-select
                    v-model:value="overAssignmentId"
                    :dropdown-match-select-width="false"
                    :get-popup-container="(triggerNode) => triggerNode.parentNode"
                    :class="overAssignmentId ? '' : 'has-error'"
                    :placeholder="afterGoodRuleSelected ? 'Услуга...' : 'Препарат...'"
                    :span="6"
                >
                    <a-select-option
                        v-for="assignment in appropriateSelectableAssignments"
                        :key="getAssignmentId(assignment)"
                    >
                        {{ `${getAssignmentIndex(assignment)}. ` }}
                        {{ assignment.name }}
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col
                v-if="afterGoodRuleSelected || afterMedicationRuleSelected"
                :span="8"
            >
                <span class="prescription-rule-updater-window__day-label">через</span>
                <a-input-number
                    v-model:value="overDaysCount"
                    :class="overDaysCount === 0 || overDaysCount > 0 ? '' : 'has-error'"
                    :min="0"
                />
                <span class="prescription-rule-updater-window__day-label">дней</span>
            </a-col>
        </a-row>
        <a-row v-else>
            <span v-if="assignmentRules.error">
                Ошибка загрузки списка правил: {{ assignmentRules.error }}
            </span>
            <span v-else>
                Загрузка...
            </span>
        </a-row>
        <div class="prescription-rule-updater-window__actions">
            <div class="prescription-rule-updater-window__actions__spacer" />
            <a-button
                class="prescription-rule-updater-window__actions__btn"
                @click="onCancelClick"
            >
                Отмена
            </a-button>
            <a-button
                v-if="assignmentRules.data"
                class="prescription-rule-updater-window__actions__btn"
                :disabled="inputIsInvalid"
                type="primary"
                @click="onSetRuleClick"
            >
                Установить правило
            </a-button>
        </div>
    </a-modal>
</template>

<script>

export default {
    props: {
        patientAssignments: {
            type: Array,
            required: true,
        },
        checkedAssignments: {
            type: Object,
            required: true,
        },
        patientAssignmentsIndices: {
            type: Object,
            required: true,
        },
    },
    emits: ['prescription-rule-change', 'close'],
    data() {
        return {
            assignmentRules: {
                data: null,
                error: null,
            },
            ruleCode: 'MANUAL_DATE',
            datePlanStart: this.$moment(),
            overAssignmentId: null,
            overDaysCount: 0,
        };
    },

    computed: {
        selectableMedication() {
            return this.acceptableDependentAssignments.filter(
                (assignment) => assignment.medicationCourse,
            );
        },

        selectableGoods() {
            return this.acceptableDependentAssignments.filter(
                (assignment) => assignment.clinicGoodId,
            );
        },

        appropriateSelectableAssignments() {
            if (this.afterGoodRuleSelected) {
                return this.selectableGoods;
            }
            if (this.afterMedicationRuleSelected) {
                return this.selectableMedication;
            }
            return [];
        },

        inputIsInvalid() {
            switch (this.ruleCode) {
            case 'MANUAL_DATE':
                return !this.datePlanStart;
            case 'AFTER_MED_COURSE_START':
            case 'AFTER_MED_COURSE_END':
            case 'AFTER_GOOD_CREATED':
                return !this.overAssignmentId || this.overDaysCount < 0;
            default:
                return true;
            }
        },

        afterGoodRuleSelected() {
            return this.ruleCode === 'AFTER_GOOD_CREATED';
        },

        afterMedicationRuleSelected() {
            return this.ruleCode === 'AFTER_MED_COURSE_START'
                || this.ruleCode === 'AFTER_MED_COURSE_END';
        },

        acceptableDependentAssignments() {
            const ruledOutAssignments = { ...this.explicitlyRuledOutAssignments };
            this.ruleOutDependentAssignments(ruledOutAssignments);

            const nonRuledOutAssignments = this.patientAssignments.filter((assignment) => {
                const id = this.getAssignmentId(assignment);
                return !ruledOutAssignments[id];
            });
            return nonRuledOutAssignments;
        },

        patientAssignmentsMap() {
            return this.patientAssignments.reduce((map, assignment) => {
                const id = this.getAssignmentId(assignment);
                // eslint-disable-next-line no-param-reassign
                map[id] = assignment;
                return map;
            }, {});
        },

        explicitlyRuledOutAssignments() {
            return this.patientAssignments.reduce(
                (map, assignment) => {
                    let ruleMeOut = false;
                    const id = this.getAssignmentId(assignment);

                    if (this.checkedAssignments[id] || assignment.deleted) {
                        ruleMeOut = true;
                    }

                    // eslint-disable-next-line no-param-reassign
                    map[id] = ruleMeOut;
                    return map;
                },
                {},
            );
        },

        availableAssignmentRules() {
            if (!this.assignmentRules.data) {
                return [];
            }
            return this.assignmentRules.data.filter((ruleCode) => {
                switch (ruleCode.code) {
                case 'AFTER_MED_COURSE_START':
                case 'AFTER_MED_COURSE_END':
                    return this.selectableMedication.length;
                case 'AFTER_GOOD_CREATED':
                    return this.selectableGoods.length;
                default:
                    return true;
                }
            });
        },
    },

    mounted() {
        this.fetchAssignmentRules();
    },

    methods: {
        // helpers
        getAssignmentId(record) {
            return record.id ? `id-${record.id}` : `localId-${record.localId}`;
        },

        getNextAssignmentId(record) {
            if (!record.overAssignmentId && !record.overAssignmentLocalId) {
                return null;
            }
            return record.overAssignmentId
                ? `id-${record.overAssignmentId}`
                : `localId-${record.overAssignmentLocalId}`;
        },

        getAssignmentIndex(assignment) {
            const id = this.getAssignmentId(assignment);
            return this.patientAssignmentsIndices[id] || '?';
        },

        dateIsDisabled(date) {
            return date <= this.$moment().endOf('day').add(-1, 'days');
        },

        // assignments filtering logic
        ruleOutDependentAssignments(ruledOutAssignments) {
            Object.keys(ruledOutAssignments).forEach(
                (assignmentId) => this.reachAssignmentsByRecursion(
                    assignmentId,
                    ruledOutAssignments,
                ),
            );
        },

        reachAssignmentsByRecursion(
            assignmentId,
            ruledOutAssignments,
        ) {
            if (ruledOutAssignments[assignmentId]) {
                return true;
            }

            if (!this.patientAssignmentsMap[assignmentId]) {
                // NOTE: data inconsistency,
                // lack of some indexes in the database
                return false;
            }

            const afterId = this.getNextAssignmentId(
                this.patientAssignmentsMap[assignmentId],
            );
            if (!afterId) {
                // reached a tree's root
                return false;
            }

            const reachedRuledOutAssignment = this.reachAssignmentsByRecursion(
                afterId,
                ruledOutAssignments,
            );
            if (reachedRuledOutAssignment) {
                // NOTE: exclude all assignments on the way to a ruled out one
                // eslint-disable-next-line no-param-reassign
                ruledOutAssignments[assignmentId] = true;
            }
            return reachedRuledOutAssignment;
        },

        // data fetching
        fetchAssignmentRules() {
            this.assignmentRules.error = null;
            this.assignmentRules.data = null;

            this.$http.get('/assignment-rules').then((response) => {
                this.assignmentRules.data = response.data;
            }, (error) => {
                this.assignmentRules.error = error.response.data.msg;
            });
        },

        // event handlers
        onSetRuleClick() {
            this.$emit('prescription-rule-change', {
                ruleCode: this.ruleCode,
                overAssignmentId: this.overAssignmentId,
                datePlanStart: this.datePlanStart,
                overDaysCount: this.overDaysCount,
            });
            this.$emit('close');
        },

        onCancelClick() {
            this.$emit('close');
        },

        onRuleCodeChange(value) {
            this.overAssignmentId = null;
            if (value === 'MANUAL_DATE') {
                this.overDaysCount = 0;
                this.datePlanStart = this.$moment();
                return;
            }
            this.datePlanStart = null;
        },
    },
};
</script>

<style lang="scss">
.prescription-rule-updater-window {
    .ant-calendar-picker,
    .ant-select {
        width: 98%;
    }

    .has-error {
        input.ant-input-number-input,
        input.ant-calendar-picker-input,
        &.ant-select
        {
            border: 1px solid red;
        }
    }
}

.prescription-rule-updater-window__actions {
    display: flex;
    margin-top: 10px;
}

.prescription-rule-updater-window__actions__spacer {
    flex-grow: 1;
}

.prescription-rule-updater-window__actions__btn {
    margin-right: 8px;
}

.prescription-rule-updater-window__day-label {
    display: inline-block;
    padding: 0 5px;
}
</style>
