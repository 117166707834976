<template>
    <a-select
        :get-popup-container="(triggerNode) => triggerNode.parentNode"
        :value="value"
        :disabled="disabled"
        class="survey-status-combo"
        @change="onChange"
    >
        <a-select-option
            v-for="item in store"
            :key="item.id"
        >
            {{ item.name }}
        </a-select-option>
    </a-select>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        value: {
            type: Number,
            default: null,
        },
        disabled: Boolean,
    },
    emits: ['change'],

    data() {
        return {
            store: [],
        };
    },

    mounted() {
        this.loadStore();
    },

    methods: {
        loadStore() {
            this.$http.get('/survey/labs/states').then((response) => {
                this.store = response.data;
            });
        },

        onChange(value) {
            this.$emit('change', value);
        },
    },
});
</script>

<style lang="scss">
.ant-select-dropdown-content {
    text-align: left;
}

.survey-status-combo {
    width: 100%;
}
</style>
