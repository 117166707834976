<template>
    <div class="patient-list">
        <div class="patient-list__cards">
            <a-card
                :class="{ selected: !selectedPatient }"
                class="patient-list__card patient-list__card_deselector"
                @click="onDeselectPatient"
            >
                <span>Показать пациентов</span><br>
                <a-switch
                    v-model:checked="myPatientsOnly"
                    data-test-id="my-patients-switch"
                    checked-children="Мои"
                    un-checked-children="Все"
                />
            </a-card>
            <a-card
                v-for="patient in list"
                :key="patient.patientId"
                :class="{
                    selected: selectedPatient == patient.patientId,
                    inspected: patient.lastProtocolTimeCreate
                }"
                class="patient-list__card"
                data-test-id="patient-card"
                @click="onPatientSelected(patient.patientId)"
                @dblclick="onCardDblClick()"
            >
                <div class="patient-list__card__content">
                    <div class="name-avatar">
                        <a-avatar
                            :src="`${patient.previewFileId
                                ? '/api/patient/file/' + patient.previewFileId
                                : ''
                            }`"
                            size="large"
                            class="card-avatar"
                        >
                            <template #icon>
                                <user-outlined />
                            </template>
                        </a-avatar>
                        <span class="name">{{ patient.name }}</span>
                    </div>
                    <div>
                        <pushpin-two-tone
                            :two-tone-color="patient.pinned ? '#eb2f96' : undefined"
                            class="patient-list__card__pin"
                            @click="(e) => pushPin(e, patient)"
                        />
                        <img
                            alt=""
                            :src="`images/patient_state_icons/${patient.stateCode}.svg`"
                            class="patient-state-icon"
                        >
                    </div>
                </div>
                <div
                    v-if="patient.lastProtocolTimeCreate"
                    class="last-appointment-day"
                >
                    Предыдущий приём: {{
                        $moment(patient.lastProtocolTimeCreate, "YYYY-MM-DD").format("DD.MM.YYYY")
                    }}
                </div>
            </a-card>
        </div>
        <div class="spacer" />
        <div class="patient-pagination">
            <a-pagination
                v-if="pagination.total > pagination.pageSize"
                :current="pagination.current"
                :total="pagination.total"
                :show-total="(total, range) => `${range[0]}-${range[1]} из ${total}`"
                :page-size="pagination.pageSize"
                class="pagination_full-blown"
                @change="onPaginationChange"
            />
            <a-pagination
                v-if="pagination.total > pagination.pageSize"
                :current="pagination.current"
                :total="pagination.total"
                :page-size="pagination.pageSize"
                class="pagination_simple"
                simple
                @change="onPaginationChange"
            />
        </div>
    </div>
</template>

<script>
import PushpinTwoTone from '@ant-design/icons-vue/PushpinTwoTone';
import UserOutlined from '@ant-design/icons-vue/UserOutlined';

export default {
    components: {
        PushpinTwoTone,
        UserOutlined,
    },
    props: {
        selectedPatient: {
            type: Number,
            default: null,
        },
        list: {
            type: Array,
            default: () => [],
        },
        pagination: {
            type: Object,
            required: true,
        },
        onlyStaffPatients: {
            type: null,
            required: true,
        },
        onPaginationChange: {
            type: Function,
            required: true,
        },
        onPatientSelected: {
            type: Function,
            required: true,
        },
        onDeselectPatient: {
            type: Function,
            required: true,
        },
        onChangePatientAffiliation: {
            type: Function,
            required: true,
        },
        onRefresh: {
            type: Function,
            required: true,
        },
    },
    computed: {
        myPatientsOnly: {
            get() {
                return this.onlyStaffPatients;
            },
            set(value) {
                this.onChangePatientAffiliation(value);
            },
        },
    },
    methods: {
        onCardDblClick() {
            this.$router.push({ path: `/card/${this.selectedPatient}` });
        },

        async pushPin(e, patient) {
            e.stopPropagation();
            // TODO: replace outside to the store and api/index.js
            // handle errors, show mask
            await this.$http.put(`patient/${patient.patientId}/pin`, {
                pin: !patient.pinned,
            });
            this.onRefresh();
        },
    },
};
</script>

<style lang="scss">
.patient-list {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.patient-list__cards {
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 3px 0;

    .patient-list__card {
        margin: 0 10px 10px;
        cursor: pointer;
        height: 81px;

        .ant-card-body {
            padding: 0px 0px 24px 12px;
        }
        .name-avatar {
            display: flex;
            margin-top: 12px;

            .card-avatar {
                flex: 0 0 40px;
            }
        }

        span.name {
            text-align: left;
            margin-left: 5px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &.patient-list__card_deselector {
            .ant-card-body {
                padding-top: 12px;
                text-align: center;
                padding-left: 0px;

                .name {
                    display: inline-block;
                    padding: 6px;
                    width: 176px;
                }
                .name-avatar {
                    margin-top: 0px;
                }

                .ant-switch {
                    line-height: 25px;
                    height: 25px;
                    margin-top: 10px;
                }
                .ant-switch-inner {
                    padding: 0 10px;
                    font-size: 14px;
                }
                .ant-switch::after {
                    height: 21px;
                    width: 21px;
                }
            }
        }

        .patient-list__card__pin {
            float: right;
            opacity: .5;
            transition: opacity .2s;
        }

        &:hover .patient-list__card__pin {
            opacity: 1;
        }
    }

    .patient-list__card__content {
        max-height: 61px;
        display: grid;
        grid-template-columns: auto 20px;

        .patient-state-icon {
            margin-top: 27px;
        }
    }

    .inspected {
        border-color: #1890ff;
        .ant-card-body {
            padding: 0;
        }
        .name-avatar {
            padding: 0 12px 10px;
        }
    }
    .last-appointment-day {
        width: 100%;
        text-align: center;
        background-color: #1890ff;
        color: #fff;
        font-size: 12px;
    }
    .selected {
        background-color: #d6f39f;
    }
}

.spacer {
    flex: 1 1 1px;
}

.patient-pagination {
    display: flex;
    margin: 10px 5px;
    justify-content: flex-end;
}

.pagination_full-blown {
    display: block;
}

.pagination_simple {
    display: none;
}

 @media screen and (max-width: 709px) {
    .pagination_full-blown {
        display: none;
    }

    .pagination_simple {
        display: block;
    }
}
</style>
