/* eslint-disable no-param-reassign */

const appModeModule = {
    namespaced: true,

    state: {
        mode: 'doctor',
    },

    mutations: {
        setAppMode(state, val) {
            state.mode = val;
        },
    },
};

export default appModeModule;
