import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "anamneses-menstruation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, [
      _createTextVNode(" С "),
      _createVNode(_component_a_input, {
        value: _ctx.modelValue.startedAt,
        class: "anamneses-input",
        size: "small",
        "onUpdate:value": _cache[0] || (_cache[0] = (e) => _ctx.$emit('update:modelValue', {
                    ..._ctx.modelValue,
                    startedAt: Number.parseInt(e),
                }))
      }, null, 8, ["value"]),
      _createTextVNode(" лет с продолжительностью менструального цикла "),
      _createVNode(_component_a_input, {
        value: _ctx.modelValue.duration,
        class: "anamneses-input",
        size: "small",
        "onUpdate:value": _cache[1] || (_cache[1] = (e) => _ctx.$emit('update:modelValue', {
                    ..._ctx.modelValue,
                    duration: Number.parseInt(e),
                }))
      }, null, 8, ["value"]),
      _createTextVNode(" дней, "),
      _createVNode(_component_a_select, {
        value: _ctx.modelValue.pain,
        "default-value": 0,
        style: {"width":"170px"},
        size: "small",
        onSelect: _cache[2] || (_cache[2] = (e) => _ctx.$emit('update:modelValue', {
                    ..._ctx.modelValue,
                    pain: Number.parseInt(e),
                }))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pain, (entry) => {
            return (_openBlock(), _createBlock(_component_a_select_option, {
              key: entry.id,
              value: entry.id
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(entry.name), 1)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["value"]),
      _createTextVNode("   "),
      _createVNode(_component_a_select, {
        value: _ctx.modelValue.bleeding,
        "default-value": 0,
        style: {"width":"170px"},
        size: "small",
        onSelect: _cache[3] || (_cache[3] = (e) => _ctx.$emit('update:modelValue', {
                    ..._ctx.modelValue,
                    bleeding: Number.parseInt(e),
                }))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bleeding, (entry) => {
            return (_openBlock(), _createBlock(_component_a_select_option, {
              key: entry.id,
              value: entry.id
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(entry.name), 1)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _createElementVNode("p", null, [
      _createTextVNode(" Половая жизнь с "),
      _createVNode(_component_a_input, {
        value: _ctx.modelValue.sexLifeStartedAt,
        class: "anamneses-input",
        size: "small",
        "onUpdate:value": _cache[4] || (_cache[4] = (e) => _ctx.$emit('update:modelValue', {
                    ..._ctx.modelValue,
                    sexLifeStartedAt: Number.parseInt(e),
                }))
      }, null, 8, ["value"]),
      _createTextVNode(" лет. ")
    ]),
    _createElementVNode("p", null, [
      _createTextVNode(" Половая жизнь регулярная: "),
      _createVNode(_component_a_radio_group, {
        value: _ctx.modelValue.sexIsRegular,
        "onUpdate:value": _cache[5] || (_cache[5] = (e) => _ctx.$emit('update:modelValue', {
                    ..._ctx.modelValue,
                    sexIsRegular: e,
                }))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_radio, { value: true }, {
            default: _withCtx(() => [
              _createTextVNode(" Да ")
            ]),
            _: 1
          }),
          _createVNode(_component_a_radio, { value: false }, {
            default: _withCtx(() => [
              _createTextVNode(" Нет ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}