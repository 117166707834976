/* eslint-disable no-param-reassign */
import _ from 'lodash';

const createObj = (data, keys) => {
    const result = {};
    keys.forEach((key) => {
        if (!result[key]) {
            result[key] = {};
        }
        data[key].forEach((value) => {
            result[key][value.id] = value;
        });
    });
    return result;
};

const compare = (sended, received, notComparedKeys) => {
    const comparingResult = {
        different: [],
        added: [],
        removed: [],
    };
    _.reduce(sended, (result, value, key) => {
        if (notComparedKeys[key]) {
            return result;
        }
        if (received[key] !== undefined) {
            if (_.isEqual(value, received[key])) {
                return result;
            }
            if ((typeof sended[key] !== 'object' || typeof received[key] !== 'object')
                || (Array.isArray(sended[key]) || Array.isArray(received[key]))) {
            // dead end.
                result.different.push(key);
                return result;
            }
            const deeper = compare(sended[key], received[key], notComparedKeys, true);
            result.different = [...result.different, ..._.map(deeper.different, (subPath) => `${key}.${subPath}`)];
            result.added = [...result.added, ..._.map(deeper.added, (subPath) => `${key}.${subPath}`)];
            result.removed = [...result.removed, ..._.map(deeper.removed, (subPath) => `${key}.${subPath}`)];
            return result;
        }
        result.removed.push(key);
        return result;
    }, comparingResult);

    _.reduce(received, (result, value, key) => {
        if (sended[key] !== undefined) {
            return result;
        }
        result.added.push(key);
        return result;
    }, comparingResult);

    return comparingResult;
};

const compareFn = (sendedData, receivedData, properties, notComparedKeysArr) => {
    const notComparedKeys = _.keyBy(notComparedKeysArr);
    const sended = createObj(sendedData, properties);
    const received = createObj(receivedData, properties);
    const diffs = compare(sended, received, notComparedKeys);
    const result = {
        diagnoses: {},
        diagnosesICD: {},
        assignments: {},
        removed: {},
        added: {},
    };
    diffs.different.forEach((entry) => _.setWith(
        result,
        entry,
        _.get(received, entry),
        Object,
    ));
    diffs.added.forEach((entry) => _.setWith(
        result.added,
        entry,
        _.get(received, entry),
        Object,
    ));
    diffs.removed.forEach((entry) => _.setWith(
        result.removed,
        entry,
        _.get(sended, entry),
        Object,
    ));

    result.added = _.mapValues(result.added, _.valuesIn);
    result.removed = _.mapValues(result.removed, _.valuesIn);

    return result;
};

export default compareFn;
