<template>
    <a-modal
        :visible="isVisible"
        :mask-closable="false"
        :width="700"
        ok-text="Готово"
        :cancel-button-props="{class: {
            'biomaterial-spec__cancel-btn': true,
        }}"
        @ok="onSaveClick"
        @cancel="onClose"
    >
        <template #title>
            Уточните тип биоматериала исследований
        </template>
        <div
            v-for="good in goodsWithSurveys"
            :key="getGoodId(good)"
        >
            <div>
                Услуга:
                <strong>
                    {{ good.goodName }}
                </strong>
            </div>
            <div
                v-for="surveyType in good.surveys"
                :key="surveyType.id"
                class="biomaterial-spec__survey"
            >
                {{ surveyType.name }}:
                <a-select
                    :disabled="disabled"
                    :dropdown-match-select-width="false"
                    :get-popup-container="(triggerNode) => triggerNode.parentNode"
                    :value="getSpecifiedBiomaterial(good, surveyType)"
                    @change="(biomaterialId) => specifiedBiomaterial[
                        getGoodId(good)][surveyType.id] = biomaterialId"
                >
                    <!-- first option is a workaround to display biomaterial
                    which has been deleted from the survey -->
                    <a-select-option
                        v-if="hasDeletedBiomaterial(good, surveyType)"
                        :key="getSpecifiedBiomaterial(good, surveyType)"
                        :disabled="true"
                    >
                        {{
                            getSpecifiedBiomaterialName(good, surveyType)
                        }}
                    </a-select-option>
                    <a-select-option
                        v-if="getSpecifiedBiomaterial(good, surveyType) === -1"
                        :key="-1"
                        :disabled="true"
                    >
                        Не указан
                    </a-select-option>
                    <a-select-option
                        v-for="biomaterialId in surveyType.biomaterial"
                        :key="biomaterialId"
                    >
                        {{ biomaterialMap[biomaterialId].name }}
                    </a-select-option>
                </a-select>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        goodsWithSurveys: {
            type: Array,
            required: true,
        },
        biomaterial: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        done: null,
        close: null,
    },
    data() {
        return {
            isVisible: false,
            specifiedBiomaterial: {},
        };
    },

    computed: {
        biomaterialMap() {
            return this.biomaterial.reduce((map, biomaterial) => {
                // eslint-disable-next-line no-param-reassign
                map[biomaterial.id] = biomaterial;
                return map;
            }, {});
        },
    },

    watch: {
        goodsWithSurveys: {
            immediate: true,
            handler(ls) {
                this.isVisible = ls.length > 0;
                this.specifiedBiomaterial = ls.reduce(
                    (goodsMap, good) => {
                        const { surveys } = good;
                        // eslint-disable-next-line no-param-reassign
                        goodsMap[this.getGoodId(good)] = surveys.reduce(
                            (surveysMap, survey) => {
                                // eslint-disable-next-line no-param-reassign
                                surveysMap[survey.id] = survey.biomaterialId;
                                return surveysMap;
                            },
                            {},
                        );
                        return goodsMap;
                    },
                    {},
                );
            },
        },
    },

    methods: {
        getGoodId(good) {
            return good.localId
                ? `localId-${good.localId}`
                : `id-${good.id}`;
        },

        hasDeletedBiomaterial(good, surveyType) {
            const biomaterialId = this.getSpecifiedBiomaterial(good, surveyType);
            if (biomaterialId === -1) {
                return false;
            }
            return !surveyType.biomaterial.includes(biomaterialId);
        },

        getSpecifiedBiomaterial(good, surveyType) {
            const goodId = this.getGoodId(good);
            const surveyTypeId = surveyType.id;

            return this.specifiedBiomaterial[goodId][surveyTypeId] || -1;
        },

        getSpecifiedBiomaterialName(good, surveyType) {
            const biomaterialId = this.getSpecifiedBiomaterial(good, surveyType);
            if (biomaterialId === -1) {
                return '';
            }

            const biomaterial = this.biomaterialMap[biomaterialId];
            return biomaterial ? biomaterial.name : '';
        },

        onSaveClick() {
            this.$emit('done', this.specifiedBiomaterial);
        },

        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
.biomaterial-spec__survey {
    margin-top: 8px;
    margin-left: 15px;
}

.ant-btn.biomaterial-spec__cancel-btn {
    display: none;
}
</style>
