<template>
    <div>
        <a-spin
            :spinning="isLoading"
            :indicator="loadingIndicator"
            tip="Загрузка..."
        >
            <a-alert
                v-if="isError"
                type="error"
                message="Произошла ошибка во время загрузки данных!"
                closable
            />
            <div class="action-buttons-block">
                <a-button
                    :disabled="!selectedReferrals.length"
                    class="action-btn"
                    type="primary"
                    @click="acceptRecord"
                >
                    Принять
                </a-button>
                <a-button
                    :disabled="!selectedReferrals.length"
                    class="action-btn"
                    type="text"
                    danger
                    @click="defectRecord"
                >
                    Отбраковать
                </a-button>
            </div>
            <a-table
                :columns="tableColumns"
                :pagination="pagination"
                :data-source="referralData"
                :scroll="{ x: true }"
                row-key="id"
                class="reception-grid"
                @change="handleTableChange"
            >
                <template #checked="{ record }">
                    <a-checkbox v-model:checked="record.checked" />
                </template>

                <template #biomaterialName="{ record }">
                    <span>
                        <a-tag
                            v-if="record.biomaterialName"
                            color="blue"
                        >
                            {{ record.biomaterialName }}
                        </a-tag>
                    </span>
                </template>

                <template #name="{ record }">
                    {{ record.name }}
                </template>
            </a-table>
        </a-spin>
        <a-modal
            v-model:visible="showModal"
            :ok-button-props="{
                disabled: isSaving
                    || (!resultScheduledDate && acceptOrDefect === 'accept')
                    || (!rejectionReason && acceptOrDefect === 'defect'),
                loading: isSaving,
                type: acceptOrDefect === 'defect'
                    ? 'danger'
                    : 'primary'
            }"
            :mask-closable="false"
            title="Подтверждение"
            @ok="confirmAction"
        >
            <template #okText>
                <span v-if="acceptOrDefect === 'defect'">
                    Отбраковать
                </span>
                <span v-if="acceptOrDefect === 'accept'">
                    Готово
                </span>
            </template>
            <a-alert
                v-if="isSaveError"
                type="error"
                message="Произошла ошибка во время сохранения изменений!"
                closable
            />
            <p class="modal-confirm-text">
                {{ confirmText }}
            </p>
            <a-date-picker
                v-if="showDatePicker"
                v-model:value="resultScheduledDate"
                format="DD.MM.YYYY"
            />
            <a-form v-if="showRejectionReasonInput">
                <a-form-item label="Основание для отбраковки">
                    <a-input v-model:value="rejectionReason" />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import LoadingSpinner from '@/utils/Spinner.vue';

export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        LoadingSpinner,
    },

    props: {
        patientId: {
            type: String,
            default: null,
        },
    },

    emits: [
        'referral-update',
    ],

    data() {
        return {
            tableColumns: [{
                title: '',
                key: 'checked',
                width: 32,
                dataIndex: 'checked',
                slots: { customRender: 'checked' },
            }, {
                title: 'ФИО',
                key: 'patientName',
                width: 300,
                dataIndex: 'patientName',
                sorter: true,
            }, {
                title: 'Дата рождения',
                key: 'birthDate',
                width: 100,
                dataIndex: 'birthDate',
            }, {
                title: 'Биоматериал',
                key: 'biomaterialName',
                width: 90,
                dataIndex: 'biomaterialName',
                slots: { customRender: 'biomaterialName' },
            }, {
                title: 'Объем',
                key: 'biomaterialAmount',
                width: 50,
                dataIndex: 'biomaterialAmount',
            }, {
                title: 'Исследования/препараты',
                key: 'name',
                width: 300,
                class: 'survey-col',
                slots: { customRender: 'name' },
            }, {
                title: 'Дата формирования направления',
                key: 'referralDate',
                width: 100,
                dataIndex: 'referralDate',
                sorter: true,
            }, {
                title: 'Номер заказа',
                key: 'orderNumber',
                width: 50,
                class: 'num-order-col',
                dataIndex: 'orderNumber',
            }, {
                title: 'Дата формирования заказа',
                key: 'orderDate',
                width: 100,
                dataIndex: 'orderDate',
            }],
            referralData: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            sorting: null,
            isLoading: true,
            isError: false,
            loadingIndicator: {
                template: '<loading-spinner />',
            },

            isSaving: false,
            isSaveError: false,
            showModal: false,
            confirmText: '',
            acceptOrDefect: '',
            rejectionReason: '',
            resultScheduledDate: this.$moment(),
        };
    },

    computed: {
        selectedReferrals() {
            return this.referralData.filter((rec) => rec.checked);
        },
        showDatePicker() {
            return this.acceptOrDefect === 'accept';
        },
        showRejectionReasonInput() {
            return this.acceptOrDefect === 'defect';
        },
    },

    watch: {
        patientId() {
            this.fetchReferrals();
        },
        showModal(value) {
            if (!value) {
                this.isSaveError = false;
                this.rejectionReason = '';
            }
        },
    },

    created() {
        this.fetchReferrals();
    },

    methods: {
        fetchReferrals() {
            this.isError = false;
            this.isLoading = true;
            const { current, pageSize } = this.pagination;
            const params = {
                stateCode: 'SENT',
                groupByBiomaterial: true,
                page: JSON.stringify([current - 1, pageSize]),
            };
            if (this.sorting) {
                const { property, direction } = this.sorting;
                params.sort = JSON.stringify([{ property, direction }]);
            }
            if (this.patientId) {
                params.patientId = this.patientId;
            }
            this.$http.get('/procedure/laboratory/referrals', { params })
                .then((response) => this.fetchReferralsSuccess(response))
                .catch((err) => this.fetchReferralsError(err));
        },

        fetchReferralsSuccess(response) {
            this.isLoading = false;
            this.referralData = this.expandFetchedData(
                response.data.data,
            );
            this.pagination = {
                ...this.pagination,
                total: response.data.count,
            };
        },

        fetchReferralsError() {
            this.isError = true;
            this.isLoading = false;
        },

        expandFetchedData(data) {
            return data.map((rec) => ({
                ...rec,
                birthDate: this.$moment(rec.birthDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                referralDate: this.$moment(rec.referralDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                orderDate: this.$moment(rec.orderDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            }));
        },

        handleTableChange(pagination, filters, sorter) {
            if (sorter.field) {
                this.sorting = {
                    property: sorter.field,
                    direction: sorter.order === 'descend' ? 'DESC' : 'ASC',
                };
            } else {
                this.sorting = null;
            }
            this.pagination = {
                ...this.pagination,
                current: pagination.current,
            };
            this.fetchReferrals();
        },

        acceptRecord() {
            const record = this.selectedReferrals[0];
            this.confirmText = 'Укажите плановую дату завершения и подтвердите приём заявок.';
            this.acceptOrDefect = 'accept';
            const resultDate = this.$moment(record.resultScheduledDate);
            this.resultScheduledDate = resultDate.isValid()
                ? resultDate
                : this.$moment();
            this.showModal = true;
        },

        confirmAction() {
            const data = {
                orders: this.selectedReferrals.map((order) => order.id),
            };
            let url;
            if (this.acceptOrDefect === 'accept') {
                data.datePlanResult = this.resultScheduledDate.format('YYYY-MM-DD');
                url = '/procedure/laboratory/referral/accept';
            } else {
                data.reason = this.rejectionReason;
                url = '/procedure/referral/orders/reject';
            }

            this.$http.put(url, data)
                .then((response) => this.updateReferralSuccess(response))
                .catch((err) => this.updateReferralError(err));
            this.isSaving = true;
            this.isSaveError = false;
        },

        updateReferralSuccess() {
            this.showModal = false;
            this.isSaving = false;
            this.isSaveError = false;
            this.fetchReferrals();
            this.$emit('referral-update');
        },

        updateReferralError() {
            this.isSaving = false;
            this.isSaveError = true;
        },

        defectRecord() {
            this.confirmText = 'Заявки будут отбракованы! Вы уверены?';
            this.acceptOrDefect = 'defect';
            this.showModal = true;
        },
    },
};
</script>

<style lang="scss" scoped>
    .action-buttons-block {
        text-align: left;
    }
    button.action-btn {
        margin: 10px;
        margin-left: 0;
        .ant-btn-primary[disabled] {
            cursor: pointer;
        }
    }

    p.modal-confirm-text {
        margin-top: 10px;
    }
    div.reception-grid {
        width: calc(100vw - 335px);
    }
    ::v-deep(.ant-table-column-title) {
        display: flex;
        text-align: center;
        word-break: normal;
    }
</style>
