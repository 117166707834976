<template>
    <div>
        <a-table
            :columns="blankColumns"
            :data-source="blankStore"
            :pagination="false"
            :bordered="true"
            :custom-row="customizeRow"
            :row-key="(record) => record.id"
            class="blank-grid"
        >
            <template
                v-for="column in realColumns"
                #[column.dataIndex]
                :key="column.dataIndex"
            />
            <template
                v-if="showPollFiles && filesAreExpected"
                #attachedFiles="{ record }"
            >
                <div
                    v-for="file in getAttachedFiles(record.id)"
                    :key="file.id"
                    class="file-link"
                    @click="goToFileUrl(file.id)"
                >
                    {{ file.filename }}
                </div>
            </template>
        </a-table>

        <a-table
            v-if="microorganismRecords.length"
            :bordered="true"
            :pagination="false"
            :columns="microorganismColumns"
            :data-source="microorganismRecords"
            class="blank-grid microorganism-grid"
        />

        <a-table
            v-if="Array.isArray(data.drugs) && data.drugs.length"
            :bordered="true"
            :pagination="false"
            :columns="drugColumns"
            :data-source="data.drugs"
            class="blank-grid"
        />
    </div>
</template>

<script>
import nunjucks from 'nunjucks';
import { h } from 'vue';
import SurveyCell from '@/components/patientcard/SurveyCell.vue';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        blanksInEdit: {
            type: Boolean,
            default: false,
        },
        surveyBlankId: {
            type: Number,
            default: null,
        },
        showPollFiles: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editableRecordId: null,
            blankColumns: [],
            blankStore: [],
            realColumns: [],
            microorganismColumns: [{
                title: '№',
                key: 'index',
                dataIndex: 'index',
            }, {
                title: 'Выделенные микроорганизмы',
                key: 'name',
                dataIndex: 'name',
            }, {
                title: 'Результат',
                key: 'value',
                dataIndex: 'value',
            }],
        };
    },

    computed: {
        microorganismRecords() {
            const src = this.data.microorganisms || [];
            return src.map((record, index) => ({
                ...record,
                index: `[ ${index + 1} ]`,
            }));
        },

        drugColumns() {
            return [{
                title: 'Антибиотикограмма',
                key: 'name',
                dataIndex: 'name',
            }].concat(Array.from({
                length: this.microorganismRecords.length,
            }).map((_, index) => ({
                title: `[ ${index + 1} ]`,
                dataIndex: index + 1,
            })));
        },

        filesAreExpected() {
            return Array.isArray(this.data.quizSettings)
                && this.data.quizSettings.some(({ fileRule }) => fileRule);
        },
    },

    mounted() {
        this.prepareData();
    },
    methods: {
        skipMe(settings) {
            return !settings || (!settings.visible && !settings.editable);
        },

        renderCell({ columns }, record, j) {
            const { columnsSettings, values } = record;

            if (this.skipMe(columnsSettings[columns[j].id])) {
                return {
                    attrs: {
                        colSpan: 0,
                    },
                };
            }

            const hiddenCount = columns.reduce(
                (count, column) => (this.skipMe(columnsSettings[column.id]) ? count + 1 : count),
                0,
            );
            const visibleCount = columns.length - hiddenCount;

            const firstVisible = columns.find((col) => {
                const settings = columnsSettings[col.id];
                if (!settings) {
                    return false;
                }
                const { visible, editable } = settings;
                return visible === 1 || editable === 1;
            });

            const attrs = {
                colSpan: (firstVisible.id === columns[j].id
                    ? Math.ceil(hiddenCount / visibleCount)
                    : (hiddenCount / visibleCount) || 0) + 1,
            };

            let value = values[columns[j].id];
            if (value && value.name) {
                value = values[columns[j].id].name;
            } else if (value && value.push) {
                value = '';
                for (let c = 0; c < values[columns[j].id].length; c += 1) {
                    value += `, ${values[columns[j].id][c].name}`;
                }
                value = value.split('').slice(2).join('');
            } else if (columnsSettings[columns[j].id].tpl) {
                value = nunjucks.renderString(columnsSettings[columns[j].id].tpl, {
                    cols: values,
                });
            }
            return {
                children: h(SurveyCell, {
                    value,
                    editable: !!this.calcEditable(
                        record,
                        record.columnsSettings[columns[j].id].editable,
                    ),
                    type: {
                        numberfield: columns[j].isResultDecimal,
                        combobox: columns[j].isResultRef,
                        tagfield: columns[j].isResultRefArray,
                        textfield: columns[j].isResultString,
                    },
                    columnId: columns[j].id,
                    localStore: record.columnsSettings[columns[j].id].items,
                    dropdownTpl: record.columnsSettings[columns[j].id].tpl,
                    recordValues: record.values,
                    onChange: (val) => {
                        this.setData(record.id, columns[j].id, val);
                    },
                    onAddItem: (addedValues) => this.addDropdownItem(
                        addedValues,
                        record,
                        columns[j].id,
                    ),
                }),
                attrs,
            };
        },
        addDropdownItem(values, record, columnId) {
            if (columnId && record.columnsSettings && record.columnsSettings[columnId]) {
                record.columnsSettings[columnId].items.splice(
                    record.columnsSettings[columnId].items.length,
                    0,
                    values,
                );
                this.$http.put(`/survey/blank/${this.surveyBlankId}/indicator/${record.id}`, {
                    columnsSettings: record.columnsSettings,
                });
            }
        },
        calcEditable(record, columnEditable) {
            return this.editable
                && this.blanksInEdit
                && columnEditable
                && (this.editableRecordId === record.id);
        },

        getAttachedFiles(indicatorId) {
            if (!Array.isArray(this.data.quizFiles)) {
                return [];
            }
            return this.data.quizFiles.filter((file) => file.indicatorId === indicatorId);
        },

        prepareData() {
            if (!this.data.indicators || !this.data.groupColumns) {
                return;
            }
            this.blankStore = this.data.indicators;
            const { groupColumns } = this.data;
            let columnCount = groupColumns.length + 1;
            if (this.showPollFiles && this.filesAreExpected) {
                columnCount += 1;
            }
            const columnWidth = `${100 / columnCount}%`;

            this.blankColumns = [{
                title: this.data.captionIndicators,
                width: columnWidth,
                dataIndex: 'name',
            }];
            for (let i = 0; i < groupColumns.length; i += 1) {
                this.blankColumns.push({
                    title: groupColumns[i].name,
                    width: columnWidth / groupColumns[i].columns.length,
                    colSpan: groupColumns[i].columns.length,
                    slots: {
                        customRender: groupColumns[i].columns[0].id,
                    },
                    customRender: (entry) => {
                        // eslint-disable-next-line no-param-reassign
                        entry.editable = false;
                        return this.renderCell(groupColumns[i], entry.record, 0);
                    },
                });
                for (let j = 0; j < groupColumns[i].columns.length; j += 1) {
                    this.realColumns.push({
                        dataIndex: groupColumns[i].columns[j].id,
                    });

                    if (j > 0) {
                        this.blankColumns.push({
                            colSpan: 0,
                            width: columnWidth / groupColumns[i].columns.length,
                            slots: {
                                customRender: groupColumns[i].columns[j].id,
                            },
                            customRender: (entry) => {
                                // eslint-disable-next-line no-param-reassign
                                entry.record.editable = false;
                                return this.renderCell(groupColumns[i], entry.record, j);
                            },
                        });
                    }
                }
            }

            if (this.showPollFiles && this.filesAreExpected) {
                this.blankColumns.push({
                    title: 'Файлы',
                    width: columnWidth,
                    slots: {
                        customRender: 'attachedFiles',
                    },
                });
            }
        },
        customizeRow(record) {
            const self = this;
            return {
                onClick(e) {
                    e.preventDefault();
                    self.editableRecordId = record.id;
                },
            };
        },
        setData(recordId, columnId, value) {
            const record = this.blankStore.find((x) => x.id === recordId);

            if (value && value.items) {
                Object.keys(value.items).forEach((cid) => {
                    record.values[cid] = value.items[cid];
                });
            } else {
                record.values[columnId] = value;
            }
        },

        goToFileUrl(id) {
            window.open(`/api/patient/quiz/file/${id}`, '_blank');
        },
    },
};
</script>

<style lang="scss" scoped>
    .microorganism-grid {
        margin-bottom: 10px;
    }

    .file-link {
        cursor: pointer;
        text-decoration: underline;
    }
</style>
