<template>
    <div class="notifications">
        <page-header
            class="notifications__header"
            :filter-disabled="true"
            @search="onPatientSearch"
        />
        <div class="notifications__content">
            <h1>
                Уведомления
            </h1>
            <a-table
                :columns="columns"
                :data-source="notifications"
                :pagination="false"
                :loading="loading"
                :show-header="false"
                size="small"
                row-key="rowKey"
            >
                <template #icon="{ record }">
                    <reconciliation-two-tone
                        v-if="record.entityName === 'goodSurveysReadyNew'"
                        two-tone-color="#f5222d"
                    />
                    <close-square-two-tone
                        v-else-if="record.entityName === 'patientTask'"
                        two-tone-color="#f5222d"
                    />
                    <bell-two-tone
                        v-else
                        two-tone-color="#f5222d"
                    />
                </template>
                <template #text="{ record }">
                    <a-button
                        v-if="record.entityName === 'protocolModification'"
                        size="small"
                        class="clear-btn"
                        type="text"
                        @click="onDeleteNotification(record.id)"
                    >
                        <check-square-two-tone
                            two-tone-color="#1da929"
                        />
                    </a-button>
                    <span
                        class="notification__content_item-text"
                        @click="onNotificationClick(record)"
                    >
                        {{ getNotificationText(record) }}
                    </span>
                </template>
            </a-table>
            <a-pagination
                v-if="pagination.total > pagination.pageSize"
                :current="pagination.current"
                :total="pagination.total"
                :show-total="(total, range) => `${range[0]}-${range[1]} из ${total}`"
                :page-size="pagination.pageSize"
                size="small"
                class="notifications__pagination"
                @change="onPaginationChange"
            />
        </div>
    </div>
</template>

<script>
import ruRU from 'ant-design-vue/lib/locale-provider/ru_RU';
import ReconciliationTwoTone from '@ant-design/icons-vue/ReconciliationTwoTone';
import CloseSquareTwoTone from '@ant-design/icons-vue/CloseSquareTwoTone';
import BellTwoTone from '@ant-design/icons-vue/BellTwoTone';
import CheckSquareTwoTone from '@ant-design/icons-vue/CheckSquareTwoTone';

import PageHeader from '@/components/PageHeader.vue';
import { showErrorNotification } from '@/utils';

const PAGE_SIZE = 20;
export default {
    components: {
        PageHeader,
        ReconciliationTwoTone,
        CloseSquareTwoTone,
        BellTwoTone,
        CheckSquareTwoTone,
    },

    data() {
        return {
            ruRU,

            notifications: [],
            loading: false,
            pagination: {
                pageSize: PAGE_SIZE,
                current: 1,
                total: 0,
            },
            patientQuery: '',

            columns: [{
                width: 40,
                align: 'center',
                slots: { customRender: 'icon' },
            }, {
                slots: { customRender: 'text' },
            }],
        };
    },

    watch: {
        patientQuery: 'fetchNotifications',
    },

    mounted() {
        this.fetchNotifications();
    },

    methods: {
        async fetchNotifications() {
            const { current, pageSize } = this.pagination;
            const params = {
                page: JSON.stringify([current - 1, pageSize]),
                query: this.patientQuery,
            };

            this.loading = true;
            let response;
            try {
                response = await this.$http.get('/patient/doctor/notifications', {
                    params,
                });
            } catch (err) {
                return;
            } finally {
                this.loading = false;
            }

            const { data, count } = response.data;
            this.notifications = data.map((record) => ({
                ...record,
                rowKey: `${record.entityName}-${record.id}`,
            }));

            const totalPages = Math.ceil(count / this.pagination.pageSize) || 1;
            const activePage = this.pagination.current > totalPages
                ? totalPages
                : this.pagination.current;
            this.pagination.current = activePage;
            this.pagination.total = count;
        },

        getNotificationText(record) {
            switch (record.entityName) {
            case 'goodSurveysReadyNew':
                return `${record.eventDate} - ${record.patientName.trim()}. ${record.eventName}`;
            case 'patientTask':
                return `${record.patientName.trim()}. ${record.eventName}`;
            case 'protocolModification':
                return `${record.eventDate} - ${record.eventName}`;
            default:
                return record.eventName;
            }
        },

        onPatientSearch({ search }) {
            this.patientQuery = search;
        },

        async onDeleteNotification(notificationId) {
            try {
                await this.$http.delete(
                    `/patient/doctor/notification/${
                        notificationId}?entityName=protocolModification`,
                );
                this.notifications = this.notifications
                    .filter((n) => notificationId !== n.id);
            } catch {
                showErrorNotification('Не удалось удалить уведомление.');
            }
        },

        onPaginationChange(page) {
            this.pagination.current = page;
            this.fetchNotifications();
        },

        onNotificationClick(record) {
            switch (record.entityName) {
            case 'goodSurveysReadyNew':
                this.$router.push({
                    path: `/good-surveys/${record.id}`,
                });
                break;
            case 'protocolModification':
            case 'patientTask':
                this.$router.push({
                    path: `/reception/${record.id}`,
                });
                break;
            default:
                break;
            }
        },
    },
};
</script>

<style lang="scss">
    .notifications {
        display: grid;
        height: 100vh;
        gap: 10px;
        grid:
            "header" 64px
            "content" calc(100% - 74px);
        .clear-btn {
            margin-right: 20px;
        }
    }

    .notifications__header {
        grid-area: header;
    }

    .notifications__content {
        grid-area: content;
        text-align: left;
        margin: 10px;
    }

    .notifications__pagination {
        &.ant-pagination {
            padding: 10px;
        }

        .ant-pagination-prev .ant-pagination-item-link::after,
        .ant-pagination-next .ant-pagination-item-link::after,
        .ant-pagination-jump-next:hover::after,
        .ant-pagination-jump-next::after,
        .ant-pagination-jump-prev:hover::after,
        .ant-pagination-jump-prev::after {
            content: none;
        }
    }

    .notification__content_item-text {
        cursor: pointer;
    }
</style>
