<template>
    <a-spin
        :spinning="showMask"
        wrapper-class-name="prescriptions-grid"
        tip="Загрузка..."
    >
        <div class="prescriptions-grid__header">
            <div class="selection-column">
                <a-checkbox
                    v-if="!disabled"
                    :checked="allAssignmentsChecked"
                    @change="(e) => $emit('check-all-assignments', e.target.checked)"
                />
            </div>
            <div class="index-column" />
            <div class="name-column">
                <span>Название</span>
            </div>
            <div class="price-column">
                Стоимость
            </div>
            <div class="username-column">
                Врач
            </div>
            <div class="rule-column">
                Условие создания услуги/начала приёма препарата
            </div>
            <div class="checker-column">
                <a-tooltip>
                    <template #title>
                        Оповещать
                    </template>
                    <bell-two-tone />
                </a-tooltip>
            </div>
            <div class="checker-column">
                <a-tooltip>
                    <template #title>
                        Уточнить
                    </template>
                    <question-circle-two-tone />
                </a-tooltip>
            </div>
            <div class="comment-column">
                Комментарий врача
            </div>
            <div
                class="date-column sortable-column"
                @click="onSortByDateClick"
            >
                Плановая дата
                <div class="sorter">
                    <CaretUpOutlined />
                    <CaretDownOutlined />
                </div>
            </div>
            <div class="action-column" />
            <div class="action-column" />
        </div>
        <div
            v-for="(record, index) in displayedPatientAssignments"
            :key="getAssignmentId(record)"
        >
            <div
                class="prescriptions-grid__body"
                :class="getPrescriptionRowClass(record)"
                :data-id="record.id || record.localId"
                @mouseenter="onPrescriptionRowMouseenter(record, $event)"
                @mouseleave="onPrescriptionRowMouseleave(record)"
            >
                <div
                    class="selection-column"
                    @mouseenter="onSelectionCheckboxMouseenter(record, $event)"
                    @mouseleave="onSelectionCheckboxMouseleave"
                >
                    <a-checkbox
                        v-if="!assignmentIsDisabled(record)"
                        :checked="assignmentChecked(record)"
                        @change="(e) => $emit('check-assignment', {
                            checked: e.target.checked,
                            id: record.id,
                            localId: record.localId,
                        })"
                    />
                </div>
                <div class="index-column">
                    {{ index + 1 }}
                </div>
                <div class="name-column">
                    <medicine-box-two-tone
                        v-if="!record.clinicGoodId"
                    />
                    {{ record.name }}
                    {{ getAssignmentSubtypesText(record) }}
                    {{ getNonPrimaryBiomaterialList(
                        record.clinicGoodId,
                        record.surveyBiomaterial,
                    ) }}
                </div>
                <div class="price-column">
                    <div v-if="record.clinicGoodPrice">
                        {{ record.clinicGoodPrice }}
                    </div>
                    <div v-if="record.medicationCourse && isLoaded">
                        {{ calcMedicationPrice(record) }}
                    </div>
                </div>
                <div class="username-column">
                    <span style="min-width: 100px; display: block">
                        {{ record.userEditName || record.userCreateName }}
                    </span>
                </div>
                <div class="rule-column">
                    <prescription-rule
                        :ref="`ruleForm${index}`"
                        :assignment="record"
                        :disabled="assignmentIsDisabled(record)"
                        :assignment-rules="assignmentRules"
                        :patient-assignments="patientAssignments"
                        :patient-assignments-indices="patientAssignmentsIndices"
                        @change-rule-code="(value, record) => $emit(
                            'change-rule-code',
                            value,
                            record,
                        )"
                        @date-plan-change="(value, record) => $emit(
                            'date-plan-change',
                            value,
                            record,
                        )"
                        @preceding-assignment-change="(value, record) => $emit(
                            'preceding-assignment-change',
                            value,
                            record,
                        )"
                        @over-days-count-change="(value, record) => $emit(
                            'over-days-count-change',
                            value,
                            record,
                        )"
                    />
                </div>
                <div
                    class="checker-column"
                    @mouseenter="onNotificationCheckboxMouseenter(record, $event)"
                    @mouseleave="onNotificationCheckboxMouseleave"
                >
                    <a-checkbox
                        data-test-id="notify-after-surveys-is-ready"
                        :checked="record.notifyAfterSurveysIsReady"
                        :disabled="notifyCheckboxIsDisabled(record)"
                        @change="(e) => $emit('survey-result-notify-change', e, record)"
                    />
                </div>
                <div
                    class="checker-column"
                    @mouseenter="onClarificationCheckboxMouseenter($event)"
                    @mouseleave="onClarificationCheckboxMouseleave"
                >
                    <a-checkbox
                        data-test-id="clarification-required"
                        :checked="record.unaccepted"
                        :disabled="assignmentIsDisabled(record)"
                        @change="(e) => $emit('clarification-required-change', e, record)"
                    />
                </div>
                <div class="comment-column">
                    <a-textarea
                        v-if="record.unaccepted"
                        :disabled="disabled || record.mergeIsRequired"
                        :value="record.comment"
                        :rows="4"
                        placeholder="Комментарий для менеджера в таблице назначений"
                        @change="(e) => $emit('comment-change', e, record)"
                    />
                </div>
                <div class="date-column">
                    {{ record.datePlanStart && record.datePlanStart.isValid()
                        ? $moment(record.datePlanStart).format('DD.MM.YYYY')
                        : ''
                    }}
                </div>
                <div class="action-column">
                    <div class="assignment-edit-group">
                        <goods-schedule
                            v-if="record.medicationCourse
                                && record.datePlanStart
                                && !record.deleted"
                            :course="record.medicationCourse"
                            :date-plan-start="record.datePlanStart"
                            :medications="medications"
                            :goods="goods"
                            :patient-id="patientId"
                        />
                        <edit-assignment-button
                            :assignment="record"
                            :disabled="assignmentIsDisabled(record)"
                            :goods-map="goodsMap"
                            @assignment-edit-click="onAssignmentEditClick"
                        />
                    </div>
                </div>
                <div class="action-column">
                    <a-tooltip
                        v-if="record.mergeIsRequired"
                        placement="left"
                    >
                        <template #title>
                            <span>Согласовать</span>
                        </template>

                        <a-button
                            data-test-id="prescription-merge-apply-btn"
                            size="small"
                            @click="onMergeApply(record)"
                        >
                            <template #icon>
                                <check-outlined />
                            </template>
                        </a-button>
                    </a-tooltip>

                    <a-tooltip
                        v-if="record.mergeIsRequired"
                        placement="left"
                    >
                        <template #title>
                            <span>Вернуть мою версию</span>
                        </template>

                        <a-button
                            data-test-id="prescription-merge-reject-btn"
                            size="small"
                            @click="onMergeReject(record)"
                        >
                            <template #icon>
                                <close-outlined />
                            </template>
                        </a-button>
                    </a-tooltip>
                    <delete-restore-assignment-button
                        v-if="!disabled"
                        :assignment="record"
                        :disabled="assignmentIsDisabled(record)"
                        @restore-click="onAssignmentRestoreClick"
                        @remove-click="onAssignmentRemoveClick"
                    />
                </div>
            </div>
            <div
                v-if="isLoaded && record.medicationCourse && medicationsMap[
                    record.medicationCourse.clinicMedicationId
                ].schemeId !== 2"
                class="medication-addon"
            >
                <div class="medication-addon-block">
                    <medication-production-freq
                        v-if="medicationsMap[
                            record.medicationCourse.clinicMedicationId
                        ].schemeId"
                        :value="record.medicationCourse.productionFreq"
                        :disabled="assignmentIsDisabled(record)"
                        :assignment="record"
                        class="medication-addon__item"
                        @change="(e) => {
                            $emit('create-medication-course', {
                                ...record.medicationCourse,
                                productionFreq: e
                            });
                        }"
                    />
                    <no-scheme-medication-frequency
                        v-else
                        :value="record.medicationCourse.productionFreq"
                        :disabled="assignmentIsDisabled(record)"
                        class="medication-addon__item"
                        @fix-period-click="$emit('fix-period-click', record)"
                    />
                </div>
                <div>
                    <template-select
                        :allow-clear="false"
                        :course="record.medicationCourse"
                        :medication-map="medicationsMap"
                        :subtypes-map="subtypesMap"
                        :disabled="assignmentIsDisabled(record)"
                        class="medication-addon__item"
                        @change="(_, e) => $emit('create-medication-course', e)"
                    />
                </div>
                <div>
                    <donor-select
                        :disabled="!medicationsMap[
                            record.medicationCourse.clinicMedicationId
                        ].allowDonorBiomaterial || assignmentIsDisabled(record)"
                        :donor-id="record.medicationCourse.donorId"
                        :patient-id="patientId"
                        class="medication-addon__item"
                        @change="$emit('donor-changed', record.id, $event)"
                    />
                </div>
            </div>
        </div>
        <footer>
            <h2 v-if="isLoaded">
                Стоимость невыполненных услуг: {{ totalGoodsPrice }} руб.
            </h2>
        </footer>
        <div ref="mergeTooltipWrapper">
            <a-tooltip
                v-if="showTooltipForRecord.previous && showTooltipForRecord.helpers"
                :visible="true"
                placement="top"
                :overlay-style="{maxWidth: '800px'}"
            >
                <template #title>
                    <strong>
                        Изменено в протоколе
                        №
                        {{ showTooltipForRecord.updatedIn }}
                        от
                        {{ showTooltipForRecord.updatedAt }}
                        врачом: {{ showTooltipForRecord.updatedBy }}</strong>
                    <ul
                        style="margin-right:30px"
                    >
                        <li
                            v-for="entry in showTooltipForRecord.helpers"
                            :key="entry[0] + entry[1]"
                        >
                            {{ entry[0] }}: {{ entry[1] }} - {{ entry[2] }}
                        </li>
                    </ul>
                </template>
            </a-tooltip>
        </div>
        <!-- NOTE: the next tooltips are here to enforce
            single tooltip for each task instead of a tooltip for each row
            and save time on useless rerendering -->
        <div ref="ruleCheckboxTooltipWrapper">
            <a-tooltip
                v-if="showRuleCheckboxTooltip"
                :visible="true"
                placement="top"
            >
                <template #title>
                    Установить
                    <br>
                    правило
                </template>
            </a-tooltip>
        </div>
        <div ref="notificationCheckboxTooltipWrapper">
            <a-tooltip
                v-if="showNotificationCheckboxTooltip"
                :visible="true"
                placement="top"
            >
                <template #title>
                    Создать оповещение врача после выполнения исследований
                </template>
            </a-tooltip>
        </div>
        <div ref="clarificationCheckboxTooltipWrapper">
            <a-tooltip
                v-if="showClarificationCheckboxTooltip"
                :visible="true"
                placement="top"
            >
                <template #title>
                    Отобразить менеджеру необходимость уточнения
                    у врача необходимости выполнения услуги
                </template>
            </a-tooltip>
        </div>
    </a-spin>
</template>

<script>
/* eslint-disable no-param-reassign */
import TemplateSelect from 'vue-web-components/src/components/prescriptiontemplate/prescription/TemplateSelect.vue';
import DonorSelect from 'vue-web-components/src/components/prescriptiontemplate/prescription/DonorSelect.vue';
import BellTwoTone from '@ant-design/icons-vue/BellTwoTone';
import QuestionCircleTwoTone from '@ant-design/icons-vue/QuestionCircleTwoTone';
import MedicineBoxTwoTone from '@ant-design/icons-vue/MedicineBoxTwoTone';
import CheckOutlined from '@ant-design/icons-vue/CheckOutlined';
import CloseOutlined from '@ant-design/icons-vue/CloseOutlined';
import CaretUpOutlined from '@ant-design/icons-vue/CaretUpOutlined';
import CaretDownOutlined from '@ant-design/icons-vue/CaretDownOutlined';

import { useScheduleStore } from '@tools/methods';
import { getPatientCommonDayActions, getPatientDayActions } from '@api';
import { showErrorNotification } from '@/utils';

import MedicationProductionFreq from './MedicationProductionFreq.vue';
import NoSchemeMedicationFrequency from './NoSchemeMedicationFrequency.vue';
import PrescriptionRule from './PrescriptionRule.vue';
import GoodsSchedule from './GoodsSchedule.vue';
import EditAssignmentButton from './EditAssignmentButton.vue';
import DeleteRestoreAssignmentButton from './DeleteRestoreAssignmentButton.vue';

export default {
    components: {
        TemplateSelect,
        MedicationProductionFreq,
        DonorSelect,
        GoodsSchedule,
        BellTwoTone,
        QuestionCircleTwoTone,
        MedicineBoxTwoTone,
        CheckOutlined,
        CloseOutlined,
        CaretUpOutlined,
        CaretDownOutlined,
        PrescriptionRule,
        EditAssignmentButton,
        DeleteRestoreAssignmentButton,
        NoSchemeMedicationFrequency,
    },
    props: {
        patientId: {
            type: Number,
            default: null,
        },
        patientAssignments: {
            type: Array,
            required: true,
        },
        displayedPatientAssignments: {
            type: Array,
            required: true,
        },
        patientAssignmentsIndices: {
            type: Object,
            required: true,
        },
        checkedAssignments: {
            type: Object,
            required: true,
        },
        allAssignmentsChecked: {
            type: Boolean,
            required: true,
        },
        medications: {
            type: Array,
            required: true,
        },
        medicationsMap: {
            type: Object,
            required: true,
        },
        subtypesMap: {
            type: Object,
            required: true,
        },
        goodsMap: {
            type: Object,
            required: true,
        },
        goods: {
            type: Object,
            required: true,
        },
        biomaterial: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        goodMedicationMap: {
            type: Object,
            required: true,
        },
        showMask: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'prescription-rule-change',
        'check-assignment',
        'check-several-assignments',
        'change',
        'template-assignments-add',
        'update-medication-course',
        'add',
        'change-prescription',
        'remove-prescription',
        'survey-result-notify-change',
        'over-days-count-change',
        'clarification-required-change',
        'assignment-edit-click',
        'remove-record',
        'comment-change',
        'change-rule-code',
        'date-plan-change',
        'preceding-assignment-change',
        'check-assignment',
        'check-all-assignments',
        'assignment-changed',
        'assignment-replaced',
        'create-medication-course',
        'donor-changed',
        'sort-change',
        'fix-period-click',
    ],
    data() {
        return {
            isLoaded: false,
            showTooltipForRecord: {},
            assignmentRules: [],
            keys: {},
            sorting: {
                field: null,
                order: null,
            },
            showRuleCheckboxTooltip: false,
            showNotificationCheckboxTooltip: false,
            showClarificationCheckboxTooltip: false,
        };
    },
    computed: {
        biomaterialMap() {
            return this.biomaterial.reduce((map, biomRec) => {
                map[biomRec.id] = biomRec;
                return map;
            }, {});
        },

        nonDeletedAssignments() {
            return this.patientAssignments.filter((x) => !x.deleted);
        },

        totalGoodsPrice() {
            if (!this.isLoaded) {
                return '';
            }

            const pricesSum = this.displayedPatientAssignments.reduce(({ min, max }, a) => {
                if (!a.medicationCourse) {
                    const price = a.clinicGoodPrice || 0;
                    return { min: min + price, max: max + price };
                }
                const medication = this.medicationsMap[a.medicationCourse.clinicMedicationId];
                if (!medication.prices) {
                    return { min, max };
                }
                if (this.goodMedicationMap[a.id]) {
                    const price = this.calcMedicationsGoodsPrice(a);
                    if (price) {
                        return { min: min + price, max: max + price };
                    }
                }
                return {
                    min: min + medication.prices.min,
                    max: max + medication.prices.max,
                };
            }, { min: 0, max: 0 });
            return pricesSum.min === pricesSum.max
                ? `${pricesSum.min}`
                : `${pricesSum.min} - ${pricesSum.max}`;
        },
    },
    async created() {
        try {
            const commonActions = (await getPatientCommonDayActions()).data;
            const patientActions = (await getPatientDayActions(this.patientId)).data;
            // NOTE: side effect of this call is used inside MedicationProducationFreq.
            // Feel free to refactor it (get rid of useScheduleStore whatsoever).
            useScheduleStore(
                this.medications,
                commonActions,
                patientActions,
                this.goods,
            );
        } catch {
            showErrorNotification('Не удалось загрузить расписание пациента.');
        }

        this.getAssignmentRules();
        this.isLoaded = true;
    },

    methods: {
        onAssignmentEditClick(record) {
            this.$emit('assignment-edit-click', record);
        },

        onAssignmentRestoreClick(record) {
            this.$emit('assignment-changed', record, 'deleted', undefined);
        },

        onAssignmentRemoveClick(record) {
            this.$emit('remove-record', record);
        },

        validateForms() {
            this.displayedPatientAssignments.forEach((_, index) => {
                let componentRef = this.$refs[`ruleForm${index}`];
                if (Array.isArray(componentRef)) {
                    [componentRef] = componentRef;
                }
                if (!componentRef) {
                    return;
                }
                componentRef.validate();
            });
        },

        calcMedicationPrice(record) {
            const medication = this.medicationsMap[record.medicationCourse.clinicMedicationId];
            const actualMedication = this.medicationsMap[medication.currentId];
            const { prices } = actualMedication;

            if (!prices) {
                return '0';
            }
            if (this.goodMedicationMap[record.id]) {
                let price;
                try {
                    price = this.calcMedicationsGoodsPrice(record);
                } catch (err) {
                    return '0';
                }
                if (price !== false) {
                    return `${price}`;
                }
            }
            if (prices.min === prices.max) {
                return `${prices.min}`;
            }
            return `${prices.min} - ${prices.max}`;
        },
        calcMedicationsGoodsPrice(record) {
            if (record.deleted || !record.datePlanStart) {
                return false;
            }

            const goodMedication = this.goodMedicationMap[record.id];
            if (!goodMedication || !goodMedication.length || !goodMedication.every(
                (good) => good.clinicGoodId || good.cancellationComment,
            )) {
                return false;
            }
            return goodMedication.reduce((acc, cur) => (cur.clinicGoodId
                ? this.goodsMap[cur.clinicGoodId]?.price.value || 0
                : acc
            ), 0);
        },

        onMergeApply(data) {
            this.$emit('assignment-changed', data, 'previous', undefined);
            this.$emit('assignment-changed', data, 'mergeIsRequired', undefined);
            this.$emit('assignment-changed', data, 'deleted', data.deleteByMerge);
        },
        onMergeReject(data) {
            this.$emit('assignment-changed', data, 'mergeIsRequired', undefined);
            this.$emit('assignment-replaced', data, data.previous);
            const parentId = data.previous.overAssignmentId
                || data.previous.overAssignmenLocaltId;
            if (!parentId) {
                return;
            }
            const parent = this.patientAssignments.find((a) => a.id === parentId);
            if (parent && !parent.deleted && !parent.mergeIsRequired) {
                return;
            }
            this.$emit('assignment-changed', data, 'overAssignmentId', undefined);
            this.$emit('assignment-changed', data, 'overAssignmentLocalId', undefined);
            this.$emit('assignment-changed', data, 'ruleCode', 'MANUAL_DATE');
        },
        getAssignmentRules() {
            this.$http.get('/assignment-rules').then((response) => {
                this.assignmentRules = response.data;
            });
        },

        getPrescriptionRowClass(record) {
            if (record.previous) {
                return { 'merge-record': true };
            }
            if (record.timeStarted
                || record.cancellationComment
            ) {
                if (record.justUpdated) {
                    return { 'completed-updated-record': true };
                }
                if (record.justCreated) {
                    return { 'completed-created-record': true };
                }
                return { 'completed-record': true };
            }
            if (record.deleted) {
                return { 'deleted-record': true };
            }
            if (record.justUpdated) {
                return { 'updated-record': true };
            }
            if (record.justCreated) {
                return { 'created-record': true };
            }
            return {};
        },

        assignmentIsDisabled(assignment) {
            return !!(assignment.timeStarted
                || assignment.cancellationComment
                || this.disabled
                || assignment.deleted
                || assignment.mergeIsRequired);
        },

        assignmentChecked(record) {
            return this.checkedAssignments[this.getAssignmentId(record)];
        },

        getAssignmentId(record) {
            return record.id ? `id-${record.id}` : `localId-${record.localId}`;
        },

        notifyCheckboxIsDisabled(record) {
            return this.assignmentIsDisabled(record)
                || !record.clinicGoodId
                || !this.goodsMap[record.clinicGoodId]
                || (this.goodsMap[record.clinicGoodId].surveyTypes
                    && !this.goodsMap[record.clinicGoodId].surveyTypes.length);
        },

        getAssignmentSubtypesText(assignment) {
            if (assignment.medicationCourse
                && assignment.medicationCourse.medicationSubtypeId
            ) {
                const subtype = this.subtypesMap[assignment.medicationCourse.medicationSubtypeId];
                return subtype ? `(${subtype.name})` : '';
            }

            const names = (assignment.medicationAssignmentInfo || []).reduce(
                (subtypeNames, info) => {
                    const subtype = this.subtypesMap[info.medicationSubtypeId];
                    return subtype ? subtypeNames.concat(subtype.name) : subtypeNames;
                },
                [],
            );
            return names.length ? `(${names.join(', ')})` : '';
        },

        getNonPrimaryBiomaterialList(clinicGoodId, surveyBiomaterial) {
            if (!clinicGoodId) {
                return '';
            }

            const good = this.goodsMap[clinicGoodId];
            if (!good) {
                return '';
            }
            const ls = good.surveyTypes.reduce((biomaterialList, surveyType) => {
                const { primaryBiomaterialId, id } = surveyType;
                if (!surveyBiomaterial[id]) {
                    return biomaterialList.concat(['б/м не указан']);
                }
                if (primaryBiomaterialId === surveyBiomaterial[id]
                    || !this.biomaterialMap[surveyBiomaterial[id]]
                ) {
                    return biomaterialList;
                }
                return biomaterialList.concat([this.biomaterialMap[surveyBiomaterial[id]].name]);
            }, []).join(', ');
            return ls && `(${ls})`;
        },

        onPrescriptionRowMouseenter(record, event) {
            if (!record.previous) {
                return;
            }
            this.showTooltipForRecord = record;
            this.placeTooltip(
                'mergeTooltipWrapper',
                event.target.closest('div.prescriptions-grid__body'),
            );
        },

        onPrescriptionRowMouseleave(record) {
            if (!record.previous) {
                return;
            }
            this.showTooltipForRecord = {};
        },

        onSelectionCheckboxMouseenter(record, event) {
            if (this.assignmentIsDisabled(record)) {
                return;
            }
            this.showRuleCheckboxTooltip = true;
            this.placeTooltip(
                'ruleCheckboxTooltipWrapper',
                event.target.closest('div.selection-column'),
            );
        },

        onSelectionCheckboxMouseleave() {
            this.showRuleCheckboxTooltip = false;
        },

        onNotificationCheckboxMouseenter(record, event) {
            if (this.notifyCheckboxIsDisabled(record)) {
                return;
            }
            this.showNotificationCheckboxTooltip = true;
            this.placeTooltip(
                'notificationCheckboxTooltipWrapper',
                event.target.closest('div.checker-column'),
            );
        },

        onNotificationCheckboxMouseleave() {
            this.showNotificationCheckboxTooltip = false;
        },

        onClarificationCheckboxMouseenter(event) {
            this.showClarificationCheckboxTooltip = true;
            this.placeTooltip(
                'clarificationCheckboxTooltipWrapper',
                event.target.closest('div.checker-column'),
            );
        },

        onClarificationCheckboxMouseleave() {
            this.showClarificationCheckboxTooltip = false;
        },

        placeTooltip(tooltipRef, target, center = false) {
            const tooltip = this.$refs[tooltipRef];
            if (!target) {
                return;
            }
            const pos = target.getBoundingClientRect();
            tooltip.style.position = 'fixed';
            tooltip.style.top = `${pos.y}px`;
            tooltip.style.left = center ? '50%' : `${pos.x + pos.width / 2}px`;
        },

        onSortByDateClick() {
            if (this.sorting.order === 'ascend') {
                this.sorting.order = 'descend';
            } else if (this.sorting.order === 'descend') {
                this.sorting.field = null;
                this.sorting.order = null;
            } else {
                this.sorting.field = 'datePlanStart';
                this.sorting.order = 'ascend';
            }
            this.$emit(
                'sort-change',
                this.sorting.field,
                this.sorting.order,
            );
        },
    },
};
</script>

<style lang="scss">
.prescriptions-grid {
    margin-top: 10px;
    overflow-x: auto;

    .prescriptions-grid__header,
    .prescriptions-grid__body {
        display: flex;
        min-width: 1540px;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;

        & > div {
            margin: auto;
        }

        div.selection-column {
            margin-left: 10px;
        }

        div.index-column {
            padding-left: 12px;
        }
    }

    .prescriptions-grid__header {
        background-color: #fafafa;

        & > div {
            padding: 10px 8px;
        }
    }

    .prescriptions-grid__body {
        padding: 10px 0;

        & > div {
            padding: 0 8px;
        }
    }

    footer {
        background-color: #fafafa;
        min-width: 1540px;
        padding: 10px;
    }

    .sortable-column {
        cursor: pointer;
        transition: background-color .3s;
        &:hover {
            background-color: #e8e8e8;
        }
    }

    .selection-column {
        flex: 0 0 30px;
    }
    .checker-column {
        flex: 0 0 30px;
    }
    .index-column {
        flex: 0 0 50px;
    }
    .action-column {
        flex: 0 0 50px;
    }
    .name-column {
        flex: 1 0 340px;
        overflow-wrap: break-word;
        min-width: 10%;
    }
    .price-column {
        flex: 1 0 100px;
    }
    .username-column {
        flex: 1 0 180px;
    }
    .rule-column {
        flex: 1 0 320px;
    }
    .comment-column {
        flex: 1 0 200px;
    }
    .date-column {
        flex: 1 0 130px;
    }

    .merge-record {
        background-color: #ffffb5;
    }
    .updated-record {
        background-color: #ddfeff;
    }
    .created-record {
        background-color: #ddf5d3;
    }
    .deleted-record {
        background-color: #f5e5dd;
    }
    .completed-created-record {
        background-color: #e9f5e4;
    }
    .completed-updated-record {
        background-color: #ddf3f3;
    }
    .completed-record {
        background-color: #f5f5f5;
    }

    .assignment-edit-group {
        gap: 10px;
        display: flex;
        flex-direction: column;
    }

    .medication-addon {
        .medication-addon-block {
            width: 300px;
        }

        min-width: 1540px;
        padding: 5px 30px 5px 10px;
        display: flex;
        justify-content: space-between;
        gap: 50px;
        background-color: #fbfbfb;

        .medication-addon__item {
            width: 20vw;
        }
    }

    .sorter {
        display: inline-flex;
        gap: -2px;
        flex-direction: column;
        font-size: 10px;
        transform: translateY(-5px);

        span {
            color: #bfbfbf;
        }
    }
}
</style>
