<template>
    <div>
        <a-spin
            :spinning="isLoading"
            :indicator="loadingIndicator"
            tip="Загрузка..."
        >
            <a-alert
                v-if="isError"
                type="error"
                message="Произошла ошибка во время загрузки данных!"
            />
            <template v-if="!blanks.length">
                <div class="action-buttons-block">
                    <a-button
                        :disabled="!selectedOrders.length"
                        class="action-btn"
                        type="primary"
                        @click="setResult"
                    >
                        Ввести результат
                    </a-button>
                    <a-button
                        :disabled="!selectedOrders.length"
                        class="action-btn"
                        type="text"
                        danger
                        @click="defectRecord"
                    >
                        Отбраковать
                    </a-button>
                </div>
                <a-table
                    :columns="tableColumns"
                    :pagination="pagination"
                    :data-source="referralData"
                    :scroll="{ x: true }"
                    row-key="id"
                    class="progress-grid"
                    @change="handleTableChange"
                >
                    <template #checkDisabled="{ text: disabled, record }">
                        <a-checkbox
                            v-model:checked="record.checked"
                            :disabled="disabled"
                            @change="onSelect($event, record)"
                        />
                    </template>
                </a-table>
            </template>
            <div
                v-else
                class="main page"
            >
                <survey-card
                    ref="surveyCard"
                    :survey-id="null"
                    :blank-ids="blanks"
                    :read-only="false"
                    :editable="true"
                    :defaults="surveyCardDefaults"
                    :blanks-in-edit="true"
                />
                <a-button
                    class="action-btn right"
                    type="primary"
                    @click="onSurveySaveClick"
                >
                    Сохранить
                </a-button>
                <a-button
                    class="action-btn right"
                    @click="onCancel"
                >
                    Отмена
                </a-button>
            </div>
            <save-medication
                v-if="showModal"
                :update-mode="false"
                :orders="selectedOrders"
                @close="modalWindowClose"
            />
        </a-spin>
        <a-modal
            v-model:visible="showDefectModal"
            :ok-button-props="{
                loading: isDefecting,
                disabled: !rejectionReason,
                type: 'primary'
            }"
            :mask-closable="false"
            title="Выбранные заявки будут отбракованы! Вы уверены?"
            ok-text="Отбраковать"
            @ok="confirmDefect"
        >
            <a-alert
                v-if="defectErrorMsg"
                type="error"
            >
                <template #message>
                    {{ defectErrorMsg }}
                </template>
            </a-alert>
            <a-form>
                <a-form-item label="Основание для отбраковки">
                    <a-input v-model:value="rejectionReason" />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import { defineComponent, h } from 'vue';
import LoadingSpinner from '@/utils/Spinner.vue';
import SurveyCard from '@/components/patientcard/SurveyCard.vue';
import SaveMedication from '@/components/labpatientcard/SaveMedication.vue';
import { showErrorNotification } from '@/utils';

export default defineComponent({
    components: {
        SurveyCard,
        SaveMedication,
    },

    props: {
        patientId: {
            type: String,
            default: null,
        },
    },

    emits: [
        'referral-update',
    ],

    data() {
        return {
            tableColumns: [{
                title: '',
                width: 32,
                key: 'checkDisabled',
                dataIndex: 'checkDisabled',
                slots: { customRender: 'checkDisabled' },
            }, {
                title: 'ФИО',
                width: 300,
                key: 'patientName',
                dataIndex: 'patientName',
                sorter: true,
            }, {
                title: 'Дата рождения',
                width: 100,
                key: 'birthDate',
                dataIndex: 'birthDate',
            }, {
                title: 'Тип б/м',
                width: 90,
                key: 'biomaterialName',
                dataIndex: 'biomaterialName',
            }, {
                title: 'Объем б/м',
                width: 90,
                key: 'biomaterialAmount',
                dataIndex: 'biomaterialAmount',
            }, {
                title: 'Исследование/препарат',
                width: 300,
                key: 'name',
                dataIndex: 'name',
            }, {
                title: 'Дата формирования направления',
                width: 100,
                key: 'referralDate',
                dataIndex: 'referralDate',
                sorter: true,
            }, {
                title: 'Дата формирования заказа',
                width: 100,
                key: 'orderDate',
                dataIndex: 'orderDate',
            }],
            referralData: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            sorting: {
                property: 'referralDate',
                direction: 'DESC',
            },
            isLoading: true,
            isError: false,
            loadingIndicator: {
                template: h(LoadingSpinner),
            },
            blanks: [],
            surveyCardDefaults: {
                clinicId: null,
                dateMaterial: null,
                surveyStatus: null,
                patientId: null,
            },
            newSurveyTypeId: null,

            showModal: false,
            showDefectModal: false,
            isDefecting: false,
            rejectionReason: '',
            defectErrorMsg: '',
        };
    },

    computed: {
        selectedOrders() {
            return this.referralData.filter((rec) => rec.checked);
        },
    },

    watch: {
        patientId(val) {
            this.surveyCardDefaults.patientId = val;
            this.fetchReferrals();
        },
        showDefectModal(value) {
            if (!value) {
                this.defectErrorMsg = '';
                this.rejectionReason = '';
            }
        },
    },

    mounted() {
        this.fetchReferrals();
    },

    methods: {
        fetchReferrals() {
            this.isError = false;
            this.isLoading = true;
            const { current, pageSize } = this.pagination;
            const params = {
                stateCode: 'ACCEPTED',
                groupByBiomaterial: false,
                page: JSON.stringify([current - 1, pageSize]),
            };
            if (this.sorting) {
                const { property, direction } = this.sorting;
                params.sort = JSON.stringify([{ property, direction }]);
            }
            if (this.patientId) {
                params.patientId = this.patientId;
            }
            this.$http.get('/procedure/laboratory/referrals', { params })
                .then((response) => this.fetchReferralsSuccess(response))
                .catch((err) => this.fetchReferralsError(err));
        },

        fetchReferralsSuccess(response) {
            this.isLoading = false;
            this.referralData = this.expandFetchedData(
                response.data.data,
            );
            this.pagination = {
                ...this.pagination,
                total: response.data.count,
            };
        },

        fetchReferralsError() {
            this.isError = true;
            this.isLoading = false;
        },

        expandFetchedData(data) {
            return data.map((rec) => ({
                ...rec,
                birthDate: this.$moment(rec.birthDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                referralDate: this.$moment(rec.referralDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                orderDate: this.$moment(rec.orderDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                checkDisabled: false,
            }));
        },

        handleTableChange(pagination, filters, sorter) {
            if (sorter.field) {
                this.sorting = {
                    property: sorter.field,
                    direction: sorter.order === 'descend' ? 'DESC' : 'ASC',
                };
            } else {
                this.sorting = null;
            }
            this.pagination = {
                ...this.pagination,
                current: pagination.current,
            };
            this.fetchReferrals();
        },

        // it's possible to select:
        // 1. multiple surveys of different types
        // with the same patient and parent survey
        // 2. multiple medication
        onSelect(event, record) {
            if (!this.selectedOrders.length) {
                this.surveyCardDefaults.patientId = null;
                this.referralData = this.referralData.map((dataRecord) => ({
                    ...dataRecord,
                    checkDisabled: false,
                }));
            }
            const isMedicationSelected = !record.surveyTypeId;

            const selectedSurveys = isMedicationSelected
                ? null
                : this.selectedOrders.reduce((acc, rec) => ({
                    ...acc,
                    [rec.surveyTypeId]: true,
                }), {});
            const selectedRecords = this.selectedOrders.reduce((acc, rec) => ({
                ...acc,
                [rec.id]: true,
            }), {});

            this.referralData = this.referralData.map((nextRec) => {
                const { id } = nextRec;
                if (selectedRecords[id]) {
                    // all selected records have to be enabled
                    return {
                        ...nextRec,
                        checkDisabled: false,
                    };
                }

                let { checkDisabled } = nextRec;
                if (!isMedicationSelected) {
                    if (event.target.checked) {
                        if (!nextRec.surveyTypeId
                            || record.patientId !== nextRec.patientId
                            || record.parentSurveyId !== nextRec.parentSurveyId
                            || selectedSurveys[nextRec.surveyTypeId]
                        ) {
                            checkDisabled = true;
                        } else {
                            // same parent survey and only different surveys
                            checkDisabled = false;
                        }
                    } else if (record.patientId === nextRec.patientId
                        && record.parentSurveyId === nextRec.parentSurveyId
                        && !selectedSurveys[nextRec.surveyTypeId]
                    ) {
                        // enable same parent, non-selected-type surveys
                        checkDisabled = false;
                    }
                } else if (event.target.checked) {
                    checkDisabled = !!nextRec.surveyTypeId;
                }
                return {
                    ...nextRec,
                    checkDisabled,
                };
            });

            this.surveyCardDefaults.patientId = this.selectedOrders.length
                ? this.selectedOrders[0].patientId
                : null;
        },

        setResult() {
            if (this.selectedOrders[0].surveyTypeId) {
                this.setSurveyResult();
                return;
            }
            this.setMedicationResult();
        },

        async setSurveyResult() {
            const firstRef = this.selectedOrders[0];
            this.surveyCardDefaults.dateMaterial = firstRef.timeCollection;
            this.newSurveyTypeId = firstRef.parentSurveyId;
            this.blanks = await this.loadSurveyBlanks();
        },

        loadSurveyBlanks() {
            const surveys = this.selectedOrders.map(
                (rec) => parseInt(rec.surveyTypeId, 10),
            );
            this.isLoading = true;
            return this.$http.get('/survey/labs/types', {
                params: {
                    ids: JSON.stringify(surveys),
                },
            }).then(
                (response) => {
                    const surveyBlanks = response.data.map((rec) => rec.blanks);
                    const blanks = surveyBlanks.reduce((acc, next) => acc.concat(next), []);
                    return blanks.map((blank) => blank.id);
                },
                () => {
                    this.isError = true;
                    return [];
                },
            ).then((data) => {
                this.isLoading = false;
                return data;
            });
        },

        onCancel() {
            this.blanks = [];
        },

        async onSurveySaveClick() {
            let values;
            try {
                values = await this.$refs.surveyCard.$refs.form.validate();
            } catch (err) {
                return;
            }
            this.surveySave(values);
        },

        surveySave(values) {
            const referralOrderSurveys = this.selectedOrders.map((rec) => ({
                referralOrderId: rec.id,
                surveyTypeId: rec.surveyTypeId,
            }));
            const patientGoods = this.selectedOrders.map((rec) => ({
                patientGoodId: parseInt(rec.patientGoodId, 10),
                surveyTypeId: rec.surveyTypeId,
            }));

            const data = {
                blanks: this.$refs.surveyCard.blanks.map(
                    (blank) => ({
                        indicators: blank.indicators,
                        surveyBlankId: blank.id,
                    }),
                ),
                clinicId: values.clinicId,
                comment: values.comment,
                dateSurvey: values.dateSurvey.format('YYYY-MM-DD'),
                equipment: values.equipments,
                stateId: values.surveyStatus,
                files: this.$refs.surveyCard.fileList.map(
                    (f) => (f.response && f.response.id ? f.response.id : f.uid),
                ),
                surveyTypeId: this.newSurveyTypeId,
                referralOrderSurveys,
                patientGoods,
            };

            if (values.dateMaterial) {
                data.dateTake = values.dateMaterial.format('YYYY-MM-DD');
            }
            const patientId = this.patientId || this.selectedOrders[0].patientId;
            this.$http.post(`patient/${patientId}/survey/labs`, data)
                .then(() => {
                    this.onCancel();
                    this.fetchReferrals();
                    this.$emit('referral-update');
                })
                .catch((err) => {
                    showErrorNotification(err.response.data.msg);
                });
        },

        setMedicationResult() {
            this.showModal = true;
        },

        modalWindowClose(updateStore) {
            this.showModal = false;
            if (updateStore) {
                this.fetchReferrals();
                this.$emit('referral-update');
            }
        },

        defectRecord() {
            this.showDefectModal = true;
        },

        confirmDefect() {
            this.$http.put('/procedure/referral/orders/reject', {
                orders: this.selectedOrders.map((order) => order.id),
                reason: this.rejectionReason,
            })
                .then((response) => this.defectReferralSuccess(response))
                .catch((err) => this.defectReferralError(err));
            this.isDefecting = true;
            this.defectErrorMsg = '';
        },

        defectReferralSuccess() {
            this.showDefectModal = false;
            this.isDefecting = false;
            this.fetchReferrals();
            this.$emit('referral-update');
        },

        defectReferralError(err) {
            this.isDefecting = false;
            if (err.response.data.msg) {
                this.defectErrorMsg = `Ошибка! ${err.response.data.msg}`;
            } else {
                this.defectErrorMsg = 'Произошла ошибка во время сохранения изменений!';
            }
        },
    },
});
</script>

<style lang="scss" scoped>
    .action-buttons-block {
        text-align: left;
    }
    button.action-btn {
        margin: 10px;
        margin-left: 0;
        .ant-btn-primary[disabled] {
            cursor: pointer;
        }
    }

    button.right {
        float: right;
    }

    div.progress-grid {
        width: calc(100vw - 335px);
    }
    ::v-deep(.ant-table-column-title) {
        display: flex;
        text-align: center;
        word-break: normal;
    }
</style>
