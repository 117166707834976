import { AxiosRequestConfig } from 'axios';
import {
    IPatientLightweight,
    IPatient,
    IPatientInfo,
    IPatientRelated,
    IPatientDayActions,
    IPatientSchedule,
    IGoodEntity,
} from '../../life-impulse-tools/types';
import { get } from '../utils/axiosConnectionTimeout';

export const getPatientsListLightweigh = (
    options?: AxiosRequestConfig,
) => get<{ data: IPatientLightweight[], count: number }>('/patients', options);

export const getPatientsList = (
    options?: AxiosRequestConfig,
) => get<IPatient[]>('/patients', options);

export const getPatientInfo = (
    patientId: number,
    options?: AxiosRequestConfig,
) => get<IPatientInfo>(`/patient/info/${patientId}`, options);

export const getPatientRelatedList = (
    patientId: number,
    options?: AxiosRequestConfig,
) => get<IPatientRelated[]>(`/patient/related/list/${patientId}`, options);

export const getPatientCommonDayActions = (
    options?: AxiosRequestConfig,
) => get<IPatientDayActions>('/common/patient/day/actions/', options);

export const getPatientDayActions = (
    patientId: number,
    options?: AxiosRequestConfig,
) => get<IPatientDayActions>(`/patient/${patientId}/day/actions/`, options);

export const getPatientSchedules = (
    patientId: number,
    options?: Record<string, any>,
) => get<IPatientSchedule[]>(`/patient/${patientId}/medication/schedules`, options);

export const getPatientClinicGoods = (
    options?: Record<string, any>,
) => get<IGoodEntity[]>('/clinic/goods/patient', options);
