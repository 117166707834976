<template>
    <div class="main page">
        <a-layout>
            <page-header />
            <a-layout-content class="info">
                <a-spin
                    :spinning="isLoading"
                    tip="Загрузка..."
                >
                    <a-breadcrumb
                        v-if="protocol"
                    >
                        <a-breadcrumb-item href="">
                            <home-outlined
                                @click="$router.push({name: 'main'})"
                            />
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>
                            <a :href="`/card/${protocolPatientId}`">{{ patientName }}</a>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>
                            Приём
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                    <div
                        class="main-info"
                    >
                        <h2>
                            Приём пациента
                            <span v-if="protocol && !patientId && !mergeTimestamp">
                                №{{ protocol.id }}
                                от {{ $moment(protocol.timeCreate).format('DD.MM.YYYY') }}
                            </span>
                        </h2>
                        <div
                            class="reception-patient-info"
                        >
                            <div>
                                <a-avatar
                                    :src="avatarSrc"
                                    size="large"
                                >
                                    <template #icon>
                                        <user-outlined />
                                    </template>
                                </a-avatar>
                                <div id="info-string">
                                    <h2>
                                        <patient-info-string
                                            :patient-info="patientInfo"
                                        />
                                    </h2>
                                </div>
                            </div>
                            <a-button
                                data-test-id="open-patient-card-btn"
                                type="primary"
                                html-type="submit"
                                @click="openPatientCard"
                            >
                                Карта пациента
                            </a-button>
                        </div>
                        <div
                            v-if="protocol"
                        >
                            <div class="protocol-top-controls">
                                <div class="presence-checkbox">
                                    <div>
                                        В присутствии пациента
                                    </div>
                                    <div>
                                        <a-checkbox
                                            v-model:checked="protocol.inThePresenceOfThePatient"
                                            data-test-id="in-the-presence-of-the-patient"
                                        />
                                    </div>
                                </div>
                                <div class="presence-controls">
                                    <a-button
                                        data-test-id="draft-save-top-btn"
                                        type="primary"
                                        :disabled="!valuesAreChanged"
                                        @click.stop="saveAsDraft(false)"
                                    >
                                        Сохранить как черновик
                                    </a-button>
                                    <a-button
                                        data-test-id="save-top-btn"
                                        :disabled="!valuesAreChanged"
                                        :loading="isLoading"
                                        type="primary"
                                        @click="onSave"
                                    >
                                        Сохранить
                                    </a-button>

                                    <print-protocol
                                        :protocol-id="protocolId"
                                        :disabled="valuesAreChanged || !!mergeIsRequiredCount"
                                    />

                                    <a-button
                                        data-test-id="reception-close-top-btn"
                                        type="default"
                                        @click="onCancel"
                                    >
                                        {{ valuesAreChanged ? 'Отмена' : 'Закрыть' }}
                                    </a-button>

                                    <save-status
                                        :value="saveStatus"
                                        @input="(e) => saveStatus = e"
                                    />
                                </div>
                            </div>

                            <protocol-text-description
                                :complaints="protocol.complaints"
                                :external-evidence="protocol.externalEvidence"
                                :recommendations="protocol.recommendations"
                                :patient-id="protocolPatientId"
                                :recommendation-notification="protocol
                                    .recommendationNotification"
                                @text-description-change="onTextDescriptionChange"
                                @recommendation-notification-change="
                                    (e) => protocol.recommendationNotification = e"
                            />

                            <diagnoses-tree-picker
                                v-model:patient-diagnoses="protocol.diagnoses"
                                title="Дерево диагнозов"
                                :disabled="!isLastProtocol"
                                :diagnoses="clinicDiagnoses"
                                :header="diagnosesTreeOptions.header"
                                :required="diagnosesTreeOptions.required"
                                :fields="diagnosesTreeOptions.fields"
                                color-mode
                                @diagnosis-changed="(...args) =>
                                    diagnosisChange('diagnoses', ...args)"
                                @diagnosis-added="(...args) =>
                                    diagnosisAdd('diagnoses', ...args)"
                                @diagnosis-replaced="(...args) =>
                                    diagnosisReplace('diagnoses', ...args)"
                                @diagnosis-removed="(...args) =>
                                    diagnosisRemove('diagnoses', ...args)"
                                @diagnosis-search="onDiagnosisSearch($event, 'clinic')"
                            />
                            <diagnoses-tree-picker
                                v-model:patient-diagnoses="protocol.diagnosesICD"
                                title="Диагнозы МКБ"
                                :disabled="!isLastProtocol"
                                :diagnoses="clinicICDDiagnoses"
                                :header="diagnosesICDTreeOptions.header"
                                :required="diagnosesICDTreeOptions.required"
                                :fields="diagnosesICDTreeOptions.fields"
                                color-mode
                                @diagnosis-changed="(...args) =>
                                    diagnosisChange('diagnosesICD', ...args)"
                                @diagnosis-added="(...args) =>
                                    diagnosisAdd('diagnosesICD', ...args)"
                                @diagnosis-replaced="(...args) =>
                                    diagnosisReplace('diagnosesICD', ...args)"
                                @diagnosis-removed="(...args) =>
                                    diagnosisRemove('diagnosesICD', ...args)"
                                @diagnosis-search="onDiagnosisSearch($event, 'icd')"
                            />
                            <h2>Назначения</h2>
                            <prescription
                                ref="prescription"
                                :disabled="!isLastProtocol"
                                :patient-id="protocolPatientId"
                                :protocol-id="protocolId"
                                :patient-assignments="protocol.assignments"
                                :good-medication-map="goodMedicationMap"
                                @change-assignment="changeAssignment"
                                @add-assignment="addAssignment"
                                @replace-assignment="replaceAssignment"
                            />

                            <appointments-list
                                :patient-id="protocolPatientId"
                                :protocol-id="protocolId"
                            />

                            <div class="presence-controls">
                                <a-button
                                    data-test-id="draft-save-btn"
                                    :disabled="!valuesAreChanged"
                                    type="primary"
                                    @click.stop="saveAsDraft(false)"
                                >
                                    Сохранить как черновик
                                </a-button>
                                <a-button
                                    data-test-id="reception-save-btn"
                                    :disabled="!valuesAreChanged"
                                    :loading="isLoading"
                                    type="primary"
                                    @click="onSave"
                                >
                                    Сохранить
                                </a-button>

                                <print-protocol
                                    :protocol-id="protocolId"
                                    :disabled="valuesAreChanged || !!mergeIsRequiredCount"
                                />

                                <a-button
                                    data-test-id="reception-close-btn"
                                    type="default"
                                    @click="onCancel"
                                >
                                    {{ valuesAreChanged ? 'Отмена' : 'Закрыть' }}
                                </a-button>

                                <save-status
                                    :value="saveStatus"
                                    @input="(e) => saveStatus = e"
                                />
                            </div>
                        </div>
                    </div>
                </a-spin>
            </a-layout-content>
        </a-layout>
    </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
    cloneDeep,
    isEqual,
    merge,
    keyBy,
    groupBy,
    debounce,
} from 'lodash';
import DiagnosesTreePicker from 'vue-web-components/src/components/anamneses/DiagnosesTreePicker.vue';
import HomeOutlined from '@ant-design/icons-vue/HomeOutlined';
import UserOutlined from '@ant-design/icons-vue/UserOutlined';
import QuestionCircleOutlined from '@ant-design/icons-vue/QuestionCircleOutlined';
import {
    defineComponent,
    createVNode,
} from 'vue';
import { Modal } from 'ant-design-vue';
import titleMixin from '@/mixins/titleMixin';
import PageHeader from '@/components/PageHeader.vue';
import PatientInfoString from '@/components/patientcard/PatientInfoString.vue';
import Prescription from '@/components/reception/prescription/Prescription.vue';
import ProtocolTextDescription from '@/components/reception/ProtocolTextDescription.vue';
import SaveStatus from '@/components/infocomponents/SaveStatus.vue';
import PrintProtocol from '@/components/PrintProtocol.vue';
import AppointmentsList from '@/components/reception/AppointmentsList.vue';
import StateTraveler from '@/utils/StateTraveler';
import compareProtocols from '@/utils/comparator';
import { showErrorNotification, showNotification } from '@/utils';

export default defineComponent({
    components: {
        PageHeader,
        PatientInfoString,
        PrintProtocol,
        DiagnosesTreePicker,
        SaveStatus,
        ProtocolTextDescription,
        Prescription,
        HomeOutlined,
        UserOutlined,
        AppointmentsList,
    },
    mixins: [titleMixin],
    inject: ['sessionIsExpired'],
    async beforeRouteLeave(to, from, next) {
        if (!this.valuesAreChanged || to.name === 'reception' || to.name === 'login') {
            next();
            return;
        }
        const isConfirmed = await new Promise((resolve) => {
            Modal.confirm({
                title: 'Вы точно хотите закрыть несохраненный протокол?',
                icon: createVNode(QuestionCircleOutlined),
                okText: 'Да',
                onOk() { resolve(true); },
                onCancel() { resolve(false); },
                width: 600,
            });
        });
        if (isConfirmed) {
            next();
            return;
        }
        next(false);
    },
    props: {
        patientId: {
            type: Number,
            default: null,
        },
        appointmentId: {
            type: Number,
            default: null,
        },
        protocolId: {
            type: Number,
            default: null,
        },
        generatingPatientGoodId: {
            type: Number,
            default: null,
        },
        draftId: {
            type: Number,
            default: null,
        },
    },
    emits: [],
    data() {
        return {
            draft: null,
            state: Function,
            mergeTimestamp: null,
            diagnosesTreeOptions: {
                header: ['Диагноз', 'Статус', 'Начало', 'Завершение', 'Свойства'],
                required: ['name', 'stateCode'],
                fields: ['startByDay', 'endByDay', 'stateCode', 'attributes'],
            },
            diagnosesICDTreeOptions: {
                header: ['Диагноз', 'Статус', 'Начало', 'Завершение'],
                required: ['name', 'stateCode'],
                fields: ['startByDay', 'stateCode', 'endByDay'],
            },
            protocol: null,
            baseProtocol: null,
            clinicDiagnoses: [],
            clinicICDDiagnoses: [],
            patientInfo: null,
            saveStatus: {
                code: null,
                text: null,
            },
            patientAvatarPreviewFileId: null,
            isLoading: true,
            valuesAreChanged: false,
            confirmedAssignments: new Set(),
            // goodMedicationMap is for PatientPrescriptionGrid component
            // make use of a state manager here
            goodMedicationMap: {},
            appoinmentToEdit: null,
        };
    },
    computed: {
        patientName() {
            if (!this.patientInfo) {
                return '';
            }
            const { firstname, lastname, middlename } = this.patientInfo;
            const name = `${lastname} ${firstname}`;
            return middlename ? `${name} ${middlename}` : name;
        },
        protocolPatientId() {
            if (this.patientId) {
                return this.patientId;
            }
            if (this.protocol && this.protocol.patientId) {
                return this.protocol.patientId;
            }
            return this.draft && this.draft.patientId;
        },
        mergeIsRequiredCount() {
            const entities = [
                ...this.protocol.diagnoses,
                ...this.protocol.diagnosesICD,
                ...this.protocol.assignments,
            ];
            return entities.filter((entity) => entity.mergeIsRequired).length;
        },
        isLastProtocol() {
            if (this.draft) {
                return true;
            }
            if (!this.patientInfo) {
                return false;
            }
            return this.patientInfo.lastProtocolId === this.protocolId || !this.protocolId;
        },
        avatarSrc() {
            return this.patientAvatarPreviewFileId
                ? `/api/patient/file/${this.patientAvatarPreviewFileId}`
                : '';
        },
    },
    watch: {
        protocol: {
            deep: true,
            handler() {
                this.valuesAreChanged = true;
                if (this.isLoading) {
                    return;
                }
                this.debouncedDraftSave();
            },
        },
        protocolPatientId: {
            immediate: true,
            handler: 'loadGoodMedication',
        },
        draftId: 'onDraftIdChange',
    },
    async created() {
        if (this.draftId) {
            this.draft = (await this.loadDraft(this.draftId)).description;
        }
        window.onbeforeunload = () => {
            if (this.valuesAreChanged && !this.sessionIsExpired.value) {
                return true;
            }
            return undefined;
        };
        this.state = new StateTraveler(this, 'protocol', [
            'diagnoses',
            'diagnosesICD',
            'assignments',
        ]);
        this.isLoading = true;
        try {
            const [, diagnoses, diagnosesIcd] = await Promise.all([
                this.initPatientProtocols(),
                this.loadClinicDiagnoses(),
                this.loadClinicICDDiagnoses(),
            ]);
            this.clinicDiagnoses = diagnoses.data;
            this.clinicICDDiagnoses = diagnosesIcd.data;
        } catch (err) {
            let msg = 'Не удалось загрузить данные.';
            if (err.response && err.response.data) {
                msg = err.response.data.msg;
            }
            showErrorNotification(msg);
        }

        if (this.draft) {
            this.protocol = this.mapProtocol(this.draft, false, true);
        }
        this.valuesAreChanged = false;
        // need a delay for the protocol watcher to fire first
        this.$nextTick(() => { this.isLoading = false; });
    },
    unmounted() {
        window.onbeforeunload = undefined;
        this.state.destroy();
        this.debouncedDraftSave(true);
    },
    methods: {
        async onDraftIdChange() {
            if (!this.draftId) {
                return;
            }
            this.isLoading = true;
            try {
                this.draft = (await this.loadDraft(this.draftId)).description;
                await this.initPatientProtocols();
                this.protocol = this.mapProtocol(this.draft, false, true);
            } catch (err) {
                showErrorNotification(
                    'Не удалось загрузить черновик.',
                );
            }
            this.isLoading = false;
            this.valuesAreChanged = false;
        },

        async initPatientProtocols() {
            if (this.protocolId) {
                this.baseProtocol = await this.loadProtocol(this.protocolId, false);
                this.protocol = this.mapProtocol(cloneDeep(this.baseProtocol), false);
                this.patientInfo = await this.loadPatientInfo(this.protocolPatientId);
                return;
            }

            this.patientInfo = await this.loadPatientInfo(this.protocolPatientId);
            const protocol = await this.loadProtocol(
                this.patientInfo.lastProtocolId,
                true,
            );
            this.baseProtocol = cloneDeep(protocol);
            this.protocol = {
                ...this.mapProtocol(protocol, true),
                recommendations: null,
                complaints: null,
                externalEvidence: null,
                timeCreate: undefined,
                userName: undefined,
                recommendationNotification: {
                    required: false,
                    comment: null,
                    dueDate: null,
                    isDone: false,
                },
            };
        },

        async loadGoodMedication(patientId) {
            if (!patientId) {
                this.goodMedicationMap = {};
                return;
            }

            let goodMedication;
            try {
                goodMedication = await this.$http.get(
                    '/patient-good-medication',
                    { patientId },
                );
            } catch (err) {
                showErrorNotification(
                    'Не удалось загрузить список препаратов-назначений.',
                );
                this.goodMedicationMap = {};
                return;
            }
            this.goodMedicationMap = groupBy(
                goodMedication.data,
                'patientAssignmentId',
            );
        },

        validatePrescriptions() {
            this.$refs.prescription.validatePrescriptions();
        },

        debouncedDraftSave: debounce(function debouncedDraftSave(isDestroyed) {
            if (isDestroyed) {
                return;
            }
            this.saveAsDraft(true);
        }, 30000),
        async loadDraft(draftId) {
            try {
                const draftResponse = await this.$http.get(
                    `/patient/protocol-draft/${draftId}`,
                );
                return draftResponse.data;
            } catch (error) {
                showErrorNotification('Не удалось загрузить черновик.');
                return {};
            }
        },
        loadClinicICDDiagnoses(name = '') {
            return this.$http.get(
                'diagnoses-icd',
                { params: { name } },
            );
        },
        loadClinicDiagnoses(name = '') {
            return this.$http.get(
                'diagnoses',
                { params: { name } },
            );
        },
        async onDiagnosisSearch(e, target) {
            try {
                if (target === 'icd') {
                    this.clinicICDDiagnoses = (await this.loadClinicICDDiagnoses(e)).data;
                } else {
                    this.clinicDiagnoses = (await this.loadClinicDiagnoses(e)).data;
                }
            } catch (err) {
                let msg = 'Не удалось загрузить данные.';
                if (err.response && err.response.data) {
                    msg = err.response.data.msg;
                }
                showErrorNotification(msg);
            }
        },
        async saveAsDraft(autoSave = false) {
            try {
                await this.$http.post(
                    `/patient/${this.protocolPatientId}/protocol-draft`,
                    { protocol: this.protocol, draftId: this.draftId, autoSave },
                );
                this.saveStatus = {
                    code: 'OK',
                    text: 'Черновик сохранен успешно',
                };
            } catch (err) {
                showErrorNotification('Не удалось сохранить черновик.');
                this.saveStatus = {
                    code: 'ERROR',
                    text: 'Ошибка сохранения',
                };
            }
        },
        diagnosisChange(type, idx, key, value) {
            if (key === 'deleted' && this.protocol[type][idx].localId) {
                this.diagnosisRemove(type, idx);
                return;
            }
            if (key === 'parentId') {
                const parent = this.protocol[type].find((d) => d.id === value);
                if (!parent || parent.patientProtocolId) {
                    this.protocol[type][idx].parentLocalId = undefined;
                }
                if (parent && !parent.patientProtocolId) {
                    this.protocol[type][idx].parentLocalId = parent.id;
                }
            }
            this.protocol[type][idx][key] = value;
        },
        diagnosisAdd(type, value, target) {
            if (value.parentId && target && !target.patientProtocolId) {
                this.protocol[type].push({ ...value, parentLocalId: target.id });
                return;
            }
            this.protocol[type].push(value);
        },
        diagnosisReplace(type, idx, value) {
            if (!value) {
                this.protocol[type].splice(idx, 1);
                return;
            }
            this.protocol.diagnoses.splice(idx, 1, value);
        },
        diagnosisRemove(type, idx) {
            this.protocol[type].splice(idx, 1);
        },
        findLocalIndex(array, element) {
            return array.findIndex(
                (a) => (a.id && a.id === element.id)
                    || (a.localId && a.localId === element.localId),
            );
        },
        changeAssignment(assignment, prop, value, skipUpdate) {
            const idx = this.findLocalIndex(this.protocol.assignments, assignment);
            if (isEqual(this.protocol.assignments[idx][prop], value)) {
                return;
            }
            if (prop === 'deleted' && !assignment.patientProtocolId) {
                this.protocol.assignments.splice(idx, 1);
                return;
            }
            this.protocol.assignments[idx][prop] = value;
            if (prop === 'deleted') {
                return;
            }
            if (skipUpdate) {
                return;
            }
            this.protocol.assignments[idx].justUpdated = true;
        },
        replaceAssignment(assignment, value) {
            const idx = this.findLocalIndex(this.protocol.assignments, assignment);
            this.protocol.assignments.splice(idx, 1, value);
        },
        addAssignment(assignment, idx = 0) {
            this.protocol.assignments.splice(idx, 0, assignment);
            this.protocol.assignments[idx].justCreated = true;
        },
        onTextDescriptionChange(target, value) {
            if (![
                'complaints',
                'externalEvidence',
                'recommendations',
            ].includes(target)) {
                throw new Error('Unknown description field.');
            }
            this.protocol[target] = value;
        },
        mapProtocol(protocol, clean = false, isDraft = false) {
            return {
                ...protocol,
                inThePresenceOfThePatient: !!protocol.inThePresenceOfThePatient,
                recommendationNotification: {
                    ...protocol.recommendationNotification,
                    dueDate: protocol.recommendationNotification.dueDate
                        && this.$moment(protocol.recommendationNotification.dueDate, 'YYYY-MM-DD'),
                    isDone: !!protocol.recommendationNotification.isDone,
                },
                assignments: protocol.assignments.map((a) => ({
                    ...a,
                    datePlanStart: this.$moment(a.datePlanStart),
                    notifyAfterSurveysIsReady: !!a.notifyAfterSurveysIsReady,
                    unaccepted: !!a.unaccepted,
                    wasDeleted: clean ? undefined : a.wasDeleted,
                    userCreate: clean ? undefined : a.userCreate,
                    userEdit: clean ? undefined : a.userEdit,
                    timeCreate: clean ? undefined : a.timeCreate,
                    timeEdit: clean ? undefined : a.timeEdit,
                    justCreated: clean ? undefined : a.justCreated,
                    justUpdated: clean ? undefined : a.justUpdated,
                    previouslyDeleted: isDraft ? a.previouslyDeleted : a.deleted,
                })).filter((d) => (!clean ? true : !d.deleted)),
                diagnosesICD: protocol.diagnosesICD.map((d) => ({
                    ...d,
                    timeStart: d.timeStart ? this.$moment(d.timeStart, 'YYYY-MM-DD') : null,
                    timeEnd: d.timeEnd ? this.$moment(d.timeEnd, 'YYYY-MM-DD') : null,
                    justCreated: clean ? undefined : d.justCreated,
                    justUpdated: clean ? undefined : d.justUpdated,
                    previouslyDeleted: isDraft ? d.previouslyDeleted : d.deleted,
                })).filter((d) => (!clean ? true : !d.deleted)),
                diagnoses: protocol.diagnoses.map((d) => ({
                    ...d,
                    timeStart: d.timeStart ? this.$moment(d.timeStart, 'YYYY-MM-DD') : null,
                    timeEnd: d.timeEnd ? this.$moment(d.timeEnd, 'YYYY-MM-DD') : null,
                    justCreated: clean ? undefined : d.justCreated,
                    justUpdated: clean ? undefined : d.justUpdated,
                    previouslyDeleted: isDraft ? d.previouslyDeleted : d.deleted,
                })).filter((d) => (!clean ? true : !d.deleted)),
            };
        },
        async loadProtocol(protocolId, last = false) {
            if (!protocolId) {
                return {
                    assignments: [],
                    complaints: null,
                    diagnoses: [],
                    diagnosesICD: [],
                    externalEvidence: null,
                    id: null,
                    inThePresenceOfThePatient: true,
                    patientId: this.protocolPatientId,
                    recommendationNotification: {
                        required: false,
                        comment: null,
                        dueDate: null,
                        isDone: false,
                    },
                    recommendations: null,
                };
            }
            const protocol = await this.$http.get(`/patient/protocol/${protocolId}`, {
                params: {
                    // NOTE: this is only to distinguish new protocol creation
                    // from existing protocol review in the requests log
                    getExistingProtocol: last ? 0 : 1,
                    getLastProtocol: last ? 1 : 0,
                },
            });
            return protocol.data;
        },

        async loadPatientInfo(id) {
            let patientResponse;
            try {
                patientResponse = await this.$http.get(`/patient/info/${id}`);
            } catch (err) {
                showErrorNotification(
                    'Не удалось загрузить информацию о пациенте.',
                );
                return {};
            }

            return {
                ...patientResponse.data,
                birthDate: patientResponse.data.birth_date,
            };
        },

        async onSave() {
            if (this.mergeIsRequiredCount) {
                showErrorNotification(
                    'Для сохранения протокола необходимо решить конфликты в диагнозах и/или направлениях.',
                );
                return;
            }

            try {
                const ids = await this.$refs.prescription.checkAssignmentsProduction(
                    this.confirmedAssignments,
                );
                this.confirmedAssignments = new Set(ids);
            } catch (err) {
                // user interrupted the process of the protocol saving
                return;
            }

            this.isLoading = true;
            this.validatePrescriptions();

            const clonedProtocol = cloneDeep(this.protocol);
            const errors = [];

            clonedProtocol.assignments.forEach((assignment) => {
                const {
                    datePlanStart,
                    deleted,
                    medicationCourse,
                    name,
                    overAssignmentId,
                    overAssignmentLocalId,
                    ruleCode,
                    timeStarted,
                } = assignment;

                if (deleted || timeStarted) {
                    return;
                }
                if (ruleCode === 'MANUAL_DATE' && !datePlanStart) {
                    errors.push(`Не заполнена дата у назначения ${name}.`);
                }
                if ((ruleCode === 'AFTER_MED_COURSE_START'
                    || ruleCode === 'AFTER_MED_COURSE_END'
                    || ruleCode === 'AFTER_GOOD_CREATED')
                    && !overAssignmentLocalId
                    && !overAssignmentId
                ) {
                    errors.push(`Не указаны зависимые назначения у "${name}".`);
                }
                if (medicationCourse
                    && (!medicationCourse.times
                    || !medicationCourse.times.length)
                ) {
                    errors.push(`Не заполнено расписание приема у назначения ${name}.`);
                }
            });
            errors.forEach(showErrorNotification);
            if (errors.length) {
                this.isLoading = false;
                return;
            }

            const notificationDueDate = clonedProtocol.recommendationNotification.dueDate;
            // map protocol for save
            const protocol = {
                ...clonedProtocol,
                baseProtocolTimestamp: new Date(
                    this.baseProtocol.timeEdit || this.baseProtocol.timeCreate,
                ).getTime(),
                assignments: clonedProtocol.assignments
                    .filter((a) => !a.previouslyDeleted && !a.timeStarted && !a.cancellationComment)
                    .map((a) => ({
                        ...a,
                        datePlanStart: a.datePlanStart
                            ? a.datePlanStart.format('YYYY-MM-DD')
                            : this.$moment().format('YYYY-MM-DD'),
                        notifyAfterSurveysIsReady: a.notifyAfterSurveysIsReady ? 1 : 0,
                        unaccepted: a.unaccepted ? 1 : 0,
                        comment: a.unaccepted ? a.comment : null,
                        deleted: a.deleted ? 1 : 0,
                    })),
                recommendationNotification: undefined,
                recommendationNotificationRequired:
                    clonedProtocol.recommendationNotification.required,
                recommendationNotificationComment:
                    clonedProtocol.recommendationNotification.comment,
                recommendationNotificationDueDate: notificationDueDate
                    ? notificationDueDate.format('YYYY-MM-DD')
                    : null,
                diagnoses: clonedProtocol.diagnoses
                    .filter((d) => !d.previouslyDeleted)
                    .map((d) => ({
                        ...d,
                        id: d.patientProtocolId ? d.id : undefined,
                        localId: d.patientProtocolId ? undefined : d.localId,
                        parentId: d.parentLocalId ? undefined : d.parentId,
                        timeStart: d.timeStart ? d.timeStart.format('YYYY-MM-DD') : null,
                        timeEnd: d.timeEnd ? d.timeEnd.format('YYYY-MM-DD') : null,
                    })),
                diagnosesICD: clonedProtocol.diagnosesICD
                    .filter((d) => !d.previouslyDeleted)
                    .map((d) => ({
                        ...d,
                        id: d.patientProtocolId ? d.id : undefined,
                        localId: d.patientProtocolId ? undefined : d.localId,
                        parentId: d.parentLocalId ? undefined : d.parentId,
                        timeStart: d.timeStart ? d.timeStart.format('YYYY-MM-DD') : null,
                        timeEnd: d.timeEnd ? d.timeEnd.format('YYYY-MM-DD') : null,
                    })),
            };
            const protocolSavingErrorHeader = this.protocolId
                ? `Ошибка. Протокол №${this.protocolId}`
                : 'Ошибка при создании нового протокола';
            try {
                if (!this.protocolId || this.mergeTimestamp) {
                    const response = await this.$http.post(
                        `patient/${this.protocolPatientId}/protocol`,
                        {
                            ...protocol,
                            id: null,
                            baseProtocolTimestamp: this.mergeTimestamp
                                ? this.mergeTimestamp
                                : protocol.baseProtocolTimestamp,
                            timeCreate: null,
                        },
                    );
                    this.baseProtocol = cloneDeep(response.data);
                    this.protocol = this.mapProtocol(response.data);
                    if (this.generatingPatientGoodId) {
                        this.$http.put(`patient/good/${this.generatingPatientGoodId}/seen-surveys-is-ready`, {
                            seenSurveysIsReady: 1,
                        })
                            .catch((err) => {
                                showNotification(protocolSavingErrorHeader, err.response.data.msg);
                            });
                    }
                    this.patientInfo = await this.loadPatientInfo(this.protocolPatientId);
                    this.$router.replace({ path: `/reception/${this.protocol.id}` });
                } else {
                    const response = (await this.$http.put(`/patient/protocol/${this.protocolId}`, protocol)).data;
                    this.baseProtocol = cloneDeep(response);
                    this.protocol = this.mapProtocol(response);
                }
                if (this.appointmentId) {
                    this.$http.put(`appointment/${this.appointmentId}/finish`).catch((err) => {
                        showNotification(protocolSavingErrorHeader, err.response.data.msg);
                    });
                }
                await this.loadGoodMedication(this.protocolPatientId);
                this.saveStatus = {
                    code: 'OK',
                    text: 'Сохранено успешно',
                };
                // FIX-ME: who mutating component ??
                this.$nextTick(() => {
                    this.valuesAreChanged = false;
                });
                this.mergeTimestamp = null;
            } catch (error) {
                if (!error.response || !error.response.data) {
                    showNotification(
                        protocolSavingErrorHeader,
                        'Не удалось сохранить протокол.',
                    );
                    // TODO: replace error response status with HTTP Conflict
                    // use the status instead of msg text
                } else if (error.response.data.msg === 'Протокол неактуален.') {
                    window.history.pushState(
                        {},
                        '',
                        `/new-reception/${this.protocolPatientId}`,
                    );
                    await this.loadGoodMedication(this.protocolPatientId);
                    const changedData = error.response.data.data;
                    this.mergeTimestamp = new Date(changedData.updatedAt).getTime();
                    const mergedProtocol = this.mergeProtocols(
                        changedData,
                        this.baseProtocol,
                        this.protocol,
                    );
                    this.protocol = this.mapProtocol(mergedProtocol, false, true);
                    this.$notification.open({
                        message: 'Внимание',
                        duration: 0,
                        description: 'Был создан новый протокол, диагнозы и назначения будут обновлены!\nНажмите сохранить для подтвержения',
                    });
                } else if (error.response.data.data) {
                    const dataField = error.response.data.data.assignments
                        || error.response.data.data.diagnoses
                        || error.response.data.data;
                    showNotification(
                        protocolSavingErrorHeader,
                        Array.isArray(dataField)
                            ? dataField.map((a) => a.message).join('. ')
                            : error.response.data.msg,
                    );
                } else {
                    showNotification(
                        protocolSavingErrorHeader,
                        error.response.data.msg,
                    );
                }
            }
            this.state.initState();
            this.isLoading = false;
        },

        resetDraftBeforeMerging(currentProtocol, newestProtocol) {
            if (!this.draft) {
                return;
            }

            const newestProtocolAssignmentsMap = keyBy(newestProtocol.assignments, 'id');
            currentProtocol.assignments.forEach((assignment) => {
                const newest = newestProtocolAssignmentsMap[assignment.id];
                if (!newest) {
                    return;
                }
                // hide "grey" assignments if they are started or cancelled in newer protocols
                assignment.timeStarted = newest.timeStarted;
                assignment.cancellationComment = newest.cancellationComment;
            });
        },

        mergeProtocols(changedData, baseProtocol, currentProtocol) {
            this.resetDraftBeforeMerging(currentProtocol, changedData);
            const props = ['diagnoses', 'diagnosesICD', 'assignments'];
            const notComparedKeys = [
                'timeEdit',
                'timeCreate',
                'patientProtocolId',
                'justCreated',
                'justUpdated',
                'userAppointedId',
                'userAppointedName',
                'userCreate',
                'userEdit',
            ];
            const keysMap = {
                timeStart: 'Дата начала',
                timeEnd: 'Дата завершения',
                cancellationComment: 'Комментарий при отмене',
                comment: 'Комментарий',
                datePlanStart: 'Дата начала',
                clinicGoodId: 'Идентификатор услуги',
                deleted: 'Удалена запись',
                name: 'Название',
                notifyAfterSurveysIsReady: 'Оповещать',
                overAssignmentId: 'Зависит от',
                overDaysCount: 'После количества дней',
                patientMedicationCourseId: 'Курс приема препарата',
                ruleName: 'Изменено правило',
                surveyBiomaterial: 'Биоматериал',
                unaccepted: 'Добавлен комментарий',
                attributes: 'Свойства',
                stateName: 'Статус',
                parentId: 'Изменен родительский диагноз',
                medicationCourse: 'Курс приема',
            };
            const replacer = (data) => {
                const date = this.$moment(data, 'YYYY-MM-DD');
                if (typeof data === 'string' && date.isValid()) {
                    return date.format('DD.MM.YYYY');
                }
                if (typeof data === 'object') {
                    return 'набор значений';
                }
                if (typeof data === 'boolean') {
                    return data ? 'да' : 'нет';
                }
                return data;
            };
            const diffsToBase = compareProtocols(
                baseProtocol,
                changedData,
                props,
                notComparedKeys,
            );

            const startedAssignmentConflicts = [];
            const mergedProtocol = cloneDeep(currentProtocol);
            props.forEach((prop) => {
                // handle changed
                const propIdxs = mergedProtocol[prop].map((x) => x.id);
                Object.keys(diffsToBase[prop]).forEach((changedEntryKey) => {
                    const idx = propIdxs.indexOf(Number.parseInt(changedEntryKey, 10));
                    const actual = cloneDeep(mergedProtocol[prop][idx]);
                    const mergeIsRequired = mergedProtocol[prop][idx].modified;
                    const entryChanges = diffsToBase[prop][changedEntryKey];
                    if (entryChanges.deleted) {
                        actual.justCreated = true;
                        actual.justUpdated = false;
                    }

                    const merged = merge(
                        cloneDeep(mergedProtocol[prop][idx]),
                        entryChanges,
                    );

                    if (merged.timeStarted && mergeIsRequired) {
                        mergedProtocol[prop][idx] = merged;
                        startedAssignmentConflicts.push(mergedProtocol[prop][idx]);
                        // NOTE: warn about "started" assignments and skip them
                        return;
                    }

                    const helpers = Object.entries(entryChanges)
                        .filter((c) => keysMap[c[0]])
                        .map((c) => ([
                            c[0] === 'deleted' ? 'Удалено' : 'Изменено',
                            keysMap[c[0]],
                            replacer(c[1]),
                        ]));

                    mergedProtocol[prop][idx] = {
                        ...merged,
                        previous: mergeIsRequired ? actual : undefined,
                        updatedAt: mergeIsRequired
                            ? this.$moment(changedData.updatedAt, 'YYYY-MM-DD')
                                .format('DD.MM.YYYY')
                            : undefined,
                        updatedBy: mergeIsRequired ? changedData.updatedBy : undefined,
                        updatedIn: mergeIsRequired ? changedData.protocolId : undefined,
                        deleteByMerge: mergeIsRequired ? merged.deleted : undefined,
                        helpers: mergeIsRequired ? helpers : undefined,
                        previouslyDeleted: merged.deleted,
                        deleted: mergeIsRequired ? undefined : merged.deleted,
                        patientProtocolId: this.protocol.id,
                        mergeIsRequired,
                    };
                });
            });
            props.forEach((prop) => {
                // handle added
                const added = diffsToBase.added[prop];
                if (added) {
                    mergedProtocol[prop].unshift(
                        ...added.map((entry) => ({
                            ...entry,
                            previous: null,
                            mergeIsRequired: false,
                            justCreated: false,
                            justUpdated: false,
                        })),
                    );
                }
            });
            props.forEach((prop) => {
                // handle removed
                const removed = diffsToBase.removed[prop];
                if (!removed) {
                    return;
                }
                removed.forEach((removedEntry) => {
                    const propIdxs = mergedProtocol[prop].map((x) => x.id);
                    const idx = propIdxs.indexOf(Number.parseInt(removedEntry.id, 10));
                    mergedProtocol[prop][idx] = {
                        ...mergedProtocol[prop][idx],
                        previous: {
                            ...mergedProtocol[prop][idx],
                            justCreated: true,
                            justUpdated: false,
                        },
                        updatedAt: this.$moment(changedData.updatedAt, 'YYYY-MM-DD')
                            .format('DD.MM.YYYY'),
                        updatedBy: changedData.updatedBy,
                        updatedIn: changedData.protocolId,
                        deleteByMerge: true,
                        helpers: [['Удалено', keysMap.deleted, 'да']],
                        previouslyDeleted: true,
                        patientProtocolId: this.protocol.id,
                        mergeIsRequired: true,
                    };
                });
            });
            props.forEach((prop) => {
                // when delete parent diagnosis - release children
                const paramsMap = groupBy(mergedProtocol[prop], 'id');
                mergedProtocol[prop] = mergedProtocol[prop].map((d) => {
                    if (prop === 'diagnoses' || prop === 'diagnosesICD') {
                        if (!d.parentId && !d.parentLocalId) {
                            return d;
                        }
                        const parent = paramsMap[d.parentId || d.parentLocalId];
                        if (parent) {
                            return d;
                        }
                        return {
                            ...d,
                            parentId: undefined,
                            parentLocalId: undefined,
                            patientProtocolId: this.protocol.id,
                        };
                    }
                    if (!d.overAssignmentId && !d.overAssignmentLocalId) {
                        return d;
                    }
                    const parent = paramsMap[d.parentId || d.parentLocalId];
                    if (parent) {
                        return d;
                    }
                    return {
                        ...d,
                        overAssignmentId: undefined,
                        overAssignmentLocalId: undefined,
                        patientProtocolId: this.protocol.id,
                        ruleCode: 'MANUAL_DATE',
                    };
                });
            });
            if (startedAssignmentConflicts.length) {
                this.$notification.open({
                    message: 'Внимание',
                    duration: 0,
                    // NOTE: it would by hidden by another one otherwise
                    placement: 'topLeft',
                    description: `По назначениям "${
                        startedAssignmentConflicts.map(
                            (a) => `${a.name} (${
                                this.$moment(a.datePlanStart, 'YYYY-MM-DD').format('DD.MM.YYYY')
                            })`,
                        ).join(', ')
                    }" была начата работа, сохранение изменений по ним отменено.`,
                });
            }

            if (this.draft) {
                return mergedProtocol;
            }
            props.forEach((prop) => {
                mergedProtocol[prop] = mergedProtocol[prop]
                    .filter((d) => d.mergeIsRequired || !d.previouslyDeleted);
            });
            return mergedProtocol;
        },
        onCancel() {
            if (this.appointmentId && !this.protocolId) {
                this.$http.put(`appointment/${this.appointmentId}/schedule`).then(() => {
                    this.$router.push({ name: 'main' });
                }).catch((err) => showErrorNotification(err.response.data.msg));
            } else {
                this.$router.push({ name: 'main' });
            }
        },
        openPatientCard() {
            const routeData = this.$router.resolve({ path: `/card/${this.protocolPatientId}` });
            window.open(routeData.href, '_blank');
        },
    },
});
</script>

<style lang="scss">
.main-info {
    padding: 10px 20px 1px 20px;
    .diagnoses-tree-picker {
        padding-top: 30px;
    }
    .has-error .ant-form-explain {
        display: none;
    }

    .ant-btn-primary, .ant-btn-default {
        float: right;
        display: block;
    }

    .current-diagnoses {
        color: #000000d9;
        margin: 20px 0;
        span {
            margin-right: 15px;
        }
    }
    .new-diagnose {
        width: 80px;
        height: 80px;
        border-style: dashed;
        border-width: 2px;
        border-radius: 4px;
        color: #e8e8e8;
        cursor: pointer;
        float: left;
        font-weight: 500;

        .ant-card-body {
            padding: 0;
            text-align: center;
            font-size: 50px;
            line-height: 76px;
        }
    }
    .diagnose-card {
        width: 180px;
        float: left;
        margin-right: 15px;
        border-radius: 4px;

        .ant-card-body {
            padding: 0;
        }
    }
    .diagnose-card-edit {
        padding: 10px;
        .ant-card-body {
            padding: 0;
        }
        .ant-form-item {
            margin-bottom: 0;
        }
        .icon-buttons-group {
            text-align: center;
            margin-top: 15px;
            width: 100%;

            .ant-btn-primary, .ant-btn-default {
                float: none;
                display: inherit;
                margin: 0;
            }
        }
    }
    .diagnose-card-view {
        padding-top: 10px;
        .diagnose-title, .diagnose-state, .diagnose-date, .diagnose-date-label {
            display: block;
            padding: 0 10px;
        }
        .ant-btn {
            margin-top: 10px;
        }
        .diagnose-title {
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: #e8e8e8 solid 1px;
        }
        .diagnose-date-label {
            color: rgba(0,0,0,.45);
            line-height: 1.524;
            transition: color .3s cubic-bezier(.215,.61,.355,1);
            font-size: 13px;
        }
        .diagnose-date {
            margin-left: 12px;
        }
    }
    .prescriptions {
        margin-bottom: 40px;
    }
    .protocol-top-controls {
        align-items: center;
        display: flex;
        justify-content: space-between;
        & .presence-checkbox {
            padding-left: 45px;
            display: flex;
            & > div {
                padding: 5px;
            }
        }
    }
    .print-menu {
        float: right;
        border: 1px solid #d9d9d9;
        border-radius: 3px;

        .ant-menu-submenu-title {
            line-height: 30px;
            margin: 0;
            padding: 0 15px;
            height: auto;
        }

        .ant-menu-submenu-arrow {
            display: none;
        }
    }
    .presence-controls {
        display: flex;
        gap: 10px;
        height: 50px;
        flex-direction: row-reverse;
    }
}

.reception-patient-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
        display: flex;
        align-items: center;
    }
    & .ant-avatar-lg.ant-avatar-icon {
        margin: 0px;
        margin-right: 10px;
    }
}
.protocol-text-description {
    padding-top: 20px;
}
.main .ant-tabs-nav {
    width: 100%;
}
.protocol-text-description {
    padding-top: 20px;
}
</style>
