<template>
    <div class="patient-physical-metrics">
        <a-form
            ref="formRef"
            layout="inline"
            :model="metricsFormState"
            :rules="formValidationRules"
        >
            <a-form-item
                :label="`Дата${mode === 'add' ? ' последнего ' : ' '}измерения`"
                :colon="false"
                name="measurementDate"
                class="form__measurement-date"
            >
                <a-date-picker
                    v-model:value="metricsFormState.measurementDate"
                    :disabled="mode === 'add'"
                    format="DD.MM.YYYY"
                />
            </a-form-item>
            <a-form-item
                label="Рост"
                :colon="false"
                name="height"
            >
                <a-input-number
                    v-model:value="metricsFormState.height"
                    :disabled="mode==='add'"
                    :min="null"
                    :placeholder="lastMetrics.height ? lastMetrics.height.toString() : ''"
                />
                <a-button
                    v-if="lastMetrics.height && !metricsFormState.height"
                    type="link"
                    @click="applyLastValue('height')"
                >
                    <template #icon>
                        <check-outlined />
                    </template>
                </a-button> см
            </a-form-item>
            <a-form-item
                label="Масса тела"
                :colon="false"
                name="weight"
            >
                <a-input-number
                    v-model:value="metricsFormState.weight"
                    :disabled="mode==='add'"
                    :min="null"
                    :placeholder="lastMetrics.weight ? lastMetrics.weight.toString() : ''"
                />
                <a-button
                    v-if="lastMetrics.weight && !metricsFormState.weight"
                    type="link"
                    @click="applyLastValue('weight')"
                >
                    <template #icon>
                        <check-outlined />
                    </template>
                </a-button> кг
                <span v-if="metricsFormState.height && metricsFormState.weight">
                    Индекс массы тела: {{ bodyMassIndex }}
                </span>
            </a-form-item>
            <div class="grey-metrics-wrapper">
                <div class="blood-metrics">
                    <a-form-item
                        label="Кровяное давление"
                        help="систолическое"
                        name="systolicBloodPreasure"
                        :colon="false"
                    >
                        <a-input-number
                            v-model:value="metricsFormState.systolicBloodPreasure"
                            :disabled="mode === 'add'"
                            :min="null"
                            :placeholder="lastMetrics.systolicBloodPreasure
                                ? lastMetrics.systolicBloodPreasure.toString()
                                : ''"
                        />
                        <a-button
                            v-if="lastMetrics.systolicBloodPreasure
                                && !metricsFormState.systolicBloodPreasure"
                            type="link"
                            @click="applyLastValue('systolicBloodPreasure')"
                        >
                            <template #icon>
                                <check-outlined />
                            </template>
                        </a-button> мм рт. ст.
                    </a-form-item>
                    <a-form-item
                        label="/"
                        name="diastolicBloodPreasure"
                        :colon="false"
                        help="диастолическое"
                    >
                        <a-input-number
                            v-model:value="metricsFormState.diastolicBloodPreasure"
                            :disabled="mode === 'add'"
                            :min="null"
                            :placeholder="lastMetrics.diastolicBloodPreasure
                                ? lastMetrics.diastolicBloodPreasure.toString()
                                : ''"
                        />
                        <a-button
                            v-if="lastMetrics.diastolicBloodPreasure
                                && !metricsFormState.diastolicBloodPreasure"
                            type="link"
                            @click="applyLastValue('diastolicBloodPreasure')"
                        >
                            <template #icon>
                                <check-outlined />
                            </template>
                        </a-button> мм рт. ст.
                    </a-form-item>
                </div>
                <div class="tile-metrics">
                    <a-form-item
                        label="ЧСС (пульс)"
                        name="heartRate"
                        :colon="false"
                    >
                        <a-input-number
                            v-model:value="metricsFormState.heartRate"
                            :disabled="mode==='add'"
                            :min="null"
                            :placeholder="lastMetrics.heartRate
                                ? lastMetrics.heartRate.toString()
                                : ''"
                        />
                        <a-button
                            v-if="lastMetrics.heartRate && !metricsFormState.heartRate"
                            type="link"
                            @click="applyLastValue('heartRate')"
                        >
                            <template #icon>
                                <check-outlined />
                            </template>
                        </a-button> уд./мин.
                    </a-form-item>
                    <a-form-item
                        label="Температура"
                        name="temperature"
                        :colon="false"
                    >
                        <a-input-number
                            v-model:value="metricsFormState.temperature"
                            :disabled="mode==='add'"
                            :min="null"
                            :placeholder="lastMetrics.temperature
                                ? lastMetrics.temperature.toString()
                                : ''"
                        />
                        <a-button
                            v-if="lastMetrics.temperature && !metricsFormState.temperature"
                            type="link"
                            @click="applyLastValue('temperature')"
                        >
                            <template #icon>
                                <check-outlined />
                            </template>
                        </a-button>  <sup>o</sup>C
                    </a-form-item>
                    <a-form-item
                        label="SpO2"
                        name="spo2"
                        :colon="false"
                    >
                        <a-input-number
                            v-model:value="metricsFormState.spo2"
                            :disabled="mode==='add'"
                            :min="null"
                            :placeholder="lastMetrics.spo2 ? lastMetrics.spo2.toString() : ''"
                        />
                        <a-button
                            v-if="lastMetrics.spo2 && !metricsFormState.spo2"
                            type="link"
                            @click="applyLastValue('spo2')"
                        >
                            <template #icon>
                                <check-outlined />
                            </template>
                        </a-button> %
                    </a-form-item>
                    <a-form-item
                        label="Частота дыхания"
                        name="breathingRate"
                        :colon="false"
                    >
                        <a-input-number
                            v-model:value="metricsFormState.breathingRate"
                            :disabled="mode==='add'"
                            :min="null"
                            :placeholder="lastMetrics.breathingRate
                                ? lastMetrics.breathingRate.toString()
                                : ''"
                        />
                        <a-button
                            v-if="lastMetrics.breathingRate && !metricsFormState.breathingRate"
                            type="link"
                            @click="applyLastValue('breathingRate')"
                        >
                            <template #icon>
                                <check-outlined />
                            </template>
                        </a-button> /мин.
                    </a-form-item>
                </div>
            </div>
        </a-form>
        <a-button
            v-if="mode === 'save'"
            type="primary"
            class="save-metrics-btn"
            @click="validateSaveMetrics"
        >
            <template #icon>
                <check-circle-outlined />
            </template>
            Сохранить измерение
        </a-button>
        <a-button
            v-else
            type="primary"
            class="save-metrics-btn"
            @click="addMetrics"
        >
            <template #icon>
                <check-circle-outlined />
            </template>
            Добавить измерение
        </a-button>
        <div style="margin: 0 10px">
            <h2>История</h2>
            <a-table
                size="middle"
                :columns="metricsHistoryColumns"
                :data-source="metricsHistoryData"
                :pagination="false"
                row-key="id"
            >
                <template #bmi="{ record }">
                    {{ record.height && record.weight
                        ? getBodyMassIndex(record.height, record.weight)
                        : ''
                    }}
                </template>
                <template #bloodPreasure="{ record }">
                    {{ record.systolicBloodPreasure && record.diastolicBloodPreasure
                        ? `${record.systolicBloodPreasure}/${record.diastolicBloodPreasure}`
                        : ''
                    }}
                </template>
                <template #dateCreate="{ record }">
                    {{ record.dateCreate
                        ? $moment(record.dateCreate).format('DD.MM.YYYY')
                        : ''
                    }}
                </template>
                <template #userCreate="{ record }">
                    <span>
                        <a-tag color="blue">
                            {{ record.userCreate }}
                        </a-tag>
                    </span>
                </template>
                <template #delete="{ record }">
                    <a-button
                        size="small"
                        @click="removeRecord(record)"
                    >
                        <template #icon>
                            <delete-outlined />
                        </template>
                    </a-button>
                </template>
                <template #temperatureTitle>
                    <span>
                        Т, <sup>o</sup>C
                    </span>
                </template>
            </a-table>
            <h2>Мониторинг</h2>
            <monitoring
                ref="monitoring"
                :patient-id="patientId"
                :only-metrics="true"
                @updated="onMonitoringUpdate"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import CheckOutlined from '@ant-design/icons-vue/CheckOutlined';
import DeleteOutlined from '@ant-design/icons-vue/DeleteOutlined';
import CheckCircleOutlined from '@ant-design/icons-vue/CheckCircleOutlined';
import Monitoring from '../monitoring/Monitoring.vue';
import {
    showErrorNotification,
    useEmitter,
} from '../../utils';

export default defineComponent({
    components: {
        Monitoring,
        CheckOutlined,
        CheckCircleOutlined,
        DeleteOutlined,
    },

    props: {
        patientId: {
            type: Number,
            required: true,
        },
        initMode: {
            type: String,
            default: 'save',
        },
    },
    // NOTE: object emits is not supported in vue3-webcomponent-wrapper
    emits: [
        'updated',
        'auth-lost',
    ],

    setup(props, { emit }) {
        const emitter = useEmitter();
        if (emitter) {
            emitter.on('auth-lost', () => emit('auth-lost'));
        }
    },

    data() {
        return {
            mode: 'save',
            metricsFormState: {
                measurementDate: this.$moment(),
                height: null,
                weight: null,
                systolicBloodPreasure: null,
                diastolicBloodPreasure: null,
                spo2: null,
                temperature: null,
                heartRate: null,
                breathingRate: null,
            },
            formValidationRules: {
                measurementDate: [{
                    required: true,
                    message: 'Обязательное поле',
                }],
                diastolicBloodPreasure: [{
                    required: false,
                    message: 'Обязательное поле',
                }],
                systolicBloodPreasure: [{
                    required: false,
                    message: 'Обязательное поле',
                }],
            },
            timeCreate: null,
            lastMetrics: {
                height: null,
                systolicBloodPreasure: null,
                diastolicBloodPreasure: null,
                weight: null,
                spo2: null,
                temperature: null,
                heartRate: null,
                breathingRate: null,
            },
            metricsHistoryColumns: [
                { title: 'Рост', dataIndex: 'height' },
                { title: 'Масса тела', dataIndex: 'weight' },
                { title: 'ИМТ', slots: { customRender: 'bmi' } },
                { title: 'Кровяное давление', slots: { customRender: 'bloodPreasure' } },
                { title: 'SpO2', dataIndex: 'spo2' },
                {
                    dataIndex: 'temperature',
                    slots: {
                        title: 'temperatureTitle',
                    },
                },
                { title: 'Частота дыхания', dataIndex: 'breathingRate' },
                { title: 'ЧСС (пульс)', dataIndex: 'heartRate' },
                {
                    title: 'Дата измерения',
                    dataIndex: 'dateCreate',
                    slots: { customRender: 'dateCreate' },
                },
                {
                    title: 'Добавил',
                    slots: { customRender: 'userCreate' },
                },
                { align: 'center', slots: { customRender: 'delete' } },
            ],
            metricsHistoryData: [],
        };
    },

    computed: {
        bodyMassIndex() {
            const { height, weight } = this.metricsFormState;
            if (!height || !weight) {
                return '';
            }

            const bmi = this.getBodyMassIndex(height, weight);
            if (bmi < 15) {
                return `${bmi} (Острый дефицит веса)`;
            }
            if (bmi >= 15 && bmi < 20) {
                return `${bmi} (Дефицит веса)`;
            }
            if (bmi >= 20 && bmi < 25) {
                return `${bmi} (Нормальный вес)`;
            }
            if (bmi >= 25 && bmi < 30) {
                return `${bmi} (Избыточный вес)`;
            }
            return `${bmi} (Ожирение)`;
        },
    },

    watch: {
        patientId(val) {
            if (!val) {
                return;
            }
            this.metricsHistoryData = [];
            this.getMetricsHistory();
        },

        initMode(val) {
            this.mode = val;
        },

        'metricsFormState.diastolicBloodPreasure': function dbpWatcher(val) {
            this.formValidationRules.systolicBloodPreasure[0].required = !!val;
        },

        'metricsFormState.systolicBloodPreasure': function sbpWatcher(val) {
            this.formValidationRules.diastolicBloodPreasure[0].required = !!val;
        },
    },

    updated() {
        this.$emit('updated');
    },

    mounted() {
        if (!this.patientId) {
            return;
        }
        this.metricsHistoryData = [];
        this.getMetricsHistory();
    },

    methods: {
        getMetricsHistory() {
            this.$http.get(`/patient/${this.patientId}/physical-metrics`).then((response) => {
                this.timeCreate = this.$moment();
                if (!response.data.length) {
                    return;
                }
                const metric = response.data[response.data.length - 1];
                this.lastMetrics = {
                    height: metric.height,
                    systolicBloodPreasure: metric.systolicBloodPreasure,
                    diastolicBloodPreasure: metric.diastolicBloodPreasure,
                    weight: metric.weight,
                    spo2: metric.spo2,
                    temperature: metric.temperature,
                    heartRate: metric.heartRate || metric.pulse,
                    breathingRate: metric.breathingRate,
                };
                this.metricsHistoryData = response.data.map(({ pulse, ...rest }) => ({
                    ...rest,
                    heartRate: rest.heartRate || pulse,
                }));
                if (this.mode === 'add') {
                    this.metricsFormState = {
                        ...this.metricsFormState,
                        ...this.lastMetrics,
                    };
                }
            }).catch((err) => {
                showErrorNotification((err.response
                    && err.response.data
                    && err.response.data.msg)
                    || 'Произошла ошибка при загрузке физ. метрик пациента.');
            });
        },

        getBodyMassIndex(height, weight) {
            if (!height || !weight) {
                return 0;
            }

            return Math.round(
                (weight / ((height / 100) ** 2)) * 100,
            ) / 100;
        },

        onMonitoringUpdate() {
            this.$emit('updated');
        },

        applyLastValue(name) {
            this.metricsFormState[name] = this.lastMetrics[name];
        },

        removeRecord(record) {
            this.$http.delete(
                `/patient/${this.patientId}/physical-metric/${record.id}`,
            ).then(() => {
                this.metricsHistoryData.splice(
                    this.metricsHistoryData.indexOf(record),
                    1,
                );
                this.$refs.monitoring.fetch(true);
            }).catch((err) => {
                showErrorNotification(err.response.data.msg);
            });
        },

        addMetrics() {
            this.metricsFormState.height = null;
            this.metricsFormState.systolicBloodPreasure = null;
            this.metricsFormState.diastolicBloodPreasure = null;
            this.metricsFormState.weight = null;
            this.metricsFormState.spo2 = null;
            this.metricsFormState.temperature = null;
            this.metricsFormState.heartRate = null;
            this.metricsFormState.breathingRate = null;
            this.mode = 'save';
        },

        async validateSaveMetrics() {
            try {
                await this.$refs.formRef.validate();
            } catch (err) {
                return;
            }

            if (!Object.values(this.metricsFormState).some((value) => value)) {
                return;
            }

            this.saveMetrics();
        },

        saveMetrics() {
            this.$http.post(`/patient/${this.patientId}/physical-metric`, {
                ...this.metricsFormState,
                measurementDate: undefined,
                timeCreate: this.metricsFormState.measurementDate.format('YYYY-MM-DD'),
            }).then(() => {
                this.mode = 'add';
                this.getMetricsHistory();
                this.$refs.monitoring.fetch(true);
            }).catch((error) => {
                showErrorNotification(error.response.data.msg);
            });
        },
    },
});
</script>

<style lang="scss">
.patient-physical-metrics {
    .ant-form-item {
        margin-bottom: 10px;
        color: #000;
    }

    h2 {
        margin-top: 30px;
    }
    .save-metrics-btn {
        margin-top: 15px;
        margin-left: 10px;
    }

    .form__measurement-date {
        flex: 0 0 100%;
    }

    .blood-metrics {
        display: flex;
    }

    .tile-metrics {
        display: grid;
        grid-template-columns: 300px auto;

        .ant-form-item-label {
            width: 130px;
            text-align: left;
        }
    }

    .grey-metrics-wrapper {
        width: 100%;
        background-color: #fafafa;
        padding: 10px 0 0 10px;
    }
}
</style>
