<template>
    <a-select
        :get-popup-container="(triggerNode) => triggerNode.parentNode"
        :value="value"
        :disabled="!!disabled"
        style="width: 100%;"
        mode="multiple"
        @change="onChange"
    >
        <a-select-option
            v-for="item in store"
            :key="item.id"
        >
            {{ item.name }}
        </a-select-option>
    </a-select>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        value: {
            type: Array,
            default: null,
        },
        disabled: Boolean,
    },
    emits: ['change'],
    data() {
        return {
            store: [],
        };
    },
    mounted() {
        this.loadStore();
    },
    methods: {
        loadStore() {
            this.$http.get('/equipment').then((response) => {
                this.store = response.data;
            });
        },
        onChange(value) {
            this.$emit('change', value);
        },
    },
});
</script>

<style lang="scss">
.ant-select-dropdown-content {
    text-align: left;
}
</style>
