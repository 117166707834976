import { createApp } from 'vue';

import 'ant-design-vue/dist/antd.less';
import { IAuth } from '@tools/types';
import axios from 'axios';
import moment from 'moment';
import {
    ConfigProvider,
    Modal,
    Alert,
    Button,
    Form,
    DatePicker,
    Spin,
    Input,
    Badge,
    Layout,
    Avatar,
    Table,
    Row,
    Drawer,
    Select,
    TreeSelect,
    Switch,
    Card,
    Pagination,
    Tree,
    Tabs,
    Breadcrumb,
    Col,
    Tooltip,
    Checkbox,
    InputNumber,
    Tag,
    Radio,
    Dropdown,
    Menu,
    notification,
    Divider,
    TimePicker,
    Slider,
    Upload,
} from 'ant-design-vue';
import mitt from 'mitt';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { axiosPlugin, get, post } from 'vue-web-components/src/utils/axiosConnectionTimeout';
import { showErrorNotification } from '@/utils';

import App from './App.vue';
import router from './router';
import store from './store';

const emitter = mitt();

require('moment/locale/ru');

const app = createApp(App)
    .use(ConfigProvider)
    .use(Modal)
    .use(Alert)
    .use(Button)
    .use(Form)
    .use(DatePicker)
    .use(Spin)
    .use(Input)
    .use(Badge)
    .use(Layout)
    .use(Avatar)
    .use(Table)
    .use(Row)
    .use(Drawer)
    .use(Select)
    .use(TreeSelect)
    .use(Switch)
    .use(Card)
    .use(Pagination)
    .use(Tree)
    .use(Tabs)
    .use(Col)
    .use(Tooltip)
    .use(Breadcrumb)
    .use(Checkbox)
    .use(InputNumber)
    .use(Tag)
    .use(Radio)
    .use(Dropdown)
    .use(Menu)
    .use(Divider)
    .use(TimePicker)
    .use(Slider)
    .use(Upload)
    .use(axiosPlugin, axios)
    .use(router)
    .use(store);
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$notification = notification;
app.config.performance = true;

require('moment/locale/ru');
// @ts-ignore
app.$moment = moment;
// @ts-ignore
app.$emitter = emitter;
// @ts-ignore
app.$notification = notification;
app.mount('#app');

if (process.env.VUE_APP_ENV_NAME !== 'development') {
    Sentry.init({
        app,
        dsn: 'https://2dd14cc6e2794d0d848ef84bd645a3e3@sentry.raketa.im/5',
        environment: process.env.VUE_APP_ENV_NAME || 'production',
        release: `life-impulse-doc@${process.env.VUE_APP_VERSION}`,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['localhost', 'lifeimpulse.net', 'test.raketa.im', /^\//],
            }),
        ],
    });
}

axios.defaults.timeout = 60000;
axios.defaults.baseURL = '/api';
axios.interceptors.request.use(
    (request) => {
        if (request.method === 'get' || !request.data) {
            return request;
        }
        const data = JSON.stringify(request.data).replace(
            new RegExp('[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-'
                + '\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}'
                + '\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}'
                + '\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}'
                + '\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]', 'gu'),
            () => '',
        );
        return { ...request, data: JSON.parse(data) };
    },
);
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response || error.response.status !== 401) {
            return Promise.reject(error);
        }
        const { name: routeName } = router.currentRoute.value;
        if (routeName !== 'login') {
            router.push({ name: 'login' });
        }
        return Promise.reject(error);
    },
);

router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
        next();
        return;
    }
    get<IAuth>('/auth').then((response) => {
        if (!localStorage.LoggedIn) {
            next({ name: 'login' });
            return;
        }

        if (response.data.disableNotifications) {
            next({ name: 'login' });
            showErrorNotification('Приложение находится в разработке');
            return;
        }

        if (response.data.isDoctor) {
            store.commit('appMode/setAppMode', 'doctor');
        } else if (response.data.isLaborant) {
            store.commit('appMode/setAppMode', 'labworker');
        } else {
            post('/user/logout').catch(() => {});
            next({ name: 'login' });
            showErrorNotification('У вашей роли отсутствуют доступ к этому приложению.');
            return;
        }

        const toRouteName = to.name || '';
        if (toRouteName === 'login'
            || toRouteName === 'survey-print-report'
        ) {
            next();
            return;
        }

        if (response.data.isDoctor) {
            if (toRouteName === 'patient-card'
                || toRouteName === 'survey-card'
                || toRouteName === 'poll-card'
                || toRouteName === 'new-reception'
                || toRouteName === 'reception'
                || toRouteName === 'goodsurveys'
                || toRouteName === 'notifications'
                || toRouteName === 'main'
                || toRouteName === 'draft'
            ) {
                next();
            } else {
                next({ name: 'main' });
            }
        } else if (response.data.isLaborant) {
            if (toRouteName === 'lab-patient-card'
                || toRouteName === 'lab-survey-card'
                || toRouteName === 'lab-reception'
                || toRouteName === 'lab-results'
                || toRouteName === 'lab-in-progress'
                || toRouteName === 'lab-store'
                || toRouteName === 'main'
            ) {
                next();
            } else {
                next({ name: 'main' });
            }
        }
    }).catch(() => {
        next({ name: 'login' });
    });
});

export default app;
