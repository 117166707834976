<template>
    <div class="childbearing">
        <div class="childbearing-item">
            <named-add-input
                :value="childeringCount"
                disabled
                name="Беременности"
            />
            , из них:
        </div>
        <div class="childbearing-item childbearing-item__error">
            <div>
                <span>Аборт</span>
                <a-input
                    v-model:value.number="modelValueCloned.canceledCount"
                    size="small"
                    class="anamneses-input__number"
                    type="number"
                />
            </div>
            <childering-problems
                v-model="modelValueCloned.throwed"
                name="Выкидыш"
            />
            <childering-problems
                v-model="modelValueCloned.stopped"
                name="Замершая"
            />
        </div>
        <div class="childbearing-item childbearing-item__success">
            <named-add-input
                :value="modelValueCloned.successed.length"
                name="Роды"
                @change="modelValueCloned.successed.push({
                    week: null,
                    assistance: null,
                    status: null
                })"
            />
            <div
                v-if="modelValueCloned.successed && modelValueCloned.successed.length > 0"
                class="anamneses-childbearing__successed"
            >
                <transition-group
                    name="list"
                    tag="p"
                >
                    <div
                        v-for="(successData, i) in modelValueCloned.successed"
                        :key="i"
                        class="anamneses-childbearing__successed-item"
                    >
                        <a-button
                            shape="circle"
                            size="small"
                            @click="modelValueCloned.successed.splice(i, 1)"
                        >
                            <template #icon>
                                <delete-outlined />
                            </template>
                        </a-button>
                        {{ i + 1 }}. Роды на
                        <a-input
                            v-model:value.number="successData.week"
                            class="anamneses-input"
                            size="small"
                        />
                        неделе,
                        <a-select
                            v-model:value.number="successData.assistance"
                            default-value="0"
                            style="width: 230px"
                            size="small"
                        >
                            <a-select-option
                                v-for="entry in assistance"
                                :key="entry.id"
                                :value="entry.id"
                            >
                                {{ entry.name }}
                            </a-select-option>
                        </a-select>
                        , состоянее ребенка после рождения
                        <a-select
                            v-model:value.number="successData.status"
                            :default-value="0"
                            style="width: 170px"
                            size="small"
                        >
                            <a-select-option
                                v-for="entry in child"
                                :key="entry.id"
                                :value="entry.id"
                            >
                                {{ entry.name }}
                            </a-select-option>
                        </a-select>
                    </div>
                </transition-group>
            </div>
        </div>
        <div class="childbearing-item sterility">
            Бесплодие
            <a-select
                v-model:value="modelValueCloned.sterility"
                default-value="0"
                style="width: 170px"
                size="small"
            >
                <a-select-option
                    v-for="entry in sterility"
                    :key="entry.id"
                    :value="entry.id"
                >
                    {{ entry.name }}
                </a-select-option>
            </a-select>
        </div>
    </div>
</template>

<script>
import DeleteOutlined from '@ant-design/icons-vue/DeleteOutlined';
import { cloneDeep, isEqual } from 'lodash';
import { defineComponent } from 'vue';
import ChilderingProblems from './ChilderingProblems.vue';
import NamedAddInput from './NamedAddInput.vue';

export default defineComponent({
    components: {
        NamedAddInput,
        ChilderingProblems,
        DeleteOutlined,
    },
    props: {
        modelValue: {
            type: Object,
            default: null,
        },
        assistance: {
            type: Array,
            default: () => [],
        },
        child: {
            type: Array,
            default: () => [],
        },
        sterility: {
            type: Array,
            default: () => [],
        },
    },

    emits: {
        'update:modelValue': null,
        input: null,
    },

    data() {
        return {
            modelValueCloned: {},
        };
    },
    computed: {
        childeringCount() {
            if (!this.modelValueCloned.successed) {
                return 0;
            }
            return this.modelValueCloned.successed.length
                + this.modelValueCloned.canceledCount
                + this.modelValueCloned.throwed.length
                + this.modelValueCloned.stopped.length;
        },
    },
    watch: {
        modelValue: {
            deep: true,
            immediate: true,
            handler() {
                if (isEqual(this.modelValue, this.modelValueCloned)) {
                    return;
                }
                this.modelValueCloned = cloneDeep(this.modelValue);
            },
        },
        modelValueCloned: {
            deep: true,
            handler() {
                this.$emit('update:modelValue', this.modelValueCloned);
            },
        },
    },
    methods: {
        successedAdd() {
            this.modelValueCloned.successed.push({ week: null, assistance: null, status: null });
            this.$emit('input', this.modelValueCloned);
        },
    },
});
</script>

<style lang="scss">
    .childbearing {
        .list-enter-active, .list-leave-active {
            transition: all 0.3s;
        }
        .list-enter, .list-leave-to {
            opacity: 0;
            transform: translateY(5px);
        }
    }
    .anamneses-input {
        width: 40px;
        &__number {
            width: 50px
        }
    }
    .anamneses-input__number.ant-input {
        width: 50px
    }
    .childbearing-item {
        margin-top: 10px;
        min-height: 50px;
        &.sterility {
            padding-top: 20px;
        }
        &__error {
            display: grid;
            grid-template-areas: "x x x";
            grid-template-columns: 20% 40% 40%;
            position: relative !important;
            &::before {
                position: absolute;
                border-left: 1px solid #ddd;
                left: 18%;
                height: 100%;
                content: '';
            }
            &::after {
                position: absolute;
                border-right: 1px solid #ddd;
                left: 58%;
                height: 100%;
                content: '';
            }
        }
    }
    .anamneses-childbearing__successed {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        &-item {
            margin-top: 5px;
        }
    }
    .anamneses-childbearing__successed .anamneses-input.ant-input {
        width: 35px;
    }
</style>
