import { createRouter, createWebHistory } from 'vue-router';

import MainSurveysInfo from '@/components/labpatientcard/MainSurveysInfo.vue';
import LabReception from '@/components/labpatientcard/LabReception.vue';
import LabInProgress from '@/components/labpatientcard/LabInProgress.vue';
import LabStore from '@/components/labpatientcard/LabStore.vue';
import LabResults from '@/components/labpatientcard/LabResults.vue';

import Main from './Main.vue';
import Login from './views/Login.vue';
import PatientCard from './views/PatientCard.vue';
import SurveyCardWrapper from './components/patientcard/SurveyCardWrapper.vue';
import PollCard from './components/patientcard/PollCard.vue';
import Reception from './views/Reception.vue';
import GoodSurveys from './views/GoodSurveys.vue';
import NotificationsPanel from './views/NotificationsPanel.vue';

import store from './store';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [{
        path: '/:catchAll(.*)',
        component: Main,
        props: () => ({ mode: store.state.appMode.mode }),
    }, {
        path: '/login',
        name: 'login',
        component: Login,
    }, {
        path: '/',
        name: 'main',
        component: Main,
        props: () => ({
            mode: store.state.appMode.mode,
        }),
        children: [{
            path: 'lab-reception/:patientId?',
            name: 'lab-reception',
            component: LabReception,
            props: (route) => ({
                patientId: route.params.patientId,
            }),
        }, {
            path: 'lab-in-progress/:patientId?',
            name: 'lab-in-progress',
            component: LabInProgress,
            props: (route) => ({
                patientId: route.params.patientId,
            }),
        }, {
            path: 'lab-store/:patientId?',
            name: 'lab-store',
            component: LabStore,
            props: (route) => ({
                patientId: route.params.patientId,
            }),
        }, {
            path: 'lab-results',
            name: 'lab-results',
            component: LabResults,
            children: [{
                path: 'patient/:patientId',
                name: 'lab-patient-card',
                component: MainSurveysInfo,
                props: (route) => ({
                    patientId: parseInt(route.params.patientId, 10),
                }),
            }, {
                path: 'patient/:patientId/survey/:surveyId',
                name: 'lab-survey-card',
                component: MainSurveysInfo,
                props: (route) => ({
                    patientId: parseInt(route.params.patientId, 10),
                    surveyId: parseInt(route.params.surveyId, 10),
                }),
            }],
        }],
    }, {
        path: '/card/:patientId',
        name: 'patient-card',
        component: PatientCard,
        props: (route) => ({
            patientId: +route.params.patientId,
        }),
    }, {
        path: '/survey/:surveyId',
        name: 'survey-card',
        component: SurveyCardWrapper,
        props: (route) => ({ surveyId: +route.params.surveyId }),
    }, {
        path: '/poll/:pollId',
        name: 'poll-card',
        component: PollCard,
        props: (route) => ({ pollId: +route.params.pollId }),
        beforeEnter: (to, from, next) => {
            if (to.params.pollId && Number.isInteger(to.params.pollId * 1)) {
                next();
            }
        },
    }, {
        path: '/new-reception/:patientId',
        name: 'new-reception',
        component: Reception,
        props: (route) => ({
            patientId: +route.params.patientId,
            appointmentId: +route.params.appointmentId,
            protocolId: +route.params.protocolId,
            generatingPatientGoodId: +route.params.generatingPatientGoodId,
        }),
    }, {
        path: '/reception/:protocolId?/draft/:draftId',
        name: 'draft',
        component: Reception,
        props: (route) => ({
            protocolId: +route.params.protocolId,
            draftId: +route.params.draftId,
        }),
    }, {
        path: '/reception/:protocolId?',
        name: 'reception',
        component: Reception,
        props: (route) => ({
            protocolId: parseInt(route.params.protocolId, 10),
            patientId: route.params.patientId ? parseInt(route.params.patientId, 10) : undefined,
        }),
    }, {
        path: '/patient/survey/labs/:surveyId/download',
        name: 'survey-print-report',
    }, {
        path: '/good-surveys/:patientGoodIdentificator',
        name: 'goodsurveys',
        component: GoodSurveys,
        props: (route) => ({
            patientGoodId: parseInt(route.params.patientGoodIdentificator, 10),
        }),
    }, {
        path: '/notifications',
        name: 'notifications',
        component: NotificationsPanel,
    }],
});

router.beforeEach((to, from, next) => {
    if (to.path === from.path && to.name === from.name) {
        next(false);
        return;
    }
    next();
});

export default router;
