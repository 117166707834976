<template>
    <div>
        <a-modal
            visible
            :ok-button-props="{
                disabled: saveBtnDisabled,
                loading: isPending,
                type: 'primary'
            }"
            :mask-closable="false"
            :width="900"
            class="medicatiom-add-window"
            ok-text="Сохранить"
            @cancel="cancelClick"
            @ok="saveMedication"
        >
            <template #title>
                <span v-if="updateMode">
                    Передача изготовленного препарата в клинику
                </span>
                <span v-else>
                    Сохранение изготовленных препаратов
                </span>
            </template>
            <a-alert
                v-if="isError || isSaveError"
                type="error"
                message="Произошла ошибка во время выполнения запроса на сервер."
            />
            <a-alert
                v-if="isSuppliedError"
                :message="[
                    'Количество отправляемого в клинику препарата',
                    'не должно превышать количество изготовленного.'
                ].join(' ')"
                type="error"
            />
            <a-alert
                v-if="isEmptyError"
                type="error"
                message="Укажите количество изготовленного и отправляемого в клинику препарата."
            />
            <a-alert
                v-if="isEmptySubtypeError"
                type="error"
                message="Укажите подтип препарата."
            />
            <template v-if="!isError && !isPending">
                <made-medication
                    v-for="order in orders"
                    :key="order.id"
                    :patient-name="order.patientName"
                    :clinic-medication="clinicMedicationById[order.medicationId]"
                    :data="madeMedicationByOrder[order.id]"
                    :update-mode="updateMode"
                    @add="() => addMedication(order.id)"
                    @remove="(index) => removeMedication(order.id, index)"
                    @update:subtype-id="(index, value) => updateMedicationValue(
                        'subtypeId', order.id, index, value)"
                    @update:supplied="(index, value) => updateMedicationValue(
                        'supplied', order.id, index, value)"
                    @update:produced="(index, value) => updateMedicationValue(
                        'produced', order.id, index, value)"
                />
            </template>
            <template v-else>
                <loading-spinner />
                Подождите... Операция выполняется...
            </template>
        </a-modal>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import MadeMedication from '@/components/labpatientcard/MadeMedication.vue';
import { showErrorNotification } from '@/utils';
import LoadingSpinner from '@/utils/Spinner.vue';

export default defineComponent({
    components: {
        MadeMedication,
        LoadingSpinner,
    },

    props: {
        orders: {
            type: Array,
            required: true,
        },
        updateMode: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['close'],
    data() {
        return {
            isPending: true,
            isError: false,
            isSaveError: false,
            clinicMedication: [],
            madeMedicationByOrder: {},
        };
    },

    computed: {
        saveBtnDisabled() {
            return this.isPending
                || this.isSuppliedError
                || this.isEmptyError
                || this.isEmptySubtypeError;
        },

        results() {
            return Object.keys(this.madeMedicationByOrder).reduce(
                (list, orderId) => list.concat(this.madeMedicationByOrder[orderId]),
                [],
            );
        },

        isSuppliedError() {
            return this.results.some(
                (result) => result.supplied && result.produced
                        && result.supplied > result.produced,
            );
        },

        isEmptyError() {
            return this.results.some(
                (result) => !result.produced || !(`${result.supplied}`),
            );
        },

        isEmptySubtypeError() {
            return !this.updateMode && Object.keys(this.madeMedicationByOrder).some((orderId) => {
                const madeMedication = this.madeMedicationByOrder[orderId];
                const { medicationId } = this.ordersById[orderId];
                const clinicMedication = this.clinicMedicationById[medicationId];

                if (!clinicMedication || clinicMedication.schemeId !== 2) {
                    return false;
                }
                return madeMedication.some((result) => !result.subtypeId);
            });
        },

        clinicMedicationById() {
            return this.clinicMedication.reduce((map, medication) => ({
                ...map,
                [medication.id]: medication,
            }), {});
        },

        ordersById() {
            return this.orders.reduce((map, order) => ({
                ...map,
                [order.id]: order,
            }), {});
        },
    },

    watch: {
        orders(value) {
            this.initResults(this.updateMode ? value[0] : value);
        },
    },

    created() {
        this.fetchClinicMedication();
        this.initResults(this.updateMode
            ? this.orders[0]
            : null);
    },

    methods: {
        fetchClinicMedication() {
            this.isPending = true;
            this.isError = false;
            this.$http.get('/clinic/medications', {
                params: {
                    deleted: true,
                    ids: this.orders.map((order) => order.medicationId),
                },
            })
                .then((response) => this.fetchClinicMedicationSuccess(response))
                .catch((err) => this.fetchClinicMedicationError(err));
        },

        fetchClinicMedicationSuccess(response) {
            this.isPending = false;
            this.clinicMedication = response.data;
        },

        fetchClinicMedicationError() {
            this.isPending = false;
            this.isError = true;
        },

        updateMedication() {
            const data = {
                medicationAmountSupplied: this.results[0].supplied,
            };
            if (this.results[0].supplied <= this.orders[0].medicationAmountSupplied) {
                showErrorNotification(`Указанное количество препарата
                    уже было отправлено в клинику ранее.`);
                return;
            }
            this.$http.put(`/procedure/laboratory/medication/store/${this.orders[0].id}`, data)
                .then((response) => this.saveMedicationSuccess(response))
                .catch((err) => this.saveMedicationError(err));
            this.isPending = true;
            this.isSaveError = false;
        },

        saveMedication() {
            if (this.updateMode) {
                this.updateMedication();
                return;
            }
            const data = this.orders.map((order) => ({
                orderId: order.id,
                medication: this.madeMedicationByOrder[order.id].map((subMed) => ({
                    medicationAmount: subMed.produced,
                    medicationAmountSupplied: subMed.supplied,
                    medicationSubtypeId: subMed.subtypeId && parseInt(subMed.subtypeId, 10),
                })),
            }));
            this.$http.post('/procedure/laboratory/medication/store', data)
                .then((response) => this.saveMedicationSuccess(response))
                .catch((err) => this.saveMedicationError(err));
            this.isPending = true;
            this.isSaveError = false;
        },

        saveMedicationSuccess() {
            this.isPending = false;
            this.closeModal();
        },

        saveMedicationError() {
            this.isPending = false;
            this.isSaveError = true;
        },

        closeModal() {
            this.$emit('close', true);
        },

        cancelClick() {
            this.$emit('close', false);
        },

        initResults(editedMedication) {
            if (editedMedication) {
                this.madeMedicationByOrder = {
                    [editedMedication.id]: [{
                        produced: editedMedication.medicationAmount,
                        supplied: editedMedication.medicationAmountSupplied,
                        subtypeId: null,
                    }],
                };
                return;
            }

            this.madeMedicationByOrder = this.orders.reduce((map, order) => ({
                ...map,
                [order.id]: [{
                    produced: 1,
                    supplied: 0,
                    subtypeId: null,
                }],
            }), {});
        },

        addMedication(orderId) {
            this.madeMedicationByOrder[orderId].push({
                produced: 1,
                supplied: 0,
                subtypeId: null,
            });
        },

        removeMedication(orderId, index) {
            const madeMedication = this.madeMedicationByOrder[orderId];
            if (index < 0 || index >= madeMedication.length) {
                throw new Error('Illegal argument exception.');
            }
            madeMedication.splice(index, 1);
        },

        updateMedicationValue(property, orderId, index, newValue) {
            this.madeMedicationByOrder[orderId][index][property] = newValue;
        },
    },
});
</script>

<style lang="scss">
    .medicatiom-add-window .ant-alert {
        margin: 2px;
    }
</style>
