<template>
    <div>
        <p
            v-if="!$route.params.patientId"
            class="hint"
        >
            Для просмотра результатов исследований выберите пациента в списке слева.
        </p>
        <router-view />
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
    .hint {
        margin: 10px 20px;
        color: #00706c;
        font-size: 18px;
    }
</style>
