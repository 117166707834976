<template>
    <div class="main page">
        <a-layout>
            <page-header />
            <a-layout-content class="info">
                <a-breadcrumb>
                    <a-breadcrumb-item href="">
                        <home-outlined
                            @click="$router.push({name: 'main'})"
                        />
                    </a-breadcrumb-item>
                    <a-breadcrumb-item href="">
                        <a :href="`/card/${patientId}`">{{ patientName }}</a>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        {{ surveyType }}
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <div class="survey-with-print">
                    <div class="main-info">
                        <div class="card-info-wrapper">
                            <survey-card
                                :survey-id="surveyId"
                                read-only
                                :defaults="{}"
                                style="margin-top: 20px"
                                @survey-data-load="onSurveyDataLoad"
                            />
                        </div>
                    </div>
                    <div class="button-wrapper">
                        <a-button
                            type="primary"
                            @click="print"
                        >
                            <template #icon>
                                <printer-outlined />
                            </template>
                            Печать
                        </a-button>
                    </div>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>

<script>
import HomeOutlined from '@ant-design/icons-vue/HomeOutlined';
import PrinterOutlined from '@ant-design/icons-vue/PrinterOutlined';
import { defineComponent } from 'vue';
import titleMixin from '@/mixins/titleMixin';
import PageHeader from '@/components/PageHeader.vue';
import SurveyCard from '@/components/patientcard/SurveyCard.vue';

export default defineComponent({
    components: {
        PageHeader,
        SurveyCard,
        HomeOutlined,
        PrinterOutlined,
    },
    mixins: [titleMixin],
    props: {
        surveyId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            patientId: null,
            patientName: localStorage.patientName,
            surveyType: null,
        };
    },
    methods: {
        onSurveyDataLoad(data) {
            this.patientId = data.patientId;
            this.patientName = data.patientName;
            this.surveyType = data.surveyType;
            this.setPageTitle(data.patientName);
        },
        print() {
            window.open(`/api/patient/survey/labs/${this.surveyId}/download`);
        },
    },
});
</script>

<style lang="scss" scoped>
.main.page .ant-layout-content .survey-with-print {
    box-shadow: 0px 1px 2px 0px #3c40434d, 0px 2px 6px 2px #3c404326;
    background-color: #fff;

    .main-info {
        box-shadow: none;
    }
    .button-wrapper {
        display: block;
        text-align: right;
        padding: 0 20px 20px 0;
    }
}
</style>
