<template>
    <div>
        <h2>Лабораторные исследования</h2>
        <lab-surveys
            :patient-id="patientId"
            survey-base-url="/survey/"
        />
    </div>
</template>

<script>
import LabSurveys from '@/components/surveys/LabSurveys.vue';

export default {
    components: {
        LabSurveys,
    },
    props: {
        patientId: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.survey-table {
    margin-bottom: 20px;

    .hidden-expander {
        .ant-table-row-expand-icon {
            display: none;
        }
    }
}
</style>
