<template>
    <div class="main page">
        <a-layout>
            <page-header />
            <a-layout-content class="info">
                <a-breadcrumb>
                    <a-breadcrumb-item href="">
                        <home-outlined
                            @click="$router.push({name: 'main'})"
                        />
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        {{ patientName }}
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <div class="main-info">
                    <a-tabs>
                        <a-tab-pane
                            key="1"
                        >
                            <template #tab>
                                <span data-test-id="patient-main-tab">
                                    Основная
                                </span>
                            </template>
                            <main-info
                                ref="mainInfo"
                                :patient-id="patientId"
                                :patient-is-vip="patientIsVip"
                                class="card-info-wrapper"
                                @patient-info="patientName = $event.name"
                            />
                        </a-tab-pane>
                        <a-tab-pane
                            key="5"
                        >
                            <template #tab>
                                <span data-test-id="patient-anamnesis-tab">
                                    Анамнезы
                                </span>
                            </template>
                            <anamnesis-wrapper
                                v-if="patientId"
                                :patient-id="patientId"
                                @anamnesis-update="onAnamnesisUpdate"
                            />
                        </a-tab-pane>
                        <a-tab-pane
                            key="2"
                            force-render
                        >
                            <template #tab>
                                <span data-test-id="patient-treatment-history-tab">
                                    История лечения
                                </span>
                            </template>
                            <h2 :style="{marginLeft: '20px', marginBottom: '20px'}">
                                История лечения
                            </h2>

                            <div class="events-timeline">
                                <treatment-history
                                    :patient-id="patientId"
                                    class="card-info-wrapper"
                                />
                            </div>
                        </a-tab-pane>
                        <a-tab-pane
                            v-if="userHasSurveysAccess"
                            key="3"
                        >
                            <template #tab>
                                <span data-test-id="patient-surveys-tab">
                                    Исследования
                                </span>
                            </template>
                            <surveys
                                :patient-id="patientId"
                                class="card-info-wrapper"
                            />
                        </a-tab-pane>
                        <a-tab-pane
                            key="4"
                        >
                            <template #tab>
                                <span data-test-id="patient-polls-tab">
                                    Опросы
                                </span>
                            </template>
                            <polls
                                :patient-id="patientId"
                                class="card-info-wrapper"
                            />
                        </a-tab-pane>
                        <a-tab-pane
                            key="6"
                        >
                            <template #tab>
                                <span data-test-id="patient-medication-calendar-tab">
                                    Мониторинг МикроРНК
                                </span>
                            </template>
                            <patient-medication-calendar
                                :patient-id="patientId"
                            />
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>

<script>
import HomeOutlined from '@ant-design/icons-vue/HomeOutlined';
import { defineComponent } from 'vue';
import PatientMedicationCalendar
    from 'vue-web-components/src/components/patient-medication-calendar/PatientMedicationCalendar.vue';
import titleMixin from '@/mixins/titleMixin';
import PageHeader from '@/components/PageHeader.vue';
import MainInfo from '@/components/patientcard/MainInfo.vue';
import Surveys from '@/components/patientcard/Surveys.vue';
import Polls from '@/components/patientcard/Polls.vue';
import TreatmentHistory from '@/components/patientcard/TreatmentHistory.vue';
import AnamnesisWrapper from '@/components/patientcard/AnamnesisWrapper.vue';
import useUserRights from '@/utils/userRights';
import { showErrorNotification } from '@/utils';

export default defineComponent({
    components: {
        AnamnesisWrapper,
        HomeOutlined,
        MainInfo,
        PageHeader,
        PatientMedicationCalendar,
        Polls,
        Surveys,
        TreatmentHistory,
    },
    mixins: [titleMixin],

    props: {
        patientId: {
            type: Number,
            default: null,
        },
    },

    setup() {
        const { userRightsMap } = useUserRights();

        return {
            userRightsMap,
        };
    },

    data() {
        return {
            patientName: null,
            patientIsVip: null,
        };
    },

    computed: {
        userHasSurveysAccess() {
            return this.patientIsVip === false
                || (this.userRightsMap && this.userRightsMap.VIP_PATIENT_SURVEYS.r);
        },
    },

    watch: {
        patientId: {
            immediate: true,
            handler(val) {
                this.patientIsVip = null;
                if (!val) {
                    return;
                }

                this.$http.get(`patient/info/${val}`).then(
                    (response) => {
                        this.patientIsVip = !!response.data.isVip;
                    },
                    () => showErrorNotification('Ошибка загрузки данных пациента.'),
                );
            },
        },

        patientName(val) {
            if (val) {
                this.setPageTitle(val);
            }
        },
    },
    methods: {
        onAnamnesisUpdate() {
            if (Object.prototype.hasOwnProperty.call(this.$refs, 'mainInfo')) {
                this.$refs.mainInfo.getPatientHealthPassport(this.patientId);
            }
        },
    },
});
</script>

<style lang="scss">
@import "@/styles/page.scss";
.main.page {
    .ant-layout-content {
        .ant-tabs-nav-scroll {
            text-align: left;
            padding-left: 20px;
        }
    }
}
</style>
