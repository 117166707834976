<template>
    <a-modal
        centered
        title="Распорядок недели пациента"
        :visible="visible"
        :mask-closable="false"
        @cancel="close"
    >
        <div class="patient-schedule-container">
            <div>
                <h4>Выходные</h4>
                <a-checkbox-group
                    v-model:value="holidays"
                    :options="weekDays"
                />
            </div>

            <div>
                <h4>Будний день</h4>
                <a-slider
                    ref="workday-time-slider"
                    v-model:value="patientTimes.workday"
                    range
                    :marks="marks"
                    :tip-formatter="tipFormatter"
                    :min="0"
                    :max="24"
                />
            </div>
            <div>
                <h4>Выходной день</h4>
                <a-slider
                    ref="holiday-time-slider"
                    v-model:value="patientTimes.holiday"
                    range
                    :marks="marks"
                    :tip-formatter="tipFormatter"
                    :min="0"
                    :max="24"
                />
            </div>
            <a-table
                size="small"
                :columns="dayActionColumns"
                :data-source="dayActionData"
                :pagination="false"
            >
                <template #timeAction="{ record }">
                    <a-time-picker
                        v-model:value="record.timeAction"
                        format="HH:mm"
                        :allow-clear="false"
                    />
                </template>
                <template #timeActionHoliday="{ record }">
                    <a-time-picker
                        v-model:value="record.timeActionHoliday"
                        format="HH:mm"
                        :allow-clear="false"
                    />
                </template>
            </a-table>
        </div>
        <template #footer>
            <a-button
                key="back"
                @click="close"
            >
                Отмена
            </a-button>
            <a-button
                key="submit"
                type="primary"
                @click="handleSave"
            >
                Сохранить
            </a-button>
        </template>
    </a-modal>
</template>

<script>
import { pickBy } from 'lodash';
import { defineComponent } from 'vue';

import { showErrorNotification } from '../../../utils';

export default defineComponent({
    props: {
        patientId: {
            type: Number,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        close: null,
        update: null,
    },
    data() {
        return {
            patientDayActions: null,
            commonDayActions: [],
            dayActions: null,
            dayTimes: null,

            weekDays: [
                { label: 'Пн', value: 'mon' },
                { label: 'Вт', value: 'tue' },
                { label: 'Ср', value: 'wed' },
                { label: 'Чт', value: 'thu' },
                { label: 'Пт', value: 'fri' },
                { label: 'Сб', value: 'sat' },
                { label: 'Вс', value: 'sun' },
            ],
            holidays: ['sat', 'sun'],
            weekDaysVocabular: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
            marks: {
                0: '00:00',
                6: '06:00',
                12: '12:00',
                18: '18:00',
                24: '24:00',
            },
            workdayTimes: [],
            holidayTimes: [],
            dayActionColumns: [
                { title: 'Событие', dataIndex: 'name' },
                { title: 'В будний день', dataIndex: 'timeAction', slots: { customRender: 'timeAction' } },
                { title: 'В выходной', dataIndex: 'timeActionHoliday', slots: { customRender: 'timeActionHoliday' } },
            ],
            dayActionData: [],
            patientTimes: {
                workday: [],
                holiday: [],
            },
            patientActions: [],
        };
    },
    async created() {
        try {
            const patientDayActionsResponse = await this.$http.get(`/patient/${this.patientId}/day/actions/`);
            this.patientDayActions = patientDayActionsResponse.data;
            if (!this.patientDayActions.dayActions.length && !this.commonDayActions.length) {
                const commonDayActionsResponse = (await this.$http.get('/common/patient/day/actions/'));
                this.commonDayActions = commonDayActionsResponse.data.dayActions;
            }
            const dayActionsResponse = await this.$http.get('/day/actions/');
            this.dayActions = dayActionsResponse.data;
            const dayTimesResponse = await this.$http.get('/day/times/');
            this.dayTimes = dayTimesResponse.data;
            this.setPatientSchedule();
        } catch (err) {
            showErrorNotification(
                'Произошла ошибка во время загрузки данных распорядка недели пациента',
            );
        }
    },
    methods: {
        setPatientSchedule() {
            const patientDayActions = this.patientDayActions.dayActions.length
                ? this.patientDayActions.dayActions
                : this.commonDayActions;
            this.patientTimes.workday = this.dayTimes.map((time) => {
                const action = patientDayActions.find((x) => x.dayActionId === time.id);
                if (!action) {
                    return this.$moment.duration(time).asHours();
                }
                return this.$moment.duration(action.timeAction).asHours();
            });
            this.patientTimes.holiday = this.dayTimes.map((time) => {
                const action = patientDayActions.find((x) => x.dayActionId === time.id);
                if (!action) {
                    return this.$moment.duration(time).asHours();
                }
                return this.$moment.duration(action.timeActionHoliday).asHours();
            });
            patientDayActions.forEach((action) => {
                const relatedAction = this.dayActions.find((x) => x.id === action.dayActionId);
                if (relatedAction) {
                    this.dayActionData.push({
                        get key() {
                            return this.id;
                        },
                        id: relatedAction.id,
                        name: relatedAction.name,
                        timeAction: this.$moment(action.timeAction, 'HH:mm:ss'),
                        timeActionHoliday: this.$moment(action.timeActionHoliday, 'HH:mm:ss'),
                    });
                }
            });
            this.holidays = Object.keys(
                pickBy(this.patientDayActions.holidays, (val) => val === 1),
            );
        },
        tipFormatter(value) {
            return `${value}:00`;
        },
        close() {
            this.$emit('close');
        },
        async handleSave() {
            const data = {
                dayActions: [],
                holidays: {
                    ...Object.fromEntries(this.holidays.map((day) => ([day, 1]))),
                    patientId: this.patientId,
                },
            };
            this.dayActionData.forEach((day) => {
                data.dayActions.push({
                    dayActionId: day.id,
                    timeAction: day.timeAction.format('HH:mm:ss'),
                    timeActionHoliday: day.timeActionHoliday.format('HH:mm:ss'),
                });
            });
            this.dayTimes.forEach((time, i) => {
                data.dayActions.push({
                    dayActionId: time.id,
                    timeAction: this.$moment.utc(
                        this.$moment.duration(this.patientTimes.workday[i], 'hours').asMilliseconds(),
                    ).format('HH:mm'),
                    timeActionHoliday: this.$moment.utc(
                        this.$moment.duration(this.patientTimes.holiday[i], 'hours').asMilliseconds(),
                    ).format('HH:mm'),
                });
            });
            try {
                await this.$http.put(`patient/${this.patientId}/day/actions`, data);
                this.$emit('update');
                this.close();
            } catch (err) {
                showErrorNotification();
            }
        },
    },
});
</script>

<style lang="scss">
.patient-schedule .ant-modal-body {
    padding-top: 5px;

    .ant-form-item {
        margin-bottom: 10px;
    }

    .day-slider-tooltips {
        height: 12px;
        display: grid;

        .slider-tip {
            line-height: 12px;
            text-align: center;
            font-style: italic;
        }
    }
}
.patient-schedule-container {
    margin: 0 20px;
}
.patient-schedule-container > div {
    padding-bottom: 20px;
}
.patient-schedule-container h4 {
    padding-bottom: 10px;
}
</style>
