<template>
    <a-spin :spinning="!patientInfo">
        <div
            v-if="patientInfo"
            class="health-passport"
        >
            <h2>Паспорт здоровья</h2>
            <div class="patient-info">
                <a-avatar
                    :src="avatarSrc"
                    size="large"
                >
                    <template #icon>
                        <user-outlined />
                    </template>
                </a-avatar>
                <div id="info-string">
                    <h2>
                        <patient-info-string
                            :patient-info="patientInfo"
                        />
                    </h2>
                    <pushpin-two-tone
                        :two-tone-color="patientInfo.pinned ? '#52c41a' : undefined"
                        class="patient-pin"
                        @click="(e) => pushPin(e)"
                    />
                    <span
                        :id="patientInfo.pinned ? 'pinned' : null"
                        class="pin-patient"
                        @click="(e) => pushPin(e)"
                    >{{ patientInfo.pinned ? 'Закреплено' : 'Закрепить' }}</span>
                </div>
                <h2 class="card-num">
                    {{ healthPassport.cardNum }}
                </h2>
            </div>

            <combo-box-save
                id="therapist"
                v-model:value="saveStatusDoctor"
                title="Лечащий врач:"
            >
                <med-staff
                    v-model:value="therapistId"
                    @input="saveTherapist"
                />
            </combo-box-save>

            <combo-box-save
                v-if="patientInfo.stateName"
                id="state-name-label"
                v-model:value="saveStatusState"
                title="Состояние/Статус:"
            >
                <states
                    v-model:value="patientInfo.stateCode"
                    @input="savePatientState"
                />
            </combo-box-save>

            <a-row>
                <h3>Диагнозы</h3>
            </a-row>
            <a-row>
                <a-col :span="12">
                    Диагноз(ы) по обращению:
                </a-col>
                <a-col :span="12">
                    Клинический диагноз(ы): <strong>{{ healthPassport.clinicalDiagnoses }}</strong>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                    Диагноз(ы) Импульс жизни: <strong>{{ healthPassport.lfDiagnoses }}</strong>
                </a-col>
                <a-col :span="12">
                    Проходит лечение в клинике:
                </a-col>
            </a-row>
            <a-row>
                <h3>Анамнез</h3>
            </a-row>
            <a-row>
                <a-col :span="12">
                    Сведения о перенесенных детский заболеваниях:
                    <strong> {{ healthPassport.historyChildhoodDiseases }}</strong>
                </a-col>
                <a-col :span="12">
                    Семейный список заболеваний:
                    <strong> {{ healthPassport.historyFamilyDiseases }}</strong>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                    Список непереносимых препаратов:
                    <strong> {{ healthPassport.intoleranceDrugs }}</strong>
                </a-col>
                <a-col :span="12">
                    Акушерский список заболеваний:
                    <strong> {{ healthPassport.historyObstetricalDiseases }}</strong>
                </a-col>
            </a-row>
            <a-row>
                <h3>Оценка состояния</h3>
            </a-row>
            <a-row>
                <a-col :span="12">
                    Энергетический статус:
                </a-col>
                <a-col :span="12">
                    Состояние по опросу пациента:
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                    Бактериологический статус:
                </a-col>
                <a-col :span="12">
                    Индекс массы тела: <strong>{{ healthPassport.patientIMT }}</strong>
                </a-col>
            </a-row>
        </div>
        <div
            v-if="patientInfo && patientInfo.lastProtocolId"
            id="last-protocol"
        >
            <h2>Предыдущий приём</h2>
            <a-row>
                <h3>Жалобы</h3>
                <a-tooltip>
                    <template #title>
                        Открыть историю жалоб
                    </template>
                    <a-button
                        class="history-btn"
                        size="small"
                        @click="setHistoryFieldName('complaints')"
                    >
                        <template #icon>
                            <ellipsis-outlined />
                        </template>
                    </a-button>
                </a-tooltip>
            </a-row>
            <a-row class="protocol-text">
                <template v-if="lastProtocol.complaints">
                    {{ lastProtocol.complaints }}
                </template>
                <span
                    v-else
                    class="empty-label"
                >
                    Нет данных
                </span>
            </a-row>
            <a-row>
                <h3>Объективные данные</h3>
                <a-tooltip>
                    <template #title>
                        Открыть историю объективных данных
                    </template>
                    <a-button
                        class="history-btn"
                        size="small"
                        @click="setHistoryFieldName('externalEvidence')"
                    >
                        <template #icon>
                            <ellipsis-outlined />
                        </template>
                    </a-button>
                </a-tooltip>
            </a-row>
            <a-row class="protocol-text">
                <template v-if="lastProtocol.externalEvidence">
                    {{ lastProtocol.externalEvidence }}
                </template>
                <span
                    v-else
                    class="empty-label"
                >
                    Нет данных
                </span>
            </a-row>
            <a-row>
                <h3>Рекомендации</h3>
                <a-tooltip>
                    <template #title>
                        Открыть историю рекомендаций
                    </template>
                    <a-button
                        class="history-btn"
                        size="small"
                        @click="setHistoryFieldName('recommendations')"
                    >
                        <template #icon>
                            <ellipsis-outlined />
                        </template>
                    </a-button>
                </a-tooltip>
            </a-row>
            <a-row class="protocol-text">
                <template v-if="lastProtocol.recommendations">
                    {{ lastProtocol.recommendations }}
                </template>
                <span
                    v-else
                    class="empty-label"
                >
                    Нет данных
                </span>
            </a-row>
        </div>
        <div
            v-if="userHasAccessToMonitoring"
        >
            <h2>Мониторинг показателей</h2>
            <monitoring
                :patient-id="patientId"
            />
        </div>
        <description-history-window
            v-if="patientId && descriptionHistoryFieldName"
            :patient-id="patientId"
            :field-name="descriptionHistoryFieldName"
            :with-paste="false"
            @close="handleHistoryWindowClose"
        />
    </a-spin>
</template>

<script>
import UserOutlined from '@ant-design/icons-vue/UserOutlined';
import PushpinTwoTone from '@ant-design/icons-vue/PushpinTwoTone';
import EllipsisOutlined from '@ant-design/icons-vue/EllipsisOutlined';
import Monitoring from 'vue-web-components/src/components/monitoring/Monitoring.vue';
import DescriptionHistoryWindow from 'vue-web-components/src/components/descriptionhistory/DescriptionHistoryWindow.vue';
import { defineComponent } from 'vue';
import PatientInfoString from '@/components/patientcard/PatientInfoString.vue';
import MedStaff from '@/components/comboboxes/MedStaff.vue';
import States from '@/components/comboboxes/States.vue';
import ComboBoxSave from '@/components/wrappers/ComboBoxSave.vue';
import useUserRights from '@/utils/userRights';
import { showErrorNotification } from '@/utils';

export default defineComponent({
    components: {
        Monitoring,
        PatientInfoString,
        MedStaff,
        DescriptionHistoryWindow,
        States,
        ComboBoxSave,
        UserOutlined,
        PushpinTwoTone,
        EllipsisOutlined,
    },
    props: {
        patientId: {
            type: Number,
            required: true,
        },

        patientIsVip: {
            type: Boolean,
            default: null,
        },
    },
    emits: ['input', 'patient-info'],

    setup() {
        const { userRightsMap } = useUserRights();
        return {
            userRightsMap,
        };
    },

    data() {
        return {
            saveStatusDoctor: {
                code: null,
                text: null,
            },
            saveStatusState: {
                code: null,
                text: null,
            },
            patientInfo: null,
            lastProtocol: {},
            healthPassport: {},
            patientName: '',
            patientAvatarPreviewFileId: null,
            therapistId: null,
            tempTherapistId: null,
            descriptionHistoryFieldName: null,
        };
    },
    computed: {
        avatarSrc() {
            return this.patientAvatarPreviewFileId
                ? `/api/patient/file/${this.patientAvatarPreviewFileId}`
                : '';
        },

        userHasAccessToMonitoring() {
            return this.patientIsVip === false
                || (this.userRightsMap && this.userRightsMap.VIP_PATIENT_SURVEYS.r);
        },
    },

    mounted() {
        this.getPatientInfo(this.patientId);
        this.getPatientHealthPassport(this.patientId);
    },
    methods: {

        getPatientInfo(id) {
            this.$http.get(`/patient/info/${id}`).then((response) => {
                this.patientAvatarPreviewFileId = response.data.photo.preview_file_id;
                this.patientInfo = {
                    name: `${response.data.lastname} ${
                        response.data.firstname || ''} ${
                        response.data.middlename || ''}`,
                    birthDate: response.data.birth_date,
                    gender: response.data.gender,
                    phones: response.data.phones,
                    pinned: response.data.pinned,
                    lastProtocolId: response.data.lastProtocolId,
                    stateCode: response.data.stateCode,
                    stateName: response.data.stateName,
                };
                this.patientName = this.patientInfo.name;
                const shortName = `${response.data.lastname} ${
                    response.data.firstname ? `${response.data.firstname[0]}. ` : ''
                }${response.data.middlename ? `${response.data.middlename[0]}.` : ''}`;
                this.$emit('input', { patientName: this.patientInfo.name, patientShortName: shortName });
                this.$emit('patient-info', this.patientInfo);

                if (response.data.lastProtocolId) {
                    this.$http.get(`/patient/protocol/${response.data.lastProtocolId}`)
                        .then((resp) => {
                            this.lastProtocol = {
                                complaints: resp.data.complaints,
                                externalEvidence: resp.data.externalEvidence,
                                recommendations: resp.data.recommendations,
                            };
                        }).catch(() => showErrorNotification(
                            'Не удалось загрузить протокол.',
                        ));
                }
            }).catch(() => showErrorNotification(
                'Не удалось загрузить информацию о пациенте.',
            ));

            this.$http.get(`/patient/${id}/doctor-in-charge`).then((response) => {
                this.therapistId = response.data.doctorInChargeId;
                this.tempTherapistId = response.data.doctorInChargeId;
            }).catch(() => showErrorNotification(
                'Не удалось загрузить информацию о лечащем враче.',
            ));
        },
        getPatientHealthPassport(id) {
            this.$http.get(`patient/${id}/health-passport`).then((response) => {
                this.healthPassport = response.data;
            }).catch(() => showErrorNotification('Не удалось загрузить паспорт здоровья.'));
        },
        handleHistoryWindowClose() {
            this.descriptionHistoryFieldName = null;
        },
        setHistoryFieldName(name) {
            this.descriptionHistoryFieldName = name;
        },
        pushPin(e) {
            e.stopPropagation();
            this.$http.put(`patient/${this.patientId}/pin`, { pin: !this.patientInfo.pinned }).then(() => {
                this.patientInfo.pinned = !this.patientInfo.pinned;
            }).catch(() => showErrorNotification(
                'Не удалось прикрепить пациента.',
            ));
        },
        saveTherapist(id) {
            this.$http.put(
                `/patient/${this.patientId}/doctor-in-charge`,
                { doctorInChargeId: id },
            ).then(() => {
                this.tempTherapistId = id;
                this.saveStatusDoctor = {
                    code: 'OK',
                    text: 'Сохранено успешно',
                };
            }).catch(() => {
                this.saveStatusDoctor = {
                    code: 'ERROR',
                    text: 'Ошибка сохранения',
                };
            });
        },
        savePatientState(state) {
            this.$http.put(
                `/patient/${this.patientId}/patient-state`,
                { stateCode: state },
            ).then(() => {
                this.saveStatusState = {
                    code: 'OK',
                    text: 'Сохранено успешно',
                };
            }).catch(() => {
                this.saveStatusState = {
                    code: 'ERROR',
                    text: 'Ошибка сохранения',
                };
            });
        },
    },
});
</script>

<style lang="scss">
.health-passport {
    margin-bottom: 30px;

    .ant-row {
        text-align: left;
        h3 {
            margin-top: 20px;
        }
    }
}
.patient-info {
    text-align: left;
    .ant-avatar-lg.ant-avatar-icon {
        float: left;
        margin-top: 7px;
    }
    #info-string {
        display: inline-block;
        margin-left: 10px;
        h2 {
            margin-bottom: 0px;
        }
        .pin-patient {
            margin-left: 3px;
            cursor: pointer;
            text-decoration: underline;
        }
        #pinned {
            color: #52c41a;
        }
    }
    .card-num {
        float: right;
    }
}
#therapist {
    display: grid;
    grid-template-columns: 130px 20% 200px;
    margin-top: 10px;

    h3 {
        line-height: 32px;
        margin: 0;
    }

    button, button:hover {
        padding: 0;
        border: none;
        background: none;
        box-shadow: none;
        height: 30px;
        margin-left: 10px;
    }
}

#last-protocol {
    margin-bottom: 20px;

    .empty-label {
        font-style: italic;
    }
    .protocol-text {
        white-space: pre-wrap;
        margin-bottom: 15px;
    }
    h3, .ant-btn {
        display: inline;
    }
    .ant-btn {
        margin-left: 15px;
    }
}
#state-name-label {
    display: grid;
    grid-template-columns: 160px 20% 200px;
    margin-top: 20px;

    &::before {
        content: unset;
    }

    h3 {
        line-height: 32px;
        margin: 0;
    }
}
</style>
