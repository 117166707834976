<template>
    <div>
        <a-spin
            :spinning="isLoading"
            :indicator="loadingIndicator"
            tip="Загрузка..."
        >
            <a-alert
                v-if="isError"
                type="error"
                message="Произошла ошибка во время загрузки данных!"
            />
            <div class="action-buttons-block">
                <a-button
                    :disabled="selectedMedication.length !== 1"
                    class="action-btn"
                    type="primary"
                    @click="showMedicationAmountWindow"
                >
                    Отправить препарат в клинику
                </a-button>
            </div>
            <a-table
                :columns="tableColumns"
                :pagination="pagination"
                :data-source="labStoreData"
                :scroll="{ x: true }"
                row-key="id"
                class="store-grid"
                @change="handleTableChange"
            >
                <template #checked="{ record }">
                    <a-checkbox v-model:checked="record.checked" />
                </template>
            </a-table>
            <save-medication
                v-if="showModal"
                update-mode
                :orders="[selectedMedication[0]]"
                @close="modalWindowClose"
            />
        </a-spin>
    </div>
</template>

<script>
import { defineComponent, h } from 'vue';
import LoadingSpinner from '@/utils/Spinner.vue';
import SaveMedication from '@/components/labpatientcard/SaveMedication.vue';
import { showErrorNotification } from '@/utils';

export default defineComponent({
    components: {
        SaveMedication,
    },

    props: {
        patientId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            tableColumns: [{
                title: '',
                width: 32,
                key: 'checked',
                dataIndex: 'checked',
                slots: { customRender: 'checked' },
            }, {
                title: 'ФИО',
                width: 300,
                key: 'patientName',
                dataIndex: 'patientName',
                sorter: true,
            }, {
                title: 'Препарат',
                width: 300,
                key: 'name',
                dataIndex: 'name',
            }, {
                title: 'Дата производства',
                width: 100,
                key: 'createDate',
                dataIndex: 'createDate',
                sorter: true,
            }, {
                title: 'Количество произведенных доз',
                width: 100,
                key: 'medicationAmount',
                dataIndex: 'medicationAmount',
            }, {
                title: 'Количество отправленных в клинику доз',
                width: 100,
                key: 'medicationAmountSupplied',
                dataIndex: 'medicationAmountSupplied',
            }],
            labStoreData: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            sorting: {
                property: 'createDate',
                direction: 'DESC',
            },
            isLoading: true,
            isError: false,
            loadingIndicator: {
                template: h(LoadingSpinner),
            },
            showModal: false,
        };
    },

    computed: {
        selectedMedication() {
            return this.labStoreData.filter((rec) => rec.checked);
        },
    },

    watch: {
        patientId() {
            this.fetchLabStore();
        },
    },

    mounted() {
        this.fetchLabStore();
    },

    methods: {
        fetchLabStore() {
            this.isError = false;
            this.isLoading = true;
            const { current, pageSize } = this.pagination;
            const params = {
                page: JSON.stringify([current - 1, pageSize]),
            };
            if (this.sorting) {
                const { property, direction } = this.sorting;
                params.sort = JSON.stringify([{ property, direction }]);
            }
            if (this.patientId) {
                params.patientId = this.patientId;
            }
            this.$http.get('/procedure/laboratory/medication/store', { params })
                .then((response) => this.fetchLabStoreSuccess(response))
                .catch((err) => this.fetchLabStoreError(err));
        },

        fetchLabStoreSuccess(response) {
            this.isLoading = false;
            this.labStoreData = this.expandFetchedData(
                response.data.data,
            );
            this.pagination = {
                ...this.pagination,
                total: response.data.count,
            };
        },

        fetchLabStoreError() {
            this.isError = true;
            this.isLoading = false;
        },

        expandFetchedData(data) {
            return data.map((rec) => ({
                ...rec,
                createDate: this.$moment(rec.createDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            }));
        },

        handleTableChange(pagination, filters, sorter) {
            if (sorter.field) {
                this.sorting = {
                    property: sorter.field,
                    direction: sorter.order === 'descend' ? 'DESC' : 'ASC',
                };
            } else {
                this.sorting = null;
            }
            this.pagination = {
                ...this.pagination,
                current: pagination.current,
            };
            this.fetchLabStore();
        },

        showMedicationAmountWindow() {
            const record = this.selectedMedication[0];
            if (record.medicationAmount === record.medicationAmountSupplied) {
                showErrorNotification('Весь произведенный препарат уже передан в клинику.');
                record.checked = false;
                return;
            }
            this.showModal = true;
        },

        modalWindowClose(updateStore) {
            this.showModal = false;
            if (updateStore) {
                this.fetchLabStore();
            }
        },
    },
});
</script>

<style lang="scss" scoped>
    .action-buttons-block {
        text-align: left;
    }
    button.action-btn {
        margin: 10px;
        margin-left: 0;
        .ant-btn-primary[disabled] {
            cursor: pointer;
        }
    }
    div.store-grid {
        width: calc(100vw - 335px);
    }
    ::v-deep(.ant-table-column-title) {
        display: flex;
        text-align: center;
        word-break: normal;
    }
</style>
