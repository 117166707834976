import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_config_provider = _resolveComponent("a-config-provider")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_inactivity_alert = _resolveComponent("inactivity-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_component_a_config_provider, { locale: _ctx.ruRu }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_KeepAlive, { include: "Main" }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1024))
          ]),
          _: 2
        }, 1032, ["locale"])
      ]),
      _: 1
    }),
    _createVNode(_component_inactivity_alert, {
      onSessionExpired: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sessionIsExpired.value = true))
    })
  ]))
}