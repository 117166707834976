import { createStore } from 'vuex';

import appMode from './modules/appMode';
import mainDoctor from './modules/mainDoctor';
import appointmentSchedule from './modules/appointmentSchedule';
import patientList from './modules/patientList';
import labPatientList from './modules/labPatientList';

const store = createStore({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        appMode,
        mainDoctor,
        appointmentSchedule,
        patientList,
        labPatientList,
    },
});

export default store;
