import { cloneDeep, isEqual, pick } from 'lodash';

class StateTraveler {
    constructor(vue, property, propertyKeys) {
        this.vue = vue;
        this.property = property;
        this.propertyKeys = propertyKeys;
        this.disabledTargets = [
            'textarea',
            'input',
        ];
        this.init();
    }

    init() {
        this.initState();
        this.propertyKeys.forEach((propertyKey) => {
            this.vue.$watch(
                `${this.property}.${propertyKey}`,
                this.onChange.bind(this),
                { deep: true },
            );
        });

        document.addEventListener('keydown', this.handleKeyDown.bind(this));
        document.addEventListener('keyup', this.handleKeyUp.bind(this));
    }

    initState() {
        this.vue.$options.state = {
            bias: 0,
            staging: false,
            data: [],
        };
    }

    onChange() {
        if (!this.vue.$options.state.data.length) {
            this.vue.$options.state.data.push(
                cloneDeep(pick(this.vue[this.property], this.propertyKeys)),
            );
            return;
        }
        if (this.vue.$options.state.staging) {
            return;
        }
        const currentState = pick(this.vue[this.property], this.propertyKeys);
        const previousState = this.vue.$options.state.data[this.vue.$options.state.data.length - 1];
        if (!isEqual(previousState, currentState)) {
            // if data changed on previuous state need splice state to new branch
            if (this.vue.$options.state.bias !== 0) {
                const stateLen = this.vue.$options.state.data.length - 1;
                const prevIdx = stateLen + this.vue.$options.state.bias;
                this.vue.$options.state.data.splice(prevIdx + 1);
                this.vue.$options.state.bias = 0;
            }
            // push new state
            this.vue.$options.state.data.push(cloneDeep(currentState));
        }
    }

    handleKeyDown(e) {
        const target = e.target && e.target.localName
            ? e.target.localName
            : null;
        if (this.disabledTargets.includes(target)) {
            return;
        }
        if (e.code !== 'KeyZ') {
            return;
        }
        if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            const stateLen = this.vue.$options.state.data.length - 1;
            const nextIdx = stateLen + this.vue.$options.state.bias + 1;
            const nextState = this.vue.$options.state.data[nextIdx];
            if (!nextState) {
                return;
            }
            this.vue.$options.state.staging = true;
            this.vue[this.property] = {
                ...this.vue[this.property],
                ...nextState,
            };
            this.vue.$options.state.bias += 1;
            return;
        }
        if ((e.ctrlKey || e.metaKey) && this.vue.$options.state.data.length > 1) {
            const stateLen = this.vue.$options.state.data.length - 1;
            const prevIdx = stateLen + this.vue.$options.state.bias - 1;
            const previousState = this.vue.$options.state.data[prevIdx];
            if (!previousState) {
                return;
            }
            this.vue.$options.state.staging = true;
            this.vue[this.property] = {
                ...this.vue[this.property],
                ...previousState,
            };
            this.vue.$options.state.bias -= 1;
        }
    }

    handleKeyUp(e) {
        if ((!(e.ctrlKey || e.metaKey) || !e.shiftKey) && this.vue.$options.state.staging) {
            this.vue.$options.state.staging = false;
        }
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeyDown.bind(this));
        document.removeEventListener('keyup', this.handleKeyUp.bind(this));
    }
}

export default StateTraveler;
