<template>
    <div
        class="main-surveys-info main page"
        @click="onClick"
    >
        <h2>
            <patient-info-string :patient-info="patientInfo" />
        </h2>
        <div v-if="patientId && !surveyId && blankIds.length===0">
            <a-spin
                :spinning="!readySurveys"
                :indicator="loadingIndicator"
                tip="Загрузка..."
            >
                <a-button
                    :style="{float: 'left', cursor: 'pointer'}"
                    type="primary"
                    @click="addNewSurvey()"
                >
                    Добавить
                </a-button>
                <br>
                <survey-tree-modal
                    :visible="surveyTreeVisible"
                    @close="surveyTreeCancel"
                    @submit="surveyTreeSubmit"
                />

                <br>
                <lab-surveys
                    v-if="patientId && !surveyId"
                    :patient-id="patientId"
                    :survey-base-url="surveyBaseUrl"
                    @ready-loaded="readySurveys = $event"
                />
            </a-spin>
        </div>

        <div v-if="surveyId || blankIds.length>0">
            <div class="main-info">
                <div class="card-info-wrapper">
                    <survey-card
                        ref="surveyCard"
                        :survey-id="surveyId"
                        :blank-ids="blankIds"
                        :read-only="false"
                        :defaults="{
                            clinicId: null,
                            dateMaterial: null,
                            surveyStatus: null
                        }"
                        :blanks-in-edit="blanksInEdit"
                    />
                </div>
            </div>

            <a-button
                type="primary"
                @click="onSurveyCardSaveClick"
            >
                Сохранить
            </a-button>
            <a-button
                type="default"
                @click="onCancel()"
            >
                Отмена
            </a-button>
        </div>
    </div>
</template>

<script>
import { defineComponent, h } from 'vue';
import phoneFormat from '@/helpers';
import LabSurveys from '@/components/surveys/LabSurveys.vue';
import SurveyCard from '@/components/patientcard/SurveyCard.vue';
import SurveyTreeModal from '@/components/labpatientcard/SurveyTreeModal.vue';
import PatientInfoString from '@/components/patientcard/PatientInfoString.vue';
import LoadingSpinner from '@/utils/Spinner.vue';

import { showNotification, showErrorNotification } from '@/utils';

export default defineComponent({
    components: {
        LabSurveys,
        SurveyCard,
        SurveyTreeModal,
        PatientInfoString,
    },
    filters: {
        phoneFormat,
    },
    beforeRouteUpdate(to, from, next) {
        if (!this.blankIds.length) {
            next();
        } else {
            next(false);
        }
    },

    props: {
        patientId: {
            type: Number,
            default: null,
        },
        surveyId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            patientInfo: null,
            surveyTreeVisible: false,
            blankIds: [],
            newSurveyTypeId: null,
            loadingIndicator: h(LoadingSpinner),
            readySurveys: false,
            blanksInEdit: false,
        };
    },

    computed: {
        surveyBaseUrl() {
            return `/lab-results/patient/${this.patientId}/survey/`;
        },
    },

    watch: {
        patientId(val) {
            this.updatePatientId(val);
        },
    },
    mounted() {
        this.updatePatientId(this.patientId);
    },
    methods: {
        phoneFormat,
        updatePatientId(id) {
            if (id) {
                this.readySurveys = false;
                this.getPatientInfo(id);
            } else {
                this.patientInfo = null;
            }
        },
        getPatientInfo(id) {
            this.$http.get(`/laboratory/patients?id=${id}`).then((response) => {
                [this.patientInfo] = response.data;
            });
        },

        addNewSurvey() {
            this.surveyTreeVisible = true;
        },
        surveyTreeCancel() {
            this.surveyTreeVisible = false;
        },
        surveyTreeSubmit(nodes, surveyTypeId) {
            this.surveyTreeVisible = false;
            this.blankIds = nodes;
            this.newSurveyTypeId = surveyTypeId;
        },
        onClick(event) {
            const path = event.composedPath();
            const pathContainsTableRow = path.some(
                ({ classList }) => classList && classList.contains('ant-table-row'),
            );
            this.blanksInEdit = pathContainsTableRow;
        },

        onCancel() {
            this.blankIds = [];
            this.$router.push({ path: `/lab-results/patient/${this.patientId}` });
        },

        async onSurveyCardSaveClick() {
            let formValues;
            try {
                formValues = await this.$refs.surveyCard.$refs.form.validate();
            } catch (err) {
                showNotification('Ошибка', 'Форма исследования некорректна.');
                return;
            }
            this.prepareAndSaveSurvey(formValues);
        },

        async prepareAndSaveSurvey(formValues) {
            const data = {
                blanks: this.$refs.surveyCard.blanks,
                clinicId: formValues.clinicId,
                comment: formValues.comment,
                dateSurvey: formValues.dateSurvey.format('YYYY-MM-DD'),
                equipment: formValues.equipments,
                stateId: formValues.surveyStatus,
                dateTake: formValues.dateMaterial.format('YYYY-MM-DD'),
                files: this.$refs.surveyCard.fileList.map(
                    (f) => (f.response && f.response.id ? f.response.id : f.uid),
                ),
                patientGoods: [],
                biomaterialSurveys: [],
            };

            if (this.surveyId) {
                data.id = this.surveyId;
                try {
                    await this.$http.put(
                        `patient/${this.patientId}/survey/labs/${this.surveyId}`,
                        data,
                    );
                    this.onCancel();
                } catch (err) {
                    showErrorNotification(err.response.data.msg);
                }
                return;
            }

            if (formValues.dateMaterial) {
                data.dateTake = formValues.dateMaterial.format('YYYY-MM-DD');
            }
            if (data.blanks) {
                data.blanks = data.blanks.map((b) => ({
                    indicators: b.indicators,
                    surveyBlankId: b.id,
                }));
            }
            data.surveyTypeId = this.newSurveyTypeId;
            try {
                await this.$http.post(`patient/${this.patientId}/survey/labs`, data);
                this.onCancel();
            } catch (err) {
                showErrorNotification(err.response.data.msg);
            }
        },
    },
});
</script>

<style lang="scss">
.main-surveys-info {
    padding-left: 10px;
    width: 100%;

    .ant-btn-primary, .ant-btn-default {
        float: right;
        margin-bottom: 20px;
    }
    .ant-btn-default {
        margin-right: 20px;
    }

    .empty-message {
        color: #cccccc;
    }
}
</style>
