import {
    IMedication,
    IMedicationCourse,
    IMedicationGroup,
    IMedicationSet,
    IPatientMedicationCalendarItem,
} from '../../../life-impulse-tools/types';
import {
    delete as deleteFn,
    get,
    post,
    put,
} from '../../utils/axiosConnectionTimeout';
import { catchAxiosError } from '../utils';

export const getClinicMedication = (
    params?: Record<string, any>,
) => get<IMedication[]>('clinic/medications', { params })
    .then((response) => response.data)
    .catch((err) => catchAxiosError(err, 'Не удалось загрузить список препаратов.'));

export const getClinicMedicationGroups = () => get<IMedicationGroup[]>('clinic/medication/types');

export const getMedicationMonitoringSets = () => get<IMedicationSet[]>('medication/monitoring/sets');

export const deleteMedicationMonitoringSet = (
    id: number,
) => deleteFn(`medication/monitoring/set/${id}`)
    .then(() => null)
    .catch((err) => catchAxiosError(err, 'Не удалось удалить набор МикроРНК.'));

export const deleteMedicationMonitoringSetOption = (
    setId: number,
    medicationId: number,
) => deleteFn(`medication/monitoring/set/${setId}/${medicationId}`)
    .then(() => null)
    .catch((err) => catchAxiosError(err, 'Не удалось удалить препарат из набора.'));

export const getPatientMedicationCourses = (
    patientId?: number,
) => {
    if (!patientId) {
        throw new Error('No patient id provided.');
    }
    return get<IMedicationCourse[]>(`patient/${patientId}/medication/courses`);
};

export const saveMedicationMonitoringSetOption = (
    setId: number,
    medicationId: number,
) => post(`medication/monitoring/set/${setId}/${medicationId}`)
    .then(() => null)
    .catch((err) => catchAxiosError(err, 'Не удалось добавить препарат в набор.'));

export const createMedicationMonitoringSet = (
    name: string,
) => post('medication/monitoring/set', { name })
    .then(() => null)
    .catch((err) => catchAxiosError(err, 'Не удалось создать набор МикроРНК.'));

export const updateMedicationMonitoringSet = (
    id: number,
    name: string,
) => put(`medication/monitoring/set/${id}`, { name })
    .then(() => null)
    .catch((err) => catchAxiosError(err, 'Не удалось обновить набор МикроРНК.'));

export const getPatientMedicationCalendar = (
    patientId: number,
    params: {
        clinicMedicationIds: number[],
        medicationSubtypeIds: number[],
        dateFrom?: string,
        dateTo?: string,
    },
) => {
    const updatedParams = {
        ...params,
        clinicMedicationIds: JSON.stringify(params.clinicMedicationIds),
        medicationSubtypeIds: JSON.stringify(params.medicationSubtypeIds),
    };
    return get<IPatientMedicationCalendarItem[]>(`/patient/${patientId}/medication/calendar`, {
        params: updatedParams,
    }).then(
        (response) => response.data,
    ).catch(
        (err) => catchAxiosError(err, 'Не удалось загрузить календарь приёмов препаратов.'),
    );
};

export const getPatientMedicationCalendarSelectedTypes = (
    patientId: number,
) => get<number[]>(`/patient/${patientId}/medication/calendar/selected/types`)
    .then((response) => response.data)
    .catch((err) => catchAxiosError(err, 'Не удалось загрузить список выбранных препаратов.'));

export const updatePatientMedicationCalendarSelectedTypes = (
    patientId: number,
    ids: number[],
) => put(`/patient/${patientId}/medication/calendar/selected/types`, {
    ids: JSON.stringify(ids),
});
