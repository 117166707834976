<template>
    <a-modal
        :visible="visible"
        :mask-closable="false"
        title="Выберите типы исследований"
        @ok="submit"
        @cancel="$emit('close')"
    >
        <a-tree-select
            v-model:value="selectedNodes"
            :get-popup-container="(triggerNode) => triggerNode.parentNode"
            :tree-data="store"
            :tree-data-simple-mode="nodeStructure"
            :filter-tree-node="treeFilter"
            style="width: 100%"
            tree-node-filter-prop="title"
            tree-checkable
            placeholder="Типы исследований..."
        />
    </a-modal>
</template>

<script>
import { defineComponent } from 'vue';
import { convertLayout, convertAlphabet } from '@/utils';

export default defineComponent({
    props: {
        visible: Boolean,
    },
    emits: ['close', 'submit'],
    data() {
        return {
            store: [],
            nodeStructure: {
                id: 'id',
                pId: 'parentId',
                rootPId: 22,
            },
            selectedNodes: [],
        };
    },
    mounted() {
        this.$http.get('/survey/labs/types').then((response) => {
            this.store = response.data.map((x) => ({
                id: x.id,
                key: `id-${x.id}`,
                title: x.name,
                value: x.id,
                parentId: x.parentId,
                disableCheckbox: x.blanks.length === 0,
                isLeaf: x.blanks.length > 0,
                blanks: x.blanks,
            }));
        }, () => {});
    },
    methods: {
        treeFilter(inputValue, treeNode) {
            const title = treeNode.props.title.toLowerCase();
            return title.includes(inputValue.toLowerCase())
                || title.includes(convertLayout(inputValue).toLowerCase())
                || title.includes(convertAlphabet(inputValue).toLowerCase());
        },
        submit() {
            const blanks = [];
            this.selectedNodes.forEach((x) => {
                this.store.find((r) => r.id === x).blanks.forEach((b) => {
                    blanks.push(b.id);
                });
            });
            let surveyTypeId = this.selectedNodes[0];
            if (this.selectedNodes.length > 1) {
                surveyTypeId = this.store.find((r) => r.id === this.selectedNodes[0]).parentId;
            }
            this.$emit('submit', blanks, surveyTypeId);
        },
    },
});
</script>
