<template>
    <div>
        <h3>{{ title }}</h3>

        <slot />

        <save-status
            :value="value"
            @input="changeStatus"
        />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import SaveStatus from '@/components/infocomponents/SaveStatus.vue';

export default defineComponent({
    components: {
        SaveStatus,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        value: {
            type: Object,
            default: () => ({ code: null, text: null }),
        },
    },
    emits: ['input'],
    methods: {
        changeStatus(value) {
            this.$emit('input', value);
        },
    },
});
</script>
