const postfix = 'АРМ Врача - LifeImpulse';

function getTitle(vm) {
    const { title } = vm.$options;
    if (title) {
        return typeof title === 'function'
            ? title.call(vm)
            : `${title} - ${postfix}`;
    }
    return '';
}

export default {
    created() {
        const title = getTitle(this);
        if (title) {
            document.title = title;
        } else {
            document.title = postfix;
        }
    },
    methods: {
        setPageTitle(title) {
            if (title) {
                document.title = `${title} - ${postfix}`;
            } else {
                document.title = postfix;
            }
        },
    },
};
