<template>
    <div class="user-panel">
        <a-avatar
            :src="`${previewFileId
                ? '/api/user/file/' + previewFileId
                : ''}`"
            size="large"
        >
            <template #icon>
                <user-outlined />
            </template>
        </a-avatar>
        <span class="username">
            {{ username }}
        </span>
        <a-button
            shape="circle"
            data-test-id="logout-btn"
            @click="logout"
        >
            <template #icon>
                <logout-outlined />
            </template>
        </a-button>
    </div>
</template>

<script>
import UserOutlined from '@ant-design/icons-vue/UserOutlined';
import LogoutOutlined from '@ant-design/icons-vue/LogoutOutlined';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        UserOutlined,
        LogoutOutlined,
    },
    data() {
        return {
            username: '',
            previewFileId: '',
        };
    },
    mounted() {
        if (localStorage.username) {
            this.username = localStorage.username;
        }
        if (localStorage.previewFileId && localStorage.previewFileId !== 'null') {
            this.previewFileId = localStorage.previewFileId;
        }
    },
    methods: {
        logout() {
            this.$http.post('/user/logout').then(() => {
                window.location = '/login';
            }, () => {
                window.location = '/login';
            });
            localStorage.clear();
        },
    },
});
</script>

<style lang="scss">
.user-panel {
    float: right;
    height: 100%;
    margin-right: 10px;

    .username {
        max-width: 140px;
        line-height: 21px;
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        margin: 0 10px;
    }
}

@media screen and (max-width: 579px) {
    .user-panel {
        .username, .ant-avatar {
            display: none;
        }
    }
}
</style>
