<template>
    <div class="main page">
        <a-layout>
            <page-header />
            <a-layout-content class="info">
                <a-breadcrumb>
                    <a-breadcrumb-item href="">
                        <home-outlined
                            @click="$router.push({name: 'main'})"
                        />
                    </a-breadcrumb-item>
                    <a-breadcrumb-item href="">
                        <a :href="`/card/${patientId}`">{{ patientName }}</a>
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <div
                    :style="{padding: '10px 20px 30px 20px'}"
                    class="main-info"
                >
                    <div
                        v-for="survey in surveys"
                        :key="survey.id"
                    >
                        <h2>{{ survey.surveyType }}</h2>
                        <survey-card
                            :survey-id="survey.id"
                            :read-only="true"
                            :defaults="{
                                clinicId: null,
                                dateMaterial: null
                            }"
                            style="margin-top: 20px"
                            @survey-data-load="onSurveyDataLoad"
                        />
                    </div>
                    <div class="button-wrapper">
                        <a-button
                            type="primary"
                            @click="createNewProtocol"
                        >
                            <template #icon>
                                <plus-square-outlined />
                            </template>
                            Новый протокол приёма
                        </a-button>
                        <a-button
                            :disabled="wasSeen"
                            type="primary"
                            @click="setSurveySeen"
                        >
                            <template #icon>
                                <check-square-outlined />
                            </template>
                            Запись просмотрена
                        </a-button>
                    </div>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import HomeOutlined from '@ant-design/icons-vue/HomeOutlined';
import CheckSquareOutlined from '@ant-design/icons-vue/CheckSquareOutlined';
import PlusSquareOutlined from '@ant-design/icons-vue/PlusSquareOutlined';
import SurveyCard from '@/components/patientcard/SurveyCard.vue';
import PageHeader from '@/components/PageHeader.vue';
import titleMixin from '@/mixins/titleMixin';
import { showErrorNotification } from '@/utils';

export default defineComponent({
    components: {
        PageHeader,
        SurveyCard,
        HomeOutlined,
        CheckSquareOutlined,
        PlusSquareOutlined,
    },
    mixins: [titleMixin],
    props: {
        patientGoodId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            surveys: [],
            patientId: null,
            patientName: null,
            wasSeen: false,
        };
    },
    created() {
        this.getSurveys(this.patientGoodId);
    },
    methods: {
        getSurveys(goodId) {
            this.$http.get('/patient/surveys/labs', {
                params: {
                    patientGoodId: goodId,
                },
            }).then((response) => {
                for (let i = 0; i < response.data.length; i += 1) {
                    if (response.data[i].surveys) {
                        this.surveys.push(
                            ...response.data[i].surveys.map((s) => ({
                                id: s.id,
                                surveyType: s.surveyType,
                            })),
                        );
                    } else if (response.data[i].blanks) {
                        this.surveys.push({
                            id: response.data[i].id,
                            surveyType: response.data[i].surveyType,
                        });
                    }
                }
            });
        },
        onSurveyDataLoad(data) {
            this.patientId = data.patientId;
            this.patientName = data.patientName;
            this.setPageTitle(data.patientName);
        },
        setSurveySeen() {
            this.$http.put(`patient/good/${this.patientGoodId}/seen-surveys-is-ready`, {
                seenSurveysIsReady: 1,
            })
                .then(() => {
                    this.wasSeen = true;
                })
                .catch((err) => {
                    showErrorNotification(err.response.data.msg);
                });
        },
        createNewProtocol() {
            this.$router.push({
                name: 'new-reception',
                params: {
                    patientId: this.patientId,
                    generatingPatientGoodId: this.patientGoodId,
                },
            });
        },
    },

});
</script>

<style lang="scss" scoped>
.button-wrapper {
    display: block;
    text-align: right;
    padding: 10px 0px 20px 0;

    button {
        margin-left: 10px;
    }
}
</style>
