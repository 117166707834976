<template>
    <transition
        name="fade"
        leave-active-class="fade"
    >
        <div
            v-if="showSaveStatus"
            id="save-status"
        >
            <check-circle-two-tone
                v-if="value.code === 'OK'"
                :two-tone-color="'#52c41a'"
            />
            <close-circle-two-tone
                v-if="value.code === 'ERROR'"
                :two-tone-color="'#ff4d4f'"
            />

            {{ value.text }}
        </div>
    </transition>
</template>

<script>
import CloseCircleTwoTone from '@ant-design/icons-vue/CloseCircleTwoTone';
import CheckCircleTwoTone from '@ant-design/icons-vue/CheckCircleTwoTone';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        CloseCircleTwoTone,
        CheckCircleTwoTone,
    },
    props: {
        value: {
            type: Object,
            default: () => ({ code: null, type: null }),
        },
    },
    emits: ['input'],
    data() {
        return {
            saveStatusTimer: null,
        };
    },
    computed: {
        showSaveStatus() {
            return this.value.code === 'OK' || this.value.code === 'ERROR';
        },
    },
    watch: {
        value(newValue) {
            if (newValue.code === null) {
                return;
            }

            if (this.saveStatusTimer) {
                clearInterval(this.saveStatusTimer);
            }

            this.saveStatusTimer = setInterval(() => {
                this.$emit('input', { code: null, text: null });
                clearInterval(this.saveStatusTimer);
            }, 5000, this);
        },
    },
});
</script>

<style lang="scss">
#save-status {
    opacity: 1;
    transition: opacity 1s;

    display: block;
    float: right;
    height: 32px;
    line-height: 32px;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 14px;

    .anticon {
        margin-right: 5px;
    }

    &.fade {
         opacity: 0;
    }
}
</style>
