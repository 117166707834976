import { ref } from 'vue';
import { get } from 'vue-web-components/src/utils/axiosConnectionTimeout';
import { IUserRight } from '@tools/types';

const userRightsMap = ref<Record<string, {
    r: boolean;
    w: boolean;
}> | null>(null);

let isLoading = false;

const useUserRights = () => {
    if (userRightsMap.value || isLoading) {
        return {
            userRightsMap,
        };
    }

    isLoading = true;
    get<IUserRight[]>('user/role/right/list').then(
        (response) => {
            isLoading = false;

            userRightsMap.value = {};
            response.data.forEach(({ code, rightModes }) => {
                const modesMap = {
                    r: false,
                    w: false,
                };
                userRightsMap.value![code] = (rightModes || []).reduce((acc, item) => {
                    acc[item.mode] = true;
                    return acc;
                }, modesMap);
            });
        },
        () => {
            isLoading = false;
        },
    );
    return {
        userRightsMap,
    };
};

export default useUserRights;
