/* eslint-disable no-param-reassign */
import * as ACTIONS from '../actionTypes';

import { getLabPatients } from '../../api';

const PAGE_SIZE = 20;
const transformPatient = (rec) => ({
    patientId: rec.patientId,
    name: rec.name,
    receptionCount: rec.receptionCount,
    inworkCount: rec.inworkCount,
    previewFileId: rec.previewFileId,
});

const labPatientListModule = {
    namespaced: true,

    state: {
        data: [],
        hasMore: false,
        fetching: false,
        error: null,
        pagesLoaded: 0,
    },

    mutations: {
        [ACTIONS.LAB_PATIENT_LIST_FETCH_START](state) {
            state.fetching = true;
            state.error = null;
        },
        [ACTIONS.LAB_PATIENT_LIST_FETCH_DONE](state, payload) {
            state.fetching = false;
            state.data = payload.data;
            state.hasMore = payload.hasMore;
            state.pagesLoaded = payload.pagesLoaded;
        },
        [ACTIONS.LAB_PATIENT_LIST_FETCH_ERROR](state, payload) {
            state.fetching = false;
            state.error = payload.error;
        },
    },

    actions: {
        async fetchPatients({ commit, state }, {
            search,
            offset = 0,
        }) {
            commit(ACTIONS.LAB_PATIENT_LIST_FETCH_START);
            try {
                const { data } = await getLabPatients({
                    search,
                    pagination: {
                        pageSize: PAGE_SIZE,
                        current: offset + 1,
                    },
                    sort: {
                        property: 'name',
                        direction: 'ASC',
                    },
                });
                const { data: patientList, count } = data;
                const hasMore = count > patientList.length + (PAGE_SIZE * offset);
                const transformed = patientList.map(transformPatient);
                commit(ACTIONS.LAB_PATIENT_LIST_FETCH_DONE, {
                    data: offset ? state.data.concat(transformed) : transformed,
                    pagesLoaded: offset + 1,
                    hasMore,
                });
            } catch (error) {
                commit(ACTIONS.LAB_PATIENT_LIST_FETCH_ERROR, { error });
            }
        },
    },
};

export default labPatientListModule;
