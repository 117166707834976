<template>
    <div
        ref="tableWrapper"
        style="height: 100%"
    >
        <div
            v-if="surveysStore.length > 0 || metricsStore.length > 0"
            style="display: block"
        >
            <a-spin
                tip="Загрузка..."
                :spinning="spinLoader"
            >
                <div
                    style="
                        display: grid;
                        grid-template-columns: 220px 130px 120px;
                        margin-bottom: 20px;"
                >
                    <span
                        style="line-height: 32px;
                            font-family: Chinese Quote,-apple-system,BlinkMacSystemFont,
                                Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,
                                Helvetica Neue,Helvetica,Arial,sans-serif;
                            font-size: 14px;
                            font-variant: tabular-nums;"
                    >
                        Дата проведения исследования
                    </span>
                    <a-date-picker
                        v-model:value="surveyDateFrom"
                        data-test-id="survey-date-from"
                        placeholder="С"
                        mode="date"
                        style="margin-right: 10px"
                        format="DD.MM.YYYY"
                        :disabled-date="(current) => surveyDateTo
                            ? current && current.isAfter($moment(new Date(surveyDateTo)), 'day')
                            : false"
                        @change="onIndicatorChange(selectedIndicators)"
                    />
                    <a-date-picker
                        v-model:value="surveyDateTo"
                        data-test-id="survey-date-to"
                        placeholder="По"
                        format="DD.MM.YYYY"
                        :disabled-date="(current) => surveyDateFrom
                            ? current && current.isBefore(
                                $moment(new Date(surveyDateFrom)),
                                'day',
                            )
                            : false"
                        @change="onIndicatorChange(selectedIndicators)"
                    />
                </div>
                <div
                    style="
                        margin-bottom: 20px;
                        display: grid;
                        grid-template-columns: 1fr 190px;
                        grid-column-gap: 10px;
                        grid-row-gap: 10px;"
                    class="monitoring__indicator-settings"
                >
                    <div>
                        <a-select
                            data-test-id="selected-survey"
                            :value="selectedSurvey"
                            placeholder="Исследование"
                            :mode="nonViewedIndicators ? 'multiple' : undefined"
                            style="width: 100%;"
                            show-search
                            :filter-option="filterOption"
                            :get-popup-container="(triggerNode) => triggerNode.parentNode"
                            :allow-clear="true"
                            :disabled="!!nonViewedIndicators || onlyMetrics"
                            @change="onSurveyChange"
                        >
                            <a-select-opt-group label="Наборы показателей">
                                <a-select-option
                                    v-for="item in setsStore"
                                    :key="'set-'+item.id"
                                >
                                    {{ item.name }}
                                </a-select-option>
                                <a-select-option
                                    v-if="metricsStore.length"
                                    key="metrics"
                                >
                                    Физиологические данные
                                </a-select-option>
                            </a-select-opt-group>
                            <a-select-opt-group label="Исследования">
                                <a-select-option
                                    v-for="item in surveysStore"
                                    :key="'survey-'+item.id"
                                >
                                    {{ item.name }}
                                </a-select-option>
                            </a-select-opt-group>
                            <a-select-opt-group label="Опросы">
                                <a-select-option
                                    v-for="item in quizStore"
                                    :key="'quiz-'+item.surveyTypeId"
                                >
                                    {{ item.name }}
                                </a-select-option>
                            </a-select-opt-group>
                        </a-select>
                    </div>

                    <a-button
                        v-if="!nonViewedIndicators"
                        data-test-id="add-indicators"
                        type="primary"
                        :loading="filterInProgress"
                        :disabled="!metricsStore.length && onlyMetrics"
                        @click="addAllIndicators"
                    >
                        <template #icon>
                            <plus-outlined />
                        </template>
                        Добавить показатели
                    </a-button>
                    <div v-if="nonViewedIndicators" />

                    <div>
                        <a-select
                            ref="indicators"
                            v-model:value="selectedIndicators"
                            data-test-id="selected-indicators"
                            mode="multiple"
                            style="width: 100%;"
                            placeholder="Показатель"
                            :show-arrow="false"
                            show-search
                            :filter-option="filterOption"
                            :get-popup-container="(triggerNode) => triggerNode.parentNode"
                            :allow-clear="true"
                            :disabled="!metricsStore.length && onlyMetrics"
                            @deselect="onIndicatorDeselect"
                            @select="onIndicatorSelect"
                        >
                            <a-select-option
                                v-for="item in indicatorsStore"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </div>

                    <a-button
                        v-if="!nonViewedIndicators"
                        data-test-id="replace-indicators"
                        type="primary"
                        :loading="filterInProgress"
                        :disabled="!metricsStore.length && onlyMetrics"
                        @click="replaceAllIndicators"
                    >
                        <template #icon>
                            <retweet-outlined />
                        </template>
                        Заменить показатели
                    </a-button>
                    <div v-if="nonViewedIndicators" />
                </div>

                <div id="setting-panel">
                    <div class="settings">
                        <div class="tollbar-delimeter">
                            <h3>Общие настройки</h3>
                            <div id="toolbar">
                                <a-checkbox
                                    v-if="!onlyMetrics"
                                    v-model:checked="tmpGroupIndicators"
                                    data-test-id="group_by_sets"
                                >
                                    Группировать по наборам
                                </a-checkbox>
                                <div
                                    v-if="!onlyMetrics"
                                    id="last-results-count"
                                >
                                    Значения последних
                                    <a-input
                                        v-model:value="resultDatesCount"
                                        data-test-id="last-results-count"
                                        style="width: 80px"
                                        type="number"
                                    />
                                    исследований
                                </div>
                                <br>
                                <div
                                    id="metric-settings"
                                    :class="!metricsStore.length ? 'disabled-area' : ''"
                                >
                                    Все физ. данные
                                    <a-switch
                                        v-model:checked="tmpLocateClosestMetrics"
                                        data-test-id="metric-settings-switch"
                                        :disabled="onlyMetrics || !metricsStore.length"
                                        class="metric-settings-switch"
                                    />
                                    Находить ближайшие физ. данные
                                </div>
                                <a-button
                                    data-test-id="rebuild-table"
                                    type="primary"
                                    @click="rebuildTable"
                                >
                                    <template #icon>
                                        <table-outlined />
                                    </template>
                                    Перестроить таблицу
                                </a-button>
                            </div>
                        </div>
                    </div>

                    <div class="print-toolbar">
                        <h3>Настройки печати</h3>
                        <a-switch
                            id="first-switch"
                            v-model:checked="printSettings.table"
                            data-test-id="print-table-switch"
                            :disabled="onlyMetrics"
                            @change="setPrintTable"
                        /> +Таблица
                        <a-switch
                            v-if="onlyMetrics"
                            v-model:checked="printSettings.chart"
                            :disabled="onlyMetrics"
                            @change="setPrintChart"
                        /><span v-if="onlyMetrics"> +График</span>
                        <a-switch
                            v-if="!onlyMetrics"
                            v-model:checked="printSettings.perPage"
                            data-test-id="set-page-switch"
                            :disabled="!groupIndicators"
                            @change="setPrintPerPage"
                        /><span v-if="!onlyMetrics"> Набор/лист</span>
                        <a-button
                            data-test-id="print-btn"
                            type="primary"
                            style="float: inherit"
                            @click="monitoringPrint()"
                        >
                            <template #icon>
                                <printer-outlined />
                            </template>
                            Печать
                        </a-button>
                    </div>
                </div>

                <div
                    v-if="!onlyMetrics && data.length"
                    :id="`monitoringTable-${uuid}`"
                    class="monitoringTable"
                >
                    <div class="patient-info-substitution-template" />
                    <div v-if="groupIndicators">
                        <div
                            v-for="(set, index) in Object.keys(groupedData)"
                            :key="set"
                        >
                            <div
                                v-if="index > 0"
                                class="patient-info-substitution-template"
                            />
                            <div :id="`monitoringSet-${uuid}-${set}`">
                                <h2>
                                    {{ groupedData[set].name }}
                                    <a-button
                                        type="default"
                                        size="small"
                                        style="margin-left: 15px;"
                                        @click="monitoringPrint(
                                            `monitoringSet-${uuid}-${set}`,
                                        )"
                                    >
                                        <template #icon>
                                            <printer-outlined />
                                        </template>
                                    </a-button>
                                </h2>

                                <a-table
                                    v-if="data.length"
                                    :ref="addRef"
                                    :columns="indicatorsSetColumns[set]"
                                    :data-source="groupedData[set].indicators"
                                    :pagination="false"
                                    :scroll="scroll"
                                    size="small"
                                    :bordered="true"
                                    row-key="key"
                                    :custom-row="customizeGroupedRow"
                                    :data-ref-id="`table-${set}`"
                                />
                                <div style="margin-top: 15px">
                                    <i
                                        :ref="addRef"
                                        :data-ref-id="`monitoringNorms-${set}`"
                                        class="monitoringNorms"
                                    >
                                        {{ getNormDescription(set) }}
                                    </i>
                                </div>
                            </div>
                            <div
                                v-if="index < Object.keys(groupedData).length - 1"
                                class="pagebreak-substitution-template"
                            />
                        </div>
                    </div>
                    <a-table
                        v-if="!groupIndicators"
                        ref="table"
                        :columns="indicatorsColumns"
                        :data-source="data"
                        :pagination="false"
                        :scroll="scroll"
                        size="small"
                        :bordered="true"
                        row-key="indicatorId"
                        :custom-row="customizeRow"
                    />
                </div>
                <div
                    v-if="!data.length && !spinLoader"
                    class="center-info-msg"
                    style="height: 100%"
                >
                    <div class="x-form-display-field x-form-display-field-default">
                        <div class="dashed-border">
                            За выбранный период результатов нет.
                        </div>
                    </div>
                </div>
                <div
                    v-if="!groupIndicators"
                    id="monitoringNorms"
                    style="margin-top: 15px"
                >
                    <i
                        ref="allNorms"
                        class="monitoringNorms"
                    >
                        {{ getAllNormDescription() }}
                    </i>
                </div>

                <v-chart
                    v-if="showChart"
                    ref="chart"
                    class="monitoring-chart"
                    theme="macarons"
                    :option="chart"
                />
            </a-spin>
        </div>
        <div
            v-if="surveysStore.length == 0 && metricsStore.length == 0 && !spinLoader"
            class="center-info-msg"
            style="height: 100%"
        >
            <div class="x-form-display-field x-form-display-field-default">
                <div class="dashed-border">
                    У этого пациента нет результатов исследований.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ECharts from 'vue-echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
    TooltipComponent,
    LegendScrollComponent,
    ToolboxComponent,
    DataZoomComponent,
    GridComponent,
    MarkPointComponent,
} from 'echarts/components';
import { use } from 'echarts/core';
import 'echarts/theme/macarons';

import { defineComponent, h } from 'vue';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { groupBy, keyBy } from 'lodash';
import PlusOutlined from '@ant-design/icons-vue/PlusOutlined';
import RetweetOutlined from '@ant-design/icons-vue/RetweetOutlined';
import PrinterOutlined from '@ant-design/icons-vue/PrinterOutlined';
import TableOutlined from '@ant-design/icons-vue/TableOutlined';
import SurveyCell from './SurveyCell.vue';

import {
    showErrorNotification,
    useEmitter,
} from '../../utils';

use([
    CanvasRenderer,
    TooltipComponent,
    LegendScrollComponent,
    ToolboxComponent,
    DataZoomComponent,
    GridComponent,
    MarkPointComponent,
    LineChart,
]);

export default defineComponent({
    components: {
        SurveyCell, // eslint-disable-line
        'v-chart': ECharts,
        PlusOutlined,
        RetweetOutlined,
        PrinterOutlined,
        TableOutlined,
    },
    props: {
        patientId: {
            type: Number,
            required: true,
        },
        nonViewedIndicators: {
            type: Boolean,
            default: false,
        },
        onlyMetrics: {
            type: Boolean,
            default: false,
        },
    },
    // NOTE: object emits is not supported in vue3-webcomponent-wrapper
    // emits: {
    //     updated: null,
    // },
    emits: [
        'updated',
        'auth-lost',
    ],

    setup(props, { emit }) {
        const emitter = useEmitter();
        if (emitter) {
            emitter.on('auth-lost', () => emit('auth-lost'));
        }
    },

    data() {
        return {
            groupIndicators: true,
            tmpGroupIndicators: true,
            lastResultsCount: null,
            resultDatesCount: null,
            normWidth: 100,
            otherWidth: 120,
            otherColumnWidthBySet: {
                others: 120,
            },
            normColumnWidthBySet: {
                others: 100,
            },
            spinLoader: false,
            data: [],
            dataDates: [],
            dataDict: {},
            groupedData: {},
            norms: [],
            throughNormList: [],
            pagination: {},
            loading: false,
            columns: [],
            indicatorsStore: [],
            surveysStore: [],
            setsStore: [],
            quizStore: [],
            blankStore: [],
            metricsStore: [],
            selectedSurvey: undefined,
            surveyDateFrom: null,
            surveyDateTo: moment(),
            selectedIndicators: [],
            scroll: {},
            filterInProgress: false,
            printSettings: {
                table: true,
                chart: false,
                perPage: false,
            },
            locateClosestMetrics: false,
            tmpLocateClosestMetrics: false,
            patientName: null,
            patientDateOfBirth: null,
            showChart: false,
            chart: {
                tooltip: {
                    trigger: 'item',
                    formatter(params) {
                        if (params.componentType === 'series') {
                            return `${params.marker + params.data[1]}<br/>${
                                moment(params.data[0]).format('DD.MM.YYYY')}<br/>${
                                params.seriesName}`;
                        }
                        return '';
                    },
                },
                legend: {
                    type: 'scroll',
                    data: [],
                    textStyle: {
                        fontSize: 14,
                    },
                },
                toolbox: {
                    show: true,
                    top: 'bottom',
                    feature: {
                        saveAsImage: { title: 'Сохранить как...', show: true },
                    },
                },
                dataZoom: [{
                    show: true,
                    // yAxisIndex: [0],
                    filterMode: 'none',
                    start: 0,
                    end: 100,
                }, {
                    type: 'inside',
                    // yAxisIndex: [0],
                    filterMode: 'none',
                    start: 1,
                    end: 100,
                }],
                calculable: true,
                xAxis: [{
                    type: 'time',
                    boundaryGap: false,
                    axisTick: { onGap: false },
                    axisLabel: {
                        formatter(value) {
                            return moment(value).format('DD.MM.YYYY');
                        },
                    },
                    axisLine: {
                        onZero: true,
                        onZeroAxisIndex: 0,
                    },
                    data: [],
                }],
                yAxis: [],
                series: [],
            },
            componentRefs: {},
        };
    },
    computed: {
        uuid() {
            return uuid();
        },

        mapOfSets() {
            return keyBy(this.setsStore, 'id');
        },

        patientDateOfBirthRu() {
            if (!this.patientDateOfBirth) {
                return '?';
            }
            const date = this.$moment(this.patientDateOfBirth);
            return date.format('DD.MM.YYYY');
        },

        patientAge() {
            if (!this.patientDateOfBirth) {
                return '?';
            }
            const age = this.$moment().diff(this.patientDateOfBirth, 'years');
            return `${age} ${this.getAgeNounDeclension(age)}`;
        },

        indicatorsColumns() {
            return [{
                title: 'Показатель',
                dataIndex: '[\'indicatorName\']',
                customRender: ({ text }) => {
                    if (!this.$refs.table) {
                        return text;
                    }

                    this.updateIndicatorColumnWidth(this.$refs.table);
                    return text;
                },
            },
            ...this.columns.filter(
                (x, index, arr) => !x.date
                    || !this.lastResultsCount
                    || (arr.length - 2 - index) <= this.lastResultsCount,
            ).map((x) => {
                if (x.dataIndex !== 'lastNorm') {
                    return {
                        ...x,
                        width: this.otherWidth,
                    };
                }
                return {
                    title: 'Норма',
                    width: this.normWidth,
                    dataIndex: 'lastNorm',
                    customRender: ({ text, record }) => {
                        if (!record.invisible && !this.recordIsFilteredOut(record)) {
                            this.updateNormColumnWidth(this.$refs.table, text);
                        }
                        return {
                            children: h(SurveyCell, { value: `<span>${text || ''}</span>` }),
                            attrs: {},
                        };
                    },
                };
            })];
        },

        indicatorsSetColumns() {
            return Object.keys(this.groupedData).reduce((columnsBySet, set) => {
                // eslint-disable-next-line no-param-reassign
                columnsBySet[set] = [{
                    title: 'Показатель',
                    dataIndex: '[\'indicatorName\']',
                    customRender: ({ text }) => {
                        const tableRef = this.componentRefs[`table-${set}`];
                        if (!tableRef) {
                            return text;
                        }
                        this.updateIndicatorColumnWidth(tableRef, set);
                        return text;
                    },
                },
                ...this.columns.filter(
                    (x) => (x.date
                        && this.groupedData[set].dates.indexOf(x.date) > -1
                        && (
                            !this.lastResultsCount
                            || this.groupedData[set].dates.indexOf(x.date) < this.lastResultsCount
                        )
                    ) || !x.date,
                ).map((x) => {
                    if (x.dataIndex !== 'lastNorm') {
                        return {
                            ...x,
                            width: this.otherColumnWidthBySet[set],
                        };
                    }

                    return {
                        title: 'Норма',
                        width: this.normColumnWidthBySet[set],
                        dataIndex: 'lastNorm',
                        customRender: ({ text, record }) => {
                            if (!record.invisible && !this.groupedRecordIsFilteredOut(record)) {
                                const tableRef = this.componentRefs[`table-${set}`];
                                this.updateNormColumnWidth(tableRef && tableRef[0], text, set);
                            }

                            const obj = {
                                children: h(SurveyCell, { value: `<span>${text || ''}</span>` }),
                                attrs: {},
                            };
                            return obj;
                        },
                    };
                })];
                return columnsBySet;
            }, {});
        },
    },
    watch: {
        groupIndicators(val) {
            if (!val) {
                this.setPrintPerPage(false);
            }
        },
        locateClosestMetrics() {
            this.onIndicatorChange(this.selectedIndicators);
        },
        patientId() {
            this.fetchPatientInfo();
        },
    },
    mounted() {
        this.fetch();
        this.fetchPatientInfo();
    },
    updated() {
        setTimeout(() => {
            this.$emit('updated');
        }, 100);
    },
    methods: {
        async fetchPatientInfo() {
            if (!this.patientId) {
                return;
            }

            this.patientName = null;
            this.patientDateOfBirth = null;

            let response;
            try {
                response = await this.$http.get(`/patient/info/${this.patientId}`);
            } catch (err) {
                return;
            }
            const patientInfo = response.data;
            this.patientName = this.getPatientName(patientInfo);
            this.patientDateOfBirth = patientInfo.birth_date;
        },

        async fetch() {
            this.spinLoader = true;
            try {
                const surveyTypes = await this.$http.get('/survey/labs/types', {
                    params: {
                        surveys: 1,
                        withBlanksOnly: 1,
                        deleted: 1,
                        patientId: this.patientId,
                    },
                });
                this.surveysStore = surveyTypes.data;

                const monitoringSets = await this.$http.get('/monitoring/sets', {
                    params: {
                        enabled: 1,
                        patientId: this.patientId,
                    },
                });
                this.setsStore = monitoringSets.data;
                this.otherColumnWidthBySet = this.setsStore.map((set) => set.id).reduce(
                    (map, id) => ({ ...map, [id]: 120 }),
                    { others: 120 },
                );
                this.normColumnWidthBySet = this.setsStore.map((set) => set.id).reduce(
                    (map, id) => ({ ...map, [id]: 100 }),
                    { others: 100 },
                );

                const quizList = await this.$http.get('/patient/surveys/quiz', {
                    params: {
                        patientId: this.patientId,
                    },
                });
                this.quizStore = quizList.data.reduce((acc, quiz) => {
                    const existed = acc.find((t) => t.surveyTypeId === quiz.surveyTypeId);
                    if (!existed) {
                        acc.push({
                            surveyTypeId: quiz.surveyTypeId,
                            name: quiz.surveyType,
                        });

                        quiz.blanks.forEach((blank) => {
                            const exists = this.blankStore.find(
                                (t) => t.surveyBlankId === blank.surveyBlankId,
                            );
                            if (!exists) {
                                this.blankStore.push({
                                    id: `blank${blank.surveyBlankId}`,
                                    surveyBlankId: blank.surveyBlankId,
                                    name: blank.name,
                                    quizId: quiz.surveyTypeId,
                                });
                            }
                        });
                    }
                    return acc;
                }, []);
            } catch (err) {
                // maybe something can still be loaded
            }

            await this.loadPhysicalMetrics();

            this.loadIndicators(true);
        },

        addRef(el) {
            if (!el) {
                return;
            }
            if (el.dataset) {
                this.componentRefs[el.dataset.refId] = el;
                return;
            }

            const tableWrapperElement = el.$el.querySelector('.ant-table');
            if (!tableWrapperElement || !tableWrapperElement.dataset.refId) {
                return;
            }

            this.componentRefs[tableWrapperElement.dataset.refId] = el;
        },

        async loadPhysicalMetrics() {
            try {
                const metricRefs = await this.$http.get('/refs/physical-metrics-indicators');
                if (!metricRefs.data.length) {
                    return;
                }

                const patientMetrics = await this.$http.get(
                    `/patient/${this.patientId}/physical-metrics`,
                );
                if (!patientMetrics.data.length) {
                    return;
                }

                this.metricsStore = metricRefs.data.map((metric) => ({
                    ...metric,
                    metricId: metric.id,
                    id: metric.code,
                }));
            } catch (err) {
                // no access to the metrics
            }
        },

        getAgeNounDeclension(age) {
            if (age % 10 === 1 && age % 100 !== 11) {
                return 'год';
            }
            if (age % 10 >= 2 && age % 10 <= 4 && (age % 100 < 10 || age % 100 >= 20)) {
                return 'года';
            }
            return 'лет';
        },

        setOnlyMetricsMode() {
            this.setPrintTable(false);
            this.setPrintChart(true);
            if (this.metricsStore.length) {
                this.onSurveyChange('metrics');
            }
            const metricIds = this.metricsStore.map((x) => x.code);
            this.selectedIndicators = metricIds;
            this.onIndicatorChange(metricIds);
        },

        getPatientName(patientRecord) {
            const name = `${patientRecord.lastname} ${patientRecord.firstname}`;
            return patientRecord.middlename
                ? `${name} ${patientRecord.middlename}`
                : name;
        },

        loadIndicators(firstLoad = false) {
            this.$http.get('/survey/labs/indicators', {
                params: {
                    patientId: this.patientId,
                },
            }).then((response) => {
                this.indicatorsStore = response.data;
                this.indicatorsStore = this.indicatorsStore.concat(this.metricsStore);
                this.indicatorsStore = this.indicatorsStore.concat(this.blankStore);
                if (firstLoad) {
                    if (this.onlyMetrics) {
                        this.setOnlyMetricsMode();
                    } else if (!this.nonViewedIndicators) {
                        this.getLastIndicators();
                    } else {
                        this.getNonViewedIndicators();
                    }
                }
            }, () => {
                if (this.metricsStore.length) {
                    this.setOnlyMetricsMode();
                }
                this.spinLoader = false;
            });
        },
        getLastIndicators() {
            this.$http.get('/patient/monitoring/labs/indicators/last', {
                params: {
                    patientId: this.patientId,
                },
            }).then((response) => {
                this.spinLoader = false;
                this.selectedIndicators = response.data.indicatorIds;
                if (this.blankStore.length) {
                    this.selectedIndicators = this.selectedIndicators.concat(
                        response.data.blankIds.reduce((acc, b) => `blank${b}`, []),
                    );
                }
                if (response.data.surveyTypeId) {
                    this.onSurveyChange(response.data.surveyTypeId);
                } else if ((response.data.indicatorIds && response.data.indicatorIds.length > 0)
                        || (response.data.blankIds && response.data.blankIds.length > 0)
                ) {
                    this.onIndicatorChange(this.selectedIndicators);
                }
            }).catch((err) => {
                showErrorNotification(err.response.data.msg);
            });
        },
        getNonViewedIndicators() {
            this.$http.get('/patient/monitoring/non-viewed/indicator-types', {
                params: {
                    patientId: this.patientId,
                },
            }).then((response) => {
                this.spinLoader = false;
                if (response.data.indicators.length) {
                    this.selectedSurvey = response.data.sets.map((x) => `set-${x}`);
                    this.onSurveyChange(this.selectedSurvey, response.data.indicators);
                }
            }).catch((err) => {
                showErrorNotification(err.response.data.msg);
            });
        },
        resultsCountFormatter(value) {
            const parsedNum = Number.parseInt(value, 10);
            const isValid = !Number.isNaN(parsedNum) && parsedNum >= 0;
            if (value === null) {
                return null;
            }
            if (value !== '' && isValid && value < 999) {
                return parsedNum;
            }
            return 0;
        },
        rebuildTable() {
            this.spinLoader = true;
            setTimeout(() => {
                this.resetColumnWidth();
                this.groupIndicators = this.tmpGroupIndicators;
                this.locateClosestMetrics = this.tmpLocateClosestMetrics;
                const sets = Object.keys(this.groupedData);
                for (let i = 0; i < sets.length; i += 1) {
                    this.groupedData[sets[i]].norms = {};
                    const normRef = this.componentRefs[`monitoringNorms-${sets[i]}`];
                    if (normRef) {
                        while (normRef.firstChild) {
                            normRef.removeChild(normRef.firstChild);
                        }
                    }
                }

                this.lastResultsCount = this.resultDatesCount;
                this.$nextTick(() => {
                    this.spinLoader = false;
                });
            }, 0);
        },
        getNormDescription(set) {
            this.$nextTick(() => {
                const normKeys = Object.keys(this.groupedData[set].norms);
                if (!normKeys.length) {
                    return;
                }
                const normRef = this.componentRefs[`monitoringNorms-${set}`];
                if (!normRef) {
                    return;
                }

                while (normRef.firstChild) {
                    normRef.removeChild(normRef.firstChild);
                }
                let normKeysIndex = 0;
                while (normKeysIndex < normKeys.length) {
                    const normTag = document.createElement('span');
                    normRef.append(normTag);
                    normKeysIndex += 1;
                }

                for (let i = 0; i < normKeys.length; i += 1) {
                    if (!this.groupedData[set].norms[normKeys[i]]) {
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                    if (!this.lastResultsCount
                        || this.groupedData[set].dates.indexOf(
                            this.groupedData[set].norms[normKeys[i]].date,
                        ) < this.lastResultsCount
                    ) {
                        const normTag = normRef.childNodes[
                            this.groupedData[set].norms[normKeys[i]].num - 1];
                        const numTag = document.createElement('strong');
                        numTag.append(`${this.groupedData[set].norms[normKeys[i]].num} - `);
                        if (this.groupedData[set].norms[normKeys[i]].num !== 1) {
                            normTag.append(', ');
                        }
                        normTag.append(numTag);
                        normTag.append(this.groupedData[set].norms[normKeys[i]].name);
                    }
                }
            });
        },
        getAllNormDescription() {
            this.$nextTick(() => {
                const normKeys = Object.keys(this.norms);
                if (!normKeys.length) {
                    return;
                }
                const normRef = this.$refs.allNorms;
                if (!normRef) {
                    return;
                }

                while (normRef.firstChild) {
                    normRef.removeChild(normRef.firstChild);
                }
                let normKeyIndex = 0;
                while (normKeyIndex < normKeys.length) {
                    const normTag = document.createElement('span');
                    normRef.append(normTag);
                    normKeyIndex += 1;
                }
                for (let i = 0; i < normKeys.length; i += 1) {
                    if (!this.lastResultsCount
                        || this.dataDates.length <= this.lastResultsCount
                        || this.dataDates.indexOf(this.norms[normKeys[i]].date)
                            >= (this.dataDates.length - this.lastResultsCount)
                    ) {
                        const normTag = normRef.childNodes[this.norms[normKeys[i]].num - 1];
                        const numTag = document.createElement('strong');
                        numTag.append(`${this.norms[normKeys[i]].num} - `);
                        if (this.norms[normKeys[i]].num !== 1) {
                            normTag.append(', ');
                        }
                        normTag.append(numTag);
                        normTag.append(this.norms[normKeys[i]].name);
                    }
                }
            });
        },

        onSurveyChange(value, defaultIndicators) {
            this.selectedSurvey = value;

            if (!Array.isArray(value)) {
                this.updateIndicatorsStore(value);
                return;
            }

            // NOTE: the rest of the handler is for getNonViewedIndicators loading case
            this.selectedIndicators = [];
            if (defaultIndicators.length && Number.isInteger(defaultIndicators[0])) {
                this.selectedIndicators = defaultIndicators;
            }

            const mapOfSelectedIndicators = {};
            value.forEach((selectedValue) => {
                const setMatch = selectedValue.match(/(set)-(\d+)/);
                if (!setMatch || !setMatch.length) {
                    return;
                }
                const cleanValue = parseInt(setMatch[2], 10);
                const set = this.mapOfSets[cleanValue];
                if (!set || !set.surveyIndicators) {
                    return;
                }

                set.surveyIndicators.forEach((indicator) => {
                    if (!mapOfSelectedIndicators[indicator.id]) {
                        mapOfSelectedIndicators[indicator.id] = true;
                    }
                });
            });

            this.selectedIndicators = Object.keys(mapOfSelectedIndicators).map(
                (id) => parseInt(id, 10),
            );

            this.onIndicatorChange(this.selectedIndicators);
        },

        updateIndicatorsStore(value) {
            this.filterInProgress = true;

            if (!value) {
                this.$http.get('/survey/labs/indicators', {
                    params: {
                        patientId: this.patientId,
                    },
                }).then((response) => {
                    this.indicatorsStore = response.data;
                    this.indicatorsStore = this.indicatorsStore.concat(this.metricsStore);
                    this.indicatorsStore = this.indicatorsStore.concat(this.blankStore);
                    this.filterInProgress = false;
                }).catch((err) => {
                    showErrorNotification(err.response.data.msg);
                });
                return;
            }
            if (value === 'metrics') {
                this.indicatorsStore = this.metricsStore;
                this.filterInProgress = false;
                return;
            }
            const matches = value.match(/(.+)-(\d+)/);
            const cleanValue = matches[2] * 1;
            const set = this.setsStore.find((s) => s.id === cleanValue);
            if (matches[1] === 'set' && set && set.surveyIndicators) {
                this.indicatorsStore = set.surveyIndicators;
                this.filterInProgress = false;
            } else if (matches[1] === 'quiz') {
                this.indicatorsStore = this.blankStore.filter(
                    (blank) => blank.quizId === cleanValue,
                );
                this.filterInProgress = false;
            } else {
                this.$http.get('/survey/labs/indicators', {
                    params: {
                        surveyTypeId: cleanValue,
                        patientId: this.patientId,
                    },
                }).then((response) => {
                    this.indicatorsStore = response.data;
                    this.filterInProgress = false;
                }).catch((err) => {
                    showErrorNotification(err.response.data.msg);
                });
            }
        },
        filterOption(input, option) {
            if (!option.children || !option.children.length || !option.children[0].children) {
                return false;
            }
            return option.children[0].children.toLowerCase().indexOf(
                input.toLowerCase(),
            ) >= 0;
        },
        saveLastFilterValues(indicatorIds) {
            const metricsIndicators = [];
            const labIndicators = [];
            const blankIndicators = [];
            const ids = Array.isArray(indicatorIds)
                ? indicatorIds
                : [];
            for (let i = 0; i < ids.length; i += 1) {
                const testId = ids[i].match && ids[i].match(/blank(\d+)/);
                if (testId) {
                    blankIndicators.push(parseInt(testId[1], 10));
                } else {
                    const metric = this.metricsStore.find((x) => x.code === ids[i]);
                    if (metric) {
                        metricsIndicators.push(metric.metricId);
                    } else {
                        labIndicators.push(ids[i]);
                    }
                }
            }
            this.$http.put('/patient/monitoring/labs/indicators/last', {
                patientId: this.patientId,
                indicatorIds: labIndicators,
                surveyTypeId: null,
                blankIds: blankIndicators,
                metricIds: metricsIndicators,
            }).catch((err) => {
                showErrorNotification(err.response.data.msg);
            });
        },

        resetColumnWidth() {
            this.normWidth = 100;
            this.otherWidth = 120;
            Object.keys(this.otherColumnWidthBySet).forEach((set) => {
                this.otherColumnWidthBySet[set] = 120;
                this.normColumnWidthBySet[set] = 100;
            });
        },

        clearTableData() {
            this.showChart = false;
            this.data = [];
            this.dataDates = [];
            this.groupedData = {};
            this.dataDict = {};
            this.norms = {};
            this.throughNormList = [];
            this.columns = [];
            this.scroll = {};

            this.lastResultsCount = null;
            this.resultDatesCount = null;
            this.printSettings.perPage = false;

            this.resetColumnWidth();
        },
        addAllIndicators() {
            const allIndicators = this.selectedIndicators;
            for (let i = 0; i < this.indicatorsStore.length; i += 1) {
                if (allIndicators.indexOf(this.indicatorsStore[i].id) < 0) {
                    allIndicators.push(this.indicatorsStore[i].id);
                }
            }
            this.selectedIndicators = allIndicators;
            this.onIndicatorChange(allIndicators);
        },
        replaceAllIndicators() {
            this.selectedIndicators = [];
            this.addAllIndicators();
        },
        setPrintTable(checked) {
            this.printSettings.table = !!checked;
        },
        setPrintChart(checked) {
            this.printSettings.chart = !!checked;
        },
        setPrintPerPage(checked) {
            this.printSettings.perPage = !!checked;
        },
        createPrintWindow(prtHtml, chartHtml) {
            let thFontSize = 21 - this.columns.length;

            if (thFontSize > 12) {
                thFontSize = 12;
            } else if (thFontSize < 9) {
                thFontSize = 9;
            }
            const fontSize = 10;

            const WinPrint = window.document.open('', '', 'left=0,top=0,width=900,toolbar=0,scrollbars=0,status=0');
            /* eslint-disable no-useless-escape */
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                <script src="https://use.fontawesome.com/2f70b673e2.js"><\/script>
                <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/ant-design-vue@1.1.6/dist/antd.min.css?_dc=1568383472870">
                <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap&subset=cyrillic,cyrillic-ext" rel="stylesheet">
            </head>
            <body>
                <style>
                @media print {
                    .pagebreak {
                        clear: both;
                        page-break-after: always;
                    }
                    .patient-info-substitution-template {
                        display: grid;
                        grid-template-columns: auto 70px;
                    }
                    .patient-info-substitution-template::after, .patient-info-substitution-template::before {
                        display: none;
                    }
                }
                .ant-table-row.hidden {
                    display: none;
                }
                .ant-btn {
                    display: none;
                }
                .ant-table-small {
                    border: none;
                }
                .ant-table table {
                    border-color: #000;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                    font-family: Tahoma, "sans-serif";
                    color: #000;
                }
                .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th {
                    background: #fafafa;
                    padding: 4px;
                    font-size: ${thFontSize}px;
                    border: 1px solid #000;
                }
                
                .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td {
                    padding: 4px;
                    font-size: ${fontSize}px;
                    border: 1px solid #000;
                }
                .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th:first-child,
                .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th:nth-last-child(1), 
                .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th:nth-last-child(2) {
                    font-size: ${fontSize}px;
                }
                .ant-table-thead>tr:first-child>th:first-child,
                .ant-table-thead>tr:first-child>th:last-child,
                .ant-table-small.ant-table-bordered .ant-table-tbody>tr>td:last-child, 
                .ant-table-small.ant-table-bordered .ant-table-thead>tr>th:last-child {
                    border-radius: inherit;
                }
                .ant-table-small.ant-table-bordered .ant-table-tbody>tr>td:last-child, 
                .ant-table-small.ant-table-bordered .ant-table-thead>tr>th:last-child {
                    border-right: 1px solid #000;
                }
                .ant-table-small>.ant-table-content .ant-table-placeholder, 
                .ant-table-small>.ant-table-content .ant-table-row:last-child td {
                    border-bottom: inherit;
                }
                .patient-name {
                    margin-bottom: 40px; 
                    float: left;
                    font-weight: 500;
                    font-family: 'Roboto', sans-serif;
                }
                .monitoringNorms {
                    font-family: 'Roboto', sans-serif;
                }
                </style>
                ${prtHtml}
                <img src="${chartHtml}" width="900" style="margin-top: 20px"/>
            </body>
            </html>`);
            /* eslint-enable no-useless-escape */
            WinPrint.document.close();
            WinPrint.focus();

            (function f() {
                WinPrint.onload = () => {
                    setTimeout(() => {
                        WinPrint.print();
                        WinPrint.close();
                    }, 200);
                };
            }());
        },
        monitoringPrint(set) {
            const defaultId = `monitoringTable-${this.uuid}`;
            const patientInfoTemplate = `
                <div class="patient-info-substitution-template">
                    <h2 class="patient-name">
                        ${this.patientName}
                        <br>
                        Дата рождения: ${this.patientDateOfBirthRu} (${this.patientAge})
                    </h2>
                    <img src="/resources/impulse_tree.png" width="70" style="float: right"/>
                </div>`;
            let chartHtml = '';
            if (this.printSettings.chart && this.$refs.chart) {
                chartHtml = this.$refs.chart.$el.getElementsByTagName('canvas')[0].toDataURL('image/png');
            }
            let prtHtml = '';
            if (this.printSettings.table) {
                const table = document.getElementById(set || defaultId);
                if (!table) {
                    return;
                }
                prtHtml = table.innerHTML;

                // NOTE: getting rid of printSettings.perPage in the template
                // its change causes component rerender for unknown reason
                if (set) {
                    prtHtml = `${patientInfoTemplate}${prtHtml}`;
                } else {
                    const patientInfoRegExp = this.printSettings.perPage
                        ? /<div class="patient-info-substitution-template"><\/div>/g
                        : /<div class="patient-info-substitution-template"><\/div>/;
                    const pageBreakRegExp = /<div class="pagebreak-substitution-template"><\/div>/g;
                    prtHtml = prtHtml.replace(
                        patientInfoRegExp,
                        patientInfoTemplate,
                    ).replace(
                        pageBreakRegExp,
                        this.printSettings.perPage
                            ? '<div class="pagebreak"></div>'
                            : '',
                    );
                }

                if (!this.groupIndicators) {
                    const norms = document.getElementById('monitoringNorms');
                    prtHtml += norms.innerHTML;
                }
            } else if (this.printSettings.chart) {
                prtHtml = patientInfoTemplate;
            }
            this.createPrintWindow(prtHtml, chartHtml);
        },

        customizeRow(record) {
            return record.invisible || this.recordIsFilteredOut(record) ? {
                class: 'hidden',
            } : {};
        },

        recordIsFilteredOut(record) {
            if (!this.lastResultsCount) {
                return false;
            }
            return this.dataDates.every((date, index) => {
                // examine only last `lastResultsCount` dates
                if (index < this.dataDates.length - this.lastResultsCount) {
                    return true;
                }
                return !record[date];
            });
        },

        customizeGroupedRow(record) {
            return record.invisible || this.groupedRecordIsFilteredOut(record) ? {
                class: 'hidden',
            } : {};
        },

        groupedRecordIsFilteredOut(record) {
            if (!this.lastResultsCount) {
                return false;
            }
            const dates = record.groupSetId
                ? this.groupedData[record.groupSetId].dates
                : this.groupedData.others.dates;
            return dates.every((date, index) => {
                // examine only first `lastResultsCount` dates (sorted earlier?)
                if (index >= this.lastResultsCount) {
                    return true;
                }
                return !record[date];
            });
        },

        updateIndicatorColumnWidth(tableRef, set) {
            const colgroup = tableRef.$el.getElementsByTagName('colgroup');
            if (!colgroup || !colgroup.length || colgroup[0].children.length < 2) {
                return;
            }

            const cgChildren = colgroup[0].children;
            const normColCssWidth = cgChildren[cgChildren.length - 2].style.width;
            if (!normColCssWidth) {
                return;
            }

            const normColWidth = parseInt(normColCssWidth.match(/(\d+(\.\d+)?)px/)[1], 10);
            const wholeWidth = parseInt(tableRef.$el.clientWidth, 10);
            const columnsCount = cgChildren.length;
            // NOTE: (length - 2 + 2.75) * x + norm = width;
            // indicator name has 2.75 times width; norm column has some fixed width
            let mainWidth = (wholeWidth - normColWidth) / (columnsCount - 2 + 2.75);
            // NOTE: a hack to stop infinite width updates
            mainWidth = Math.floor(mainWidth);

            // NOTE: data update in a component render function
            if (!set) {
                this.otherWidth = mainWidth;
            } else {
                this.otherColumnWidthBySet[set] = mainWidth;
            }

            const width = `${mainWidth * 2.75}px`;
            const indicatorColumn = cgChildren[0];
            indicatorColumn.style.width = width;
            indicatorColumn.style.minWidth = width;
        },

        updateNormColumnWidth(tableRef, text, set) {
            const maxWidth = 450;
            const charWidth = 10;
            if (text) {
                const longestSubstring = text.split('<br>').map(
                    (s) => s.trim(),
                ).reduce(
                    (longest, next) => (longest.length > next.length ? longest : next),
                );
                let width = set
                    ? this.normColumnWidthBySet[set]
                    : this.normWidth;
                const textWidth = longestSubstring.length * charWidth;
                if (textWidth < maxWidth && textWidth > width) {
                    width = textWidth;
                }
                if (textWidth > maxWidth) {
                    width = maxWidth;
                }

                if (!set) {
                    this.normWidth = width;
                } else {
                    this.normColumnWidthBySet[set] = width;
                }
            }

            if (!tableRef) {
                return;
            }

            const colgroup = tableRef.$el.getElementsByTagName('colgroup');
            if (!colgroup || !colgroup.length) {
                return;
            }

            const normCol = colgroup[0].children[colgroup[0].children.length - 2];
            if (normCol) {
                const width = set
                    ? this.normColumnWidthBySet[set]
                    : this.normWidth;
                normCol.style.width = `${width}px`;
                normCol.style.minWidth = `${width}px`;
            }
        },

        onIndicatorSelect() {
            this.onIndicatorChange(this.selectedIndicators);
        },

        onIndicatorDeselect(value) {
            const record = this.data.find((x) => x.indicatorId === value);
            if (!record) {
                return;
            }
            record.invisible = true;

            Object.keys(this.groupedData).forEach((key) => {
                const indicator = this.groupedData[key].indicators.find(
                    (x) => x.indicatorId === value,
                );
                if (indicator) {
                    indicator.invisible = true;
                }
            });
        },

        addUniqueDates(fromIndicatorList, to) {
            const dateProperties = fromIndicatorList.reduce(
                (allProperties, indicatorRecord) => allProperties.concat(
                    Object.keys(indicatorRecord),
                ),
                [],
            ).filter(
                (property) => this.dataDates.includes(property),
            );
            const notYetAddedDates = dateProperties.filter(
                (prop) => !to.includes(prop),
            );
            to.push(...notYetAddedDates);
        },

        onIndicatorChange(values) {
            this.spinLoader = true;
            if (!this.nonViewedIndicators && !this.onlyMetrics) {
                this.saveLastFilterValues(this.selectedIndicators);
            }

            if (values.length > 0) {
                const metricsIndicators = [];
                const labIndicators = [];
                const blankIndicators = [];
                const quizIndicators = [];
                for (let i = 0; i < values.length; i += 1) {
                    if (values[i].match) {
                        const testId = values[i].match(/blank(\d+)/);
                        if (testId && testId.length) {
                            blankIndicators.push(parseInt(testId[1], 10));

                            const blank = this.blankStore.find(
                                (b) => b.surveyBlankId === parseInt(testId[1], 10),
                            );
                            if (blank && quizIndicators.indexOf(blank.quizId) < 0) {
                                quizIndicators.push(blank.quizId);
                            }
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                    }
                    const metric = this.metricsStore.find((x) => x.code === values[i]);
                    if (metric) {
                        metricsIndicators.push(metric.metricId);
                    } else {
                        labIndicators.push(values[i]);
                    }
                }
                const params = {
                    indicatorIds: JSON.stringify(labIndicators),
                    metricsIndicators: JSON.stringify(metricsIndicators),
                    quizIndicators: JSON.stringify(quizIndicators),
                    blankIndicators: JSON.stringify(blankIndicators),
                    patientId: this.patientId,
                    surveyDateFrom: this.surveyDateFrom ? this.surveyDateFrom.format('YYYY-MM-DD') : null,
                    surveyDateTo: this.surveyDateTo ? this.surveyDateTo.format('YYYY-MM-DD') : null,
                };
                if (this.locateClosestMetrics) {
                    params.locateClosestMetrics = true;
                }
                this.$http.get('/patient/monitoring/labs/indicators', {
                    params,
                }).then((response) => {
                    this.groupedData = {};
                    const renderContent = ({ text }) => ({
                        children: h(SurveyCell, { value: `<span>${text || ''}</span>` }),
                    });

                    this.throughNormList = response.data.throughNormList;

                    this.dataDates = response.data.dates;
                    this.data = response.data.indicators;
                    this.dataDict = groupBy(this.data, 'indicatorId');
                    this.data.sort((a, b) => a.indicatorName.localeCompare(b.indicatorName));

                    let set;
                    let cleanValue;
                    if (this.selectedSurvey && !Array.isArray(this.selectedSurvey)) {
                        cleanValue = this.selectedSurvey.match(/.+-(\d+)/);
                        if (cleanValue && cleanValue.length) {
                            set = this.setsStore.find((s) => s.id === cleanValue[1] * 1);
                        }
                    }
                    const dictKeys = Object.keys(this.dataDict);
                    for (let i = 0; i < this.setsStore.length; i += 1) {
                        if (!this.setsStore[i]
                            || !this.setsStore[i].surveyIndicators
                            || !this.setsStore[i].surveyIndicators.length
                            || (set && cleanValue && cleanValue.length
                            && parseInt(cleanValue[1], 10) !== this.setsStore[i].id
                            && dictKeys.length === set.surveyIndicators.length)
                        ) {
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                        for (let j = 0; j < this.setsStore[i].surveyIndicators.length; j += 1) {
                            const indicator = this.setsStore[i].surveyIndicators[j];

                            if (Array.isArray(this.selectedSurvey)
                                && !this.selectedSurvey.includes(`set-${this.setsStore[i].id}`)
                            ) {
                                // eslint-disable-next-line no-continue
                                continue;
                            }
                            if (this.dataDict[indicator.id]) {
                                if (!this.groupedData[this.setsStore[i].id]) {
                                    this.groupedData[this.setsStore[i].id] = {
                                        name: this.setsStore[i].name,
                                        dates: [],
                                        indicators: [],
                                        norms: {},
                                    };
                                }
                                this.groupedData[this.setsStore[i].id].indicators.push(
                                    ...this.dataDict[indicator.id].map((indicatorRecord) => ({
                                        ...indicatorRecord,
                                        key: `${indicatorRecord.indicatorId}_${indicatorRecord.biomaterialId}`,
                                        groupSetId: this.setsStore[i].id,
                                    })),
                                );

                                this.addUniqueDates(
                                    this.dataDict[indicator.id],
                                    this.groupedData[this.setsStore[i].id].dates,
                                );
                                this.dataDict[indicator.id].distributed = true;
                            }
                        }
                        if (this.groupedData[this.setsStore[i].id]
                            && this.groupedData[this.setsStore[i].id].dates.length
                        ) {
                            this.groupedData[this.setsStore[i].id].dates.sort((a, b) => {
                                if (this.$moment(a) < this.$moment(b)) {
                                    return 1;
                                }
                                return -1;
                            });

                            this.groupedData[this.setsStore[i].id].indicators.sort(
                                (a, b) => a.indicatorName.localeCompare(b.indicatorName),
                            );
                        }
                    }

                    if (dictKeys.length) {
                        this.groupedData.metrics = {
                            name: 'Физиологические данные',
                            dates: [],
                            indicators: [],
                            norms: {},
                        };
                        this.groupedData.others = {
                            name: 'Другие показатели',
                            dates: [],
                            indicators: [],
                            norms: {},
                        };
                        this.groupedData.quizzes = {
                            name: 'Опросы',
                            dates: [],
                            indicators: [],
                            norms: {},
                        };
                        for (let i = 0; i < dictKeys.length; i += 1) {
                            if (this.dataDict[dictKeys[i]].distributed) {
                                // eslint-disable-next-line no-continue
                                continue;
                            }
                            if (this.metricsStore.length) {
                                const metric = this.metricsStore.find(
                                    (x) => x.code === dictKeys[i],
                                );
                                if (metric) {
                                    this.groupedData.metrics.indicators.push(
                                        ...this.dataDict[dictKeys[i]].map((indicatorRecord) => ({
                                            ...indicatorRecord,
                                            key: indicatorRecord.indicatorId,
                                        })),
                                    );
                                    this.addUniqueDates(
                                        this.dataDict[dictKeys[i]],
                                        this.groupedData.metrics.dates,
                                    );
                                    // eslint-disable-next-line no-continue
                                    continue;
                                }
                            }

                            if (dictKeys[i].match && this.blankStore.length) {
                                const matches = dictKeys[i].match(/(\D+)(\d+)/i);
                                if (matches && (matches[1] === 'quiz' || matches[1] === 'blank')) {
                                    this.groupedData.quizzes.indicators.push(
                                        ...this.dataDict[dictKeys[i]].map((indicatorRecord) => ({
                                            ...indicatorRecord,
                                            key: indicatorRecord.indicatorId,
                                        })),
                                    );
                                    this.addUniqueDates(
                                        this.dataDict[dictKeys[i]],
                                        this.groupedData.quizzes.dates,
                                    );
                                    // eslint-disable-next-line no-continue
                                    continue;
                                }
                            }

                            this.groupedData.others.indicators.push(
                                ...this.dataDict[dictKeys[i]].map((indicatorRecord) => ({
                                    ...indicatorRecord,
                                    key: `${indicatorRecord.indicatorId}_${indicatorRecord.biomaterialId}`,
                                })),
                            );

                            this.addUniqueDates(
                                this.dataDict[dictKeys[i]],
                                this.groupedData.others.dates,
                            );
                        }
                        if (!this.groupedData.metrics.indicators.length) {
                            delete this.groupedData.metrics;
                        }
                        if (!this.groupedData.quizzes.indicators.length) {
                            delete this.groupedData.quizzes;
                        }
                        if (!this.groupedData.others.indicators.length) {
                            delete this.groupedData.others;
                        } else {
                            this.groupedData.others.indicators.sort(
                                (a, b) => a.indicatorName.localeCompare(b.indicatorName),
                            );
                            this.groupedData.others.dates.sort((a, b) => {
                                if (this.$moment(a) < this.$moment(b)) {
                                    return 1;
                                }
                                return -1;
                            });
                        }
                    }

                    this.loading = false;
                    const columns = [];
                    this.columns = columns;

                    const series = [];
                    const yAxis = [];
                    const categories = [];
                    const legend = [];
                    let minValue = 0; let maxValue = 0; let
                        yAxisIndex = 0;
                    for (let i = 0; i < response.data.indicators.length; i += 1) {
                        if (response.data.indicators[i].isResultDecimal) {
                            legend.push(response.data.indicators[i].indicatorName);
                        }

                        const seriesData = [];
                        let localMin = 0; let
                            localMax = 0;
                        for (let j = 0; j < response.data.dates.length; j += 1) {
                            if (i === 0) {
                                const date = moment(response.data.dates[j]).format('DD.MM.YY');
                                categories.push(date);
                                columns.push({
                                    title: date,
                                    date: response.data.dates[j],
                                    dataIndex: `['${response.data.dates[j]}']['value']`,
                                    width: this.otherWidth,
                                    // eslint-disable-next-line no-loop-func
                                    customRender: ({ text, record: row }) => {
                                        let value = text || '&mdash;';
                                        if (row[response.data.dates[j]]
                                            && row[response.data.dates[j]].normFootnote
                                        ) {
                                            const normObject = this.throughNormList.find(
                                                (x) => x.value === parseInt(
                                                    row[response.data.dates[j]].normFootnote,
                                                    10,
                                                ),
                                            );

                                            if (this.groupedData[row.groupSetId]) {
                                                if (normObject
                                                    && !this.groupedData[row.groupSetId].norms[
                                                        row[response.data.dates[j]].normFootnote]
                                                ) {
                                                    this.groupedData[row.groupSetId].norms[
                                                        row[response.data.dates[j]].normFootnote
                                                    ] = {
                                                        num: Object.keys(this.groupedData[
                                                            row.groupSetId].norms).length + 1,
                                                        date: response.data.dates[j],
                                                        name: normObject.name.replace(/\s*<br\/{0,1}>\s*/gm, ' '),
                                                    };
                                                    value += `<sup>${this.groupedData[row.groupSetId].norms[row[response.data.dates[j]].normFootnote].num}</sup>`;
                                                } else if (this.groupedData[row.groupSetId].norms[
                                                    row[response.data.dates[j]].normFootnote]
                                                ) {
                                                    value += `<sup>${this.groupedData[row.groupSetId].norms[row[response.data.dates[j]].normFootnote].num}</sup>`;
                                                }
                                            } else if (normObject
                                                && !this.norms[
                                                    row[response.data.dates[j]].normFootnote]
                                            ) {
                                                this.norms[
                                                    row[response.data.dates[j]].normFootnote
                                                ] = {
                                                    num: Object.keys(this.norms).length + 1,
                                                    date: response.data.dates[j],
                                                    name: normObject.name.replace(/\s*<br\/{0,1}>\s*/gm, ' '),
                                                };
                                                value += `<sup>${this.norms[row[response.data.dates[j]].normFootnote].num}</sup>`;
                                            } else if (this.norms[
                                                row[response.data.dates[j]].normFootnote]
                                            ) {
                                                this.norms[
                                                    row[response.data.dates[j]].normFootnote
                                                ].date = response.data.dates[j];
                                                value += `<sup>${this.norms[row[response.data.dates[j]].normFootnote].num}</sup>`;
                                            }
                                        }
                                        if (row[response.data.dates[j]]
                                            && row[response.data.dates[j]].interpretation.length > 0
                                        ) {
                                            value += row[response.data.dates[j]].interpretation;
                                        }
                                        return {
                                            children: h(SurveyCell, { value }),
                                            attrs: {},
                                        };
                                    },
                                });
                            }

                            if (response.data.indicators[i].isResultDecimal) {
                                let value = null;
                                if (response.data.indicators[i][response.data.dates[j]]
                                    && response.data.indicators[i][response.data.dates[j]].value
                                ) {
                                    value = response.data.indicators[i][
                                        response.data.dates[j]].value * 1;

                                    if (localMax < value) {
                                        localMax = value;
                                    }
                                    if (localMin > value) {
                                        localMin = value;
                                    }
                                }
                                seriesData.push([response.data.dates[j], value, value]);
                            }
                        }
                        if (maxValue < localMax) {
                            maxValue = localMax;
                        }
                        if (minValue > localMin) {
                            minValue = localMin;
                        }

                        if (response.data.indicators[i].isResultDecimal) {
                            series.push({
                                name: response.data.indicators[i].indicatorName,
                                type: 'line',
                                smooth: true,
                                symbolSize: 10,
                                label: {
                                    show: true,
                                    position: ['top', 'left', 'right', 'bottom'][Math.floor(Math.random() * 3)],
                                    color: '#000',
                                    offset: [Math.floor(Math.random() * 5), 0],
                                },
                                markPoint: {
                                    data: seriesData.length === 1 ? [{
                                        name: 'maximum',
                                        type: 'max',
                                    }] : [],
                                },
                                yAxisIndex,
                                data: seriesData,
                            });
                            yAxisIndex += 1;

                            yAxis.push({
                                type: 'value',
                                boundaryGap: ['20%', '20%'], // не работает при min|max
                                min: localMin,
                                max: localMax,
                                splitNumber: 5,
                                axisLabel: {
                                    show: false,
                                },
                                splitLine: {
                                    show: i === 0,
                                },
                                splitArea: {
                                    show: i === 0,
                                },
                            });
                        }
                    }

                    for (let a = 0; a < yAxis.length; a += 1) {
                        const scale = (maxValue - minValue) / (yAxis[a].max - yAxis[a].min);

                        yAxis[a].scale = scale;
                        if (minValue / scale > yAxis[a].min) {
                            minValue = yAxis[a].min / (1 / scale);
                        }
                        if (maxValue / scale < yAxis[a].max) {
                            maxValue = yAxis[a].max / (1 / scale);
                        }
                    }
                    for (let a = 0; a < yAxis.length; a += 1) {
                        if (yAxis[a].max - yAxis[a].min !== 0) {
                            yAxis[a].min = minValue / yAxis[a].scale;
                            yAxis[a].max = maxValue / yAxis[a].scale;
                        }
                        if (yAxis[a].max === 0 && yAxis[a].min === 0) {
                            yAxis[a].min = minValue;
                            yAxis[a].max = maxValue;
                        }
                    }

                    this.columns.push({
                        title: 'Норма',
                        width: this.normWidth,
                        dataIndex: 'lastNorm',
                    }, {
                        title: 'Ед.изм.',
                        dataIndex: 'measureValue',
                        width: this.otherWidth,
                        customRender: renderContent,
                    });
                    if (yAxis.length) {
                        this.chart.series = series;
                        this.chart.xAxis[0].data = categories;
                        this.chart.yAxis = yAxis;
                        this.chart.legend.data = legend;
                    }
                    this.showChart = !!yAxis.length;
                    this.spinLoader = false;
                }, () => {
                    // error callback
                    this.spinLoader = false;
                });
            } else {
                this.clearTableData();
                this.spinLoader = false;
            }
        },
    },
});
</script>

<style lang="scss">
.ant-calendar-picker-container {
    z-index: 20000;
}
.monitoringTable {
    h2 {
        margin-top: 15px;
    }

    max-width: 2000px;

    span.filled-blank-value {
        font-size: 18px;
    }

    .patient-info-substitution-template,
    .pagebreak-substitution-template {
        display: none;
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
        word-break: break-word;
    }

    .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0;
    }

    .ant-table table {
        table-layout: fixed;
    }

    .ant-table-row {
        &.hidden {
            display: none;
        }
    }
}

#setting-panel {
    h2 {
        margin-top: 15px;
    }
    display: grid;
    grid-template-columns: 50% 50%;

    .settings, .print-toolbar {
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        padding: 10px 0;
        margin-bottom: 10px;
        height: auto;
    }
    .settings h3 {
        margin-bottom: 0px;
    }
    .print-toolbar h3 {
        margin-bottom: 15px;
        line-height: 17px;
    }
    .settings {
        padding-right: 20px;
        #toolbar {
            label, #last-results-count, .ant-btn, #metric-settings {
                display: inline-block;
                margin-right: 20px;
                margin-top: 15px;
            }
            label, #last-results-count {
                border-right: 1px solid #e8e8e8;
                padding-right: 10px;
            }
            #metric-settings.disabled-area {
                color: rgba(0, 0, 0, 0.25);
            }
            .metric-settings-switch {
                background-color: #1890ff;
            }
        }
        .ant-checkbox-wrapper {
            line-height: 32px;
        }

    }
    .print-toolbar {
        .ant-btn-primary, .ant-switch {
            display: inline-block;
            margin-left: 20px;
        }
        #first-switch {
            margin-left: 0;
        }
    }
    .tollbar-delimeter {
        border-right: 1px solid #e8e8e8;
    }
}

.center-info-msg {
    .x-form-display-field {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dashed-border {
        border: #d0d0d0 dashed 2px;
        border-radius: 10px;
        padding: 10px 20px;
        display: inline-block;
        margin: auto;
        text-align: center;
    }
}

.ant-calendar-picker-icon:after, .ant-select-selection__clear:before {
    display: none;
}

.monitoring-chart {
    width: 100%;
    margin-top: 20px;
    height: 400px;
}

.monitoring__indicator-settings {
    .ant-select-clear {
        top: 15px;
    }
}
</style>
