<template>
    <div class="calendar-widget">
        <div
            v-if="workHours.length"
            ref="main"
            @wheel="scrollFunction"
        >
            <div
                v-for="x in workHours"
                :key="x"
                :style="timeItemStyle()"
                class="time-item"
            >
                <div class="time-background">
                    {{ x }}
                </div>
                <div class="empty-time" />
            </div>

            <div
                v-for="a in appointmentsList"
                :key="a.id"
            >
                <div
                    :class="[{
                        selected: selectedAppointmentId === a.id,
                    }, 'status-' + a.stateCode.toLowerCase()]"
                    :style="appointmentStyle(a.blockHeight, a.positionTop)"
                    class="appointment"
                    @click="selectAppointment(a, a.patientId)"
                />
                <div
                    :style="appointmentTextStyle(a.blockHeight, a.positionTop)"
                    class="appointment-text"
                    @click="selectAppointment(a, a.patientId)"
                >
                    <span
                        :style="{fontSize: (a.blockHeight*scale >= 36 ? 12 : 10)+'px'}"
                        class="appointment-status"
                    >
                        {{ a.stateName }}
                    </span>

                    <a-tooltip
                        v-if="a.isRemote"
                    >
                        <template #title>
                            {{ getClippedUrl(a.remoteUrl) }}
                        </template>

                        <video-camera-outlined
                            class="remote-appointment-icon"
                            @click="onRemoteIconClick($event, a)"
                        />
                    </a-tooltip>

                    <div class="appointment-text-text">
                        <span
                            :style="appointmentSpanStyle(a.blockHeight)"
                            class="appointment-span"
                        >{{ a.timeStart }} - {{ a.timeEnd }}</span>
                        <span
                            :style="appointmentSpanStyle(a.blockHeight)"
                            class="appointment-span"
                        >{{ a.patientShortName }}</span>
                    </div>
                </div>
            </div>

            <div
                :style="currentTimeLineStyle()"
                class="current-time"
            >
                {{ currentTime.format("HH:mm") }}
            </div>
        </div>
        <a-empty
            v-else
            :image="emptyImage"
        >
            <template #description>
                Расписание отсутствует.
            </template>
        </a-empty>
    </div>
</template>

<script>
import VideoCameraOutlined from '@ant-design/icons-vue/VideoCameraOutlined';
import {
    Tooltip as ATooltip,
    Empty as AEmpty,
    message as antMessage,
} from 'ant-design-vue';

export default {
    name: 'Calendar',
    components: {
        AEmpty,
        ATooltip,
        VideoCameraOutlined,
    },
    props: {
        appointmentsList: {
            type: Array,
            required: true,
        },
        timeStart: {
            type: Object,
            default: null,
        },
        timeEnd: {
            type: Object,
            default: null,
        },
        selectedAppointmentId: {
            type: Number,
            required: true,
        },
    },

    emits: [
        'select-appointment',
    ],

    data() {
        return {
            scale: 1,
            scaleStep: 1.1,
            timeCellHeight: 60,
            currentTime: this.$moment(),
            statesDictionary: {
                STARTED: 'Начат',
                SCHEDULED: 'Запланирован',
                FINISHED: 'Завершён',
            },
            currentTimeInterval: null,
            emptyImage: AEmpty.PRESENTED_IMAGE_SIMPLE,
        };
    },

    computed: {
        workHours() {
            if (!this.timeEnd || !this.timeStart) {
                return [];
            }
            return Array.from({
                length: this.timeEnd.diff(this.timeStart, 'hours') + 1,
            }, (_, hours) => this.$moment(this.timeStart)
                .add(hours, 'hours').format('HH:mm'));
        },
    },

    mounted() {
        this.currentTimeInterval = setInterval(() => {
            this.currentTime = this.$moment();
        }, 60000);
    },

    beforeUnmount() {
        if (this.currentTimeInterval) {
            clearInterval(this.currentTimeInterval);
        }
    },

    methods: {
        isUrl(remoteUrl) {
            return !!remoteUrl.match(
                /(https?:\/\/(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?:\/\/(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})/,
            );
        },

        getClippedUrl(remoteUrl) {
            if (this.isUrl(remoteUrl)) {
                return remoteUrl.length > 20
                    ? `${remoteUrl.slice(0, 20)}...`
                    : remoteUrl;
            }
            return remoteUrl;
        },

        onRemoteIconClick(event, { remoteUrl }) {
            event.stopPropagation();
            if (this.isUrl(remoteUrl)) {
                window.open(remoteUrl);
                return;
            }
            navigator.clipboard.writeText(remoteUrl);
            antMessage.success('Текст скопирован в буфер обмена.');
        },

        timeItemStyle() {
            return {
                height: `${(this.timeCellHeight * this.scale) || 0}px`,
                transition: 'height 100ms ease-out',
            };
        },
        appointmentStyle(h, top) {
            return {
                top: `${(top * this.scale) || 0}px`,
                height: `${(h * this.scale) || 0}px`,
                transition: 'all 100ms ease-out',
            };
        },
        appointmentTextStyle(h, top) {
            return {
                top: `${(top * this.scale) || 0}px`,
                lineHeight: h * this.scale >= 36 ? 1.5 : `${(h * this.scale) / 3}px`,
                marginTop: h * this.scale >= 36 ? 0 : `${(h * this.scale) / 5}px`,
                transition: 'all 100ms ease-out',
            };
        },
        appointmentSpanStyle(h) {
            return {
                display: h * this.scale >= 36 ? 'block' : 'inline-block',
                marginLeft: `${h * this.scale >= 36 ? 0 : 10}px`,
                fontSize: `${h * this.scale >= 36 ? 12 : 10}px`,
                transition: 'font-size 100ms ease-out',
            };
        },
        appointmentAvatarStyle(h) {
            return {
                marginTop: `${h * this.scale >= 36 ? 8 : 0}px`,
                transition: 'margin-top 100ms ease-out',
            };
        },
        currentTimeLineStyle() {
            return {
                top: `${this.$moment.duration(this.currentTime.diff(this.timeStart)).asMinutes() * this.scale}px`,
                transition: 'top 100ms ease-out',
            };
        },
        scrollFunction(e) {
            e.preventDefault();
            if (e.deltaY < 0) {
                this.scale = (((this.scale * this.scaleStep) * 10) || 0) / 10;
            } else if (this.scale > 1) {
                this.scale = (((this.scale / this.scaleStep) * 10) || 0) / 10;
            }
            return false;
        },
        selectAppointment(appointment, patientId) {
            this.$emit('select-appointment', appointment, patientId);
        },
    },
};
</script>

<style lang="scss">
.calendar-widget {
    overflow: auto;
    scroll-behavior: smooth;
    position: relative;
}

.time-item, .current-time {
    font-size: 10px;
    text-align: left;

    .time-background {
        width: 38px;
        height: 100%;
        padding-left: 5px;
        border-top: #fff solid 1px;
        background-color: #a9de75;
        float: left;
    }
    .empty-time {
        border-top: 1px solid #e0e0e0;
        float: right;
        width: calc(100% - 38px);
    }
}
.current-time {
    border-top: #ff4040 dashed 1px;
    color: #ff4040;
    position: absolute;
    width: 100%;
    text-align: center;
}
.appointment {
    position: absolute;
    border-right: #096 solid 3px;
    border-bottom: #fff dashed 1px;
    opacity: 0.4;

    left: 38px;
    text-align: right;
    cursor: pointer;
    width: calc(100% - 38px);
}
.appointment.status-scheduled {
    background-color: #b3e241;
}
.appointment.status-started {
    background-color: #f9ff00;
}
.appointment.status-finished {
    background-color: #11d1ff;
}
.selected {
    opacity: 1;
}
.appointment-text, .ant-tooltip-inner {
    .appointment-text-text {
        text-align: right;
        float: right;
    }
    .ant-avatar {
        float: right;
        margin-top: 8px;
        margin-left: 8px;
        border: #046e66 solid 1px;
    }
    .appointment-span {
        display: block;
    }
    .remote-appointment-icon {
        margin-left: 5px;
    }
}
.ant-tooltip-inner {
    .ant-avatar {
        margin-top: 4px;
        margin-left: 15px;
    }
}
.appointment-text {
    position: absolute;
    line-height: 3px;
    cursor: pointer;

    padding-left: 43px;
    padding-right: 5px;
    width: 100%;

    span {
        font-size: 12px;
        color: #046e66;
    }
}
</style>
