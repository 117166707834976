<template>
    <a-modal
        :visible="true"
        :mask-closable="false"
        centered
        class="template-completion"
        title="Укажите даты/подтипы назначений"
        @cancel="onClose"
    >
        <div
            v-for="assignment in assignmentsOfInterest"
            :key="assignment.id"
        >
            {{ assignment.name }}
            <a-form>
                <a-form-item
                    v-if="requiresStartDate(assignment)"
                    label="Дата назначения"
                >
                    <a-date-picker
                        :value="startDates[assignment.id]"
                        :allow-clear="false"
                        :disabled-date="dateIsDisabled"
                        format="DD.MM.YYYY"
                        class="template-completion__date-field"
                        @change="(value) => startDates[assignment.id] = value"
                    />
                </a-form-item>
                <a-form-item
                    v-if="requiresSubtype(assignment)"
                    label="Подтип"
                >
                    <a-select
                        :value="subtypes[assignment.id]"
                        @change="(value) => subtypes[assignment.id] = value"
                    >
                        <a-select-option
                            v-for="subtype in medicationMap[
                                assignment.medicationCourse.clinicMedicationId].subtypes"
                            :key="subtype.id"
                        >
                            {{ subtype.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </div>
        <template #footer>
            <a-button
                key="back"
                @click="onClose"
            >
                Отмена
            </a-button>
            <a-button
                type="primary"
                @click="handleSave"
            >
                Сохранить
            </a-button>
        </template>
    </a-modal>
</template>

<script>
/* eslint-disable no-param-reassign */
import { defineComponent } from 'vue';
import { keyBy } from 'lodash';

export default defineComponent({
    props: {
        assignments: {
            type: Array,
            required: true,
        },
        medicationTypes: {
            type: Array,
            required: true,
        },
    },
    emits: ['done', 'cancel'],
    data() {
        return {
            startDates: {},
            subtypes: {},
        };
    },
    computed: {
        assignmentsOfInterest() {
            return this.assignments.filter(
                (x) => this.requiresStartDate(x) || this.requiresSubtype(x),
            );
        },

        medicationMap() {
            return keyBy(this.medicationTypes, 'id');
        },
    },

    created() {
        if (!this.assignmentsOfInterest.length) {
            this.$emit('done', this.assignments);
            return;
        }
        this.initializeDataCollections();
    },

    methods: {
        initializeDataCollections() {
            this.startDates = this.assignments.reduce((map, assignment) => {
                if (this.requiresStartDate(assignment)) {
                    map[assignment.id] = this.$moment();
                }
                return map;
            }, {});

            this.subtypes = this.assignments.reduce((map, assignment) => {
                if (this.requiresSubtype(assignment)) {
                    const medication = this.medicationMap[
                        assignment.medicationCourse.clinicMedicationId];
                    map[assignment.id] = medication.subtypes[0].id;
                }
                return map;
            }, {});
        },

        requiresStartDate(assignment) {
            return assignment.ruleCode === 'MANUAL_DATE';
        },

        requiresSubtype(assignment) {
            const { medicationCourse } = assignment;
            if (!medicationCourse) {
                return false;
            }
            const medication = this.medicationMap[medicationCourse.clinicMedicationId];
            const { medicationSubtypeId } = medicationCourse;
            return !medicationSubtypeId
                && (!medication.schemeId || medication.schemeId === 3);
        },

        dateIsDisabled(date) {
            return date <= this.$moment().endOf('day').add(-1, 'days');
        },

        onClose() {
            this.$emit('cancel');
        },

        handleSave() {
            const result = this.assignments.map((assignment) => {
                const saveDate = this.requiresStartDate(assignment);
                const saveSubtype = this.requiresSubtype(assignment);
                if (!saveDate && !saveSubtype) {
                    return assignment;
                }

                const completed = { ...assignment };
                if (saveDate) {
                    completed.datePlanStart = this.startDates[assignment.id];
                }
                if (saveSubtype) {
                    return {
                        ...completed,
                        medicationCourse: {
                            ...completed.medicationCourse,
                            medicationSubtypeId: this.subtypes[assignment.id],
                        },
                    };
                }
                return completed;
            });

            this.$emit('done', result);
        },
    },
});
</script>

<style lang="scss">
.template-completion {
    .ant-form-item-label {
        text-align: left;
        line-height: 20px;
        white-space: inherit;
    }
}

.template-completion__date-field {
    width: 100%;
}
</style>
