<template>
    <div>
        <a-alert
            v-if="isLoadingFailed"
            :closable="false"
            type="error"
            message="Произошла ошибка во время загрузки истории!"
        />
        <div v-else-if="isLoading">
            <a-spin />
            <span>
                Подождите... Выполняется загрузка истории...
            </span>
        </div>
        <div v-else-if="isEmpty">
            История отсутствует.
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isEmpty: {
            type: Boolean,
            required: true,
            default: false,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        isLoadingFailed: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss">
</style>
