/* eslint-disable no-param-reassign */
import * as ACTIONS from '../actionTypes';

import { getPatients } from '../../api';

const getCurrentUserPatientsOnlyFlag = (switchValue) => {
    if (['true', 'false'].includes(switchValue)) {
        return switchValue === 'true';
    }
    return true;
};

const patientListModule = {
    namespaced: true,

    state: {
        patientList: [],
        fetching: false,
        error: null,
        pagination: {
            pageSize: 35,
            current: 1,
            total: 0,
        },
        currentUserPatientsOnly: getCurrentUserPatientsOnlyFlag(
            localStorage.onlyStaffPatients,
        ),
    },

    mutations: {
        [ACTIONS.PATIENT_LIST_SET_CURRENT_USER_PATIENTS_ONLY](state, payload) {
            state.currentUserPatientsOnly = payload.value;
        },
        [ACTIONS.PATIENT_LIST_SET_PAGINATION](state, payload) {
            state.pagination = {
                ...state.pagination,
                ...payload.pagination,
            };
        },
        [ACTIONS.PATIENT_LIST_FETCH_START](state) {
            state.fetching = true;
            state.error = null;
        },
        [ACTIONS.PATIENT_LIST_FETCH_DONE](state, payload) {
            state.fetching = false;
            state.patientList = payload.data;
        },
        [ACTIONS.PATIENT_LIST_FETCH_ERROR](state, payload) {
            state.fetching = false;
            state.error = payload.error;
        },
    },

    actions: {
        setCurrentUserPatientsOnly({ commit }, value) {
            commit(ACTIONS.PATIENT_LIST_SET_CURRENT_USER_PATIENTS_ONLY, { value });
        },

        setPagination({ commit }, pagination) {
            commit(ACTIONS.PATIENT_LIST_SET_PAGINATION, { pagination });
        },

        async fetchPatients({ commit }, {
            filters,
            pagination,
            search,
        }) {
            commit(ACTIONS.PATIENT_LIST_FETCH_START);
            try {
                const { data } = await getPatients({
                    filters,
                    pagination,
                    search,
                });
                const { data: patientList, count } = data;
                commit(ACTIONS.PATIENT_LIST_FETCH_DONE, {
                    data: patientList.map((patient) => ({
                        stateCode: patient.stateCode,
                        patientId: patient.patientId,
                        name: patient.name,
                        previewFileId: patient.previewFileId,
                        lastProtocolTimeCreate: patient.lastProtocolTimeCreate,
                        pinned: Boolean(patient.pinned),
                        currentUserPatient: Boolean(patient.currentUserPatient),
                        createdByUser: Boolean(patient.createdByUser),
                    })),
                });

                const totalPages = Math.ceil(count / pagination.pageSize) || 1;
                const activePage = pagination.current > totalPages
                    ? totalPages
                    : pagination.current;
                commit(ACTIONS.PATIENT_LIST_SET_PAGINATION, {
                    pagination: {
                        current: activePage,
                        total: count,
                    },
                });
            } catch (error) {
                commit(ACTIONS.PATIENT_LIST_FETCH_ERROR, { error });
            }
        },
    },
};

export default patientListModule;
