<template>
    <div>
        <a-modal
            :visible="true"
            centered
            :mask-closable="false"
            class="medication-instruction"
            :width="540"
            @cancel="handleCancel"
        >
            <div
                v-if="medication"
                class="no-title-modal-inner"
            >
                <a-form
                    ref="form"
                    layout="inline"
                    :model="form"
                    :rules="formRules"
                >
                    <!-- "inline" form makes div inline? Short names displayed inline -->
                    <div style="width: 100%">
                        {{ medication.name }}
                    </div>
                    <a-form-item
                        v-if="medicationAcceptsSubtype(medication)"
                        class="subtype-form-item"
                        label="Подтип препарата"
                        :colon="false"
                        name="medicationSubtypeId"
                    >
                        <a-select
                            v-model:value="form.medicationSubtypeId"
                            :allow-clear="!medicationRequiresSubtype(medication)"
                            :dropdown-match-select-width="false"
                            :get-popup-container="(triggerNode) => triggerNode.parentNode"
                            :disabled="disabled"
                            @change="onSubtypeChange"
                        >
                            <a-select-option
                                v-if="!patientId"
                                :value="0"
                            >
                                Указать при назначении
                            </a-select-option>
                            <a-select-option
                                v-for="subtype in medication.subtypes"
                                :key="subtype.id"
                                :disabled="subtype.deleted"
                            >
                                {{ subtype.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item
                        v-if="!isAssignmentTemplateMode
                            && medication.allowDonorBiomaterial
                            && (!medicationRequiresSubtype(medication) || form.medicationSubtypeId)"
                        class="subtype-form-item"
                        :colon="false"
                        name="donorId"
                    >
                        <donor-select
                            v-if="!disabled || form.donorId"
                            :donor-id="form.donorId"
                            :patient-id="patientId"
                            :disabled="disabled"
                            @change="(e) => form.donorId = e"
                        />
                        <span v-else>
                            Нет
                        </span>
                    </a-form-item>
                    <a-form-item
                        v-if="isAssignmentTemplateMode
                            && medication.allowDonorBiomaterial
                            && (!medicationRequiresSubtype(medication) || form.medicationSubtypeId)"
                        class="subtype-form-item"
                        label="Донор б/м"
                        :colon="false"
                        name="skipDonor"
                    >
                        <a-select
                            v-model:value="form.skipDonor"
                            placeholder="Нет"
                            :get-popup-container="(triggerNode) => triggerNode.parentNode"
                        >
                            <a-select-option
                                :value="0"
                            >
                                Спрашивать
                            </a-select-option>
                            <a-select-option
                                :value="1"
                            >
                                Не спрашивать
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item
                        v-if="templates.length
                            && (form.medicationSubtypeId
                                || !medicationRequiresSubtype(medication))"
                        class="subtype-form-item"
                    >
                        <template-select
                            :course="newCourse"
                            :medication-map="medicationMap"
                            :subtypes-map="subtypesMap"
                            :disabled="disabled"
                            @change="onTemplateSelect"
                        />
                    </a-form-item>
                    <a-form-item
                        v-if="medication
                            && (form.medicationSubtypeId || !medicationRequiresSubtype(medication))"
                        label="Длительность курса"
                        :colon="false"
                        :disabled="disabled"
                        name="daysTakePeriod"
                    >
                        <a-input-number
                            v-model:value="daysTakePeriod"
                            :min="1"
                            :disabled="disabled"
                            @blur="onDaysTakePeriodBlur"
                            @focus="onDaysTakePeriodFocus"
                        /> дней
                    </a-form-item>
                    <a-form-item
                        v-if="medication
                            && (form.medicationSubtypeId || !medicationRequiresSubtype(medication))"
                        label="Количество типов приёмов"
                        :colon="false"
                        name="numberOfDoses"
                    >
                        <a-input-number
                            v-model:value="form.numberOfDoses"
                            :disabled="disabled"
                            :min="null"
                            :max="100"
                            @change="onNumberOfDosesChange"
                        />
                    </a-form-item>

                    <a-form
                        v-for="(dose, doseIndex) in doseForms"
                        ref="doseForms"
                        :key="doseIndex"
                        :model="dose"
                        style="width: 100%"
                    >
                        <div class="recipe-time-head">
                            <span class="recipe-time-title">
                                {{ `${doseIndex + 1}й приём` }}
                            </span>
                            <div class="intake-dependency-select">
                                <a-form-item
                                    label="Частота приёма"
                                    name="daysTakeRegularity"
                                    :rules="doseFormRules[doseIndex].daysTakeRegularity"
                                >
                                    <a-input-number
                                        v-model:value="dose.daysTakeRegularity"
                                        :formatter="(value) => (value ? `${+value} дней` : '')"
                                        :parser="(value) => (
                                            value ? value.replace(/[^0-9]/g, '') : '')"
                                        :min="1"
                                        :disabled="disabled"
                                        class="intake-dependency"
                                    />
                                </a-form-item>
                                <a-form-item
                                    label="Смещение от начала курса"
                                    name="daysTakeOffset"
                                    :rules="doseFormRules[doseIndex].daysTakeOffset"
                                >
                                    <a-input-number
                                        v-model:value="dose.daysTakeOffset"
                                        :formatter="(value) => (`${+value || 0} дней`)"
                                        :parser="(value) => (
                                            value ? value.replace(/[^0-9]/g, '') : '')"
                                        :min="0"
                                        :max="Math.max(form.daysTakePeriod - 1, 0)"
                                        :disabled="disabled"
                                        class="intake-dependency"
                                    />
                                </a-form-item>
                                <a-form-item
                                    label="Принять"
                                    name="intakeDependencyId"
                                    :rules="doseFormRules[doseIndex].intakeDependencyId"
                                >
                                    <a-select
                                        v-model:value="dose.intakeDependencyId"
                                        :dropdown-match-select-width="false"
                                        :get-popup-container="
                                            (triggerNode) => triggerNode.parentNode"
                                        :disabled="disabled"
                                        class="intake-dependency"
                                    >
                                        <a-select-option
                                            v-for="dependency in intakeDependency"
                                            :key="dependency.id"
                                        >
                                            {{ dependency.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </div>
                        </div>
                        <div class="recipe-time-row">
                            <a-form-item
                                label="Дозировка"
                                :colon="false"
                                name="medicationPortion"
                                :rules="doseFormRules[doseIndex].medicationPortion"
                            >
                                <a-input-number
                                    v-model:value="dose.medicationPortion"
                                    :min="0.01"
                                    :max="99999.99"
                                    :step="0.1"
                                    :disabled="disabled"
                                />
                            </a-form-item>
                            <a-form-item
                                v-if="dose.intakeDependencyId
                                    && takingFieldsVisible[dose.intakeDependencyId].dayPeriodId"
                                style="float: right"
                                label="Время суток"
                                :colon="false"
                                name="dayPeriodId"
                                :rules="doseFormRules[doseIndex].dayPeriodId"
                            >
                                <a-select
                                    v-model:value="dose.dayPeriodId"
                                    :disabled="disabled"
                                    :dropdown-match-select-width="false"
                                    :get-popup-container="
                                        (triggerNode) => triggerNode.parentNode"
                                    style="width: 160px"
                                >
                                    <a-select-option
                                        v-for="period in dayPeriods"
                                        :key="period.id"
                                    >
                                        {{ period.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>

                            <a-form-item
                                v-if="dose.intakeDependencyId
                                    && takingFieldsVisible[dose.intakeDependencyId].sleepState"
                                style="float: right"
                                label="Время"
                                :colon="false"
                                name="sleepState"
                                :rules="doseFormRules[doseIndex].sleepState"
                            >
                                <a-select
                                    v-model:value="dose.sleepState"
                                    :disabled="disabled"
                                    :dropdown-match-select-width="false"
                                    :get-popup-container="
                                        (triggerNode) => triggerNode.parentNode"
                                    style="width: 200px"
                                >
                                    <a-select-option
                                        v-for="state in sleepState"
                                        :key="state.id"
                                    >
                                        {{ state.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>

                            <a-form-item
                                v-if="dose.intakeDependencyId
                                    && takingFieldsVisible[
                                        dose.intakeDependencyId
                                    ].mealId"
                                style="float: right"
                                name="mealId"
                                :rules="doseFormRules[doseIndex].mealId"
                            >
                                <a-select
                                    v-model:value="dose.mealId"
                                    :dropdown-match-select-width="false"
                                    :disabled="disabled"
                                    :get-popup-container="
                                        (triggerNode) => triggerNode.parentNode"
                                    class="recipe-action-select"
                                >
                                    <a-select-option
                                        v-for="action in dayActions"
                                        :key="action.id"
                                    >
                                        {{ action.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item
                                v-if="dose.intakeDependencyId
                                    && takingFieldsVisible[
                                        dose.intakeDependencyId
                                    ].takeDirection"
                                style="float: right"
                                name="takeDirection"
                                :rules="doseFormRules[doseIndex].takeDirection"
                            >
                                <a-select
                                    v-model:value="dose.takeDirection"
                                    :dropdown-match-select-width="false"
                                    :disabled="disabled"
                                    :get-popup-container="
                                        (triggerNode) => triggerNode.parentNode"
                                    class="recipe-action-select"
                                >
                                    <a-select-option
                                        v-for="direction in takeDirections"
                                        :key="direction.id"
                                    >
                                        {{ direction.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>
                        <div
                            class="recipe-time-row"
                            style="text-align: right"
                        >
                            <div />
                            <a-form-item
                                v-if="dose.takeDirection != 0
                                    && dose.intakeDependencyId
                                    && takingFieldsVisible[
                                        dose.intakeDependencyId
                                    ].minutesDeviation"
                                name="minutesDeviation"
                                :rules="doseFormRules[doseIndex].minutesDeviation"
                            >
                                <span v-if="dose.takeDirection === -1">
                                    за
                                </span>
                                <span v-if="dose.takeDirection === 1">
                                    через
                                </span>
                                <a-input-number
                                    v-model:value="dose.minutesDeviation"
                                    :min="0"
                                    :disabled="disabled"
                                /> мин
                            </a-form-item>
                        </div>
                        <div class="recipe-time-row">
                            <div />
                            <a-checkbox
                                v-model:checked="dose.onEmptyStomach"
                                :disabled="disabled"
                            >
                                Натощак
                            </a-checkbox>
                        </div>
                        <div
                            v-if="patientId && intakeTimeIsVisible(dose)"
                            class="recipe-time-row"
                        >
                            <a-form-item
                                label="Время приёма в будни"
                                :colon="false"
                            >
                                <a-time-picker
                                    :value="calculateTime(dose).timeTake"
                                    :disabled="true"
                                    placeholder=""
                                    format="HH:mm"
                                    class="medication-intake-time-picker"
                                />
                            </a-form-item>
                            <a-form-item
                                label="в выходные"
                                :colon="false"
                            >
                                <a-time-picker
                                    :value="calculateTime(dose).timeTakeHoliday"
                                    :disabled="true"
                                    placeholder=""
                                    format="HH:mm"
                                    class="medication-intake-time-picker"
                                />
                            </a-form-item>
                        </div>
                        <div
                            class="recipe-time-row"
                        >
                            <div />
                            <a-form-item
                                label="Форма"
                                :colon="false"
                                class="intake-form-item"
                                name="intakeFormId"
                                :rules="doseFormRules[doseIndex].intakeFormId"
                            >
                                <a-select
                                    v-model:value="dose.intakeFormId"
                                    :dropdown-match-select-width="false"
                                    :get-popup-container="
                                        (triggerNode) => triggerNode.parentNode"
                                    :disabled="disabled"
                                    class="recipe-action-select"
                                >
                                    <a-select-option
                                        v-for="intakeForm in intakeForms"
                                        :key="intakeForm.id"
                                    >
                                        {{ intakeForm.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>
                        <div
                            class="recipe-time-row"
                        >
                            <div />
                            <a-form-item
                                v-if="dose.intakeDependencyId
                                    && takingFieldsVisible[
                                        dose.intakeDependencyId
                                    ].notification"
                                label="Оповестить о приёме"
                                :colon="false"
                                class="intake-form-item"
                                name="notificationPeriodId"
                                :rules="doseFormRules[doseIndex].notificationPeriodId"
                            >
                                <a-select
                                    v-model:value="dose.notificationPeriodId"
                                    :dropdown-match-select-width="false"
                                    :get-popup-container="
                                        (triggerNode) => triggerNode.parentNode"
                                    :disabled="disabled"
                                    class="recipe-action-select"
                                >
                                    <a-select-option
                                        v-for="notification in intakeNotification"
                                        :key="notification.id"
                                    >
                                        {{ notification.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </div>
                    </a-form>
                </a-form>
            </div>
            <div
                v-else
                class="no-title-modal-inner"
            >
                Загрузка...
            </div>
            <template #footer>
                <a-button
                    v-if="patientId"
                    type="primary"
                    :disabled="disabled"
                    :style="{float: 'left'}"
                    @click="patientScheduleVisible = true"
                >
                    <template #icon>
                        <clock-circle-outlined />
                    </template>
                    Расписание пациента
                </a-button>
                <a-button
                    key="back"
                    @click="handleCancel"
                >
                    Отмена
                </a-button>
                <a-button
                    data-test-id="medication-instruction-save-btn"
                    :disabled="disabled"
                    type="primary"
                    @click.prevent="handleSave"
                >
                    Сохранить
                </a-button>
            </template>
        </a-modal>

        <patient-schedule
            v-if="patientId"
            :patient-id="patientId"
            :visible="patientScheduleVisible"
            @close="patientScheduleVisible = false"
            @update="onPatientTimetableUpdate"
        />
    </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { defineComponent } from 'vue';
import ClockCircleOutlined from '@ant-design/icons-vue/ClockCircleOutlined';
import { keyBy, flatten } from 'lodash';
import PatientSchedule from './PatientSchedule.vue';
import DonorSelect from './DonorSelect.vue';
import TemplateSelect from './TemplateSelect.vue';
import { showErrorNotification } from '../../../utils';

export default defineComponent({
    components: {
        PatientSchedule,
        TemplateSelect,
        DonorSelect,
        ClockCircleOutlined,
    },
    props: {
        medications: {
            type: Array,
            default: () => [],
        },
        patientId: {
            type: Number,
            default: null,
        },
        course: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        cancel: null,
        'create-medication-course': null,
    },
    data() {
        const message = ' ';
        return {
            medication: null,
            daysTakePeriodHasFocus: false,
            daysTakePeriod: null,
            form: {
                daysTakePeriod: null,
                donorId: null,
                skipDonor: 0,
                medicationSubtypeId: null,
                numberOfDoses: 0,
            },
            formRules: {
                daysTakePeriod: [{ required: true, message }],
                donorId: [{ required: false }],
                skipDonor: [{ required: true }],
                medicationSubtypeId: [{ required: false, message }],
                numberOfDoses: [{
                    type: 'number',
                    required: true,
                    message,
                    validator: (rule, value) => (
                        value > 0 ? Promise.resolve() : Promise.reject()
                    ),
                }],
            },
            doseForms: [],
            dayActions: [],
            dayPeriods: [],
            intakeDependency: [],
            intakeNotification: [],
            intakeForms: [],
            patientDayActions: [],
            commonDayActions: [],
            takeDirections: [
                { id: -1, name: 'до' },
                { id: 0, name: 'во время' },
                { id: 1, name: 'после' },
            ],
            sleepState: [
                { id: 4, name: 'при подъёме' }, // 4 - утро в GET /day/times/
                { id: 7, name: 'при отходе ко сну' }, // 7 - ночь в GET /day/times/
            ],
            patientScheduleVisible: false,
            takingFieldsVisible: {
                1: { // в любое время
                    medicationPortion: true,
                    minutesDeviation: false,
                    takeDirection: false,
                    mealId: false,
                    dayPeriodId: false,
                    sleepState: false,
                    timeTake: false,
                    notification: true,
                    onEmptyStomach: false,
                },
                2: { // от приема пищи
                    medicationPortion: true,
                    minutesDeviation: true,
                    takeDirection: true,
                    mealId: true,
                    dayPeriodId: false,
                    sleepState: false,
                    timeTake: true,
                    notification: true,
                    onEmptyStomach: false,
                },
                3: { // от времени суток
                    medicationPortion: true,
                    minutesDeviation: false,
                    takeDirection: false,
                    mealId: false,
                    dayPeriodId: true,
                    sleepState: false,
                    timeTake: true,
                    notification: true,
                    onEmptyStomach: true,
                },
                4: { // от подъема/отхода ко сну
                    medicationPortion: true,
                    minutesDeviation: false,
                    takeDirection: false,
                    mealId: false,
                    dayPeriodId: false,
                    sleepState: true,
                    timeTake: true,
                    notification: true,
                    onEmptyStomach: false,
                },
            },
        };
    },

    computed: {
        newCourse() {
            return this.mapToCourse();
        },

        patientDaysSchedule() {
            if (!this.patientId) {
                return {};
            }

            const patientDayActionsMap = keyBy(this.patientDayActions, 'dayActionId');

            const effectiveDayActions = this.commonDayActions.map((defaultDayAction) => {
                const { dayActionId } = defaultDayAction;
                return patientDayActionsMap[dayActionId]
                    ? patientDayActionsMap[dayActionId]
                    : defaultDayAction;
            });

            return keyBy(effectiveDayActions, 'dayActionId');
        },

        medicationMap() {
            return keyBy(this.medications, 'id');
        },

        subtypesMap() {
            return keyBy(
                flatten(
                    this.medications.map(
                        ({ subtypes }) => subtypes || [],
                    ),
                ),
                'id',
            );
        },

        templates() {
            let subtype = null;
            if (!this.medication) {
                return [];
            }

            if (this.form.medicationSubtypeId) {
                subtype = this.subtypesMap[this.form.medicationSubtypeId];
            }
            return subtype && subtype.recipes.length
                ? subtype.recipes
                : this.medication.recipes;
        },

        isAssignmentTemplateMode() {
            return !this.patientId;
        },

        doseFormRules() {
            const message = ' ';
            return this.doseForms.map(({ intakeDependencyId: depId }) => ({
                intakeDependencyId: [{ required: true, message }],
                medicationPortion: [{ required: true, message }],
                dayPeriodId: [{
                    required: depId && this.takingFieldsVisible[depId].dayPeriodId,
                    message,
                }],
                sleepState: [{
                    required: depId && this.takingFieldsVisible[depId].sleepState,
                    message,
                }],
                mealId: [{
                    required: depId && this.takingFieldsVisible[depId].mealId,
                    message,
                }],
                takeDirection: [{
                    required: depId && this.takingFieldsVisible[depId].takeDirection,
                    message,
                }],
                minutesDeviation: [{
                    required: depId && this.takingFieldsVisible[depId].minutesDeviation,
                    message,
                }],
                daysTakeRegularity: [{ required: true, message }],
                daysTakeOffset: [{ required: true, message }],
                intakeFormId: [{ required: false }],
                notificationPeriodId: [{ required: true, message }],
            }));
        },
    },

    watch: {
        'form.daysTakePeriod': function formDaysTakePeriodWatcher(value) {
            this.daysTakePeriod = value;
            const maxOffsetValue = Math.max(this.daysTakePeriod - 1, 0);
            this.doseForms.forEach((doseForm) => {
                doseForm.daysTakeOffset = Math.min(
                    doseForm.daysTakeOffset,
                    maxOffsetValue,
                );
            });
        },
        course: {
            immediate: true,
            handler(newCourse) {
                if (!newCourse) {
                    this.medication = null;
                    this.clearForms();
                    return;
                }

                if (!newCourse.clinicMedicationId) {
                    this.handleCancel();
                    throw new Error('MedicationInstruction: no clinicMedicationId provided.');
                }

                const subtype = this.subtypesMap[newCourse.clinicMedicationId];
                this.medication = subtype
                    ? this.medicationMap[subtype.parentId]
                    : this.medicationMap[newCourse.clinicMedicationId];

                if (!this.isAssignmentTemplateMode) {
                    const isRequired = this.medicationRequiresSubtype(this.medication);
                    this.formRules.medicationSubtypeId.required = isRequired;
                }
                this.formRules.skipDonor.required = !!this.isAssignmentTemplateMode;

                this.form.daysTakePeriod = newCourse.daysTakePeriod;
                this.form.donorId = newCourse.donorId;
                this.form.skipDonor = newCourse.skipDonor || 0;
                this.form.medicationSubtypeId = newCourse.medicationSubtypeId
                    || (this.isAssignmentTemplateMode ? 0 : null);

                this.fillDailyDoses(newCourse.times);
            },
        },
    },

    async created() {
        this.loadPatientTimetable();
        try {
            this.commonDayActions = (
                await this.$http.get('/common/patient/day/actions/')
            ).data.dayActions;
            this.dayActions = (await this.$http.get('/day/actions/')).data;
            this.dayPeriods = (await this.$http.get('/day/times/')).data;
            this.intakeDependency = (await this.$http.get('/refs/intake/dependency/')).data;
            this.intakeNotification = (await this.$http.get('/refs/intake/notification/')).data;
            this.intakeForms = (await this.$http.get('/refs/intake/forms/')).data;
        } catch (err) {
            showErrorNotification('Не удалось загрузить справочные данные.');
        }
    },

    methods: {
        onDaysTakePeriodBlur() {
            this.daysTakePeriodHasFocus = false;
            this.form.daysTakePeriod = this.daysTakePeriod;

            if (this.$refs.form) {
                this.$refs.form.validate();
            }
        },

        onDaysTakePeriodFocus() {
            this.daysTakePeriodHasFocus = true;
        },

        onPatientTimetableUpdate() {
            this.patientScheduleVisible = false;
            this.loadPatientTimetable();
        },

        loadPatientTimetable() {
            if (!this.patientId) {
                return;
            }
            this.$http.get(`/patient/${this.patientId}/day/actions/`).then((response) => {
                this.patientDayActions = response.data.dayActions;
            }, (err) => showErrorNotification(err.response.data.msg));
        },

        intakeTimeIsVisible(dose) {
            const visibility = this.takingFieldsVisible[dose.intakeDependencyId];
            return dose.intakeDependencyId
                && visibility.timeTake
                && ((visibility.dayPeriodId && dose.dayPeriodId)
                    || (visibility.sleepState && dose.sleepState)
                    || (visibility.mealId && dose.mealId)
                );
        },

        fillDailyDoses(doses) {
            if (!Array.isArray(doses)) {
                this.form.numberOfDoses = 0;
                return;
            }

            this.doseForms = doses.map((dose) => ({
                id: dose.id,
                intakeDependencyId: dose.intakeDependencyId,
                mealId: dose.intakeDependencyId === 2 ? dose.dayActionId : null,
                dayPeriodId: [1, 3].includes(dose.intakeDependencyId) ? dose.dayActionId : null,
                sleepState: dose.intakeDependencyId === 4 ? dose.dayActionId : null,
                medicationPortion: dose.medicationPortion,
                minutesDeviation: dose.minutesDeviation,
                takeDirection: dose.takeDirection,
                intakeFormId: dose.intakeFormId,
                notificationPeriodId: dose.notificationPeriodId,
                onEmptyStomach: !!dose.onEmptyStomach,
                daysTakeRegularity: dose.daysTakeRegularity,
                daysTakeOffset: dose.daysTakeOffset || 0,
            }));
            this.form.numberOfDoses = this.doseForms.length;
        },

        calculateTime(dose) {
            const MORNING_TIME = 4;
            let dayActionId = dose.dayPeriodId || MORNING_TIME;
            if (dose.intakeDependencyId === 4) {
                dayActionId = dose.sleepState;
            } else if (dose.intakeDependencyId === 2) {
                dayActionId = dose.mealId;
            }
            const dayAction = this.patientDaysSchedule[dayActionId];
            return {
                // default is for empty timetable (slow request or an error during one)
                timeTake: dayAction
                    ? this.$moment(dayAction.timeAction || '06:00:00', 'HH:mm:ss') : null,
                timeTakeHoliday: dayAction
                    ? this.$moment(dayAction.timeActionHoliday || '06:00:00', 'HH:mm:ss') : null,
            };
        },

        onTemplateSelect(val) {
            if (!val) {
                this.form.daysTakePeriod = null;
                this.form.numberOfDoses = 0;
                this.onNumberOfDosesChange(0);
                return;
            }
            const template = this.templates.find((x) => x.id === val);

            this.form.daysTakePeriod = template.daysTakePeriod;

            if (Array.isArray(template.times)) {
                // убираем id, если это новое назначение препарата а не курс на редактировании
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                this.fillDailyDoses(template.times.map(({ id, ...rest }) => rest));
            }
        },

        onSubtypeChange(val) {
            if (!val) {
                this.clearForms();
            }
        },

        onNumberOfDosesChange(value) {
            if (value === this.doseForms.length) {
                return;
            }
            if (value < this.doseForms.length) {
                this.doseForms.splice(
                    value - this.doseForms.length,
                    this.doseForms.length,
                );
                return;
            }

            const newDoses = Array.from({ length: value - this.doseForms.length }).map(
                () => ({
                    intakeDependencyId: this.intakeDependency[0].id,
                    dayPeriodId: this.dayPeriods[0].id,
                    mealId: null,
                    sleepState: null,
                    medicationPortion: null,
                    minutesDeviation: null,
                    takeDirection: 0,
                    timeTake: null,
                    timeTakeHoliday: null,
                    onEmptyStomach: null,
                    intakeFormId: this.intakeForms[0].id,
                    notificationPeriodId: this.intakeNotification[0].id,
                    daysTakeOffset: 0,
                }),
            );
            this.doseForms.push(...newDoses);
        },

        clearForms() {
            const defaultSubtypeId = this.isAssignmentTemplateMode ? 0 : null;
            this.form.daysTakePeriod = null;
            this.form.donorId = null;
            this.form.skipDonor = 0;
            this.form.medicationSubtypeId = defaultSubtypeId;
            this.form.numberOfDoses = 0;
            this.doseForms = [];
        },

        handleCancel() {
            this.$emit('cancel');
            this.clearForms();
        },

        mapToCourse() {
            return {
                ...this.course,
                daysTakePeriod: this.form.daysTakePeriod,
                donorId: this.form.donorId,
                skipDonor: this.form.skipDonor,
                medicationSubtypeId: this.form.medicationSubtypeId || null,
                times: this.doseForms.map((dose) => {
                    let dayActionId = dose.dayPeriodId;
                    if (dose.intakeDependencyId === 4) {
                        dayActionId = dose.sleepState;
                    } else if (dose.intakeDependencyId === 2) {
                        dayActionId = dose.mealId;
                    }
                    return {
                        id: dose.id,
                        dayActionId,
                        minutesDeviation: dose.minutesDeviation,
                        takeDirection: dose.takeDirection,
                        medicationPortion: dose.medicationPortion,
                        intakeDependencyId: dose.intakeDependencyId,
                        onEmptyStomach: dose.onEmptyStomach,
                        intakeFormId: dose.intakeFormId,
                        notificationPeriodId: dose.notificationPeriodId,
                        daysTakeRegularity: dose.daysTakeRegularity,
                        daysTakeOffset: dose.daysTakeOffset || 0,
                    };
                }),
            };
        },

        async handleSave() {
            const forms = [this.$refs.form].concat(
                this.$refs.doseForms ? this.$refs.doseForms : [],
            );
            try {
                await Promise.all(forms.map((form) => form.validate()));
            } catch (err) {
                return;
            }

            if (!this.isAssignmentTemplateMode && !this.$refs.doseForms.length) {
                /*
                * LI-1023: a workaround for a bug
                * when dose form is not created on numberOfDoses change
                */
                return;
            }

            const result = this.mapToCourse();
            this.$emit('create-medication-course', result);
        },

        medicationRequiresSubtype(medication) {
            return !medication.schemeId || medication.schemeId === 3;
        },

        medicationAcceptsSubtype(medication) {
            return medication.schemeId !== 1;
        },
    },
});
</script>

<style lang="scss">
.medication-instruction {
    .ant-modal-body {
        padding: 15px 10px 15px 15px;
    }
}
.medication-instruction .no-title-modal-inner {
    .ant-form-item {
        margin: 5px 0;
    }

    .recipe-time-head {
        border-bottom: 1px solid #d9d9d9;
        min-height: 30px;
        padding: 0 0 15px 15px;
        margin-bottom: 10px;
        .ant-card {
            width: 100%;
        }
        .ant-card-head-title {
            padding: 7px 0;
        }
        .recipe-time-title {
            line-height: 32px;
            font-size: 16px;
            font-weight: 500;
        }
        .intake-dependency-select {
            display: flex;
            flex-direction: column;
            margin-left: 20%;
            span.label {
                float: right;
                line-height: 32px;
                margin-right: 10px;
            }
        }
        .intake-dependency {
            width: 200px;
            float: right;
        }
        .ant-row.ant-form-item {
            .ant-form-item-label {
                line-height: 32px;
            }
        }
    }
    .ant-card-body {
        padding: 0px;

        .ant-form-item {
            margin-right: 0;
        }
        .ant-time-picker {
            width: 80px;
        }
    }
    .recipe-time-row {
        margin-bottom: 5px;
        line-height: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .recipe-action-select {
        width: 120px;
        margin-left: 10px;
    }
    .ant-divider-horizontal {
        margin: 15px 0;
    }
    .ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }
    .subtype-form-item {
        display: block;
        .ant-select,
        .ant-input {
            width: 300px;
        }
    }
}
.ant-time-picker.medication-intake-time-picker {
    width: 100px;
    .ant-time-picker-icon::after,
    .ant-time-picker-icon:hover::after {
        content: none;
    }
}
</style>
