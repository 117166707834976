<template>
    <!-- eslint-disable vue/no-v-html -->
    <div>
        <span
            v-if="!editable"
            v-html="value"
        />
        <a-input
            v-if="editable && type.textfield"
            v-model:value="inputValue"
            style="width: 100%;"
            :default-value="value"
        />
        <a-input-number
            v-if="editable && type.numberfield"
            v-model:value="inputValue"
            style="width: 100%;"
            :default-value="parseInt(value, 10)"
        />
        <a-select
            v-if="editable && type.combobox"
            v-model:value="inputValue"
            :default-value="value"
            style="width: 100%;"
            :get-popup-container="(triggerNode) => triggerNode.parentNode"
            @focus="loadStore(columnId)"
        >
            <a-select-option
                v-for="item in store"
                :key="item.id"
            >
                <span v-html="item.name" />
            </a-select-option>
        </a-select>
        <a-select
            v-if="editable && type.tagfield"
            v-model:value="inputValue"
            mode="multiple"
            :default-value="value"
            style="width: 100%;"
            :get-popup-container="(triggerNode) => triggerNode.parentNode"
            @focus="loadStore(columnId)"
        >
            <a-select-option
                v-for="item in store"
                :key="item.id"
            >
                <span v-html="item.name" />
            </a-select-option>
        </a-select>

        <a-select
            v-if="editable && type.growingcombobox && !type.numberfield && !type.textfield"
            v-model:value="inputValue"
            style="width: 100%;"
            @focus="makeStore"
        >
            <template #dropdownRender="{ menuNode: menu }">
                <div>
                    <v-nodes :vnodes="menu" />
                    <a-divider style="margin: 4px 0;" />
                    <div style="padding: 8px; cursor: pointer;">
                        <plus-outlined /> Add item
                    </div>
                </div>
            </template>
            <a-select-option
                v-for="item in store"
                :key="item.id"
            >
                <span v-html="item.name" />
            </a-select-option>
        </a-select>
    </div>
</template>

<script>
import nunjucks from 'nunjucks';
import PlusOutlined from '@ant-design/icons-vue/PlusOutlined';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        VNodes: ({ vnodes }) => vnodes,
        PlusOutlined,
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        type: {
            type: Object,
            default() {
                return {};
            },
        },
        columnId: {
            type: Number,
            default: null,
        },
        localStore: {
            type: Array,
            default: null,
        },
        dropdownTpl: {
            type: String,
            default: '',
        },
    },
    emits: {
        change: null,
    },
    data() {
        return {
            store: [],
            inputValue: this.value,
        };
    },
    watch: {
        inputValue(val) {
            let changeValue;
            if (this.type.combobox) {
                changeValue = {
                    id: val,
                    name: this.store.find((x) => x.id === val).name,
                };
            }
            if (this.type.growingcombobox && !this.type.numberfield && !this.type.textfield) {
                changeValue = {
                    items: this.store.find((x) => x.id === val).items,
                };
            }
            this.$emit('change', changeValue);
        },
    },
    methods: {
        loadStore(id) {
            if (this.editable && (this.type.combobox || this.type.tagfield)) {
                this.$http.get(`/survey/column/${id}/refs`).then((response) => {
                    this.store = response.data;
                });
            }
        },
        makeStore() {
            this.store = [];
            for (let i = 0; i < this.localStore.length; i += 1) {
                this.store.push({
                    id: i,
                    name: nunjucks.renderString(this.dropdownTpl, { cols: this.localStore[i] }),
                    items: this.localStore[i],
                });
            }
        },
    },
});
</script>
