<template>
    <div>
        <p>
            <b>Пациент:</b>
            {{ patientName }}
        </p>
        <p>
            <b>Препарат:</b>
            {{ clinicMedication.name }}
        </p>
        <a-row
            v-if="subtypeRequired"
            class="medication-input-row"
        >
            <a-col :span="2" />
            <a-col :span="10">
                Подтип
            </a-col>
            <a-col :span="6">
                Изготовлено
            </a-col>
            <a-col :span="6">
                Передано
            </a-col>
        </a-row>
        <a-row
            v-else
            class="medication-input-row"
        >
            <a-col :span="12">
                Изготовлено
            </a-col>
            <a-col :span="12">
                Передано
            </a-col>
        </a-row>
        <medication-row
            v-for="(medication, index) in data"
            :key="index"
            :produced-settings="{
                disabled: updateMode,
            }"
            :subtype-settings="{
                options: subtypes,
                disabled: updateMode,
                hidden: !subtypeRequired,
            }"
            :remove-settings="{
                disabled: data.length < 2,
                hidden: !subtypeRequired || updateMode,
            }"
            :subtype-id="medication.subtypeId"
            :supplied="medication.supplied"
            :produced="medication.produced"
            @remove="removeResult(index)"
            @update:subtype-id="(value) => $emit('update:subtype-id', index, value)"
            @update:supplied="(value) => $emit('update:supplied', index, value)"
            @update:produced="(value) => $emit('update:produced', index, value)"
        />
        <a-row
            v-if="!updateMode && subtypeRequired"
            class="medication-input-row"
        >
            <a-col :span="3">
                <a-button
                    :disabled="addBtnDisabled"
                    type="primary"
                    @click="addResult"
                >
                    +
                </a-button>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import MedicationRow from '@/components/labpatientcard/MedicationRow.vue';

export default defineComponent({
    components: {
        MedicationRow,
    },

    props: {
        clinicMedication: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        patientName: {
            type: String,
            required: true,
        },
        updateMode: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:subtype-id', 'update:supplied', 'update:produced', 'add', 'remove'],
    computed: {
        subtypeRequired() {
            return this.clinicMedication.schemeId === 2;
        },

        subtypes() {
            if (!this.subtypeRequired) {
                return [];
            }
            return this.clinicMedication.subtypes.map(
                (type) => ({
                    ...type,
                    disabled: this.selectedSubtypes[type.id],
                }),
            );
        },

        addBtnDisabled() {
            return this.data.length
                >= this.clinicMedication.subtypes.length;
        },

        selectedSubtypes() {
            return this.data
                .reduce((acc, result) => ({
                    ...acc,
                    [result.subtypeId]: true,
                }), {});
        },
    },

    methods: {
        addResult() {
            this.$emit('add');
        },

        removeResult(index) {
            this.$emit('remove', index);
        },
    },
});
</script>

<style>
    .medication-input-row > div {
        padding: 5px;
    }

    .medication-input-row button {
        width: 43px;
        font-size: 18px;
        font-weight: 600;
    }

    .medication-input-row .med-input {
        width: 100%;
    }
</style>
