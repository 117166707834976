<template>
    <div class="anamneses-input-plus">
        {{ `${name}:` }}
        <strong class="value">{{ value }}</strong>
        <a-button
            v-if="!disabled"
            size="small"
            @click="$emit('change')"
        >
            <template #icon>
                <plus-outlined />
            </template>
        </a-button>
    </div>
</template>

<script>
import PlusOutlined from '@ant-design/icons-vue/PlusOutlined';

export default {
    components: {
        PlusOutlined,
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        name: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        change: null,
    },

    computed: {
        valueRightMargin() {
            return this.disabled ? '0' : '10px';
        },
    },
};
</script>

<style lang="scss">
.anamneses-input-plus {
    display: inline-flex;
    .value {
        margin-left: 5px;
        margin-right: v-bind(valueRightMargin);
    }
}
</style>
