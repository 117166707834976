<template>
    <div>
        <div class="anamneses-input-plus">
            <named-add-input
                :value="modelValueCloned.length"
                :name="name"
                @change="modelValueCloned.push({week: null, reason: null})"
            />
        </div>
        <div
            class="anamneses-childbearing__error"
        >
            <transition-group
                name="list"
                tag="p"
            >
                <div
                    v-for="(errorData, i) in modelValueCloned"
                    :key="i"
                >
                    <a-button
                        shape="circle"
                        size="small"
                        @click="modelValueCloned.splice(i, 1)"
                    >
                        <template #icon>
                            <delete-outlined />
                        </template>
                    </a-button>
                    {{ i + 1 }}. на
                    <a-input
                        v-model:value.number="errorData.week"
                        class="anamneses-input"
                        size="small"
                    />
                    неделе, причина
                    <a-input
                        v-model:value="errorData.reason"
                        style="width: 100px"
                        size="small"
                    />
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import DeleteOutlined from '@ant-design/icons-vue/DeleteOutlined';
import { cloneDeep, isEqual } from 'lodash';
import { defineComponent } from 'vue';
import NamedAddInput from './NamedAddInput.vue';

export default defineComponent({
    components: {
        NamedAddInput,
        DeleteOutlined,
    },
    props: {
        modelValue: {
            type: Array, // {week: number, reason: string} | number
            default: () => [],
        },
        name: {
            type: String,
            default: '',
        },
    },

    emits: {
        'update:modelValue': null,
    },

    data() {
        return {
            modelValueCloned: [],
        };
    },
    watch: {
        modelValue: {
            deep: true,
            immediate: true,
            handler() {
                if (isEqual(this.modelValue, this.modelValueCloned)) {
                    return;
                }
                this.modelValueCloned = cloneDeep(this.modelValue);
            },
        },
        modelValueCloned: {
            deep: true,
            handler() {
                this.$emit('update:modelValue', this.modelValueCloned);
            },
        },
    },
});
</script>

<style lang="scss">
    .anamneses-childbearing__error {
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        div {
            margin-top: 5px;
        }
        .list-enter-active, .list-leave-active {
            transition: all 0.3s;
        }
        .list-enter, .list-leave-to {
            opacity: 0;
            transform: translateY(5px);
        }
    }
    .anamneses-childbearing__error .anamneses-input.ant-input {
        width: 35px;
    }
    .list-enter-active, .list-leave-active {
        transition: all 0.3s;
    }
    .list-enter, .list-leave-to {
        opacity: 0;
        transform: translateY(5px);
    }
</style>
