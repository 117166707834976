import { get } from 'vue-web-components/src/utils/axiosConnectionTimeout';
import buildQueryParams from './utils';

export const getStaffWorkDay = (params) => get('/schedule/staff/work/day', { params });

export const getStaffAppointments = (params) => get('/appointments', { params });

export const getPatients = async ({
    filters,
    pagination,
    search,
}) => {
    const params = buildQueryParams({ pagination });
    if (search) {
        params.query = search;
    }
    const response = await get('/patients', {
        params: {
            ...filters,
            ...params,
        },
    });
    return response;
};

export const getLabPatients = async ({
    pagination,
    search,
    sort,
}) => {
    const params = buildQueryParams({ pagination, sort });
    if (search) {
        params.query = search;
    }
    const response = await get('/laboratory/patients', { params });
    return response;
};
