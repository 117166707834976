<template>
    <div class="login">
        <div
            v-if="!passwordChangeIsRequired"
            class="login-form"
        >
            <img src="/images/impulse_icon.png">
            <span class="app-title">АРМ Врача</span>
            <a-form
                ref="formRef"
                :model="formState"
                :rules="formValidationRules"
            >
                <a-form-item
                    name="login"
                >
                    <a-input
                        v-model:value="formState.login"
                        placeholder="Логин"
                    />
                </a-form-item>
                <a-form-item
                    name="password"
                >
                    <a-input
                        v-model:value="formState.password"
                        type="password"
                        placeholder="Пароль"
                    />
                </a-form-item>
                <a-form-item>
                    <a-button
                        type="primary"
                        data-test-id="login-btn"
                        @click="onSubmit"
                    >
                        Войти
                    </a-button>
                </a-form-item>
            </a-form>
        </div>
        <change-password
            v-else
            :time-password-expire="timePasswordExpire"
        />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ChangePassword from '@/views/ChangePassword.vue';
import { showErrorNotification } from '@/utils';

export default defineComponent({
    components: {
        ChangePassword,
    },
    data() {
        return {
            formState: {
                login: '',
                password: '',
            },
            formValidationRules: {
                login: [{ required: true, message: 'Обязательное поле' }],
                password: [{ required: true, message: 'Обязательное поле' }],
            },
            passwordChangeIsRequired: false,
            timePasswordExpire: null,
        };
    },

    methods: {
        onSubmit() {
            this.$refs.formRef.validate().then(({ login, password }) => {
                this.login(login, password);
            }, () => {});
        },

        async login(username, password) {
            let loginData;
            try {
                loginData = (await this.$http.post('/user/login', {
                    username,
                    password,
                })).data;
            } catch (error) {
                showErrorNotification(error.response.data.msg);
                return;
            }

            localStorage.username = loginData.name;
            localStorage.previewFileId = loginData.previewFileId;
            localStorage.clinics = loginData.clinics;
            localStorage.staffId = loginData.id;
            localStorage.chatUserId = loginData.chatUserId;
            localStorage.chatUserToken = loginData.chatUserToken;

            if (!loginData.changePassword) {
                localStorage.LoggedIn = true;
                this.$router.replace({ name: 'main' });
                return;
            }

            this.timePasswordExpire = loginData.timePasswordExpire;
            this.passwordChangeIsRequired = true;
        },
    },
});
</script>

<style lang="scss">
.ant-row,
.ant-row-flex-middle {
    height: 100%;
}

.login {
    background: url("/images/login-wall.jpg") center center / cover no-repeat rgb(255, 255, 255);
    height: 100%;
    display: flex;
}
.app-title {
    display: block;
    color: #00706c;
    font-weight: 600;
    font-size: 14px;
    font-family: Arial, sans-serif !important;
    margin-bottom: 20px;
    margin-top: 7px;
}

.login-form {
    margin: auto;
    min-width: 300px;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    padding: 10px 20px 0 20px;
    background-color: #fff;

    .ant-form-item {
        margin-bottom: 18px;
    }
    .ant-form-explain {
        margin-bottom: -17px;
        text-align: left;
        font-size: 13px;
    }
}
</style>
