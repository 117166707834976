import md5 from 'crypto-js/md5';
import { connect } from 'mqtt';
import { post } from 'vue-web-components/src/utils/axiosConnectionTimeout';
import Publisher from './prototypes/Publisher';
import app from '../../../main';

export default class MqttClient {
    constructor(sessionUrl, mqttUrl, skipConnection = 'disabled') {
        this.publisher = null;
        this.sessionUrl = sessionUrl;
        this.mqttUrl = mqttUrl;
        this.skipConnection = skipConnection;
        this.chatUserId = localStorage.chatUserId;
        this.chatUserToken = localStorage.chatUserToken;
        this.clientId = localStorage.clientId;
        this.connection = null;
    }

    async init(chatUserId, chatUserToken) {
        if (chatUserId && chatUserToken) {
            this.chatUserId = chatUserId;
            this.chatUserToken = chatUserToken;
        }
        if (!this.clientId) {
            await this.getSession();
        }
    }

    async auth() {
        const userHash = md5(this.chatUserId + this.chatUserToken).toString();
        const { data: { clientId } } = await post(
            this.sessionUrl,
            { userId: this.chatUserId, userHash },
            { baseURL: '' },
        );
        return clientId;
    }

    async getSession() {
        return new Promise((resolve) => {
            this.auth().then((clientId) => {
                localStorage.clientId = clientId;
                resolve(clientId);
            }).catch(() => {
                setTimeout(this.getSession.bind(this), 5000);
            });
        });
    }

    async connect(chatUserId, chatUserToken) {
        await this.init(chatUserId, chatUserToken);
        this.connection = connect(this.mqttUrl, {
            reconnectPeriod: this.skipConnection === 'disabled' ? 1000 : 0,
            username: this.chatUserId.toString(),
            password: this.chatUserToken,
            clientId: this.clientId,
        });

        this.publisher = new Publisher(this.connection);

        this.connection.on('message', (channel, response) => {
            try {
                const data = JSON.parse(response);
                this.$emitter.emit(data.type, data.body);
            } catch (err) {
                // PASS
            }
        });
    }

    getUserUnreadedCount() {
        this.publisher.publish(
            `@ENV/IN/${this.clientId}`,
            'getUserUnreadedCountRequest',
            { userId: this.chatUserId },
        );
        return new Promise((resolve) => {
            app.$emitter.on('getUserUnreadedCountResponse', (data) => resolve(data.count));
        });
    }
}
