<template>
    <a-select
        :get-popup-container="(triggerNode) => triggerNode.parentNode"
        :value="value"
        :disabled="!!disabled"
        :filter-option="filter"
        style="width: 100%;"
        allow-clear
        show-search
        @change="onChange"
    >
        <a-select-option
            v-for="item in store"
            :key="item.id"
        >
            {{ item.name }}
        </a-select-option>
    </a-select>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        value: {
            type: Number,
            default: null,
        },
        disabled: Boolean,
    },
    emits: ['input'],
    data() {
        return {
            store: [],
            allUsers: [],
        };
    },
    watch: {
        value(v) {
            if (!v || !this.allUsers.length) {
                return;
            }
            this.updateStore();
        },
    },
    mounted() {
        this.loadStore();
    },
    methods: {
        loadStore() {
            this.$http.get('/users').then((response) => {
                this.allUsers = response.data;
                this.updateStore();
            }, () => {});
        },
        updateStore() {
            const FIRED = 3;
            this.store = this.allUsers.filter(
                (rec) => (rec.id === this.value)
                    || (rec.med.specialty.length
                    && rec.workingState !== FIRED),
            );
        },
        onChange(value) {
            this.$emit('input', value);
        },
        filter(input, option) {
            const child = option.componentOptions.children[0];
            if (!child) {
                return undefined;
            }
            const { text } = child;
            if (!text) {
                return undefined;
            }

            return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
    },
});
</script>

<style lang="scss">
.ant-select-dropdown-content {
    text-align: left;
}
</style>
