<template>
    <div
        @drop.stop="onDrop"
        @dragstart="onDrag"
        @dragenter.prevent
        @dragover.prevent
        @dragend="onDragEnd"
    >
        <node-tree
            v-for="(node, nodeIndex) in parents"
            v-slot="{ data, lvl }"
            :key="node[idKey]"
            :editing="editing"
            :nodes-parents-map="nodesParentsMap"
            :node="node"
            :lvl="0"
            :id-key="idKey"
        >
            <div
                v-if="nodeIndex === 0 && lvl === 0"
                ref="dropper"
                class="nodes-dropper"
            />
            <div
                ref="dropElement"
                class="drop-element"
                @dragover="addHover"
                @dragleave="removeHover"
                @drop="removeHover"
            >
                <slot
                    :data="data"
                    :lvl="lvl"
                    :data-id="data[idKey]"
                />
            </div>
        </node-tree>
    </div>
</template>

<script>
import { groupBy, keyBy } from 'lodash';
import NodeTree from './NodeTree.vue';

export default {
    components: {
        NodeTree,
    },
    inheritAttrs: false,
    props: {
        nodes: {
            type: Array,
            default: () => [],
        },
        editing: {
            type: Boolean,
            default: false,
        },
        idKey: {
            type: String,
            default: 'id',
        },
        parentKey: {
            type: String,
            default: 'parentId',
        },
    },
    emits: {
        'parent-changed': null,
    },
    data() {
        return {
            dragged: null,
        };
    },
    computed: {
        nodesMap() {
            return keyBy(this.nodes, this.idKey);
        },
        nodesParentsMap() {
            return groupBy(this.nodes, this.parentKey);
        },
        parents() {
            return this.nodes.filter(
                (node) => !node[this.parentKey]
                    || !this.nodesMap[node[this.parentKey]],
            );
        },
        ids() {
            return this.nodes.map((node) => node[this.idKey]);
        },
    },
    methods: {
        addHover({ target }) {
            const dropElement = this.getClosestDragElement(target);
            if (dropElement) {
                dropElement.classList.add('hovered');
            }
        },

        removeHover({ target }) {
            const dropElement = this.getClosestDragElement(target);
            if (dropElement) {
                dropElement.classList.remove('hovered');
            }
        },

        getClosestDragElement(target) {
            const isTextNode = target.nodeType === Node.TEXT_NODE;
            const element = isTextNode
                ? target.parentElement
                : target;
            return element.closest('.drop-element');
        },

        onDrag(e) {
            if (this.editing) {
                e.preventDefault();
                return;
            }
            e.dataTransfer.effectAllowed = 'move';
            this.$refs.dropper[0].classList.add('hovered');
            const nativeTarget = e.target;
            if (!nativeTarget) {
                return;
            }
            this.dragged = Number.parseInt(nativeTarget.dataset.id, 10);
            nativeTarget.querySelectorAll('.drop-element').forEach((elem) => {
                elem.classList.add('node-hide');
            });
        },
        onDrop(e) {
            if (e.target.closest('.node-hide')) {
                return;
            }
            if (e.target.closest('.drop-element [data-deleted="true"]')) {
                e.preventDefault();
                return;
            }
            this.$refs.dropper[0].classList.remove('hovered');
            const target = this.nodes[this.ids.indexOf(this.dragged)];
            if (!target || !target[this.idKey]) {
                return;
            }
            const children = this.nodes.filter(
                (x) => x[this.parentKey] === target[this.idKey],
            ).map((x) => x[this.idKey]);
            if (e.target.classList.contains('nodes-dropper')) { // перенос на верхний уровень
                target[this.parentKey] = null;
                this.$emit('parent-changed', this.dragged, this.parentKey, null);
                return;
            }
            const targetId = Number.parseInt(
                e.target.closest('div.li-tree-node').dataset.id,
                10,
            );
            if (targetId === this.dragged) {
                return;
            }
            if (children.includes(targetId)) {
                return;
            }
            this.$emit('parent-changed', this.dragged, this.parentKey, targetId);
        },
        // fix all bugs with drop on not hundled elements
        onDragEnd() {
            document.querySelectorAll('.drop-element').forEach((elem) => {
                elem.classList.remove('node-hide');
            });
            this.$refs.dropper[0].classList.remove('hovered');
        },
    },
};
</script>

<style lang="scss">
    .nodes-dropper {
        background-color: rgb(245, 245, 245);
        height: 10px !important;
        margin-bottom: 5px;
        &.hovered{
            transition: background-color 0.2s ease-in-out,
                opacity 0.2s ease-in-out,
        }
    }
    .hovered {
            transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
            background-color: rgba(24, 144, 255, 0.5);
        }
    .node-hide {
        opacity: 0.3;
    }
</style>
