
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        modelValue: {
            type: Object,
            required: false,
            default: () => ({
                startedAt: null,
                duration: null,
                pain: null,
                bleeding: null,
                sexLifeStartedAt: null,
                sexIsRegular: null,
            }),
        },
        pain: {
            type: Array,
            default: () => [],
        },
        bleeding: {
            type: Array,
            default: () => [],
        },
    },
    emits: {
        'update:modelValue': null,
    },
    data() {
        return {
            initialized: false,
            menstruationModel: {
                startedAt: null,
                duration: null,
                pain: null,
                bleeding: null,
                sexLifeStartedAt: null,
                sexIsRegular: null,
            },
        };
    },
});
