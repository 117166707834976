const mainDoctorModule = {
    namespaced: true,

    state: {
        //
    },

    getters: {
        //
    },

    mutations: {
        //
    },

    actions: {
        //
    },
};

export default mainDoctorModule;
