<template>
    <div class="login-form">
        <img src="/images/impulse_icon.png">
        <span class="app-title">АРМ Врача</span>
        <div
            v-if="timePasswordExpire"
            class="ant-form-item info-msg"
        >
            Внимание! Если не сменить пароль, то пользователь будет заблокирован
            {{ $moment(timePasswordExpire).format('DD.MM.YYYY') }}
            в
            {{ $moment(timePasswordExpire).format('HH:mm') }}
        </div>
        <a-form
            ref="form"
            :model="formState"
            :rules="formRules"
            @submit="loginSubmit"
        >
            <div class="input-with-lang-indicator">
                <a-form-item
                    name="password"
                >
                    <a-input
                        v-model:value="formState.password"
                        type="password"
                        placeholder="Пароль"
                        @keyup="(e) => onPasswordChange(e, formState.password)"
                        @mousedown="onPasswordMouseDown"
                    />
                </a-form-item>
                <i
                    v-if="showKeyboard"
                    class="lang-indicator fa fa-keyboard-o"
                />
                <span
                    v-if="!showKeyboard"
                    class="lang"
                >
                    {{ lang }}
                </span>
            </div>
            <span
                v-if="showCapsWarn"
                class="caps-lock-warning"
            >
                включён Caps Lock
            </span>
            <a-form-item
                name="confirm"
            >
                <a-input
                    v-model:value="formState.confirm"
                    type="password"
                    placeholder="Подтвержение"
                    @keyup="(e) => onPasswordChange(e, formState.confirm)"
                    @mousedown="onPasswordMouseDown"
                />
            </a-form-item>
            <div class="login-rules">
                Пароль должен иметь:
                <span
                    :class="formState.password
                        && formState.password.length > 5 ? 'valid' : 'no-valid'"
                >
                    <i
                        :class="formState.password && formState.password.length > 5
                            ? 'fa fa-check-circle'
                            : 'fa fa-times-circle'"
                    />
                    Длину не меньше 6 символов
                </span>
                <span
                    :class="formState.password
                        && /[A-Z]/.test(formState.password) ? 'valid' : 'no-valid'"
                >
                    <i
                        :class="formState.password && /[A-Z]/.test(formState.password)
                            ? 'fa fa-check-circle'
                            : 'fa fa-times-circle'"
                    />
                    Прописные буквы английского алфавита от A до Z
                </span>
                <span
                    :class="formState.password
                        && /[a-z]/.test(formState.password) ? 'valid' : 'no-valid'"
                >
                    <i
                        :class="formState.password && /[a-z]/.test(formState.password)
                            ? 'fa fa-check-circle'
                            : 'fa fa-times-circle'"
                    />
                    Строчные буквы английского алфавита от a до z
                </span>
                <span
                    :class="formState.password
                        && /[0-9]/.test(formState.password) ? 'valid' : 'no-valid'"
                >
                    <i
                        :class="formState.password && /[0-9]/.test(formState.password)
                            ? 'fa fa-check-circle'
                            : 'fa fa-times-circle'"
                    />
                    Цифры от 0 до 9
                </span>
                <span
                    :class="formState.confirm
                        && formState.confirm === formState.password ? 'valid' : 'no-valid'"
                >
                    <i
                        :class="formState.confirm && formState.confirm === formState.password
                            ? 'fa fa-check-circle'
                            : 'fa fa-times-circle'"
                    />
                    Подтверждение
                </span>
            </div>
            <a-form-item>
                <a-button
                    :disabled="!validate()"
                    type="primary"
                    html-type="submit"
                >
                    Изменить пароль
                </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { showErrorNotification } from '@/utils';

export default defineComponent({
    props: {
        timePasswordExpire: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            formState: {
                password: null,
                confirm: null,
            },
            formRules: {
                password: [{
                    required: true,
                    message: 'Обязательное поле',
                }],
                confirm: [{
                    required: true,
                    message: 'Обязательное поле',
                }],
            },
            capsIsOn: false,
            showKeyboard: true,
            showCapsWarn: false,
            lang: null,
        };
    },
    methods: {
        onPasswordMouseDown(e) {
            if (e.getModifierState('CapsLock')) {
                if (!this.showCapsWarn) {
                    this.capsIsOn = true;
                }
                if (this.capsIsOn) {
                    this.showCapsWarn = true;
                } else {
                    this.showCapsWarn = false;
                }
            } else {
                this.capsIsOn = false;
                this.showCapsWarn = false;
            }
        },
        onPasswordChange(e, value) {
            if (e.keyCode === 20) {
                this.capsIsOn = !this.capsIsOn;
                this.showCapsWarn = !this.showCapsWarn;
                return;
            }
            // {16: shift, 17: ctrl, 18: alt, 91: cmd, 32: space, 20: capsLock,
            // 8: backspace, 46: del, 38: up, 40: down, 37: left, 39: right, 13: enter}
            if (
                [16, 17, 18, 20, 32, 91, 8, 46, 38, 40, 37, 39, 13].indexOf(e.keyCode) > -1
            ) {
                return;
            }
            const testValue = value.slice(-1);
            if (/[A-Za-z]/.test(testValue)) {
                this.showKeyboard = false;
                this.lang = 'EN';
            } else if (/[ЁЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮёйцукенгшщзхъфывапролджэячсмитьбю]/.test(testValue)) {
                this.showKeyboard = false;
                this.lang = 'RU';
            }
        },
        validate() {
            if (
                this.formState.password
                && this.formState.confirm
                && this.formState.password.length > 5
                && /[A-Z]/.test(this.formState.password)
                && /[a-z]/.test(this.formState.password)
                && /[0-9]/.test(this.formState.password)
                && this.formState.confirm === this.formState.password
            ) {
                return true;
            }
            return false;
        },
        loginSubmit(e) {
            e.preventDefault();
            if (!this.validate()) {
                return;
            }

            this.$http.post('/user/pass', {
                password: this.formState.password,
            }).then((response) => {
                localStorage.username = response.data.name;
                localStorage.LoggedIn = true;
                this.$router.replace({ name: 'main' });
            }).catch((error) => {
                showErrorNotification(error.response.data.msg);
            });
        },
    },
});
</script>

<style lang="scss">
.ant-row,
.ant-row-flex-middle {
    height: 100%;
}

.app-title {
    display: block;
    color: #00706c;
    font-weight: 600;
    font-size: 14px;
    font-family: Arial, sans-serif !important;
    margin-bottom: 20px;
    margin-top: 7px;
}
.login-form {
    margin: auto;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    padding: 10px 20px 0 20px;
    background-color: #fff;

    .ant-form-item {
        margin-bottom: 18px;
        margin-top: 18px;
    }
    .ant-form-explain {
        margin-bottom: -17px;
        text-align: left;
        font-size: 13px;
    }
    .error-msg, .no-valid, .caps-lock-warning {
        color: #f5222d;
    }
    .valid {
        color: #589046;
    }
    .info-msg {
        text-align: left;
    }
    .caps-lock-warning {
        font-family: Arial;
        text-align: left;
        width: 100%;
        display: inline-block;
    }
    .input-with-lang-indicator {
        display: grid;
        grid-template-columns: auto 25px;
        .ant-form-item {
            margin: 0;
        }
        .lang-indicator {
            padding-top: 12px;
        }
        .lang {
            font-family: Arial;
            font-size: 13px;
            padding-top: 10px;
        }
    }
    .login-rules {
        text-align: left;
        span {
            display: block;

            i {
                margin-right: 5px;
            }
        }
    }
}
</style>
