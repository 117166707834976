<template>
    <div
        :data-id="node[idKey]"
        :draggable="!editing && !node.deleted"
        :data-test-id="`li-tree-node-${node[idKey]}`"
        class="li-tree-node"
    >
        <div class="li-tree-node__vertical-path" />
        <slot
            :data="node"
            :lvl="lvl"
        />
        <node-tree
            v-for="child in nodesParentsMap[node[idKey]] || []"
            :key="child[idKey]"
            :node="child"
            :nodes-parents-map="nodesParentsMap"
            :lvl="lvl + 1"
            :id-key="idKey"
        >
            <template
                #default="scope"
            >
                <slot
                    name="default"
                    v-bind="scope"
                />
                <slot
                    name="beforeOptions"
                    v-bind="scope"
                />
            </template>
        </node-tree>
    </div>
</template>

<script>
export default {
    name: 'NodeTree',
    props: {
        node: {
            type: [Array, Object],
            default: null,
        },
        lvl: {
            type: Number,
            default: 0,
        },
        editing: {
            type: Boolean,
            default: false,
        },
        nodesParentsMap: {
            type: Object,
            default: () => ({}),
        },
        idKey: {
            type: String,
            default: 'id',
        },
    },
};
</script>

<style lang="scss">
.li-tree-node {
    margin: 0;
    padding-left: 0;
}
</style>
