<template>
    <div id="anamnesis">
        <h2>Анамнезы</h2>
        <a-button
            v-if="!isMalePatient"
            :style="{marginTop: '-40px'}"
            type="primary"
            @click="onSave()"
        >
            Сохранить
        </a-button>
        <save-status
            v-if="!isMalePatient"
            :value="saveStatus"
            :style="{marginTop: '-40px', marginRight: '120px'}"
            @input="(e) => saveStatus = e"
        />
        <anamnesis
            ref="anamnesis"
            :patient-id="patientId"
            @on-data-loaded="(patientGender) => isMalePatient = patientGender === 'Мужской'"
            @datachange="(anamnesisData, patientCardData) => {
                data = anamnesisData;
                patientCard = patientCardData;
            }"
        />

        <a-button
            :disabled="false"
            :style="{marginBottom: '20px'}"
            type="primary"
            @click="onSave()"
        >
            Сохранить
        </a-button>

        <save-status
            :value="saveStatus"
            @input="(e) => saveStatus = e"
        />
    </div>
</template>

<script>
import Anamnesis from 'vue-web-components/src/components/anamneses/Anamneses.vue';
import { defineComponent } from 'vue';
import SaveStatus from '@/components/infocomponents/SaveStatus.vue';
import { showErrorNotification } from '@/utils';

export default defineComponent({
    components: {
        Anamnesis,
        SaveStatus,
    },
    props: {
        patientId: {
            type: Number,
            required: true,
        },
    },

    emits: [
        'anamnesis-update',
    ],

    data() {
        return {
            data: null,
            patientCard: null,
            isMalePatient: null,
            saveStatus: {
                code: null,
                text: null,
            },
            saveStatusTimer: null,
        };
    },
    methods: {
        onSave() {
            this.$http.put(`patient/card/${this.patientId}`, {
                ...this.patientCard,
                ...this.data,
            })
                .then(() => {
                    this.$refs.anamnesis.cancelEditing();
                    this.saveStatus = {
                        code: 'OK',
                        text: 'Сохранено успешно',
                    };
                    this.$emit('anamnesis-update');
                })
                .catch((err) => {
                    this.saveStatus = {
                        code: 'ERROR',
                        text: 'Ошибка сохранения',
                    };
                    showErrorNotification(err.response.data.msg);
                });
        },
    },
});
</script>

<style lang="scss" scoped>
#anamnesis {
    padding: 20px;
    padding-top: 0;

    h2 {
        margin-bottom: 15px;
    }
}
</style>
