<template>
    <div>
        <h2>Опросы</h2>
        <div class="polls-card">
            <a-table
                :columns="patientPollsColumns"
                :data-source="patientPollsData"
                :row-selection="pollSelection"
                :custom-row="customizeRow"
                row-key="id"
            >
                <template #expandedRowRender="{ record }">
                    <div>
                        Дата создания:
                        {{ $moment(record.timeCreate).format('DD.MM.YYYY HH:mm') }}
                        <br>
                        Время планового проведения:
                        {{ record.timeStartPlanRu }}
                        <br>
                        Заполнить до:
                        {{ $moment(record.dateEndPlan).format('DD.MM.YYYY') }}
                        <br>
                    </div>
                </template>

                <template #timeEdit="{ record }">
                    <span>
                        {{ $moment(record.timeEdit).format('DD.MM.YYYY HH:mm') }}
                    </span>
                </template>
                <template #stateName="{ record }">
                    <span>
                        <a-tag color="blue">{{ record.stateName }}</a-tag>
                    </span>
                </template>
            </a-table>
            <div v-if="pollSelection.selectedRowKeys.length>0">
                <div class="buttons-toolbar">
                    <a-button
                        type="primary"
                        @click="print"
                    >
                        <template #icon>
                            <printer-outlined />
                        </template>
                        Печать
                    </a-button>
                    <a-button
                        type="primary"
                        @click="view"
                    >
                        <template #icon>
                            <check-outlined />
                        </template>
                        Просмотр
                    </a-button>
                    <a-button
                        type="text"
                        danger
                        @click="deletePoll"
                    >
                        <template #icon>
                            <delete-outlined />
                        </template>
                        Удалить
                    </a-button>
                </div>
                <div
                    v-for="item in poll"
                    :key="item.id"
                    class="blank"
                >
                    <h2>{{ item.caption || item.name }}</h2>
                    <survey-blank
                        :data="item"
                        show-poll-files
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DeleteOutlined from '@ant-design/icons-vue/DeleteOutlined';
import CheckOutlined from '@ant-design/icons-vue/CheckOutlined';
import PrinterOutlined from '@ant-design/icons-vue/PrinterOutlined';
import { Modal } from 'ant-design-vue';
import SurveyBlank from '@/components/patientcard/SurveyBlank.vue';
import { showErrorNotification } from '@/utils';

export default {
    components: {
        SurveyBlank,
        DeleteOutlined,
        CheckOutlined,
        PrinterOutlined,
    },
    props: {
        patientId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            patientPollsColumns: [
                { title: 'Название', dataIndex: 'surveyType' },
                { title: 'Дата изменения', dataIndex: 'timeEdit', slots: { customRender: 'timeEdit' } },
                { title: 'Статус', dataIndex: 'stateName', slots: { customRender: 'stateName' } },
            ],
            innerPatientPollsColumns: [
                { title: 'Дата создания', dataIndex: 'timeCreate', slots: { customRender: 'timeCreate' } },
                { title: 'Время планового проведения', dataIndex: 'timeStartPlanRu' },
                { title: 'Заполнить до', dataIndex: 'dateEndPlan', slots: { customRender: 'dateEndPlan' } },
            ],
            patientPollsData: [],
            poll: {},
            pollSelection: {
                type: 'radio',
                onSelect: (record) => {
                    this.setSelection(record);
                },
                selectedRowKeys: [],
            },
        };
    },
    mounted() {
        this.getPatientPolls(this.patientId);
    },
    methods: {
        getPatientPolls(id) {
            this.$http.get('/patient/surveys/quiz', {
                params: {
                    patientId: id,
                    page: JSON.stringify([0, 50]),
                },
            }).then((response) => {
                this.patientPollsData = response.data.data;
                if (this.patientPollsData.length > 0) {
                    this.pollSelection.selectedRowKeys.push(this.patientPollsData[0].id);
                    this.getPoll(this.patientPollsData[0].id);
                }
            });
        },
        getPoll(id) {
            this.$http.get(`/patient/survey/quiz/${id}`).then((response) => {
                this.poll = response.data.blanks;
            }).catch(() => showErrorNotification('Не удалось загрузить опрос.'));
        },
        setSelection(record) {
            this.pollSelection.selectedRowKeys.pop();
            this.pollSelection.selectedRowKeys.push(record.id);
            this.getPoll(record.id);
        },
        customizeRow(record) {
            const me = this;
            return {
                onClick() {
                    me.setSelection(record);
                },
            };
        },
        print() {
            window.open(`/api/patient/survey/quiz/${this.pollSelection.selectedRowKeys[0]}/download`);
        },
        view() {
            this.$router.push({ path: `/poll/${this.pollSelection.selectedRowKeys[0]}` });
        },
        deletePoll() {
            Modal.confirm({
                title: 'Вы действительно хотите удалить выбранный опрос?',
                onOk: () => {
                    this.$http.delete(`/patient/${this.patientId}/survey/quiz/${this.pollSelection.selectedRowKeys[0]}`)
                        .then(() => {
                            const record = this.patientPollsData.find((x) => {
                                if (x.id === this.pollSelection.selectedRowKeys[0]) {
                                    return true;
                                }
                                return false;
                            });
                            this.patientPollsData.splice(this.patientPollsData.indexOf(record), 1);
                            if (this.patientPollsData.length) {
                                this.setSelection(this.patientPollsData[0]);
                            }
                        });
                },
            });
        },
    },
};
</script>

<style lang="scss">
.polls-card {
    display: grid;
    grid-template-columns: 35% auto;
    grid-column-gap: 35px;
}
.buttons-toolbar {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;

    button {
        margin-right: 10px;
    }
}
</style>
