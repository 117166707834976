<template>
    <main-doctor v-if="mode === 'doctor'" />

    <main-lab-worker v-else-if="mode === 'labworker'" />
</template>

<script>
import titleMixin from '@/mixins/titleMixin';
import MainDoctor from '@/views/MainDoctor.vue';
import MainLabWorker from '@/views/MainLabWorker.vue';

export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    name: 'Main',
    components: {
        MainDoctor, MainLabWorker,
    },
    mixins: [titleMixin],
    props: {
        mode: {
            type: String,
            default: null,
        },
    },
};
</script>
