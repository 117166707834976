<template>
    <a-modal
        :visible="true"
        :mask-closable="false"
        :width="640"
        centered
        class="good-medication-completion-modal"
        title="Данные о препаратах"
        @cancel="handleCancel"
    >
        <a-form
            v-for="(medication, index) in medications"
            :key="medication.id"
            ref="medicationForms"
            :rules="formValidationRules[index]"
            :model="formModels[index]"
            layout="vertical"
        >
            {{ medication.name }}
            <a-row>
                <a-col
                    v-if="medicationAcceptsSubtype(medication)"
                    :span="medicationAcceptsDonor(medication) ? 12 : 24"
                >
                    <a-form-item
                        label="Подтип"
                        :colon="false"
                        name="medicationSubtypeId"
                    >
                        <a-select
                            v-model:value="formModels[index].medicationSubtypeId"
                            :dropdown-match-select-width="false"
                            :get-popup-container="(triggerNode) => triggerNode.parentNode"
                            placeholder="Укажите подтип..."
                            :disabled="disabled"
                        >
                            <a-select-option
                                v-if="allowEmptySubtype"
                                :value="0"
                            >
                                Указать при назначении
                            </a-select-option>
                            <a-select-option
                                v-for="subtype in getMedicationSubtypes(
                                    medication.subtypes,
                                    formModels[index].medicationSubtypeId,
                                )"
                                :key="subtype.id"
                                :disabled="!!subtype.deleted"
                            >
                                {{ subtype.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col
                    v-if="medicationAcceptsDonor(medication)"
                    :span="medicationAcceptsSubtype(medication) ? 12 : 24"
                >
                    <a-form-item
                        v-if="donorListIsVisible(medication)"
                        label="Донор б/м"
                        :colon="false"
                        name="donorId"
                    >
                        <a-select
                            v-if="!disabled || formModels[index].donorId"
                            v-model:value="formModels[index].donorId"
                            show-search
                            :dropdown-match-select-width="false"
                            :get-popup-container="(triggerNode) => triggerNode.parentNode"
                            :filter-option="false"
                            allow-clear
                            :disabled="disabled"
                            placeholder="Нет"
                            @search="searchPatient"
                            @change="onDonorChange"
                        >
                            <a-select-option
                                v-for="donor in donors"
                                :key="donor.patientId"
                            >
                                {{ donor.name }}
                            </a-select-option>
                        </a-select>
                        <span v-else>
                            Нет
                        </span>
                    </a-form-item>
                    <a-form-item
                        v-if="showSkipDonorOption"
                        label="Донор б/м"
                        :colon="false"
                        name="skipDonor"
                    >
                        <a-select
                            v-model:value="formModels[index].skipDonor"
                            :get-popup-container="(triggerNode) => triggerNode.parentNode"
                        >
                            <a-select-option
                                :value="0"
                            >
                                Спрашивать
                            </a-select-option>
                            <a-select-option
                                :value="1"
                            >
                                Не спрашивать
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
        <template #footer>
            <a-button
                key="back"
                @click="handleCancel"
            >
                {{ disabled ? "Закрыть" : "Отмена" }}
            </a-button>
            <a-button
                v-if="!disabled"
                key="submit"
                type="primary"
                @click="handleSave"
            >
                Сохранить
            </a-button>
        </template>
    </a-modal>
</template>

<script>
import {
    keyBy,
    uniq,
} from 'lodash';

import { showErrorNotification } from '../../../utils';

export default {
    props: {
        patientId: {
            type: Number,
            default: null,
        },
        good: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        allowEmptySubtype: {
            type: Boolean,
            default: false,
        },
        showSkipDonorOption: {
            type: Boolean,
            default: false,
        },
        allowToSkipDonor: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        cancel: null,
        'select-medication-subtypes': null,
    },
    data() {
        return {
            donors: [],
            related: [],
            timeout: null,
            currentPatientId: null,
            formModels: [],
        };
    },
    computed: {
        formValidationRules() {
            return this.medications.map((medication) => ({
                medicationSubtypeId: [{
                    required: !this.allowEmptySubtype
                        && this.medicationRequiresSubtype(medication),
                    message: 'Обязательно для заполнения',
                }],
                donorId: [{ required: false }],
                skipDonor: [{
                    required: this.showSkipDonorOption,
                    message: 'Обязательно для заполнения',
                }],
            }));
        },

        medicationInfoMap() {
            if (!this.good) {
                return {};
            }
            return keyBy(
                this.good.medicationAssignmentInfo || [],
                'clinicMedicationId',
            );
        },

        medications() {
            if (this.good && this.good.clinicMedications) {
                return this.good.clinicMedications.filter(
                    this.clinicMedicationFilter,
                );
            }
            return [];
        },
    },
    watch: {
        good: {
            immediate: true,
            handler(val) {
                if (!val || !val.clinicMedications) {
                    return;
                }

                this.formModels = val.clinicMedications.filter(
                    this.clinicMedicationFilter,
                ).map(({ id, subtypes }) => {
                    const infoMap = this.medicationInfoMap[id];
                    let { medicationSubtypeId } = infoMap;
                    if (!this.allowEmptySubtype && !medicationSubtypeId) {
                        const possibleSubtypes = this.getMedicationSubtypes(subtypes);
                        if (possibleSubtypes.length === 1) {
                            medicationSubtypeId = possibleSubtypes[0].id;
                        }
                    } else if (!medicationSubtypeId) {
                        medicationSubtypeId = this.allowEmptySubtype ? 0 : null;
                    }

                    return {
                        medicationSubtypeId,
                        donorId: infoMap.donorId,
                        skipDonor: infoMap.skipDonor || 0,
                    };
                });

                const hasDonors = val.medicationAssignmentInfo.some(
                    ({ donorId }) => donorId,
                );
                if (hasDonors) {
                    this.$http.get('/patients', {
                        params: {
                            lightweight: true,
                            ids: uniq(val.medicationAssignmentInfo.filter(
                                ({ donorId }) => donorId,
                            ).map(
                                ({ donorId }) => donorId,
                            )),
                        },
                    }).then((response) => {
                        this.donors = response.data.filter(
                            ({ patientId }) => patientId !== this.patientId,
                        );
                    }).catch(() => showErrorNotification('Ошибка загрузки данных пациента.'));
                }
                if (this.patientId && this.currentPatientId !== this.patientId) {
                    this.currentPatientId = this.patientId;

                    this.$http.get(`/patient/info/${this.patientId}`).then((response) => {
                        if (!response.data.represent) {
                            return;
                        }
                        this.related.push({
                            name: response.data.represent.longName,
                            patientId: response.data.represent.patientId,
                        });
                        this.donors = this.related;
                    }).catch(() => showErrorNotification('Ошибка загрузки данных пациента.'));
                    this.$http.get(`/patient/related/list/${this.patientId}`).then((response) => {
                        if (!response.data.length) {
                            if (!this.related.length && !this.donors.length) {
                                this.$http.get('/patients', {
                                    params: {
                                        lightweight: true,
                                        page: JSON.stringify([0, 10]),
                                    },
                                }).then((patientsResponse) => {
                                    this.donors = patientsResponse.data.data.filter(
                                        ({ patientId }) => patientId !== this.patientId,
                                    );
                                });
                            }
                            return;
                        }
                        this.related = response.data.map((patient) => ({
                            name: `${patient.lastname} ${patient.firstname}${
                                patient.middlename ? ` ${patient.middlename}` : ''
                            }`,
                            patientId: patient.id,
                        }));
                        this.donors = this.related;
                    }).catch(() => showErrorNotification('Ошибка загрузки данных пациента.'));
                }
            },
        },
    },

    methods: {
        clinicMedicationFilter(medication) {
            return this.medicationAcceptsSubtype(medication)
                || this.medicationAcceptsDonor(medication);
        },

        donorListIsVisible({ id }) {
            const medInfo = this.medicationInfoMap[id];
            return !this.showSkipDonorOption
                && (!this.allowToSkipDonor || !medInfo.skipDonor);
        },

        onDonorChange(value) {
            if (!value) {
                this.donors = this.related;
            }
        },
        searchPatient(value) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            if (!value || !value.length) {
                this.donors = this.related;
                return;
            }

            this.timeout = setTimeout(
                () => {
                    this.$http.get('/patients', {
                        params: {
                            query: value,
                            lightweight: true,
                        },
                    }).then((response) => {
                        this.donors = response.data.filter(
                            ({ patientId }) => patientId !== this.patientId,
                        );
                    });
                },
                300,
            );
        },
        handleCancel() {
            this.$emit('cancel');
        },
        handleSave(e) {
            e.preventDefault();

            Promise.all(this.$refs.medicationForms.map(
                (formRef) => formRef.validate(),
            )).then(
                () => this.emitResult(),
                () => {},
            );
        },

        emitResult() {
            const result = {
                ...this.good,
                medicationAssignmentInfo: this.formModels.map(
                    ({ donorId, skipDonor, medicationSubtypeId }, index) => ({
                        ...this.good.medicationAssignmentInfo[index],
                        donorId,
                        skipDonor,
                        medicationSubtypeId: medicationSubtypeId || null,
                    }),
                ),
            };
            this.$emit('select-medication-subtypes', result);
        },

        medicationAcceptsSubtype(medication) {
            return medication.schemeId !== 1;
        },

        medicationRequiresSubtype(medication) {
            return !medication.schemeId || medication.schemeId === 3;
        },

        medicationAcceptsDonor(medication) {
            const medicationInfo = this.medicationInfoMap[medication.id];

            if (!medication.allowDonorBiomaterial) {
                return false;
            }
            if (this.showSkipDonorOption) {
                return true;
            }
            if (!this.allowToSkipDonor) {
                return true;
            }
            return !medicationInfo.skipDonor;
        },

        getMedicationSubtypes(subtypes, selectedSubtypeId) {
            return subtypes.filter(
                ({ id, deleted }) => !deleted || id === selectedSubtypeId,
            );
        },
    },
};
</script>

<style lang="scss">
.good-medication-completion-modal {
    .ant-modal-content {
        .ant-modal-header {
            padding: 16px 15px;
        }
        .ant-modal-body {
            padding: 15px;

            .ant-form-item {
                margin: 5px;
            }
        }
    }
}
</style>
