<template>
    <div>
        {{ infoString }}
    </div>
</template>

<script>
export default {
    props: {
        patientInfo: {
            type: Object,
            default: null,
        },
    },
    computed: {
        infoString() {
            if (!this.patientInfo) {
                return '';
            }
            const birthDate = this.$moment(this.patientInfo.birthDate, 'YYYY-MM-DD');
            const age = Math.floor(
                this.$moment.duration(
                    this.$moment().diff(birthDate),
                ).asYears(),
            );
            const name = this.patientInfo.name || this.getPatientName(this.patientInfo);
            return `${name}, ${birthDate.format('DD.MM.YYYY')
            } (${age} ${this.formatAge(age)}) ${
                this.patientInfo.gender === 0 ? 'муж.' : 'жен.'
            }${this.formatPhone(this.patientInfo.phones)}`;
        },
    },
    methods: {
        formatAge(age) {
            const last = age % 10;
            const last2 = age % 100;
            const def = (last > 1) + (last > 4) + (last === 0) * 2
                + (last2 > 10) * (last2 < 20) * 2;
            switch (def) {
            case 0:
                return 'год';
            case 1:
                return 'года';
            default:
                return 'лет';
            }
        },

        getPatientName({ firstname, lastname, middlename }) {
            const name = `${lastname} ${firstname}`;
            return middlename ? `${name} ${middlename}` : name;
        },

        formatPhone(val) {
            return val
                ? val.replace(/^(\d)(\d{3})(\d+)/g, ', тел. $1 ($2) $3')
                : '';
        },
    },
};
</script>
