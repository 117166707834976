// Calendar
export const APPOINTMENT_SCHEDULE_SET_DATE = 'APPOINTMENT_SCHEDULE_SET_DATE';
export const APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_START = 'APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_START';
export const APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_DONE = 'APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_DONE';
export const APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_ERROR = 'APPOINTMENT_SCHEDULE_WORKING_HOURS_FETCH_ERROR';
export const APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_START = 'APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_START';
export const APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_DONE = 'APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_DONE';
export const APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_ERROR = 'APPOINTMENT_SCHEDULE_APPOINTMENTS_FETCH_ERROR';

// PatientList
export const PATIENT_LIST_SET_PAGINATION = 'PATIENT_LIST_SET_PAGINATION';
export const PATIENT_LIST_FETCH_START = 'PATIENT_LIST_FETCH_START';
export const PATIENT_LIST_FETCH_DONE = 'PATIENT_LIST_FETCH_DONE';
export const PATIENT_LIST_FETCH_ERROR = 'PATIENT_LIST_FETCH_ERROR';
export const PATIENT_LIST_SET_CURRENT_USER_PATIENTS_ONLY = 'PATIENT_LIST_SET_CURRENT_USER_PATIENTS_ONLY';

// MainLabWorker
export const LAB_PATIENT_LIST_FETCH_START = 'LAB_PATIENT_LIST_FETCH_START';
export const LAB_PATIENT_LIST_FETCH_DONE = 'LAB_PATIENT_LIST_FETCH_DONE';
export const LAB_PATIENT_LIST_FETCH_ERROR = 'LAB_PATIENT_LIST_FETCH_ERROR';
