<template>
    <!-- eslint-disable vue/no-v-html -->
    <div :class="['cell-inner', calculateVisible ? 'calculate-cell' : '']">
        <span
            v-if="!editable"
            v-html="value"
        />
        <a-input
            v-if="editable && (type.numberfield || type.textfield)"
            v-model:value="inputValue"
            :default-value="value"
            style="width: 100%;"
        />
        <a-select
            v-if="editable && type.combobox"
            v-model:value="inputValue"
            :default-value="value"
            :get-popup-container="(triggerNode) => triggerNode.parentNode"
            :dropdown-match-select-width="false"
            style="width: 100%;"
        >
            <a-select-option
                v-for="item in store"
                :key="item.id"
            >
                <span v-html="item.name" />
            </a-select-option>
        </a-select>
        <a-select
            v-if="editable && type.tagfield"
            v-model:value="inputValue"
            :default-value="value"
            :get-popup-container="(triggerNode) => triggerNode.parentNode"
            :dropdown-match-select-width="false"
            mode="multiple"
            style="width: 100%;"
        >
            <a-select-option
                v-for="item in store"
                :key="item.id"
            >
                <span v-html="item.name" />
            </a-select-option>
        </a-select>

        <a-select
            v-if="growingCombobox"
            ref="growingCombobox"
            v-model:value="inputValue"
            :get-popup-container="(triggerNode) => triggerNode.parentNode"
            :open="dropdownOpen"
            :dropdown-match-select-width="false"
            style="width: 100%;"
            @focus="onGrowingComboboxFocus"
            @change="() => dropdownOpen=false"
            @dropdown-visible-change="onDropdownVisibleChange"
        >
            <template #dropdownRender="{ menuNode: menu }">
                <div>
                    <v-nodes :vnodes="menu" />
                    <a-divider style="margin: 4px 0;" />
                    <a-form
                        ref="form"
                        :model="dropdownFormState"
                        :rules="dropdownFormRules"
                    >
                        <div class="dropdown-inputs">
                            <a-form-item
                                v-for="input in dropdownInputs"
                                :key="input"
                                :name="[input]"
                                class="form-new-item"
                            >
                                <a-input
                                    v-model:value="dropdownFormState[input]"
                                    @focus="onInputFocus"
                                    @blur="onInputBlur"
                                />
                            </a-form-item>
                            <a-button
                                type="primary"
                                @click="addNewItem"
                            >
                                <template #icon>
                                    <check-circle-outlined />
                                </template>
                            </a-button>
                        </div>
                    </a-form>
                </div>
            </template>
            <a-select-option
                v-for="item in store"
                :key="item.id"
            >
                <span
                    style="color: #990000"
                    v-html="item.name"
                />
            </a-select-option>
        </a-select>

        <a-button
            v-if="calculateVisible"
            class="value-calculate"
            @click="calculateValue"
        >
            <template #icon>
                <calculator-outlined />
            </template>
        </a-button>
    </div>
</template>

<script>
import nunjucks from 'nunjucks';
import CheckCircleOutlined from '@ant-design/icons-vue/CheckCircleOutlined';
import CalculatorOutlined from '@ant-design/icons-vue/CalculatorOutlined';

export default {
    components: {
        CheckCircleOutlined,
        CalculatorOutlined,
        VNodes: ({ vnodes }) => vnodes,
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        type: {
            type: Object,
            default() {
                return {};
            },
        },
        columnId: {
            type: Number,
            default: null,
        },
        localStore: {
            type: Array,
            default: null,
        },
        dropdownTpl: {
            type: String,
            default: '',
        },
        recordValues: {
            type: Object,
            default: () => {},
        },
    },

    emits: [
        'change',
        'add-item',
    ],

    data() {
        return {
            store: [],
            inputValue: this.value,
            dropdownInputs: [],
            dropdownOpen: false,
            isGrowingCombobox: true,
            dropdownInputFocus: false,
            dropdownFormState: {},
            dropdownFormRules: {},
        };
    },
    computed: {
        calculateVisible() {
            return this.editable && !this.isGrowingCombobox && this.dropdownTpl;
        },
        growingCombobox() {
            return this.editable
                && this.isGrowingCombobox
                && !this.type.numberfield
                && !this.type.textfield
                && !this.type.combobox;
        },
    },
    watch: {
        editable(val) {
            if (val && this.columnId) {
                this.loadStore(this.columnId);
            }
        },
        inputValue(val) {
            if (this.type.combobox) {
                this.$emit('change', {
                    id: val,
                    name: this.store.find((x) => x.id === val).name,
                });
            } else if (this.growingCombobox) {
                this.$emit('change', {
                    items: this.store.find((x) => x.id === val).items,
                });
            } else {
                this.$emit('change', val);
            }
        },
    },

    methods: {
        loadStore(id) {
            if (this.editable && (this.type.combobox || this.type.tagfield)) {
                this.$http.get(`/survey/column/${id}/refs`).then((response) => {
                    this.store = response.data;
                    if (response.data.length) {
                        this.isGrowingCombobox = false;
                    }
                });
            }
        },
        makeStore() {
            this.store = [];
            this.dropdownInputs = this.getTemplateColumnsList(this.dropdownTpl);
            this.dropdownFormState = this.dropdownInputs.reduce((state, columnId) => ({
                ...state,
                [columnId]: null,
            }), {});
            this.dropdownFormRules = this.dropdownInputs.reduce((state, columnId) => ({
                ...state,
                [columnId]: [{ required: true, message: ' ' }],
            }), {});

            for (let i = 0; i < this.localStore.length; i += 1) {
                this.store.push({
                    id: i,
                    name: nunjucks.renderString(this.dropdownTpl, { cols: this.localStore[i] }),
                    items: this.localStore[i],
                });
            }
        },
        getTemplateColumnsList(tpl) {
            if (!tpl) {
                return [];
            }
            const regexp = /cols\[(\d+)\]/g;
            const cols = [];
            let result = regexp.exec(tpl);
            while (result) {
                cols.push(result[1]);
                result = regexp.exec(tpl);
            }
            return cols.filter((value, index, self) => self.indexOf(value) === index);
        },
        onGrowingComboboxFocus() {
            if (!this.dropdownInputs.length) {
                this.makeStore();
            }
        },
        onDropdownVisibleChange(open) {
            if (!this.dropdownInputFocus) {
                this.dropdownOpen = open;
            }
        },
        onInputFocus() {
            this.dropdownInputFocus = true;
        },
        onInputBlur(e) {
            this.dropdownInputFocus = false;
            if (!e.relatedTarget || e.relatedTarget.parentNode) {
                return;
            }
            if (['ant-form-item-children', 'dropdown-inputs'].indexOf(e.relatedTarget.parentNode.className) > -1) {
                return;
            }
            this.dropdownOpen = false;
        },
        calculateValue() {
            const template = new nunjucks.Template(this.dropdownTpl);
            try {
                template.compile();
                if (template.compiled) {
                    const valueStr = template.render({ cols: this.recordValues });
                    if (/\{.+\}/.test(valueStr)) {
                        const value = JSON.parse(valueStr);
                        if (value && value.id) {
                            this.inputValue = value.id;
                        }
                    } else {
                        this.inputValue = valueStr;
                    }
                }
            } catch (e) {
                //
            }
        },

        async addNewItem() {
            let values;
            try {
                values = await this.$refs.form.validate();
            } catch (err) {
                return;
            }

            this.$emit('add-item', values);
            this.$refs.form.resetFields();
            this.makeStore();
        },
    },
};
</script>

<style lang="scss">
.dropdown-inputs {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    line-height: 1;
    margin: 0 2px 5px 2px;

    input {
        display: inline-flex;
        width: 100%;
    }
    .ant-form-item {
        margin-bottom: 0;
        line-height: 1;
    }
}
.ant-btn-default.value-calculate {
    margin: 0;
    padding: 0 8px;
}
.calculate-cell {
    display: grid;
    grid-template-columns: auto 32px;
}
</style>
