<template>
    <div class="main-lab">
        <a-layout>
            <page-header
                class="main-lab-header"
                @search="({ search }) => searchValue = search"
            />
            <a-layout-sider
                class="patient-list"
                :width="280"
            >
                <a-spin
                    :spinning="patientsLoading"
                    :indicator="loadingIndicator"
                    tip="Загрузка..."
                    class="patient-list"
                >
                    <patient-list-item
                        :item="{ name: 'Все пациенты' }"
                        :selected="!patientId"
                        @select="selectPatient"
                        @select-patient-name="selectPatientName"
                    />
                    <patient-list-item
                        v-for="item in patientList"
                        :key="item.patientId"
                        :item="item"
                        :selected="parseInt(patientId, 10) === item.patientId"
                        @select="selectPatient"
                        @select-patient-name="selectPatientName"
                    />
                    <a-button
                        v-if="hasMore"
                        type="primary"
                        class="more-btn"
                        @click="loadMore"
                    >
                        Показать ещё...
                    </a-button>
                </a-spin>
            </a-layout-sider>
            <div
                :style="{
                    marginLeft: '280px',
                    marginTop: '55px',
                    width: 'calc(100% - 280px)',
                    height: 'calc(100% - 60px)',
                    position: 'fixed',
                    backgroundColor: 'white',
                }"
            >
                <div
                    :style="{
                        height: '100%',
                        display: 'grid',
                        gridTemplateColumns: 'auto',
                        gridTemplateRows: '100%',
                    }"
                >
                    <div class="content">
                        <div class="tab-buttons-block">
                            <a-button
                                v-for="tab in tabs"
                                :key="tab.id"
                                :class="{ 'btn-active': currentTab === tab.id }"
                                @click="tabClicked(tab.id)"
                            >
                                {{ tab.name }}
                            </a-button>
                        </div>
                        <div class="content-inner">
                            <router-view
                                @referral-update="fetchPatientList"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </a-layout>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { defineComponent, h } from 'vue';
import titleMixin from '@/mixins/titleMixin';
import PageHeader from '@/components/PageHeader.vue';
import LoadingSpinner from '@/utils/Spinner.vue';
import PatientListItem from '@/components/PatientListItem.vue';

export default defineComponent({
    components: {
        PageHeader,
        PatientListItem,
    },
    mixins: [titleMixin],
    data() {
        return {
            searchValue: null,
            loadingIndicator: {
                template: h(LoadingSpinner),
            },
            tabs: [
                { id: 'lab-reception', name: 'Приёмка' },
                { id: 'lab-in-progress', name: 'Лаборатория' },
                { id: 'lab-store', name: 'Хранение' },
                { id: 'lab-results', name: 'Результаты' },
            ],
        };
    },

    computed: {
        patientId() {
            return this.$route.params.patientId;
        },
        surveyId() {
            return this.$route.params.surveyId;
        },
        routeName() {
            return this.$route.name;
        },

        currentTab() {
            if ([
                'lab-reception',
                'lab-in-progress',
                'lab-store',
            ].includes(this.routeName)) {
                return this.routeName;
            }
            if ([
                'lab-patient-card',
                'lab-survey-card',
            ].includes(this.routeName)) {
                return 'lab-results';
            }
            return '';
        },

        ...mapState('labPatientList', {
            patientsLoading: 'fetching',
            patientList: 'data',
            hasMore: 'hasMore',
            pagesLoaded: 'pagesLoaded',
        }),
    },

    watch: {
        searchValue(val) {
            this.getPatients(val);
        },
    },
    created() {
        this.getPatients(this.searchString);
        if (this.routeName === 'main') {
            this.navigate({
                newTab: 'lab-reception',
            });
        }
    },

    methods: {
        ...mapActions('labPatientList', [
            'fetchPatients',
        ]),

        getPatients(query = null) {
            this.fetchPatients({
                search: query,
                offset: 0,
            });
        },

        fetchPatientList() {
            this.getPatients(this.searchValue);
        },

        loadMore() {
            this.fetchPatients({
                search: this.searchValue,
                offset: this.pagesLoaded,
            });
        },

        selectPatient(newPatientId) {
            this.navigate({ newPatientId });
        },

        selectPatientName(patient) {
            if (patient && patient.patientId) {
                this.setPageTitle(patient.name);
            } else {
                this.setPageTitle();
            }
        },

        tabClicked(tab) {
            if (this.currentTab === tab) {
                return;
            }
            this.navigate({ newTab: tab });
        },

        navigate({ newTab, newPatientId }) {
            const params = {
                patientId: this.patientId,
            };

            let name = '';
            if (newPatientId) {
                params.patientId = newPatientId;
                if ([
                    'lab-survey-card',
                    'lab-results',
                ].indexOf(this.routeName) !== -1) {
                    name = 'lab-patient-card';
                } else {
                    name = this.routeName;
                }
            } else if (newTab) {
                if (newTab === 'lab-results') {
                    name = this.patientId
                        ? 'lab-patient-card'
                        : 'lab-results';
                } else {
                    name = newTab;
                }
            } else if (['lab-survey-card', 'lab-patient-card'].indexOf(this.routeName) !== -1) {
                name = 'lab-results';
            } else {
                name = this.routeName;
            }
            this.$router.push({ name, params });
        },
    },
});
</script>

<style lang="scss">

.content {
    padding: 10px;
    width: 100%;
    position: relative;
    overflow: auto;
}

.main-lab {
    height: 100%;

    .ant-layout, .ant-layout-sider,
    .ant-layout-sider-children, .ant-spin-nested-loading,
    .ant-spin-container {
        height: 100%;
    }

    .more-btn {
        width: 200px;
        margin: 10px 20px;
    }

    .patient-list {
        padding-top: 64px;
    }

    .main-lab-header {
        box-shadow: none;
        position: fixed;
        width: 100%;
        z-index: 2;
    }
}

.tab-buttons-block {
    text-align: left;
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px #3c40434d;
    button {
        font-weight: 500;
        border: none;
        border-bottom: 1px solid;
        border-color: rgba(0, 0, 0, 0.15);
        border-radius: 0;
        width: 120px;
        padding-bottom: 30px;
        margin: 10px;

        &[ant-click-animating-without-extra-node]::after {
            animation: 0s !important;
        }

        &.btn-active {
            color: #1890ff;
            border-bottom: 2px solid #1890ff;
        }
    }
}
</style>
