<template>
    <a-badge
        :count="unreadedCount"
    >
        <a-button
            :title="title"
            @click="goToUrl"
        >
            <template #icon>
                <message-outlined />
            </template>
        </a-button>
    </a-badge>
</template>

<script>
import MessageOutlined from '@ant-design/icons-vue/MessageOutlined';
import MqttClient from './mqtt-client';

export default {
    components: {
        MessageOutlined,
    },
    props: {
        url: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            mqtt: new MqttClient('/chat/session', process.env.VUE_APP_MQTT_URL, process.env.VUE_APP_MQTT_SKIPCONNECT),
            unreadedCount: 0,
        };
    },
    watch: {
        unreadedCount() {
            if (this.unreadedCount > 0) {
                this.openNotification();
            }
        },
    },
    async created() {
        await this.mqtt.connect();
        this.updateUnreaded();
        this.$emitter.on('onNewMessage', this.updateUnreaded);
    },
    methods: {
        openNotification() {
            this.$notification.open({
                message: 'Новое сообщение',
                description: 'У вас есть новые непрочтенные сообщения',
                placement: 'bottomRight',
                onClick: () => {
                    this.$notification.destroy();
                },
            });
        },
        goToUrl() {
            window.open(this.url, '_blank');
        },
        async updateUnreaded() {
            this.unreadedCount = await this.mqtt.getUserUnreadedCount();
        },
    },
};
</script>

<style lang="scss">
</style>
