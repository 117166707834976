<template>
    <a-modal
        :ok-button-props="{
            loading: fetching,
            type: 'primary',
        }"
        :mask-closable="false"
        :visible="showModal"
        ok-text="Да"
        cancel-text="Нет"
        title="Предупреждение"
        @ok="ping"
        @cancel="logout"
    >
        <a-alert
            v-if="error"
            type="error"
        >
            <template #message>
                {{ error }}
            </template>
        </a-alert>
        <p>
            Время сессии почти истекло.
        </p>
        <p>
            Выход через: {{ timeLeft }}
        </p>
        <p>
            Продолжить работу?
        </p>
    </a-modal>
</template>

<script>
import axios from 'axios';

const INACTIVITY_MS = 1000 * 60 * 54;
const LOGOUT_MS = 1000 * 60 * 59;

export default {
    props: {
        ms: {
            type: Number,
            required: false,
            default() {
                return 1000 * 60 * 5;
            },
        },
    },

    emits: {
        'session-expired': null,
    },

    data() {
        return {
            responseInterceptor: null,
            inactivityTimer: null,
            logoutTimer: null,
            countdownTimer: null,
            showModal: false,
            fetching: false,
            error: null,
            timeEnd: null,
            msLeft: -1,
        };
    },

    computed: {
        loggedIn() {
            return this.$route.path !== '/login';
        },

        timeLeft() {
            if (this.msLeft < 0) {
                return '00:00';
            }
            const dateLeft = new Date(this.msLeft);
            return `${
                this.withLeadingZero(dateLeft.getMinutes())
            }:${
                this.withLeadingZero(dateLeft.getSeconds())
            }`;
        },
    },

    watch: {
        showModal(value) {
            clearInterval(this.countdownTimer);
            if (!value) {
                return;
            }

            const offset = 500;
            this.timeEnd = (+new Date()) + this.ms + offset;
            this.msLeft = this.timeEnd - new Date();

            this.countdownTimer = setInterval(() => {
                this.msLeft = this.timeEnd - new Date();
            }, 1000);
        },
    },

    mounted() {
        this.responseInterceptor = axios.interceptors.response.use(
            (response) => {
                if (!this.loggedIn) {
                    return response;
                }

                clearTimeout(this.inactivityTimer);
                clearTimeout(this.logoutTimer);
                this.inactivityTimer = setTimeout(() => {
                    if (this.loggedIn) {
                        this.showModal = true;
                    }
                }, INACTIVITY_MS);
                this.logoutTimer = setTimeout(
                    () => {
                        if (this.loggedIn) {
                            this.$emit('session-expired');
                            this.logout();
                        }
                    },
                    LOGOUT_MS,
                );
                return response;
            },
            (error) => Promise.reject(error),
        );
    },

    beforeUnmount() {
        clearTimeout(this.inactivityTimer);
        clearTimeout(this.logoutTimer);
        clearInterval(this.countdownTimer);
        axios.interceptors.response.eject(this.responseInterceptor);
    },

    methods: {
        logout() {
            this.showModal = false;
            clearTimeout(this.inactivityTimer);
            clearTimeout(this.logoutTimer);
            this.$http.post('/user/logout').then(
                () => window.location.reload(),
                () => window.location.reload(),
            );
        },

        ping() {
            this.error = null;
            this.fetching = true;
            this.$http.get('keepalive')
                .then((response) => this.pingSuccess(response))
                .catch((err) => this.pingError(err));
        },

        pingSuccess() {
            this.showModal = false;
            this.fetching = false;
        },

        pingError(err) {
            this.fetching = false;
            if (err.response.status === 401) {
                window.location.reload();
                return;
            }

            if (err.response.data && err.response.data.msg) {
                this.error = `Ошибка! ${err.response.data.msg}`;
            } else {
                this.error = 'Ошибка продления сессии.';
            }
        },

        withLeadingZero(number) {
            return number < 10 ? `0${number}` : `${number}`;
        },
    },
};
</script>
