
import { defineComponent } from 'vue';
import ruRu from 'ant-design-vue/lib/locale-provider/ru_RU';
import InactivityAlert from '@/utils/InactivityAlert.vue';

const Component = defineComponent({
    components: {
        InactivityAlert,
    },
    provide() {
        return {
            sessionIsExpired: this.sessionIsExpired,
        };
    },
    data() {
        return {
            sessionIsExpired: { value: false },
            ruRu,
        };
    },
});

export default Component;
