import { AxiosRequestConfig } from 'axios';
import {
    IFatAppointment,
    IPartialAppointment,
} from '../../life-impulse-tools/types';
import { get, put, post } from '../utils/axiosConnectionTimeout';

export const getAppointments = (
    options: AxiosRequestConfig,
) => {
    const { params } = options;
    if (!params.page) {
        throw new Error('page parameter is required.');
    }
    return get<{
        data: IFatAppointment[];
        count: number;
    }>('/appointments', options);
};

export const updateAppointmentWithProtection = (
    id: number,
    action: 'PUT' | 'DELETE',
    data: IPartialAppointment,
) => put(`/appointment/${id}/protected/${action}`, data);

export const createAppointment = (
    data: IPartialAppointment,
) => post('/appointment', {
    data: JSON.stringify(data),
});
