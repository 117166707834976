import CloseCircleOutlined from '@ant-design/icons-vue/CloseCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons-vue/InfoCircleOutlined';
import {
    computed,
    h,
    ref,
    getCurrentInstance,
    Ref,
} from 'vue';
import { notification } from 'ant-design-vue';
import { Emitter } from 'mitt';
import axios, { AxiosResponse } from 'axios';

import { catchAxiosError } from '../api/utils';

axios.defaults.baseURL = '/api';
export { axios };
export * from './axiosConnectionTimeout';

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

export type XOR<T, U> = (T | U) extends object
    ? (Without<T, U> & U) | (Without<U, T> & T)
    : (T | U);

export type Nullable<T> = T | null;

export type Pagination = {
    pageSize: number;
    current: number;
    total: number;
};

export type Sort = {
    property: string;
    direction: 'ASC' | 'DESC';
};

export interface TableColumnOptions {
    title?: string | Function;
    dataIndex?: string ;
    key?: string;
    width?: string | number;
    ellipsis?: boolean;
    customRender?: Function;
    customCell?: Function;
    slots?: { [index: string]: string };
}

type debounceCallback = (input: string) => void;
type debounceParams = {
    ms: number;
    callbackFn: debounceCallback;
}
type debounceManager = {
    start: (input: string) => void;
    stop: () => void;
}

type TaskQueueElement = Record<string, any> & {
    type: string;
}

export const useEmitter = (): Emitter<Record<string, unknown>> | null => {
    const internalInstance = getCurrentInstance();
    if (!internalInstance) {
        return null;
    }
    return internalInstance.appContext.config.globalProperties.$emitter;
};

type UseRequestParamsType<T, P = any> = {
    fetcher: (params?: P) => Promise<AxiosResponse<T>>;
    defaultErrorMsg: string;
    onError?: (error: string) => void;
};
export function useRequest<T, P = any>({
    fetcher,
    defaultErrorMsg = 'Произошла сетевая ошибка.',
    onError,
}: UseRequestParamsType<T, P>): [{
    data: Ref<T | undefined>;
    isFetching: Ref<boolean>;
    error: Ref<string>;
}, (params?: P) => Promise<void>] {
    const data = ref<T>();
    const isFetching = ref(true);
    const error = ref<string>('');

    const doRequest = async (params?: P) => {
        isFetching.value = true;
        try {
            const response = await fetcher(params);
            data.value = response.data;
        } catch (err) {
            error.value = catchAxiosError(
                err,
                defaultErrorMsg,
            );
            if (onError) {
                onError(error.value);
            }
            return;
        } finally {
            isFetching.value = false;
        }
    };
    return [{
        data,
        isFetching,
        error,
    }, doRequest];
}

export const useTaskQueue = () => {
    let tasks: Array<TaskQueueElement> = [];
    const currentTask = ref<Nullable<TaskQueueElement>>(null);

    const spawnNextTask = () => {
        const nextTask = tasks.shift();
        currentTask.value = nextTask || null;
    };

    const enqueue = (newTasks: Array<TaskQueueElement> | TaskQueueElement) => {
        let tasksToEnqueue = newTasks;
        if (!tasksToEnqueue) {
            throw new Error('No tasks provided.');
        }
        if (!Array.isArray(tasksToEnqueue)) {
            tasksToEnqueue = [tasksToEnqueue];
        }
        tasks.push(...tasksToEnqueue);
        if (!currentTask.value) {
            spawnNextTask();
        }
    };

    const done = async () => {
        currentTask.value = null;
        spawnNextTask();
    };

    const cancel = (taskType: Nullable<string> = null) => {
        tasks = taskType
            ? tasks.filter(({ type }) => type !== taskType)
            : [];
    };

    return {
        currentTask,
        enqueue,
        done,
        cancel,
    };
};

export const debounce: (params: debounceParams) => debounceManager = ({
    ms = 300,
    callbackFn,
}) => {
    if (!callbackFn) {
        throw new Error('No callback function provided.');
    }

    let timerId: ReturnType<typeof setTimeout>;
    return {
        start: (input) => {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(() => callbackFn(input), ms);
        },

        stop: () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        },
    };
};

type QueryParams = {
    page?: string;
    sort?: string;
};

type QueryBuilderParams = {
    pagination: Pagination;
    sort: Sort;
}

export const buildQueryParams: (params: QueryBuilderParams) => QueryParams = ({
    pagination,
    sort,
}) => {
    const params: QueryParams = {};
    if (pagination) {
        const { current, pageSize } = pagination;
        params.page = JSON.stringify([current - 1, pageSize]);
    }
    if (sort) {
        params.sort = JSON.stringify([{
            property: sort.property,
            direction: sort.direction,
        }]);
    }
    return params;
};

const layoutMapping: Record<string, string> = {
    q: 'й',
    w: 'ц',
    e: 'у',
    r: 'к',
    t: 'е',
    y: 'н',
    u: 'г',
    i: 'ш',
    o: 'щ',
    p: 'з',
    '[': 'х',
    ']': 'ъ',
    a: 'ф',
    s: 'ы',
    d: 'в',
    f: 'а',
    g: 'п',
    h: 'р',
    j: 'о',
    k: 'л',
    l: 'д',
    ';': 'ж',
    '\'': 'э',
    z: 'я',
    x: 'ч',
    c: 'с',
    v: 'м',
    b: 'и',
    n: 'т',
    m: 'ь',
    ',': 'б',
    '.': 'ю',
    '/': '.',
    '`': 'ё',
    Q: 'Й',
    W: 'Ц',
    E: 'У',
    R: 'К',
    T: 'Е',
    Y: 'Н',
    U: 'Г',
    I: 'Ш',
    O: 'Щ',
    P: 'З',
    '{': 'Х',
    '}': 'Ъ',
    A: 'Ф',
    S: 'Ы',
    D: 'В',
    F: 'А',
    G: 'П',
    H: 'Р',
    J: 'О',
    K: 'Л',
    L: 'Д',
    ':': '^',
    '"': 'Э',
    '|': '/',
    Z: 'Я',
    X: 'Ч',
    C: 'С',
    V: 'М',
    B: 'И',
    N: 'Т',
    M: 'Ь',
    '<': 'Б',
    '>': 'Ю',
    '?': ',',
    '~': 'Ё',
    '@': '"',
    '#': '№',
    $: ';',
    '^': ':',
    '&': '?',
    й: 'q',
    ц: 'w',
    у: 'e',
    к: 'r',
    е: 't',
    н: 'y',
    г: 'u',
    ш: 'i',
    щ: 'o',
    з: 'p',
    х: '[',
    ъ: ']',
    ф: 'a',
    ы: 's',
    в: 'd',
    а: 'f',
    п: 'g',
    р: 'h',
    о: 'j',
    л: 'k',
    д: 'l',
    ж: ';',
    э: '\'',
    я: 'z',
    ч: 'x',
    с: 'c',
    м: 'v',
    и: 'b',
    т: 'n',
    ь: 'm',
    б: ',',
    ю: '.',
    ё: '`',
    Й: 'Q',
    Ц: 'W',
    У: 'E',
    К: 'R',
    Е: 'T',
    Н: 'Y',
    Г: 'U',
    Ш: 'I',
    Щ: 'O',
    З: 'P',
    Х: '{',
    Ъ: '}',
    Ф: 'A',
    Ы: 'S',
    В: 'D',
    А: 'F',
    П: 'G',
    Р: 'H',
    О: 'J',
    Л: 'K',
    Д: 'L',
    Ж: ':',
    Э: '"',
    Я: 'Z',
    Ч: 'X',
    С: 'C',
    М: 'V',
    И: 'B',
    Т: 'N',
    Ь: 'M',
    Б: '<',
    Ю: '>',
    Ё: '~',
    '№': '#',
};

const alphabetMapping: Record<string, string> = {
    e: 'е',
    t: 'т',
    y: 'у',
    o: 'о',
    p: 'р',
    a: 'а',
    h: 'н',
    k: 'к',
    x: 'х',
    c: 'с',
    n: 'н',
    m: 'м',
    E: 'Е',
    T: 'Т',
    Y: 'У',
    O: 'О',
    P: 'Р',
    A: 'А',
    H: 'Н',
    K: 'К',
    X: 'Х',
    C: 'С',
    N: 'Н',
    M: 'М',
    у: 'y',
    к: 'k',
    е: 'e',
    н: 'n',
    х: 'x',
    а: 'a',
    р: 'p',
    о: 'o',
    с: 'c',
    м: 'm',
    т: 't',
    У: 'Y',
    К: 'K',
    Е: 'E',
    Н: 'H',
    Х: 'X',
    В: 'B',
    А: 'A',
    Р: 'P',
    О: 'O',
    С: 'C',
    М: 'M',
    Т: 'T',
};

export const convertLayout = (str: string) => str.replace(
    /[qwertyuiop[\]asdfghjkl;'zxcvbnm,./`QWERTYUIOP{}ASDFGHJKL:"|ZXCVBNM<>?~@#$^&йцукенгшщзхъфывапролджэячсмитьбюёЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮЁ№]/g,
    (m: string) => layoutMapping[m],
);

export const convertAlphabet = (str: string) => str.replace(
    /[etyopahkxcnmETYOPAHKXCNMукенхаросмтУКЕНХВАРОСМТ]/g,
    (m) => alphabetMapping[m],
);

export const showErrorNotification = (
    message: string,
) => {
    notification.open({
        style: { zIndex: 20000 },
        message: 'Ошибка',
        duration: 0,
        description: message,
        icon: h(CloseCircleOutlined, { style: { color: '#f5222d' } }),
    });
};

export const showInfoNotification = (
    message: string,
) => {
    notification.open({
        style: { zIndex: 20000 },
        message: 'Информация',
        duration: 5,
        description: message,
        icon: h(InfoCircleOutlined, { style: { color: '#5d80e7' } }),
    });
};

export const getFormattedPrice = (price: number, keepCurrencySign = false) => {
    if (!price) {
        return '0,00';
    }
    const formatted = price.toLocaleString('ru-RU', {
        style: 'currency',
        currency: 'RUB',
    });
    return keepCurrencySign
        ? formatted
        : formatted.slice(0, formatted.length - 1);
};

export function useLocalQueryFilter() {
    const query = ref('');
    const onQueryChange = (event: InputEvent) => {
        const target = event.target as HTMLInputElement;
        if (!target) {
            return;
        }
        query.value = target.value;
    };

    const convertedLayoutQuery = computed(
        () => convertLayout(query.value).toLowerCase(),
    );
    const convertedAlphabetQuery = computed(
        () => convertAlphabet(query.value).toLowerCase(),
    );
    const isFiltered = (prop: string | null) => {
        const q = query.value || '';
        if (q.length < 2) {
            return false;
        }
        if (!prop) {
            return true;
        }
        const lowered = prop.toLowerCase();
        return !lowered.includes(q.toLowerCase())
            && !lowered.includes(convertedLayoutQuery.value)
            && !lowered.includes(convertedAlphabetQuery.value);
    };

    return {
        isFiltered,
        onQueryChange,
    };
}
