import axios, {
    AxiosRequestConfig,
    AxiosStatic,
} from 'axios';

import { App } from 'vue';

const CONNECTION_TIMEOUT = 50000;
const NO_CONNECTION_MSG = 'Невозможно подключиться к серверу. Пожалуйста, попробуйте позднее.';
let installedAxios = axios;

interface RequestConfig extends AxiosRequestConfig {
    connectionTimeout?: number;
}

const getConnectionTimeout = function getConnectionTimeout(config: RequestConfig): number {
    if (config.connectionTimeout !== undefined) {
        return config.connectionTimeout;
    }
    if (config.timeout !== undefined) {
        return config.timeout;
    }
    return CONNECTION_TIMEOUT;
};

type MethodWithoutData = 'get' | 'delete';
type MethodWithData = 'post' | 'put';

const requestFactory = function requestFactory<T>(
    method: MethodWithoutData,
) {
    return async <X extends T>(url: string, config: RequestConfig = {}) => {
        const timeout = getConnectionTimeout(config);
        const axiosConfig = { ...config };
        delete axiosConfig.connectionTimeout;

        const abort = installedAxios.CancelToken.source();
        const connectionTimer = setTimeout(
            () => abort.cancel(NO_CONNECTION_MSG),
            timeout,
        );

        const response = await installedAxios[method]<X>(url, {
            cancelToken: abort.token,
            ...axiosConfig,
        });
        clearTimeout(connectionTimer);
        return response;
    };
};

const dataRequestFactory = function dataRequestFactory<T>(
    method: MethodWithData,
) {
    return async <X extends T>(
        url: string,
        data: any = {},
        config: RequestConfig = {},
    ) => {
        const timeout = getConnectionTimeout(config);
        const axiosConfig = { ...config };
        delete axiosConfig.connectionTimeout;

        const abort = installedAxios.CancelToken.source();
        const connectionTimer = setTimeout(
            () => abort.cancel(NO_CONNECTION_MSG),
            timeout,
        );

        const response = await installedAxios[method]<X>(url, data, {
            cancelToken: abort.token,
            ...axiosConfig,
        });
        clearTimeout(connectionTimer);
        return response;
    };
};

const get = requestFactory('get');
const post = dataRequestFactory('post');
const put = dataRequestFactory('put');
const deleteFn = requestFactory('delete');

export {
    get,
    post,
    put,
    deleteFn as delete,
};

export const axiosPlugin = {
    install: (app: App<Element>, axiosToInstall: AxiosStatic) => {
        installedAxios = axiosToInstall;
        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$http = {
            get,
            post,
            put,
            delete: deleteFn,
        };
    },
};
