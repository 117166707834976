<template>
    <a-row class="medication-input-row">
        <a-col
            v-if="!removeHidden"
            :span="2"
        >
            <a-button
                :disabled="removeDisabled"
                type="text"
                danger
                @click="removeMe"
            >
                -
            </a-button>
        </a-col>
        <a-col
            v-if="!subtypeHidden"
            :span="removeHidden ? 12 : 10"
        >
            <a-select
                :disabled="subtypeDisabled"
                :value="subtypeId"
                class="med-input"
                @change="subtypeChange"
            >
                <a-select-option
                    v-for="subtype in subtypes"
                    :key="`${subtype.id}`"
                    :disabled="subtype.disabled"
                >
                    {{ subtype.name }}
                </a-select-option>
            </a-select>
        </a-col>
        <a-col :span="valueFieldWidth">
            <a-input-number
                :min="1"
                :disabled="producedDisabled"
                :value="produced"
                class="med-input"
                @change="producedChange"
            />
        </a-col>
        <a-col :span="valueFieldWidth">
            <a-input-number
                :min="initialSuppliedAmount"
                :value="supplied"
                class="med-input"
                @change="suppliedChange"
            />
        </a-col>
    </a-row>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        produced: {
            type: Number,
            required: true,
        },
        supplied: {
            type: Number,
            required: true,
        },
        subtypeId: {
            type: String,
            required: false,
            default() {
                return null;
            },
        },
        subtypeSettings: {
            type: Object,
            required: true,
        },
        removeSettings: {
            type: Object,
            required: true,
        },
        producedSettings: {
            type: Object,
            required: true,
        },
    },
    emits: ['remove', 'update:subtype-id', 'update:supplied', 'update:produced'],
    data() {
        return {
            initialSuppliedAmount: 0,
        };
    },

    computed: {
        producedDisabled() {
            if (!this.producedSettings) {
                return false;
            }
            return this.producedSettings.disabled;
        },
        removeDisabled() {
            return this.removeSettings.disabled;
        },
        removeHidden() {
            return this.removeSettings.hidden;
        },
        subtypes() {
            return this.subtypeSettings.options || [];
        },
        subtypeDisabled() {
            return this.subtypeSettings.disabled;
        },
        subtypeHidden() {
            return this.subtypeSettings.hidden;
        },
        valueFieldWidth() {
            if (!this.removeHidden && !this.subtypeHidden) {
                return 6;
            }
            if (!this.removeHidden) {
                return 10;
            }
            if (!this.subtypeHidden) {
                return 6;
            }
            return 12;
        },
    },

    mounted() {
        this.initialSuppliedAmount = this.supplied;
    },

    methods: {
        removeMe() {
            this.$emit('remove');
        },
        subtypeChange(value) {
            this.$emit('update:subtype-id', value);
        },
        suppliedChange(value) {
            this.$emit('update:supplied', value);
        },
        producedChange(value) {
            this.$emit('update:produced', value);
        },
    },
});

</script>
