<template>
    <div class="next-appointment">
        <div v-if="isHidden">
            <a-button
                data-test-id="new-appointment-btn"
                @click="onShowComponentClick"
            >
                <template #icon>
                    <plus-outlined />
                </template>
                Новый приём
            </a-button>
        </div>
        <div
            v-if="!isHidden"
            class="selector"
        >
            <div class="appointment-staff-select">
                <a-radio-group
                    v-model:value="nextAppointmentModel.whom"
                    data-test-id="appointment-staff-rg"
                >
                    <a-radio
                        :value="1"
                    />
                    <a-radio
                        :value="2"
                    />
                </a-radio-group>
            </div>
            <div class="appointment-staff">
                <div>
                    Врач
                    <a-select
                        v-model:value="nextAppointmentModel.staffUserId"
                        allow-clear
                        show-search
                        class="appointment-input"
                        data-test-id="appointment-user-list"
                        :filter-option="filterSelectOptions"
                        :dropdown-match-select-width="false"
                        :disabled="nextAppointmentModel.whom !== 1"
                    >
                        <a-select-option
                            v-for="staff in staffList"
                            :key="staff.id"
                        >
                            {{ staff.name }}
                        </a-select-option>
                    </a-select>
                </div>
                <div>
                    Специальность
                    <div>
                        <a-select
                            v-model:value="nextAppointmentModel.specialtyMedId"
                            allow-clear
                            show-search
                            class="appointment-input"
                            data-test-id="appointment-specialty-list"
                            :filter-option="filterSelectOptions"
                            :dropdown-match-select-width="false"
                            :get-popup-container="(triggerNode) => triggerNode.parentNode"
                            :disabled="nextAppointmentModel.whom !== 2"
                        >
                            <a-select-option
                                v-for="specialty in specialtyList"
                                :key="specialty.id"
                            >
                                {{ specialty.name }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
            </div>
            <div class="datepicker">
                Дата
                <a-date-picker
                    v-model:value="nextAppointmentModel.day"
                    :disabled-date="dateIsDisabled"
                    class="appointment-input"
                    data-test-id="appointment-date-picker"
                    format="DD.MM.YYYY"
                />
            </div>
            <div class="actions">
                <div>
                    <a-button
                        :disabled="!isValid"
                        data-test-id="appointment-save-btn"
                        @click="onSaveClick"
                    >
                        <template #icon>
                            <check-outlined />
                        </template>
                    </a-button>
                    <a-button
                        data-test-id="cancel-appointment-changes-btn"
                        @click="onCancelClick"
                    >
                        <template #icon>
                            <close-outlined />
                        </template>
                    </a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import PlusOutlined from '@ant-design/icons-vue/PlusOutlined';
import CheckOutlined from '@ant-design/icons-vue/CheckOutlined';
import CloseOutlined from '@ant-design/icons-vue/CloseOutlined';
import { Modal } from 'ant-design-vue';
import { cloneDeep, isEqual } from 'lodash';
import {
    getMedSpecialties,
    getUsers,
} from '@api';
import {
    convertAlphabet,
    convertLayout,
    showErrorNotification,
} from 'vue-web-components/src/utils';

const EMPTY_APPOINTMENT = {
    id: null,
    staffUserId: null,
    specialtyMedId: null,
    whom: null,
    day: moment(),
    clinicId: localStorage.clinics?.[0],
    state: 1,
    stateCode: 'WAIT',

    isRemote: false,
    remoteUrl: '',
    specialtyMedName: null,
    staffUserName: null,
    protocolId: null,
    patientId: null,
    clinicCabinetId: null,
    comment: '',
    timeCreate: null,
    timeEdit: null,
    timeEnd: null,
    timeStart: null,
};

export default {
    components: {
        CheckOutlined,
        CloseOutlined,
        PlusOutlined,
    },
    props: {
        value: {
            type: Object,
            default: undefined,
        },
        patientId: {
            type: Number,
            required: true,
        },
        protocolId: {
            type: Number,
            default: null,
        },
    },
    emits: [
        'cancel',
        'create',
        'update',
    ],
    data() {
        return {
            nextAppointmentModel: undefined,
            isHidden: true,
            isFetching: false,
            staffList: [],
            specialtyList: [],
        };
    },

    computed: {
        isValid() {
            const {
                day,
                specialtyMedId,
                staffUserId,
                whom,
            } = this.nextAppointmentModel;
            return (
                (staffUserId && whom === 1) || (specialtyMedId && whom === 2)
            ) && day;
        },
    },

    watch: {
        value(newValue) {
            if (!newValue) {
                this.clearAppointment();
                return;
            }

            if (isEqual(newValue, this.nextAppointmentModel)) {
                return;
            }
            this.nextAppointmentModel = cloneDeep(newValue);
            this.nextAppointmentModel.day = moment(
                this.nextAppointmentModel.day,
                'YYYY-MM-DD',
            );
            this.nextAppointmentModel.whom = this.nextAppointmentModel.staffUserId
                ? 1 : 2;
            this.isHidden = false;
        },
    },

    created() {
        this.fetchRefs();
    },

    methods: {
        filterSelectOptions(input, option) {
            const { textContent: text } = option.children[0].el;
            if (!text) {
                return false;
            }
            return text.toLowerCase().includes(input.toLowerCase())
                || text.toLowerCase().includes(convertLayout(input).toLowerCase())
                || text.toLowerCase().includes(convertAlphabet(input).toLowerCase());
        },

        onShowComponentClick() {
            this.isHidden = false;
            this.nextAppointmentModel = {
                ...EMPTY_APPOINTMENT,
                patientId: this.patientId,
                protocolId: this.protocolId,
            };
            if (!this.staffList.length && !this.isFetching) {
                this.fetchRefs();
            }
        },

        async fetchRefs() {
            this.isFetching = true;
            try {
                this.staffList = (await getUsers({
                    params: {
                        specialtyMedExists: 1,
                        sort: JSON.stringify([{
                            property: 'name',
                            direction: 'asc',
                        }]),
                    },
                })).data;
                this.specialtyList = (await getMedSpecialties()).data;
            } catch (err) {
                showErrorNotification('Не удалось загрузить справочники.');
            } finally {
                this.isFetching = false;
            }
        },

        dateIsDisabled(date) {
            return date < this.$moment().startOf('day');
        },

        onCancelClick() {
            this.$emit('cancel');
            this.clearAppointment();
        },

        clearAppointment(id) {
            if (id
                && this.nextAppointmentModel
                && this.nextAppointmentModel.id !== id
            ) {
                return;
            }

            this.nextAppointmentModel = undefined;
            this.isHidden = true;
        },

        fireSaveEvent() {
            const {
                id,
                day,
                staffUserId,
                specialtyMedId,
                whom,
            } = this.nextAppointmentModel;
            let { comment } = this.nextAppointmentModel;

            const { specialtyMedName, staffUserName } = this.getSelectedName(
                this.nextAppointmentModel,
            );
            if (!id) {
                comment = this.getCommentText(day, specialtyMedName, staffUserName);
            }
            this.$emit(id ? 'update' : 'create', {
                ...this.nextAppointmentModel,
                clinicId: this.nextAppointmentModel.clinicId
                    || localStorage.clinics?.[0],
                day: day.format('YYYY-MM-DD'),
                staffUserId: whom === 1 ? staffUserId : null,
                specialtyMedId: whom === 2 ? specialtyMedId : null,
                state: 1,
                stateCode: 'WAIT',
                timeEnd: null,
                timeStart: null,
                comment,
                specialtyMedName,
                staffUserName,
            });
        },

        onSaveClick() {
            const { stateCode } = this.nextAppointmentModel;
            if (stateCode !== 'WAIT') {
                Modal.confirm({
                    title: 'Внимание!',
                    content: 'Эта запись уже подтверждена. Хотите продолжить?',
                    onOk: () => this.fireSaveEvent(),
                });
                return;
            }
            this.fireSaveEvent();
        },

        getSelectedName(appointment) {
            let doctor;
            let specialty;
            if (appointment.whom === 1 && appointment.staffUserId) {
                doctor = this.staffList.find(
                    ({ id }) => id === appointment.staffUserId,
                );
            } else if (appointment.whom === 2 && appointment.specialtyMedId) {
                specialty = this.specialtyList.find(
                    ({ id }) => id === appointment.specialtyMedId,
                );
            }
            return {
                specialtyMedName: specialty && specialty.name,
                staffUserName: doctor && doctor.name,
            };
        },

        getCommentText(day, specialtyMedName, staffUserName) {
            let name = '';
            if (staffUserName) {
                name = `Врач: ${staffUserName}`;
            } else if (specialtyMedName) {
                name = `Специальность: ${specialtyMedName}`;
            }
            return [
                'Запись на прием из АРМ Врача',
                `Оператор: ${localStorage.username}`,
                `Дата: ${day.format('DD.MM.YYYY')}`,
                name,
            ].join('\n');
        },
    },
};
</script>

<style lang="scss">
    .next-appointment {
        margin: 5px;
        .appointment-input {
            width: 250px;
        }
        .datepicker, .actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 5px;
        }
        .actions {
            padding-top: 22px;

            div button {
                margin: 5px;
            }
        }

        .selector {
            display: flex;
            height: 100%;
        }
        .appointment-staff-select > div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 90%;
            margin-top: 15px;
        }
        .appointment-staff,
        .appointment-staff > div {
            margin: 5px;
            display: flex;
            flex-direction: column;
        }
    }
</style>
