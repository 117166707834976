<template>
    <a-drawer
        title="Фильтры"
        placement="right"
        :closable="false"
        :visible="visible"
        class="filter-drawer"
        @close="onClose"
    >
        <a-row>
            <h3>
                Состояние/Статус:
            </h3>

            <States
                :value="filter.stateCode"
                @input="onChange"
            />
        </a-row>

        <a-row
            class="filter-drawer__footer"
        >
            <a-button
                type="default"
                @click="onReset"
            >
                Очистить все фильтры
            </a-button>

            <a-button
                type="primary"
                @click="onClose"
            >
                Закрыть
            </a-button>
        </a-row>
    </a-drawer>
</template>

<script>
import States from '@/components/comboboxes/States.vue';

export default {
    components: {
        States,
    },
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        filter: {
            type: Object,
            default: () => ({ stateCode: null }),
        },
    },

    emits: [
        'close',
        'filter',
        'reset',
    ],

    methods: {
        onClose() {
            this.$emit('close');
        },
        onChange(value) {
            this.$emit('filter', { ...this.filter, stateCode: value });
        },
        onReset() {
            this.$emit('reset');
        },
    },
};
</script>

<style lang="scss">
.filter-drawer {
  &__footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 24px;
   }

  .ant-row {
    height: unset;

    .ant-btn {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
</style>
