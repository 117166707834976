<template>
    <a-modal
        v-model:visible="visible"
        :mask-closable="false"
        title="Список черновиков"
        :width="1100"
        @ok="visible=false"
    >
        <div class="drafts-list">
            <div
                v-for="draft in drafts"
                :key="draft.id"
                class="drafts-list__item"
            >
                <div>{{ $moment(draft.timeEdit).format('DD.MM.YYYY HH:mm') }}</div>

                <div>№{{ draft.baseProtocolId || draft.id }}</div>

                <div class="draft-type">
                    {{ draft.autoSave ? 'Авто' : 'Ручное' }}
                </div>

                <div class="user-name">
                    {{ usersMap && usersMap[draft.userId]
                        ? usersMap[draft.userId].name
                        : ''
                    }}
                </div>

                <div class="draft-patient-name">
                    {{ patientsMap && patientsMap[draft.patientId]
                        ? patientsMap[draft.patientId].name
                        : ''
                    }}
                </div>

                <a-button
                    :disabled="isLoading"
                    data-test-id="draft-load-btn"
                    type="primary"
                    @click="loadDraft(draft.id)"
                >
                    Продолжить редактирование
                </a-button>
                <a-button
                    :disabled="isLoading"
                    type="primary"
                    danger
                    @click="deleteDraft(draft.id)"
                >
                    Удалить черновик
                </a-button>
            </div>
        </div>
        <a-pagination
            v-if="pagination.total > pagination.pageSize"
            :current="pagination.current"
            :total="pagination.total"
            :show-total="getTotalDescription"
            :page-size="pagination.pageSize"
            @change="onPaginationChange"
        />
        <div
            v-if="!drafts.length"
            style="text-align:center"
        >
            Список черновиков пуст
        </div>
        <template #footer>
            <a-button @click="visible = false">
                Закрыть
            </a-button>
        </template>
    </a-modal>
</template>

<script>
import { keyBy } from 'lodash';
import { Modal } from 'ant-design-vue';
import { createVNode } from 'vue';
import QuestionCircleOutlined from '@ant-design/icons-vue/QuestionCircleOutlined';
import { showErrorNotification } from '@/utils';

export default {
    data() {
        return {
            isLoading: false,
            drafts: [],
            patientsMap: null,
            usersMap: null,
            visible: false,
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
        };
    },
    methods: {
        async onPaginationChange(page) {
            this.pagination.current = page;
            await this.loadDraftsList();
            await this.loadPatients();
        },

        getTotalDescription(total, range) {
            return `${range[0]}-${range[1]} из ${total}`;
        },

        async open() {
            await this.loadDraftsList();
            if (!this.usersMap) {
                await this.loadUsers();
            }
            await this.loadPatients();
            this.visible = true;
        },

        async loadUsers() {
            this.isLoading = true;
            try {
                const users = await this.$http.get('/users');
                this.usersMap = keyBy(users.data, 'id');
            } catch (error) {
                showErrorNotification('Не удалось загрузить список пользователей.');
            } finally {
                this.isLoading = false;
            }
        },

        async loadPatients() {
            const draftPatients = this.drafts.map((draft) => draft.patientId);
            if (this.patientsMap
                && draftPatients.every((id) => this.patientsMap[id])
            ) {
                return;
            }

            this.isLoading = true;
            try {
                const patients = await this.$http.get(
                    '/patients',
                    {
                        params: {
                            ids: draftPatients,
                            lightweight: true,
                        },
                    },
                );
                this.patientsMap = {
                    ...(this.patientsMap || {}),
                    ...keyBy(patients.data, 'patientId'),
                };
            } catch (error) {
                showErrorNotification(
                    'Не удалось загрузить список пациентов.',
                );
            } finally {
                this.isLoading = false;
            }
        },
        async loadDraftsList() {
            const { current, pageSize } = this.pagination;
            this.isLoading = true;
            try {
                const drafts = await this.$http.get('/patient/protocol-drafts', {
                    params: {
                        page: JSON.stringify([current - 1, pageSize]),
                    },
                });
                const { count, data } = drafts.data;
                this.drafts = data;
                const totalPages = Math.ceil(count / pageSize) || 1;
                if (current > totalPages) {
                    this.pagination.current = 1;
                    await this.loadDraftsList();
                    return;
                }
                this.pagination.total = count;
            } catch (error) {
                showErrorNotification('Не удалось загрузить список черновиков.');
            } finally {
                this.isLoading = false;
            }
        },

        loadDraft(draftId) {
            const draft = this.drafts.find((d) => d.id === draftId);
            this.$router.push({
                name: 'draft',
                params: {
                    draftId,
                    protocolId: draft.baseProtocolId,
                },
            });
            this.visible = false;
        },

        async deleteDraft(draftId) {
            const isConfirmed = await new Promise((resolve) => {
                Modal.confirm({
                    title: 'Вы точно хотите удалить черновик?',
                    icon: createVNode(QuestionCircleOutlined),
                    okText: 'Да',
                    onOk() { resolve(true); },
                    onCancel() { resolve(false); },
                    width: 600,
                });
            });
            if (!isConfirmed) {
                return;
            }

            this.isLoading = true;
            try {
                await this.$http.delete(`/patient/protocol-draft/${draftId}`);
            } catch (error) {
                showErrorNotification('Не удалось удалить черновик.');
            } finally {
                this.isLoading = false;
            }
            await this.loadDraftsList();
        },
    },
};
</script>

<style lang="scss">
.drafts-list {
    &__item {
        gap: 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .user-name {
            width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .draft-patient-name {
            width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .draft-type {
            width: 100px;
        }
    }
}
</style>
