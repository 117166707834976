<template>
    <div class="main">
        <a-spin
            :spinning="patientListIsLoading || scheduleIsLoading"
            :indicator="loadingIndicator"
            tip="Загрузка..."
        >
            <div class="doctor-page">
                <page-header
                    :focus-trigger="searchFocusTrigger"
                    class="header"
                    @search="onPatientSearch"
                />
                <div
                    :class="{
                        'top-widget': showWidget === 'calendar',
                    }"
                    class="calendar"
                >
                    <div class="date-picker-wrapper calendar__date">
                        <a-date-picker
                            v-model:value="scheduleDateModel"
                            :allow-clear="false"
                            format="DD.MM.YYYY"
                        />
                    </div>
                    <Calendar
                        :appointments-list="appointmentsList"
                        :time-start="calendarTimeStart"
                        :time-end="calendarTimeEnd"
                        :selected-appointment-id="selectedAppointment
                            ? selectedAppointment.id
                            : 0
                        "
                        class="calendar__content"
                        @select-appointment="onSelectAppointment"
                    />
                    <a-button
                        :disabled="!selectedPatient && (
                            !selectedAppointment
                            || selectedAppointment.stateCode === 'FINISHED'
                        )"
                        type="primary"
                        class="calendar__start-button"
                        data-test-id="start-reception-btn"
                        @click="startReception"
                    >
                        Начать приём
                    </a-button>
                </div>
                <patient-list
                    :selected-patient="selectedPatient"
                    :list="patientList"
                    :pagination="patientListPagination"
                    :only-staff-patients="onlyStaffPatients"
                    :on-pagination-change="onPaginationChange"
                    :on-patient-selected="selectPatient"
                    :on-deselect-patient="deselectPatient"
                    :on-change-patient-affiliation="onChangePatientAffiliation"
                    :on-refresh="onRefresh"
                    :class="{
                        'top-widget': showWidget === 'patients',
                    }"
                    class="patients"
                />
                <div
                    :class="{
                        'top-widget': showWidget === 'timeline',
                    }"
                    class="timeline events-timeline main-events-timeline"
                >
                    <treatment-history
                        :patient-id="selectedPatient"
                        :only-staff-patients="onlyStaffPatients"
                    />
                </div>
                <div class="footer">
                    <a-button
                        type="primary"
                        class="footer__button"
                        @click="showCalendar"
                    >
                        <template #icon>
                            <calendar-outlined />
                        </template>
                    </a-button>
                    <a-button
                        type="primary"
                        class="footer__button footer__button-patients"
                        @click="showPatients"
                    >
                        <template #icon>
                            <team-outlined />
                        </template>
                    </a-button>
                    <a-button
                        type="primary"
                        class="footer__button footer__button-search"
                        @click="scrollToSearch"
                    >
                        <template #icon>
                            <search-outlined />
                        </template>
                    </a-button>
                    <a-button
                        type="primary"
                        class="footer__button"
                        @click="showTimeline"
                    >
                        <template #icon>
                            <notification-outlined />
                        </template>
                    </a-button>
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
import CalendarOutlined from '@ant-design/icons-vue/CalendarOutlined';
import TeamOutlined from '@ant-design/icons-vue/TeamOutlined';
import SearchOutlined from '@ant-design/icons-vue/SearchOutlined';
import NotificationOutlined from '@ant-design/icons-vue/NotificationOutlined';
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import PageHeader from '@/components/PageHeader.vue';
import Calendar from '@/components/Calendar.vue';
import PatientList from '@/components/PatientList.vue';
import TreatmentHistory from '@/components/patientcard/TreatmentHistory.vue';
import LoadingSpinner from '@/utils/Spinner.vue';
import { showErrorNotification } from '@/utils';

export default defineComponent({
    components: {
        PageHeader,
        Calendar,
        TreatmentHistory,
        // eslint-disable-next-line vue/no-unused-components
        LoadingSpinner,
        PatientList,
        TeamOutlined,
        SearchOutlined,
        NotificationOutlined,
        CalendarOutlined,
    },
    data() {
        return {
            selectedAppointment: null,
            selectedPatient: null,

            loadingIndicator: {
                template: '<loading-spinner />',
            },
            // small screen helpers
            showWidget: 'patients',
            searchFocusTrigger: 0,
        };
    },
    computed: {
        ...mapState('appointmentSchedule', {
            scheduleDate: 'forDate',
            appointmentsList: 'appointmentList',
            calendarTimeStart: 'workStartTime',
            calendarTimeEnd: 'workEndTime',
            scheduleIsLoading: 'fetching',
            scheduleError: 'error',
        }),

        ...mapState('patientList', {
            patientList: 'patientList',
            patientListPagination: 'pagination',
            onlyStaffPatients: 'currentUserPatientsOnly',
            patientListIsLoading: 'fetching',
            patientListError: 'error',
        }),

        error() {
            return this.scheduleError || this.patientListError;
        },

        // helper
        scheduleDateModel: {
            get() {
                // antd datepicker sets locale to the date
                // and therefore mutates value,
                // hence new instance creation is being used
                return this.$moment(this.scheduleDate.toDate());
            },
            set(value) {
                this.scheduleDateChangeHandler(value);
            },
        },
    },

    beforeMount() {
        const parsed = parseInt(localStorage.patientId, 10);
        // eslint-disable-next-line no-restricted-globals
        this.selectedPatient = isNaN(parsed) ? null : parsed;
    },
    mounted() {
        this.getDaySchedule();
        this.getPatients(localStorage.search);
        localStorage.patientActiveTab = 1;
        localStorage.patientName = null;
        this.scrollToMenuButtons();
    },

    activated() {
        this.deselectPatient();
        this.getPatients(localStorage.search);
        this.getDaySchedule();
    },

    methods: {
        ...mapActions('appointmentSchedule', [
            'setScheduleDate',
            'fetchSchedule',
        ]),

        ...mapActions('patientList', [
            'setCurrentUserPatientsOnly',
            'setPagination',
            'fetchPatients',
        ]),

        async getDaySchedule() {
            if (!this.scheduleDate) {
                return;
            }
            await this.fetchSchedule({
                clinicId: localStorage.clinics[0],
                staffUserId: localStorage.staffId,
                stateCodes: JSON.stringify(['SCHEDULED', 'FINISHED', 'STARTED']),
                date: this.scheduleDate.format('YYYY-MM-DD'),
            });
        },

        scheduleDateChangeHandler(date) {
            if (this.scheduleDate && date
                && !this.scheduleDate.diff(date, 'days')
            ) {
                return;
            }
            this.setScheduleDate(date);
            if (date) {
                this.getDaySchedule();
            }
        },

        scrollToMenuButtons() {
            window.scrollTo(0, 100);
        },

        scrollToSearch() {
            window.scrollTo(0, 0);
            this.searchFocusTrigger += 1;
            this.showPatients();
        },

        onRefresh() {
            this.getPatients(localStorage.search);
        },

        onPaginationChange(page) {
            this.setPagination({
                current: page,
            });
            this.getPatients(localStorage.search);
        },

        getPatients(query = null, additionalFilter = null) {
            let patientFilter = additionalFilter;
            if (additionalFilter === null) {
                const filters = (localStorage.filter !== undefined)
                    ? JSON.parse(localStorage.filter)
                    : {};
                patientFilter = filters;
            }

            this.fetchPatients({
                filters: {
                    createdByUser: localStorage.staffId,
                    lightweight: true,
                    doctorInChargeId: this.onlyStaffPatients
                        ? localStorage.staffId
                        : null,
                    ...patientFilter,
                },
                pagination: this.patientListPagination,
                search: query,
            });
        },
        resetPatient() {
            this.selectedPatient = null;
            this.setPagination({
                current: 1,
            });
        },
        onPatientSearch({ search, filter }) {
            this.resetPatient();
            this.getPatients(search, filter);
            this.showPatients();
        },
        onSelectAppointment(appointment, patientId) {
            this.selectedAppointment = appointment;
            this.selectedPatient = patientId;
        },
        selectPatient(id) {
            this.selectedPatient = id;
            localStorage.patientId = id;
            this.showTimeline();
        },
        deselectPatient() {
            this.selectedPatient = null;
            this.selectedAppointment = null;
            localStorage.patientId = null;
        },
        onChangePatientAffiliation(checked) {
            this.setCurrentUserPatientsOnly(checked);
            localStorage.onlyStaffPatients = checked;
            this.getPatients(localStorage.search);
        },

        startReception() {
            if (!this.selectedAppointment) {
                this.$router.push({
                    name: 'new-reception',
                    params: {
                        patientId: this.selectedPatient,
                    },
                });
                return;
            }
            if (this.selectedAppointment.stateCode === 'STARTED') {
                this.$router.push({
                    name: 'new-reception',
                    params: {
                        patientId: this.selectedPatient,
                        appointmentId: this.selectedAppointment.id,
                        protocolId: this.selectedAppointment.protocolId,
                    },
                });
            } else {
                const appointmentId = this.selectedAppointment.id;
                this.$http.put(`appointment/${appointmentId}/start`).then(() => {
                    this.$router.push({
                        name: 'new-reception',
                        params: {
                            patientId: this.selectedPatient,
                            appointmentId,
                        },
                    });
                }).catch((err) => {
                    showErrorNotification(err.response.data.msg);
                });
            }
        },

        showPatients() {
            this.showWidget = 'patients';
        },
        showCalendar() {
            this.showWidget = 'calendar';
        },
        showTimeline() {
            this.showWidget = 'timeline';
        },
    },
});
</script>

<style lang="scss">
$page-block-shadow: 0px 1px 2px 0px #3c40434d, 0px 2px 6px 2px #3c404326;

.main {
    height: 100%;
    text-align: left;

    .ant-spin {
        color: #046e66;
    }

    .patients {
        grid-area: patients;
        margin: 0 10px 0 0;
        padding-top: 10px;
        box-shadow: $page-block-shadow;
    }

    .calendar {
        grid-area: main;
        display: flex;
        flex-direction: column;
        box-shadow: $page-block-shadow;
        margin-bottom: 10px;
        background-color: #fff;
    }

    .calendar__date {
        margin: 10px;
        text-align: center;
    }

    .calendar__content {
        flex: 1 1 300px;
    }

    .calendar__start-button {
        margin: 10px;
    }

    .doctor-page {
        display: grid;
        height: 100vh;
        gap: 10px;
        grid:
            "header header" 64px
            "main patients" calc(50% - 42px)
            "main timeline" calc(50% - 42px)
            "footer footer" 0
            / 280px calc(100vw - 290px);
    }

    .header {
        grid-area: header;
    }

    .timeline {
        grid-area: timeline;
        margin: 0 10px 10px 0;
        box-shadow: $page-block-shadow;
        background-color: white;

        .ant-spin-nested-loading, .ant-spin-container {
            height: 100%;
        }
    }

    .footer {
        grid-area: footer;
        display: none;
    }

    .footer__button {
        flex: 1 1 50px;
        border-radius: 0;
        height: 100%;
    }

    .footer__button-patients {
        margin: 0 1px;
    }

    .footer__button-search {
        flex: 0 1 40px;
        margin: 0 1px 0 0;
    }

    @media screen and (max-width: 579px) {
        .doctor-page {
            grid:
                "header" 64px
                "main" calc(100vh - 104px)
                "footer" 40px
                / 1fr;
            gap: 0;
        }

        .footer {
            display: flex;
        }

        .patients, .timeline {
            grid-area: main;
            grid-row: 2 / 3;
        }

        .patients, .timeline, .calendar {
            box-shadow: none;
            margin: 0;
        }

        // disable button click animation to get rid of scrolls
        [ant-click-animating-without-extra-node]::after {
            -webkit-animation: none !important;
            -moz-animation: none !important;
            -o-animation: none !important;
            -ms-animation: none !important;
            animation: none !important;
        }

        .top-widget {
            z-index: 10;
        }
    }
}
</style>
