const buildQueryParams = ({ pagination, sort }) => {
    const params = {};
    if (pagination) {
        const { current, pageSize } = pagination;
        params.page = JSON.stringify([current - 1, pageSize]);
    }
    if (sort) {
        params.sort = JSON.stringify([{
            property: sort.property,
            direction: sort.direction,
        }]);
    }
    return params;
};

export default buildQueryParams;
