<template>
    <div>
        <div
            class="li-select"
            :class="{ disabled }"
            :data-id="id"
        >
            <div
                class="li-select-input-container"
                @click="(e) => $refs.input.focus()"
            >
                <input
                    ref="input"
                    class="li-select-input"
                    :placeholder="!selectedIds.length ? placeholder : ''"
                    type="text"
                    :disabled="(isOpen && multiple) || disabled"
                    @focus.prevent="open"
                    @input="debounceSearch"
                >
                <div class="li-select-selected-container">
                    <div
                        v-if="hideSelected"
                        style="cursor:pointer"
                        @click="open"
                    />
                    <div
                        v-if="!hideSelected"
                        style="cursor:pointer"
                        @click="open"
                    >
                        <div
                            v-for="selectedId in selectedIds"
                            :key="selectedId"
                            class="li-select-selected"
                            :style="getSelectedContainerStyle(selectedId)"
                            @click="open"
                        >
                            <template v-if="!isOpen">
                                {{ optionsMap[selectedId]
                                    ? optionsMap[selectedId][titleKey]
                                    : placeholder || null }}
                            </template>
                            <div
                                v-show="multiple"
                                class="li-select-remove"
                                @click.stop="onRemoveClick(selectedId)"
                            >
                                x
                            </div>
                        </div>
                    </div>
                    <div
                        class="clear"
                        :class="{ clear__opened: isOpen }"
                    >
                        <svg
                            v-if="selectedIds.length && clearButton"
                            viewBox="64 64 896 896"
                            data-icon="close-circle"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class=""
                            @click="onRemoveAllClick"
                        ><path :d="$options.CLEAR_ICON" /></svg>
                        <svg
                            v-if="!selectedIds.length || !clearButton"
                            viewBox="64 64 896 896"
                            data-icon="down"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            @click.stop.prevent="isOpen = !isOpen"
                        ><path :d="$options.DOWN_ICON" /></svg>
                    </div>
                </div>
            </div>
            <div
                v-if="!disabled"
                v-show="isOpen"
                class="li-select-list"
                :style="listStyle"
            >
                <input
                    v-if="multiple"
                    :value="searchList"
                    placeholder="Поиск"
                    class="li-select-input-list"
                    type="text"
                    @input="debounceSearchList"
                >
                <li-tree
                    v-slot="{ data }"
                    :nodes="filteredOptions"
                    editing
                    :id-key="idKey"
                    :parent-key="parentKey"
                >
                    <div
                        class="li-select-list-item"
                        :class="getListItemClass(data)"
                        @click="(e) => onOptionClick(e, data)"
                    >
                        <div
                            v-if="treePaths"
                            class="list-item__horizontal-path"
                        />
                        <slot
                            name="beforeOptions"
                            :data="data"
                            :options-group="optionsGroup"
                        />

                        <!-- eslint-disable vue/no-v-html -->
                        <span
                            v-if="optionsMap[data[idKey]]"
                            v-html="optionsMap[data[idKey]][titleKey]"
                        />
                        <!-- eslint-enable vue/no-v-html -->
                    </div>
                </li-tree>
                <div
                    v-show="!filteredOptions.length"
                    class="li-select-list__no-result"
                >
                    Нет данных
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { keyBy, groupBy, debounce } from 'lodash';
import { convertLayout, convertAlphabet } from '../../utils';

import LiTree from './LiTree/LiTree.vue';

export default {
    components: {
        LiTree,
    },
    CLEAR_ICON: 'M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z',
    DOWN_ICON: 'M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z',
    props: {
        selectedOptionsIds: {
            type: Array,
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        dropDownHeight: {
            type: Number,
            default: 300,
        },
        dropDownWidth: {
            type: String,
            default: '70vw',
        },
        options: {
            type: Array,
            default: () => [],
        },
        idKey: {
            type: String,
            default: 'id',
        },
        parentKey: {
            type: String,
            default: 'parentId',
        },
        titleKey: {
            type: String,
            default: 'title',
        },
        highlightGroups: {
            type: Boolean,
            default: true,
        },
        clearButton: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        hideSelected: {
            type: Boolean,
            default: false,
        },
        treePaths: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearOnSelect: {
            type: Boolean,
            default: false,
        },
    },

    emits: {
        select: null,
        change: null,
        'select-search': null,
    },

    data() {
        return {
            selectedIds: [],
            search: '',
            searchList: '',
            isOpen: false,
            id: Date.now(),
        };
    },
    computed: {
        selectedIdsMap() {
            return keyBy(this.selectedIds);
        },
        optionsPaths() {
            const calculatedPaths = this.options.map(
                (opt) => ({
                    [this.idKey]: opt[this.idKey],
                    path: this.calculatePath(opt, opt[this.titleKey]),
                }),
            );
            return keyBy(calculatedPaths, this.idKey);
        },
        optionsMap() {
            return keyBy(this.options, this.idKey);
        },
        optionsGroup() {
            return groupBy(this.optionsMap, 'parentId');
        },
        filteredOptions() {
            const search = this.search || this.searchList;
            if (search && search.length > 1) {
                const opts = this.options.filter((option) => {
                    let { path } = this.optionsPaths[option[this.idKey]];
                    path = path.toLowerCase();
                    return path.includes(search.toLowerCase())
                    || path.includes(convertAlphabet(search).toLowerCase())
                    || path.includes(convertLayout(search).toLowerCase());
                });
                const optsIds = opts.map((o) => o[this.idKey]);
                const additionalOpts = [];
                const addParents = (opt) => {
                    if (!opt[this.parentKey] || optsIds.includes(opt[this.parentKey])) {
                        return;
                    }
                    const optParent = this.optionsMap[opt[this.parentKey]];
                    if (optParent) {
                        additionalOpts.push(optParent);
                    }
                    optsIds.push(opt[this.parentKey]);
                    addParents(this.optionsMap[opt[this.idKey]]);
                };
                opts.forEach((opt) => addParents(opt));
                return opts.concat(additionalOpts);
            }
            return this.options;
        },
        listStyle() {
            return {
                maxHeight: `${this.dropDownHeight}px`,
                maxWidth: this.dropDownWidth,
            };
        },
    },
    watch: {
        selectedOptionsIds() {
            if (this.selectedOptionsIds === null) {
                this.selectedIds = [];
                return;
            }
            if (!Array.isArray(this.selectedOptionsIds)) {
                this.selectedIds = [this.selectedOptionsIds];
                return;
            }
            this.selectedIds = [...this.selectedOptionsIds];
        },
        isOpen() {
            if (this.isOpen) {
                this.$refs.input.focus();
                document.addEventListener('click', this.onOuterClick);
                document.addEventListener('keydown', this.onKeyDown);
                return;
            }
            document.removeEventListener('click', this.onOuterClick);
            document.removeEventListener('keydown', this.onKeyDown);
            if (this.hideSelected) {
                this.$emit('change', this.multiple ? this.selectedIds : this.selectedIds[0]);
                this.selectedIds = [];
            }
            this.$refs.input.blur();
            if (this.clearOnSelect) {
                this.$refs.input.value = '';
                this.search = '';
            }
        },
        search(value) {
            // NOTE: emit event after the popup is closed for performance reasons
            if (!this.isOpen) {
                setTimeout(() => this.$emit('select-search', ''), 100);
                return;
            }
            this.$emit('select-search', value);
        },
        searchList(value) {
            if (!this.isOpen) {
                setTimeout(() => this.$emit('select-search', ''), 100);
                return;
            }
            this.$emit('select-search', value);
        },
    },
    methods: {
        onKeyDown(e) {
            if (e.code !== 'Escape' && e.code !== 'Tab') {
                return;
            }
            this.isOpen = false;
        },

        getListItemClass(data) {
            return {
                'li-select-list-item_group': !this.isSelectable(data),
            };
        },

        getSelectedContainerStyle(selectedId) {
            return {
                borderBottom: this.multiple && this.selectedIdsMap[selectedId]
                    ? '1px solid #ddd'
                    : 'none',
                opacity: this.search && !this.multiple ? 0 : 1,
            };
        },
        calculatePath(d, path) {
            if (!this.optionsMap[d[this.parentKey]]) {
                return path;
            }
            return this.calculatePath(
                this.optionsMap[d[this.parentKey]],
                `${this.optionsMap[d[this.parentKey]][this.titleKey]} -> ${path}`,
            );
        },
        open(e) {
            if (this.disabled) {
                e.preventDefault();
                return;
            }
            this.isOpen = true;
        },
        onOuterClick(e) {
            if (!this.isOpen) {
                return;
            }
            if (e.target && !e.target.closest(`div.li-select[data-id="${this.id}"]`)) {
                this.isOpen = false;
            }
        },
        isSelectable(option) {
            if (typeof option.selectable === 'boolean') {
                return option.selectable;
            }
            return !this.optionsGroup[option[this.idKey]];
        },
        onOptionClick(e, data) {
            if (!this.isSelectable(data)) {
                e.preventDefault();
                return;
            }
            const id = data[this.idKey];
            if (!this.selectedIdsMap[id]) {
                if (this.multiple) {
                    this.selectedIds.push(id);
                } else {
                    this.selectedIds = [id];
                }
            } else {
                this.onRemoveClick(id);
            }
            if (this.clearOnSelect) {
                this.search = '';
                this.searchList = '';
            }

            const entry = this.optionsMap[id];
            this.$emit(
                'select',
                this.multiple ? this.selectedIds : this.selectedIds[0],
                this.multiple
                    ? undefined
                    : { ...entry, path: this.optionsPaths[id].path },
            );
            if (!this.multiple) {
                this.isOpen = false;
            }
        },
        onRemoveClick(selectedId) {
            const idx = this.selectedIds.indexOf(selectedId);
            if (idx === -1) {
                return;
            }
            this.selectedIds.splice(idx, 1);
            const entry = this.optionsMap[selectedId];
            this.$emit(
                'select',
                this.multiple ? this.selectedIds : selectedId,
                this.multiple
                    ? undefined
                    : { ...entry, path: this.optionsPaths[entry.id].path },
            );
        },
        onRemoveAllClick() {
            this.isOpen = false;
            this.selectedIds = [];
            this.$emit('select', this.multiple ? this.selectedIds : null);
        },
        debounceSearch: debounce(function _(e) {
            this.search = e.target.value;
        }, 500),
        debounceSearchList: debounce(function _(e) {
            this.searchList = e.target.value;
        }, 500),
    },
};
</script>

<style lang="scss">
.li-select {
    position: relative;
    max-width: 100%;

    .li-select-input-container {
        display: flex;
        flex-wrap: wrap;
        z-index: 4;
        position: relative;
        min-height: 30px;
        max-width: 100%;
    }
    .li-select-input {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
        height: 100%;
        min-height: 30px;
        padding: 5px 5px;
        z-index: -1;
        position: absolute;
        background: white;
    }
    .li-select-input::placeholder {
        color: #ccc;
    }
    .li-select-input-list {
        width: 99%;
        margin: 3px;
        border: 1px solid #ccc;
        border-radius: 3px;
        height: 30px;
        min-height: 30px;
        padding: 5px 5px;
        z-index: -1;
    }
    .li-select-input-list::placeholder {
        color: #ccc;
    }
    .li-select-input:hover {
        border: 1px solid rgb(65,169,254);
        border-radius: 3px;
    }
    .li-select-input:focus,
    .li-select-input:focus-visible,
    .li-select-input-list:focus,
    .li-select-input-list:focus-visible {
        border: 1px solid rgb(65,169,254);
        border-radius: 3px;
        box-shadow: 0px 0px 6px -2px rgba(34, 60, 80, 0.5);
        outline: none;
    }
    .li-select-selected-container {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        width: 100vw;
        max-width: 100vw;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        z-index: -1;

        & > div:nth-child(1) {
            max-width: calc(100% - 20px);
            flex-grow: 1;
        }
        div.clear {
            cursor: pointer;
            color: #ccc;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 20px;
            margin-right: 2px;
            font-weight: 800;
            transition: transform 0.2s;
        }
    }
    .clear__opened {
        transform: rotate(180deg);
    }
    .li-select-selected {
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 2px;
        padding-left: 5px;
        margin: 2px;
        flex-direction: row;
        justify-content: space-between;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        z-index: 1000;

        span {
            max-width: 80vw;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .li-select-remove {
        padding: 2px;
        cursor: pointer;
        padding: 0px 5px;
        color: #ccc;

        &:hover {
            color: #aaa;
        }
    }
    .li-select-list {
        background: #fff;
        border: 1px solid #eee;
        position: absolute;
        display: flex;
        z-index: 500;
        border-radius: 3px;
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 16px;
        flex-direction: column;
        box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    }
    .li-select-list__no-result {
        height: 100px;
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ccc;
    }
    .li-select-list-item {
        height: 30px;
        white-space: nowrap;
        padding: 5px;
        color: #555;

        &:hover {
            background-color: #e6f7ff;
            transition: background-color 0.3s;
        }
    }

    .li-select-list-item_group {
        color: #888;
        text-transform: uppercase;
        cursor: pointer;
    }

    .diagnoses-dropper {
        display: none;
    }

    .list-item__horizontal-path {
        border-top: 1px dotted #ccc;
        width: 15px;
        position: absolute;
        margin-left: -23px;
        top: 15px;
    }

    .li-tree-node {
        position: relative;
        margin-left: 15px;
    }

    .li-tree-node__vertical-path {
        border-left: 1px dotted #ccc;
        height: 100%;
        position: absolute;
        top: -15px;
        margin-left: -20px;
    }
}
</style>
