<template>
    <a-layout-header>
        <img
            class="logo"
            src="/images/impulse_tree.png"
            @click="goInMain"
        >

        <div class="spacer" />

        <a-input-search
            ref="searchInput"
            v-model:value="search"
            data-test-id="mainpage-search-input"
            :default-value="search"
            placeholder="Поиск"
            class="patient-search-input"
            @search="onSearch"
        />

        <a-button
            class="clear-input-btn"
            title="Очистить"
            @click="clearInput"
        >
            <template #icon>
                <close-outlined />
            </template>
        </a-button>

        <!-- NOTE: string with a space is to remove badge title -->
        <a-badge
            v-if="isDoctorApp && !filterDisabled"
            :count="filterCounter"
            title="  "
        >
            <a-button
                class="clear-input-btn filter-btn"
                title="Фильтр"
                @click="openDrawer"
            >
                <template #icon>
                    <filter-outlined />
                </template>
            </a-button>
        </a-badge>

        <div class="spacer" />

        <a-badge
            v-if="userHasAccessToNotifications"
            class="header__notification-badge"
        >
            <a-button
                data-test-id="drafts-btn"
                title="Показать черновики протоколов"
                @click="openDraftsList"
            >
                <template #icon>
                    <snippets-outlined />
                </template>
            </a-button>
        </a-badge>

        <chat-badge
            v-if="isChatUser"
            url="https://chat.lifeimpulse.net/"
            class="header__notification-badge"
            title="Новые сообщения"
        />

        <a-badge
            v-if="isDoctorApp"
            :count="notificationCount"
            title="  "
            class="header__notification-badge"
        >
            <a-button
                title="Открыть панель уведомлений"
                @click="onNotificationPanelOpenClick"
            >
                <template #icon>
                    <bell-outlined />
                </template>
            </a-button>
        </a-badge>

        <logout />

        <filter-drawer
            v-if="isDoctorApp && !filterDisabled"
            :visible="visibleDraw"
            :filter="filter"
            @close="onClose"
            @filter="onPatientFilter"
            @reset="onResetFilter"
        />

        <drafts-list ref="draftsList" />
    </a-layout-header>
</template>

<script>
import BellOutlined from '@ant-design/icons-vue/BellOutlined';
import FilterOutlined from '@ant-design/icons-vue/FilterOutlined';
import CloseOutlined from '@ant-design/icons-vue/CloseOutlined';
import SnippetsOutlined from '@ant-design/icons-vue/SnippetsOutlined';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import Logout from '@/components/Logout.vue';
import FilterDrawer from '@/components/drawers/FilterDrawer.vue';
import ChatBadge from '@/components/chat/ChatBadge.vue';
import DraftsList from '@/components/reception/DraftsList.vue';
import useUserRights from '@/utils/userRights';

export default defineComponent({
    components: {
        Logout,
        FilterDrawer,
        ChatBadge,
        BellOutlined,
        FilterOutlined,
        CloseOutlined,
        SnippetsOutlined,
        DraftsList,
    },
    props: {
        defValue: {
            type: String,
            default: '',
        },
        focusTrigger: {
            type: Number,
            default: 0,
        },
        filterDisabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['search', 'input', 'filter'],

    setup() {
        const { userRightsMap } = useUserRights();
        return {
            userRightsMap,
        };
    },

    data() {
        return {
            search: '',
            filter: {},
            visibleDraw: false,
            notificationCount: 0,
        };
    },
    computed: {
        ...mapState('appMode', {
            appMode: 'mode',
        }),

        userHasAccessToNotifications() {
            return !!(this.userRightsMap && this.userRightsMap.APPOINTMENT.r);
        },

        isChatUser() {
            return localStorage.getItem('chatUserId') !== 'null'
                && localStorage.getItem('chatUserToken') !== 'null';
        },

        isDoctorApp() {
            return this.appMode === 'doctor';
        },

        filterCounter() {
            return Object
                .keys(this.filter)
                .reduce((counter, index) => {
                    if (
                        this.filter[index] !== null
                        && this.filter[index] !== undefined
                    ) {
                        return counter + 1;
                    }
                    return counter;
                }, 0);
        },
    },
    watch: {
        search(newValue) {
            localStorage.search = newValue;
        },
        filter(newValue) {
            localStorage.filter = JSON.stringify(newValue);
        },
        focusTrigger(newValue) {
            if (!newValue) {
                return;
            }
            this.$refs.searchInput.focus();
        },
        userHasAccessToNotifications(hasAccess) {
            if (hasAccess) {
                this.startNotificationsRequestLoop();
            }
        },
    },

    mounted() {
        if (localStorage.search) {
            this.search = localStorage.search;
        }
        if (localStorage.filter) {
            this.filter = JSON.parse(localStorage.filter);
        }

        this.startNotificationsRequestLoop();
    },

    beforeUnmount() {
        if (this.ntfReqTimer) {
            clearInterval(this.ntfReqTimer);
        }
    },

    methods: {
        openDraftsList() {
            this.$refs.draftsList.open();
        },
        goInMain() {
            localStorage.patientId = null;
            localStorage.search = '';
            localStorage.filter = JSON.stringify({});
            this.$router.push({ name: 'main' });
        },
        onSearch(value) {
            this.$emit('search', { search: value, filter: this.filter });
            this.$emit('input', value);
        },
        onPatientFilter(filter) {
            this.filter = filter;
            this.$emit('search', { search: this.search, filter });
        },
        onResetFilter() {
            this.filter = {};
            this.$emit('filter', { search: '', filter: {} });
            this.clearInput();
            this.onClose();
        },
        clearInput() {
            this.search = '';
            this.onSearch(this.search);
        },
        openDrawer() {
            this.visibleDraw = true;
        },
        onClose() {
            this.visibleDraw = false;
        },

        onNotificationPanelOpenClick() {
            if (this.$route.path === '/notifications') {
                return;
            }
            this.$router.push({
                name: 'notifications',
            });
        },

        async startNotificationsRequestLoop() {
            if (!this.userHasAccessToNotifications) {
                return;
            }
            try {
                await this.doNotificationsRequest();
            } catch (err) {
                // maybe next time
            }
            if (this.ntfReqTimer) {
                clearInterval(this.ntfReqTimer);
            }
            this.ntfReqTimer = setInterval(
                () => this.doNotificationsRequest,
                30 * 1000,
            );
        },

        async doNotificationsRequest() {
            const response = await this.$http.get('/patient/doctor/notifications', {
                params: {
                    page: JSON.stringify([0, 1]),
                },
            });
            const { count } = response.data;
            this.notificationCount = count;
        },
    },
});
</script>

<style lang="scss">
.ant-layout-header {
    padding: 0 20px !important;
    background: #fff !important;
    box-shadow: 0px 1px 2px 0px #3c40434d, 0px 2px 6px 2px #3c404326;
    display: flex;
    align-items: center;

    .logo {
        height: 64px;
        width: 64px;
        float: left;
        padding: 10px;
        cursor: pointer;
    }

    .patient-search-input {
        flex: 1 1 25vw;
    }

    .spacer {
        flex: 1 1 1px;
    }

    .clear-input-btn {
        margin: 0 10px 0 5px;
    }

    .filter-btn {
        margin: 0;
    }
}
@media screen and (max-width: 579px) {
    .ant-layout-header {
        padding: 0 2px !important;
    }
}

.header__notification-badge {
    margin-right: 15px;
}

</style>
