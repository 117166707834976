<template>
    <div
        :class="itemClassObject"
        @click="selectPatient"
    >
        <div class="user-list-item-inner">
            <a-avatar
                :src="avatarSrc"
                size="large"
            >
                <template #icon>
                    <user-outlined
                        v-if="item.patientId"
                    />
                    <close-outlined
                        v-else
                    />
                </template>
            </a-avatar>
            <span class="user-list-item-name">
                {{ item.name }}
                <app-number-notification
                    v-if="item.receptionCount"
                    :number="item.receptionCount"
                    icon="tool"
                />
                <app-number-notification
                    v-if="item.inworkCount"
                    :number="item.inworkCount"
                    icon="experiment"
                />
            </span>
        </div>
    </div>
</template>

<script>
import UserOutlined from '@ant-design/icons-vue/UserOutlined';
import CloseOutlined from '@ant-design/icons-vue/CloseOutlined';
import AppNumberNotification from '@/views/AppNumberNotification.vue';

export default {
    components: {
        AppNumberNotification,
        UserOutlined,
        CloseOutlined,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },

    emits: [
        'select-patient-name',
        'select',
    ],

    computed: {
        itemClassObject() {
            return {
                'user-list-item': true,
                selected: this.selected,
                'is-empty': this.isEmpty,
            };
        },

        avatarSrc() {
            return this.item.previewFileId
                ? `api/patient/file/${this.item.previewFileId}`
                : '';
        },

        isEmpty() {
            return !this.item.patientId;
        },
    },

    watch: {
        selected(val) {
            if (val) {
                this.$emit('select-patient-name', this.item);
            }
        },
    },

    methods: {
        selectPatient() {
            this.$emit('select', this.item.patientId);
        },
    },
};
</script>

<style lang="scss">
.user-list-item {
    padding: 0 10px;
    cursor: pointer;
    background-color: #fff;
    transition: background 0.3s;

    .user-list-item-inner {
        border-bottom: #e8e8e8 dashed 1px;
        display: grid;
        grid-template-columns: 40px auto;
        text-align: left;
        height: 65px;
        padding: 10px 0;
    }
    .ant-avatar {
        width: 40px;
        height: 40px;
    }
    .user-list-item-name {
        padding-left: 8px;
    }

    &.is-empty .user-list-item-name {
        padding: 10px;
    }

    &.selected {
        background-color: #d6f39f;
    }
}

.hover .user-list-item,
.user-list-item:hover {
    background-color: #efffdf;
}

</style>
